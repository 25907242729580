import { PlacesKinds, StatesCodes } from './resources/data/AutoCompleteData'
import { StatesNamesData } from './resources/data/StatesNamesData'

class AutoComplete {
  // In:  A code index is a number
  //      [0 … stateindex] represents a city
  //      [stateindex+1 … ] represents a county
  // Out: the string ending, like ' county, California' for a county
  public CodeIndexToString(index: number): string {
    const codes = this.StatesCodes()
    const count = codes.length
    if (index > 0 && index <= count) {
      return `, ${this.StateName(codes[index - 1])}`
    }
    index = -index
    if (index > 0 && index <= count) {
      return ` County, ${this.StateName(codes[index - 1])}`
    }
    return ''
  }

  // Out: Converts the string of 'ST,…' into an array of 'ST'
  private StatesCodes(): string[] {
    const codesList = StatesCodes.split(/,/)
    return codesList
  }

  // In: a string with spaces, numbers
  // Out: converts into an upper alphabetical only
  public Normalize(text: string): string {
    return text.toUpperCase().replace(/[^A-Z]/g, '')
  }

  // Out: A dictionary of [normalized names:plain English names]
  public NormalizedPlaces(): { [fuzz: string]: string } {
    var normalized: { [fuzz: string]: string } = {}
    for (const placeName in PlacesKinds) {
      normalized[this.Normalize(placeName)] = placeName
    }
    return normalized
  }

  // In : state code, like CA
  // Out: state name, like California, or state code if not found
  // O(n)
  public StateName(stateCode: string): string {
    const stateRecord = StatesNamesData.find((stateNameData) => stateNameData.id === stateCode)
    return stateRecord ? stateRecord.name : stateCode
  }

  // In : string (exact match)
  // Out: list of suggestions
  public Suggestions(text: string): string[] {
    var result: string[] = []
    const normalizedPlaces = this.NormalizedPlaces()

    const county: string = 'county'
    var countiesOnly: boolean = text.toLowerCase().endsWith(county)
    if (countiesOnly) {
      text = text.substring(0, text.length - 1 - county.length)
    }
    const plainCriteria = normalizedPlaces[this.Normalize(text)]
    const codesIndexes: number[] | null = PlacesKinds[plainCriteria]
    if (codesIndexes != null) {
      codesIndexes.forEach((codeIndex: number) => {
        const suggestion = this.CodeIndexToString(codeIndex)
        if (countiesOnly) {
          if (suggestion.toLowerCase().indexOf(county) !== -1) {
            result.push(`${plainCriteria}${suggestion}`)
          }
        } else {
          result.push(`${plainCriteria}${suggestion}`)
        }
      })
      result.sort()
    }

    return result
  }

  public static NormalizeAirportCode(code: string): string {
    const essence: string = code?.trim().toUpperCase() ?? ''
    const noDiacritics = this.RemoveDiacritics(essence)
    return noDiacritics.replace(/[^A-Z]+/g, '')
  }

  public static NormalizeStateCode(stateCode: string): string {
    return this.NormalizeAirportCode(stateCode).substring(0, 2)
  }

  public static NormalizedCode(normalizeStateCode: string, compactedName: string): string {
    return `${normalizeStateCode}-${compactedName}`
  }

  // In: a string with spaces, numbers
  // Out: converts into an upper alphabetical only
  public static CompactName(displayName: string): string {
    const abbreviated = displayName.replace(/\s+/g, ' ').trim().toLowerCase().replace('sainte', 'ste').replace('saint', 'st').replace('mount', 'mt')

    const noDiacritics = this.RemoveDiacritics(abbreviated)
    return noDiacritics.replace(/[^a-z]+/g, '')
  }

  private static RemoveDiacritics(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
}
export { AutoComplete }
