const PlacesKinds: { [state: string]: number[] } = {
  Aaronsburg: [39],
  Abbeville: [2, 11, 19, 26, 41, -41],
  Abbot: [22],
  Abbotsford: [49],
  Abbott: [44],
  Abbottstown: [39],
  Abbyville: [17],
  Abell: [21],
  Abercrombie: [29],
  Aberdeen: [14, 18, 21, 26, 28, 36, 42, 48],
  'Aberdeen Proving Ground': [21],
  Abernant: [2],
  Abernathy: [44],
  Abie: [30],
  Abilene: [17, 44],
  Abingdon: [15, 21, 46],
  Abington: [7, 20, 39],
  Abiquiu: [33],
  'Abita Springs': [19],
  Abrams: [49],
  Absaraka: [29],
  Absarokee: [27],
  Absecon: [32],
  Acadia: [-19],
  Acampo: [5],
  Accident: [21],
  Accokeek: [21],
  Accomac: [46],
  Accomack: [-46],
  Accord: [20, 35],
  Accoville: [50],
  Ace: [44],
  Achille: [37],
  Achilles: [46],
  Ackerly: [44],
  Ackerman: [26],
  Ackley: [13],
  Ackworth: [13],
  Acme: [19, 23, 39, 48],
  Acosta: [39],
  Acra: [35],
  Acton: [5, 20, 22, 27],
  Acushnet: [20],
  Acworth: [11, 31],
  Ada: [-14, 23, 24, 36, 37],
  Adah: [39],
  Adair: [13, -13, 15, -18, -25, 37, -37],
  Adairsville: [11],
  Adairville: [18],
  Adak: [1],
  Adamant: [47],
  Adams: [-6, -13, -14, -15, -16, 18, 20, 24, -26, 29, -29, 30, -30, 35, -36, 37, 38, -39, 43, -48, 49, -49],
  'Adams Basin': [35],
  'Adams Center': [35],
  'Adams Run': [41],
  Adamsburg: [39],
  Adamstown: [21, 39],
  Adamsville: [2, 36, 39, 40, 43],
  Addieville: [15],
  Addington: [37],
  Addis: [19],
  Addison: [2, 15, 22, 23, 35, 39, 44, -47],
  Addy: [48],
  Addyston: [36],
  Adel: [11, 13, 38],
  Adelanto: [5],
  Adell: [49],
  Adelphi: [36],
  Adelphia: [32],
  Adena: [36],
  Adger: [2],
  Adin: [5],
  Adirondack: [35],
  Adkins: [44],
  Admire: [17],
  Adna: [48],
  Adolphus: [18],
  Adona: [3],
  Adrian: [11, 23, 24, 25, 38, 39, 44, 50],
  Advance: [16, 25, 28],
  Advent: [50],
  Afton: [13, 23, 24, 35, 37, 43, 44, 46, 49, 51],
  Agar: [42],
  Agate: [6, 29],
  Agawam: [20],
  Agency: [13, 25],
  Agenda: [17],
  'Ages Brookside': [18],
  Agness: [38],
  'Agoura Hills': [5],
  Agra: [17, 37],
  'Agua Dulce': [44],
  Aguanga: [5],
  Aguila: [4],
  Aguilar: [6],
  Ahmeek: [23],
  Ahoskie: [28],
  Ahsahka: [14],
  Ahwahnee: [5],
  Aiea: [12],
  Aiken: [41, -41, 44],
  Ailey: [11],
  Aimwell: [19],
  Ainsworth: [13, 30],
  Airmont: [35],
  Airville: [39],
  'Airway Heights': [48],
  Aitkin: [24, -24],
  Ajo: [4],
  Akaska: [42],
  Akeley: [24],
  Akers: [19],
  Akiachak: [1],
  Akiak: [1],
  Akron: [2, 6, 13, 16, 23, 35, 36, 39],
  Akutan: [1],
  Alabaster: [2],
  Alachua: [10, -10],
  Aladdin: [51],
  Alakanuk: [1],
  Alamance: [28, -28],
  Alameda: [5, -5],
  Alamo: [5, 11, 16, 29, 34, 43, 44],
  Alamogordo: [33],
  Alamosa: [6, -6],
  Alanreed: [44],
  Alanson: [23],
  Alapaha: [11],
  Alba: [23, 25, 39, 44],
  Albany: [5, 11, 15, 16, 18, 19, 24, 25, 35, -35, 36, 37, 38, 44, 47, 49, -51],
  Albemarle: [28, -46],
  Albers: [15],
  Albert: [17, 37],
  'Albert City': [13],
  'Albert Lea': [24],
  Alberta: [2, 24, 46],
  Alberton: [27],
  Albertson: [28, 35],
  Albertville: [2, 24],
  Albia: [13],
  Albin: [51],
  Albion: [5, 13, 14, 15, 16, 22, 23, 30, 35, 37, 39, 40, 48],
  Alborn: [24],
  Albright: [50],
  Albrightsville: [39],
  Albuquerque: [33],
  Alburgh: [47],
  Alburnett: [13],
  Alburtis: [39],
  Alcalde: [33],
  Alcester: [42],
  Alcoa: [43],
  Alcolu: [41],
  Alcona: [-23],
  Alcorn: [-26],
  Alcova: [51],
  Alcove: [35],
  Alda: [30],
  Alden: [13, 17, 23, 24, 35],
  Alder: [27],
  'Alder Creek': [35],
  Alderpoint: [5],
  Alderson: [37, 50],
  Aldie: [46],
  Aldrich: [24, 25],
  Aledo: [15, 44],
  Aleknagik: [1],
  Aleppo: [39],
  'Aleutians East': [-1],
  'Aleutians West': [-1],
  Alex: [37],
  Alexander: [3, 13, 15, -15, 17, 28, -28, 29, 35],
  'Alexander City': [2],
  Alexandria: [2, 16, 18, 19, 24, 25, 30, 36, 39, 42, 43, 46],
  'Alexandria Bay': [35],
  Alexis: [15, 28],
  Alfalfa: [-37],
  Alford: [10],
  Alfred: [22, 35],
  'Alfred Station': [35],
  Alger: [23, -23, 36],
  Algodones: [33],
  Algoma: [26, 49],
  Algona: [13],
  Algonac: [23],
  Algonquin: [15],
  Alhambra: [5, 15],
  Alice: [44],
  Aliceville: [2],
  Alicia: [3],
  Alief: [44],
  Aline: [37],
  Aliquippa: [39],
  'Aliso Viejo': [5],
  Alix: [3],
  Alkol: [50],
  Allakaket: [1],
  Allamakee: [-13],
  Allamuchy: [32],
  Allardt: [43],
  Alledonia: [36],
  Alleene: [3],
  Allegan: [23, -23],
  Allegany: [-21, 35, -35, 38],
  Alleghany: [5, -28, -46],
  Allegheny: [-39],
  Alleman: [13],
  Allen: [-16, 17, -17, 18, -18, -19, 21, 23, 30, -36, 37, 42, 44],
  'Allen Junction': [50],
  'Allen Park': [23],
  Allendale: [15, 23, 25, 32, 41, -41],
  Allenhurst: [11, 32],
  Allenport: [39],
  Allenspark: [6],
  Allensville: [18, 39],
  Allenton: [23, 49],
  Allentown: [11, 32, 35, 39],
  Allenwood: [32, 39],
  Allerton: [13, 15],
  Alleyton: [44],
  Allgood: [2],
  Alliance: [28, 30, 36],
  Alligator: [26],
  'Alligator Point': [10],
  Allison: [13, 39, 44],
  'Allison Park': [39],
  Allons: [43],
  Allouez: [23],
  Alloway: [32],
  Alloy: [50],
  Allport: [39],
  Allred: [43],
  Allston: [20],
  Allyn: [48],
  Alma: [3, 6, 11, 15, 17, 23, 25, 30, 35, 49, 50],
  'Alma Center': [49],
  Almena: [17, 49],
  Almira: [48],
  Almo: [14, 18],
  Almond: [28, 35, 49],
  Almont: [6, 23, 29],
  Almyra: [3],
  Alna: [22],
  Alpaugh: [5],
  Alpena: [3, 23, -23, 42],
  Alpha: [15, 18, 23, 24, 36],
  Alpharetta: [11],
  Alpine: [2, 3, 4, 5, -5, 32, 35, 43, 44, 45, 51],
  Alplaus: [35],
  Alsea: [38],
  Alsen: [29],
  Alsey: [15],
  Alsip: [15],
  Alstead: [31],
  Alston: [11],
  Alta: [5, 13, 51],
  'Alta Vista': [13, 17],
  Altadena: [5],
  Altair: [44],
  Altamahaw: [28],
  Altamont: [15, 17, 25, 35, 43, 45],
  'Altamonte Springs': [10],
  Altaville: [5],
  Altavista: [46],
  Altenburg: [25],
  Altha: [10],
  Altheimer: [3],
  Altmar: [35],
  Alto: [11, 23, 33, 44],
  'Alto Pass': [15],
  Alton: [2, 13, 15, 17, 25, 31, 35, 45, 46],
  'Alton Bay': [31],
  Altona: [15, 35],
  Altonah: [45],
  Altoona: [2, 10, 13, 17, 39, 49],
  Altura: [24],
  Alturas: [5, 10],
  Altus: [3, 37],
  'Altus Afb': [37],
  'Alum Bank': [39],
  'Alum Bridge': [50],
  'Alum Creek': [50],
  Alva: [10, 37, 51],
  Alvada: [36],
  Alvadore: [38],
  Alvarado: [24, 44],
  Alvaton: [18],
  Alverda: [39],
  Alverton: [39],
  Alvin: [15, 44],
  Alviso: [5],
  Alvo: [30],
  Alvord: [13, 44],
  Alvordton: [36],
  Alzada: [27],
  Ama: [19],
  Amado: [4],
  Amador: [-5],
  'Amador City': [5],
  Amagansett: [35],
  Amagon: [3],
  Amalia: [33],
  Amana: [13],
  Amanda: [36],
  'Amanda Park': [48],
  'Amargosa Valley': [34],
  Amarillo: [44],
  Amasa: [23],
  Amawalk: [35],
  Amazonia: [25],
  Amber: [37],
  Amberg: [49],
  Amberson: [39],
  Ambia: [16],
  Ambler: [1, 39],
  Amboy: [5, 15, 16, 24, 48],
  Ambridge: [39],
  Ambrose: [11, 29],
  Amelia: [19, 30, 36, -46],
  'Amelia Court House': [46],
  Amenia: [29, 35],
  'American Canyon': [5],
  'American Falls': [14],
  'American Fork': [45],
  Americus: [11, 17],
  Amery: [49],
  Ames: [13, 30, 37],
  Amesbury: [20],
  Amesville: [36],
  Amherst: [6, 20, 30, 31, 35, 36, 42, 44, 46, -46, 49],
  'Amherst Junction': [49],
  Amherstdale: [50],
  Amidon: [29],
  Amigo: [50],
  Amissville: [46],
  Amistad: [33],
  Amite: [19, -26],
  Amity: [3, 25, 38, 39],
  Amityville: [35],
  Amlin: [36],
  Amma: [50],
  Ammon: [46],
  Amo: [16],
  Amonate: [46],
  Amoret: [25],
  Amorita: [37],
  Amory: [26],
  Amsterdam: [25, 35, 36],
  Amston: [7],
  Anabel: [25],
  Anacoco: [19],
  Anaconda: [27],
  Anacortes: [48],
  Anadarko: [37],
  Anaheim: [5],
  Anahola: [12],
  Anahuac: [44],
  'Anaktuvuk Pass': [1],
  Analomink: [39],
  Anamoose: [29],
  Anamosa: [13],
  Anatone: [48],
  Anawalt: [50],
  Anchor: [15],
  'Anchor Point': [1],
  Anchorage: [1, -1],
  Anchorville: [23],
  Ancona: [15],
  Ancram: [35],
  Ancramdale: [35],
  Andale: [17],
  Andalusia: [2, 15],
  Anderson: [1, 2, 5, 16, -17, -18, 25, 41, -41, -43, 44, -44],
  'Anderson Island': [48],
  Andersonville: [11, 43],
  Andes: [35],
  Andover: [7, 13, 15, 17, 20, 22, 24, 31, 32, 35, 36, 42, 46],
  Andreas: [39],
  Andrew: [13, -25],
  Andrews: [16, 28, 41, 44, -44],
  'Andrews Air Force Base': [21],
  Androscoggin: [-22],
  Aneta: [29],
  Aneth: [45],
  'Angel Fire': [33],
  Angela: [27],
  Angelica: [35],
  Angelina: [-44],
  'Angels Camp': [5],
  'Angelus Oaks': [5],
  Angie: [19],
  Angier: [28],
  'Angle Inlet': [24],
  Angleton: [44],
  Angola: [16, 19, 35],
  Angoon: [1],
  Angora: [24, 30],
  Anguilla: [26],
  Angwin: [5],
  Aniak: [1],
  Animas: [33],
  Anita: [13, 39],
  Aniwa: [49],
  Ankeny: [13],
  Anmoore: [50],
  'Ann Arbor': [23],
  Anna: [15, 36, 44],
  'Anna Maria': [10],
  Annabella: [45],
  Annada: [25],
  Annandale: [24, 32, 46],
  'Annandale On Hudson': [35],
  Annapolis: [5, 15, 21, 25],
  'Annapolis Junction': [21],
  Annawan: [15],
  'Anne Arundel': [-21],
  Anniston: [2, 25],
  Annona: [44],
  Annville: [18, 39],
  Anoka: [24, -24],
  Anselmo: [30],
  Ansley: [30],
  Anson: [22, -28, 44],
  Ansonia: [7, 36],
  Ansonville: [28],
  Ansted: [50],
  Antelope: [5, 27, -30, 38],
  'Antes Fort': [39],
  Anthem: [4],
  Anthon: [13],
  Anthony: [10, 17, 33, 44],
  Antigo: [49],
  Antimony: [45],
  Antioch: [5, 15, 43],
  Antler: [29],
  Antlers: [37],
  Antoine: [3],
  Anton: [6, 44],
  'Anton Chico': [33],
  Antonito: [6],
  Antrim: [-23, 31],
  Antwerp: [35, 36],
  Anvik: [1],
  Anza: [5],
  Apache: [-4, 37],
  'Apache Junction': [4],
  Apalachicola: [10],
  Apalachin: [35],
  Apex: [28],
  Apison: [43],
  Aplington: [13],
  Apollo: [39],
  'Apollo Beach': [10],
  Apopka: [10],
  Appalachia: [46],
  Appanoose: [-13],
  'Apple Creek': [36],
  'Apple Grove': [50],
  'Apple River': [15],
  'Apple Springs': [44],
  'Apple Valley': [5, 24],
  Applegate: [5, 23],
  Appleton: [24, 35, 48, 49],
  'Appleton City': [25],
  Appling: [11, -11],
  Appomattox: [46, -46],
  Aptos: [5],
  'Apulia Station': [35],
  Aquasco: [21],
  Aquashicola: [39],
  Aquebogue: [35],
  Aquilla: [44],
  Arab: [2],
  Arabi: [11, 19],
  Aragon: [11, 33],
  Aransas: [-44],
  'Aransas Pass': [44],
  Arapaho: [37],
  Arapahoe: [6, -6, 28, 30, 51],
  Ararat: [28, 46],
  Arbela: [25],
  Arboga: [5],
  Arboles: [6],
  Arbon: [14],
  'Arbor Vitae': [49],
  Arbovale: [50],
  Arbuckle: [5],
  Arbyrd: [25],
  Arcade: [35],
  Arcadia: [5, 10, 13, 16, 17, 19, 23, 25, 30, 36, 37, 39, 41, 49],
  Arcanum: [36],
  Arcata: [5],
  'Arch Cape': [38],
  Archbald: [39],
  Archbold: [36],
  Archdale: [28],
  Archer: [10, 13, 30, -44],
  'Archer City': [44],
  Archibald: [19],
  Archie: [25],
  Archuleta: [-6],
  Arco: [14, 24],
  Arcola: [15, 16, 25, 26],
  'Arctic Village': [1],
  Ardara: [39],
  Arden: [28, 35],
  Ardenvoir: [48],
  Ardmore: [2, 37, 39, 43],
  Ardsley: [35],
  'Ardsley On Hudson': [35],
  Aredale: [13],
  Arena: [49],
  Arenac: [-23],
  'Arenas Valley': [33],
  Arendtsville: [39],
  Arenzville: [15],
  Argenta: [15],
  Argillite: [18],
  Argonia: [17],
  Argonne: [49],
  Argos: [16],
  Argusville: [29],
  Argyle: [10, 11, 13, 23, 24, 25, 35, 44, 49],
  Ariel: [48],
  Arimo: [14],
  Arion: [13],
  Aripeka: [10],
  Arispe: [13],
  Aristes: [39],
  Ariton: [2],
  Arivaca: [4],
  'Arizona City': [4],
  Arjay: [18],
  Ark: [46],
  Arkabutla: [26],
  Arkadelphia: [3],
  Arkansas: [-3],
  'Arkansas City': [3, 17],
  Arkansaw: [49],
  Arkdale: [49],
  Arkoma: [37],
  Arkport: [35],
  Arkville: [35],
  Arlee: [27],
  Arley: [2],
  Arlington: [2, 4, 6, 11, 13, 15, 16, 17, 18, 20, 24, 30, 36, 38, 42, 43, 44, 46, -46, 47, 48, 49],
  'Arlington Heights': [15, 20],
  Arma: [17],
  Armada: [23],
  Armagh: [39],
  Armbrust: [39],
  Armington: [15],
  Arminto: [51],
  Armona: [5],
  Armonk: [35],
  Armorel: [3],
  Armour: [42],
  Armstrong: [13, 15, 25, -39, 44, -44],
  'Armstrong Creek': [49],
  Armuchee: [11],
  Arnaudville: [19],
  Arnegard: [29],
  Arnett: [37, 50],
  Arnold: [5, 17, 21, 23, 25, 30],
  'Arnold Afb': [43],
  'Arnolds Park': [13],
  Arnoldsburg: [50],
  Arnoldsville: [11],
  Arnot: [39],
  Arock: [38],
  Aroda: [46],
  'Aroma Park': [15],
  Aromas: [5],
  Arona: [39],
  Aroostook: [-22],
  Arp: [44],
  Arpin: [49],
  Arrey: [33],
  Arriba: [6],
  Arrington: [43, 46],
  'Arrow Rock': [25],
  Arrowsmith: [15],
  'Arroyo Grande': [5],
  'Arroyo Hondo': [33],
  'Arroyo Seco': [33],
  Art: [44],
  Artemas: [39],
  Artemus: [18],
  Artesia: [5, 26, 33],
  'Artesia Wells': [44],
  Artesian: [42],
  Arthur: [13, 15, 29, 30, -30, 43],
  'Arthur City': [44],
  Arthurdale: [50],
  Artie: [50],
  Artois: [5],
  Arvada: [6, 51],
  Arverne: [35],
  Arvilla: [29],
  Arvin: [5],
  Arvonia: [46],
  Ary: [18],
  Asbury: [13, 25, 32, 50],
  'Asbury Park': [32],
  Ascension: [-19],
  Ascutney: [47],
  Ash: [28],
  'Ash Flat': [3],
  'Ash Fork': [4],
  'Ash Grove': [25],
  Ashaway: [40],
  Ashburn: [11, 25, 46],
  Ashburnham: [20],
  Ashby: [20, 24, 30],
  Ashcamp: [18],
  Ashdown: [3],
  Ashe: [-28],
  Asheboro: [28],
  Asher: [18, 37],
  Asherton: [44],
  Asheville: [28],
  Ashfield: [20, 39],
  Ashford: [2, 7, 48, 50],
  Ashippun: [49],
  Ashkum: [15],
  Ashland: [2, 15, 17, 18, 19, 20, 22, 25, 26, 27, 30, 31, 35, 36, -36, 38, 39, 46, 49, -49],
  'Ashland City': [43],
  Ashley: [-3, 15, 16, 23, 29, 36],
  'Ashley Falls': [20],
  Ashmore: [15],
  Ashtabula: [36, -36],
  Ashton: [13, 14, 15, 21, 30, 42, 50],
  Ashuelot: [31],
  Ashville: [2, 35, 36, 39],
  Ashwood: [38],
  Askov: [24],
  Asotin: [48, -48],
  Aspen: [6],
  Aspermont: [44],
  Aspers: [39],
  Aspinwall: [13],
  Assaria: [17],
  Assawoman: [46],
  Assonet: [20],
  Assumption: [15, -19],
  Astatula: [10],
  Aston: [39],
  Astor: [10],
  Astoria: [15, 35, 38, 42],
  Atalissa: [13],
  Atascadero: [5],
  Atascosa: [44, -44],
  Atchison: [17, -17, -25],
  Atco: [32],
  Atglen: [39],
  Athelstane: [49],
  Athena: [38],
  Athens: [2, 11, 15, 16, 19, 22, 23, 35, 36, -36, 39, 43, 44, 49, 50],
  'Athens-Clarke': [-11],
  Atherton: [5],
  Athol: [14, 17, 20, 35],
  'Athol Springs': [35],
  Atka: [1],
  Atkins: [3, 13, 46],
  Atkinson: [-11, 15, 28, 30, 31],
  Atlanta: [11, 15, 16, 17, 19, 23, 25, 30, 35, 44],
  Atlantic: [13, 28, -32, 39, 46],
  'Atlantic Beach': [10, 28, 35],
  'Atlantic City': [32],
  'Atlantic Highlands': [32],
  'Atlantic Mine': [23],
  Atlas: [23],
  Atlasburg: [39],
  Atmore: [2],
  Atoka: [37, -37, 43],
  'Atomic City': [14],
  Atqasuk: [1],
  Attala: [-26],
  Attalla: [2],
  Attapulgus: [11],
  Attica: [16, 17, 23, 35, 36],
  Attleboro: [20],
  'Attleboro Falls': [20],
  Atwater: [5, 24, 36],
  Atwood: [5, 6, 15, 16, 17, 37, 43],
  'Au Gres': [23],
  'Au Sable Forks': [35],
  'Au Train': [23],
  Auberry: [5],
  Aubrey: [3, 44],
  Auburn: [2, 5, 11, 13, 15, 16, 17, 18, 20, 22, 23, 30, 31, 35, 39, 48, 50, 51],
  'Auburn Hills': [23],
  'Auburn University': [2],
  Auburndale: [10, 20, 49],
  Auburntown: [43],
  Audrain: [-25],
  Audubon: [13, -13, 24, 32, 39],
  Auglaize: [-36],
  Augusta: [3, 11, 15, 17, 18, 22, 23, 25, 27, 32, 36, -46, 49, 50],
  'Augusta Springs': [46],
  'Augusta-Richmond': [-11],
  'Auke Bay': [1],
  Aulander: [28],
  Ault: [6],
  Aultman: [39],
  Aumsville: [38],
  Aurelia: [13],
  Auriesville: [35],
  Aurora: [6, 13, 15, 16, 17, 22, 24, 25, 28, 30, 35, 36, 38, 42, -42, 45, 50],
  Austell: [11],
  Austerlitz: [35],
  Austin: [3, 6, 16, 18, 24, 34, 39, 44, -44],
  Austinburg: [36],
  Austinville: [13, 46],
  Austwell: [44],
  Autauga: [-2],
  Autaugaville: [2],
  Autryville: [28],
  Auxier: [18],
  Auxvasse: [25],
  Ava: [15, 25, 35, 36],
  Avalon: [5, 32, 44, 49],
  Avant: [37],
  Avawam: [18],
  Avella: [39],
  Avenal: [5],
  Avenel: [32],
  Aventura: [10],
  Avenue: [21],
  Avera: [11],
  Averill: [47],
  'Averill Park': [35],
  Avery: [5, 14, -28, 44],
  'Avery Island': [19],
  'Avila Beach': [5],
  Avilla: [16, 25],
  Avinger: [44],
  Avis: [39],
  Aviston: [15],
  Avoca: [3, 13, 16, 23, 24, 30, 35, 44, 49],
  Avon: [6, 7, 15, 16, 20, 24, 26, 27, 28, 35, 36, 42],
  'Avon By The Sea': [32],
  'Avon Lake': [36],
  'Avon Park': [10],
  Avondale: [4, 6, 39, 50],
  'Avondale Estates': [11],
  Avonmore: [39],
  Avoyelles: [-19],
  Awendaw: [41],
  Axis: [2],
  Axson: [11],
  Axtell: [17, 30, 44, 45],
  Axton: [46],
  Ayden: [28],
  Aydlett: [28],
  Ayer: [20],
  Aylett: [46],
  Aynor: [41],
  Ayr: [29, 30],
  Ayrshire: [13],
  Azalea: [38],
  Azalia: [23],
  Azle: [44],
  Aztec: [33],
  Azusa: [5],
  Babb: [27],
  Babbitt: [24],
  Babcock: [49],
  'Babson Park': [10, 20],
  Babylon: [35],
  Baca: [-6],
  Backus: [24],
  Bacliff: [44],
  Bacon: [-11],
  Baconton: [11],
  Bacova: [46],
  'Bad Axe': [23],
  Baden: [39],
  Badger: [5, 13, 24, 42],
  Badin: [28],
  Bagdad: [4, 10, 18],
  Baggs: [51],
  Bagley: [13, 24, 49],
  Bagwell: [44],
  Bahama: [28],
  Bailey: [6, 23, 26, 28, 44, -44],
  'Bailey Island': [22],
  'Baileys Harbor': [49],
  Baileyton: [2],
  Baileyville: [15, 17, 22],
  Bainbridge: [11, 16, 35, 36, 39],
  'Bainbridge Island': [48],
  Bainville: [27],
  Baird: [44],
  Bairdford: [39],
  Bairoil: [51],
  Baisden: [50],
  Baker: [5, 10, -10, -11, 19, 27, 34, -38, 50],
  'Baker City': [38],
  'Bakers Mills': [35],
  Bakersfield: [5, 25, 47],
  Bakerstown: [39],
  Bakersville: [28, 36],
  Bakerton: [50],
  Bakewell: [43],
  'Bala Cynwyd': [39],
  Balaton: [24],
  'Balch Springs': [44],
  'Bald Knob': [3],
  Baldwin: [-2, 11, -11, 13, 15, 19, 21, 23, 29, 35, 49],
  'Baldwin City': [17],
  'Baldwin Park': [5],
  'Baldwin Place': [35],
  Baldwinsville: [35],
  Baldwinville: [20],
  Baldwyn: [26],
  Balfour: [29],
  Balko: [37],
  Ball: [19],
  'Ball Ground': [11],
  Ballantine: [27],
  Ballard: [-18, 50],
  Ballentine: [41],
  Ballico: [5],
  Ballinger: [44],
  Ballouville: [7],
  'Ballston Lake': [35],
  'Ballston Spa': [35],
  Ballwin: [25],
  Bally: [39],
  Balm: [10],
  Balmorhea: [44],
  Balsam: [28],
  'Balsam Grove': [28],
  'Balsam Lake': [49],
  Balta: [29],
  Baltic: [7, 36, 42],
  Baltimore: [21, -21, 36],
  Bamberg: [41, -41],
  Banco: [46],
  Bancroft: [13, 14, 23, 30, 49, 50],
  Bandana: [18],
  Bandera: [44, -44],
  Bandon: [38],
  Bandy: [46],
  Bangall: [35],
  Bangor: [5, 22, 23, 39, 49],
  Bangs: [44],
  Banks: [2, 3, -11, 14, 38],
  Bankston: [2],
  Banner: [18, 26, -30, 51],
  'Banner Elk': [28],
  Banning: [5],
  Bannister: [23],
  Bannock: [-14, 36],
  Banquete: [44],
  Bantam: [7],
  Bantry: [29],
  Bapchule: [4],
  Baptistown: [32],
  'Bar Harbor': [22],
  'Bar Mills': [22],
  Baraboo: [49],
  Baraga: [23, -23],
  Barataria: [19],
  Barbeau: [23],
  Barber: [-17],
  Barberton: [36],
  Barberville: [10],
  Barbour: [-2, -50],
  Barboursville: [46, 50],
  Barbourville: [18],
  Barclay: [21],
  Barco: [28],
  Bard: [5, 33],
  Bardolph: [15],
  Bardstown: [18],
  Bardwell: [18, 44],
  Bargersville: [16],
  Barhamsville: [46],
  Baring: [25, 48],
  'Barium Springs': [28],
  'Bark River': [23],
  Barker: [35, 44],
  Barkhamsted: [7],
  Barksdale: [44],
  'Barksdale Afb': [19],
  Barling: [3],
  Barlow: [18, 36],
  Barnard: [17, 25, 42, 47],
  Barnardsville: [28],
  Barnegat: [32],
  'Barnegat Light': [32],
  Barnes: [17, -29],
  'Barnes City': [13],
  Barneston: [30],
  Barnesville: [11, 21, 24, 28, 36, 39],
  Barnet: [47],
  Barnett: [25],
  Barneveld: [35, 49],
  Barney: [11, 29],
  Barnhart: [25, 44],
  Barnhill: [15],
  Barnsdall: [37],
  Barnstable: [20, -20],
  Barnstead: [31],
  Barnum: [13, 24],
  Barnwell: [41, -41],
  Baroda: [23],
  Barrackville: [50],
  Barre: [20, 47],
  Barren: [-18],
  'Barren Springs': [46],
  Barrett: [24],
  Barrington: [15, 31, 32, 40],
  Barron: [49, -49],
  Barronett: [49],
  Barrow: [1, -11],
  Barry: [15, -23, 24, -25, 44],
  Barryton: [23],
  Barrytown: [35],
  Barryville: [35],
  Barstow: [5, 15, 21, 44],
  Bart: [39],
  Bartelso: [15],
  Bartholomew: [-16],
  Bartlesville: [37],
  Bartlett: [15, 17, 30, 31, 36, 43, 44],
  Bartley: [30, 50],
  Barto: [39],
  Barton: [3, -17, 21, -25, 35, 36, 47],
  'Barton City': [23],
  Bartonsville: [39],
  Bartow: [10, 11, -11, 50],
  Barwick: [11],
  Basalt: [6, 14],
  Basco: [15],
  Bascom: [10, 36],
  Basehor: [17],
  Basile: [19],
  Basin: [27, 51],
  Baskerville: [46],
  Baskett: [18],
  Baskin: [19],
  'Basking Ridge': [32],
  Basom: [35],
  'Bass Harbor': [22],
  'Bass Lake': [5],
  Bassett: [3, 30, 46, 49],
  Bassfield: [26],
  Bastian: [46],
  Bastrop: [19, 44, -44],
  Basye: [46],
  'Bat Cave': [28],
  Batavia: [13, 15, 35, 36],
  Batchelor: [19],
  Batchtown: [15],
  Bates: [-25, 38],
  'Bates City': [25],
  Batesburg: [41],
  'Batesburg-Leesville': [41],
  Batesland: [42],
  Batesville: [3, 16, 26, 44, 46],
  Bath: [15, 16, -18, 22, 23, 28, 31, 35, 36, 39, 41, 42, -46],
  'Bath Springs': [43],
  Bathgate: [29],
  Baths: [46],
  'Baton Rouge': [19],
  Batson: [44],
  'Battery Park': [46],
  Battiest: [37],
  'Battle Creek': [13, 23, 30],
  'Battle Ground': [16, 48],
  'Battle Lake': [24],
  'Battle Mountain': [34],
  Battleboro: [28],
  'Battlement Mesa': [6],
  Battletown: [18],
  Baudette: [24],
  Bausman: [39],
  Bauxite: [3],
  Baxley: [11],
  Baxter: [-3, 13, 18, 24, 43, 50],
  'Baxter Springs': [17],
  Bay: [3, -10, -23],
  'Bay Center': [48],
  'Bay City': [23, 38, 44, 49],
  'Bay Minette': [2],
  'Bay Pines': [10],
  'Bay Port': [23],
  'Bay Saint Louis': [26],
  'Bay Shore': [23, 35],
  'Bay Springs': [26],
  'Bay Village': [36],
  Bayard: [13, 30, 33, 50],
  Bayboro: [28],
  Bayfield: [6, 49, -49],
  Baylis: [15],
  Baylor: [-44],
  Bayonne: [32],
  'Bayou La Batre': [2],
  Bayport: [24, 35],
  Bays: [18],
  Bayside: [5, 35, 44],
  Baytown: [44],
  Bayview: [14],
  Bayville: [32, 35],
  Bazine: [17],
  Beach: [29],
  'Beach City': [36],
  'Beach Haven': [32, 39],
  'Beach Lake': [39],
  Beachwood: [32, 36],
  Beacon: [13, 35],
  'Beacon Falls': [7],
  Beadle: [-42],
  'Beale Afb': [5],
  Bealeton: [46],
  Beallsville: [21, 36, 39],
  Beals: [22],
  Beaman: [13],
  'Bean Station': [43],
  Bear: [9],
  'Bear Branch': [18],
  'Bear Creek': [2, 28, 39, 49],
  'Bear Lake': [-14, 23, 39],
  'Bear Mountain': [35],
  'Bear River City': [45],
  'Bear Valley': [5],
  'Bear Valley Springs': [5],
  Bearcreek: [27],
  Bearden: [3],
  Beardsley: [24],
  Beardstown: [15],
  Bearsville: [35],
  Beasley: [44],
  Beason: [15],
  Beatrice: [2, 30],
  Beattie: [17],
  Beatty: [34, 38],
  Beattyville: [18],
  Beaufort: [25, 28, -28, 41, -41],
  Beaumont: [5, 17, 18, 26, 44, 46],
  Beauregard: [-19],
  Beauty: [18],
  Beaver: [1, 3, 13, 18, 36, 37, -37, 38, 39, -39, 45, -45, 48, 50],
  'Beaver Bay': [24],
  'Beaver City': [30],
  'Beaver Creek': [24],
  'Beaver Crossing': [30],
  'Beaver Dam': [18, 49],
  'Beaver Dams': [35],
  'Beaver Falls': [35, 39],
  'Beaver Island': [23],
  'Beaver Meadows': [39],
  'Beaver Springs': [39],
  Beavercreek: [36, 38],
  Beaverdale: [39],
  Beaverdam: [36, 46],
  Beaverhead: [-27],
  Beaverton: [2, 23, 38],
  Beavertown: [39],
  Beaverville: [15],
  Beccaria: [39],
  Bechtelsville: [39],
  Beckemeyer: [15],
  Becker: [24, -24, 26],
  Becket: [20],
  Beckham: [-37],
  Beckley: [50],
  Beckville: [44],
  Beckwourth: [5],
  Bedford: [13, 16, 18, 20, 23, 31, 35, 36, 39, -39, -43, 44, 46, -46, 51],
  'Bedford Hills': [35],
  'Bedford Park': [15],
  Bedias: [44],
  Bedminster: [32, 39],
  Bedrock: [6],
  Bee: [30, -44, 46],
  'Bee Branch': [3],
  'Bee Cave': [44],
  'Bee Spring': [18],
  Beebe: [3],
  'Beebe Plain': [47],
  'Beech Bluff': [43],
  'Beech Bottom': [50],
  'Beech Creek': [18, 39],
  'Beech Grove': [3, 16, 18],
  'Beech Island': [41],
  'Beech Mountain': [28],
  Beecher: [15],
  'Beecher City': [15],
  'Beecher Falls': [47],
  Beechgrove: [43],
  Beechmont: [18],
  Beedeville: [3],
  Beeler: [17],
  Beemer: [30],
  'Beersheba Springs': [43],
  Beeson: [50],
  Beetown: [49],
  Beeville: [44],
  Beggs: [37],
  Beirne: [3],
  Bejou: [24],
  'Bel Air': [21],
  'Bel Alton': [21],
  Belcamp: [21],
  Belcher: [18, 19],
  Belchertown: [20],
  Belcourt: [29],
  Belden: [5, 26, 30],
  Beldenville: [49],
  Belding: [23],
  Belen: [33],
  'Belews Creek': [28],
  Belfair: [48],
  Belfast: [22, 35, 43],
  Belfield: [29],
  Belford: [32],
  Belfry: [18, 27],
  Belgium: [49],
  Belgrade: [22, 24, 25, 27, 30],
  'Belgrade Lakes': [22],
  Belhaven: [28],
  Belington: [50],
  Belk: [2],
  Belknap: [15, -31],
  Bell: [5, 10, -18, -44],
  'Bell Buckle': [43],
  'Bell City': [19, 25],
  'Bell Gardens': [5],
  'Bella Vista': [3, 5],
  Bellaire: [23, 36, 44],
  Bellamy: [2],
  Bellarthur: [28],
  Bellbrook: [36],
  Belle: [25, 50],
  'Belle Center': [36],
  'Belle Chasse': [19],
  'Belle Fourche': [42],
  'Belle Glade': [10],
  'Belle Haven': [46],
  'Belle Mead': [32],
  'Belle Mina': [2],
  'Belle Plaine': [13, 17, 24],
  'Belle Rive': [15],
  'Belle Rose': [19],
  'Belle Valley': [36],
  'Belle Vernon': [39],
  'Belleair Beach': [10],
  Bellefontaine: [26, 36],
  Bellefonte: [39],
  Bellemont: [4],
  Bellerose: [35],
  Belleview: [10, 25],
  Belleville: [3, 15, 17, 23, 32, 35, 39, 49, 50],
  Bellevue: [13, 14, 18, 23, 30, 36, 44, 48],
  Bellflower: [5, 15, 25],
  Bellingham: [20, 24, 48],
  Bellmawr: [32],
  Bellmont: [15],
  Bellmore: [35],
  Bellona: [35],
  'Bellows Falls': [47],
  Bellport: [35],
  Bells: [43, 44],
  Bellvale: [35],
  Bellville: [11, 36, 44],
  Bellvue: [6],
  Bellwood: [2, 15, 30, 39],
  Belmar: [32],
  Belmond: [13],
  Belmont: [5, 19, 20, 23, 26, 28, 31, 35, 36, -36, 44, 47, 48, 49, 50],
  Belmore: [36],
  Beloit: [17, 36, 49],
  Belpre: [17, 36],
  Belsano: [39],
  Belspring: [46],
  Belt: [27],
  Belton: [18, 25, 41, 44],
  Beltrami: [24, -24],
  Beltsville: [21],
  Belva: [50],
  'Belvedere Tiburon': [5],
  Belvidere: [15, 28, 30, 32, 42, 43],
  'Belvidere Center': [47],
  Belview: [24],
  Belvue: [17],
  Belzoni: [26],
  Bement: [15],
  Bemidji: [24],
  'Bemus Point': [35],
  'Ben Bolt': [44],
  'Ben Franklin': [44],
  'Ben Hill': [-11],
  'Ben Hur': [46],
  'Ben Lomond': [3, 5],
  'Ben Wheeler': [44],
  Bena: [24, 46],
  Benavides: [44],
  Bend: [38, 44],
  Bendena: [17],
  Bendersville: [39],
  Benedict: [17, 21, 24, 29, 30],
  Benedicta: [22],
  'Benet Lake': [49],
  Benewah: [-14],
  Benezett: [39],
  Benge: [48],
  Benham: [18],
  Benicia: [5],
  Benjamin: [44],
  Benkelman: [30],
  Benld: [15],
  Bennet: [30],
  Bennett: [6, 13, 28, -42],
  Bennettsville: [41],
  Bennington: [16, 17, 30, 31, 37, 47, -47],
  Benoit: [26, 49],
  Bensalem: [39],
  Bensenville: [15],
  Benson: [4, 15, 21, 24, 28, -29, 47],
  Bent: [-6, 33],
  'Bent Mountain': [46],
  Bentley: [17, 19, 23],
  Bentleyville: [39],
  Benton: [3, -3, 5, 13, -13, 15, -16, 17, 18, 19, 22, -24, 25, -25, 26, -26, -38, 39, 43, -43, -48, 49],
  'Benton City': [25, 48],
  'Benton Harbor': [23],
  'Benton Ridge': [36],
  Bentonia: [26],
  Bentonville: [3, 16, 36, 46],
  Benwood: [50],
  Benzie: [-23],
  Benzonia: [23],
  Berclair: [44],
  Berea: [18, 36, 50],
  Beresford: [42],
  Bergen: [-32, 35],
  Bergenfield: [32],
  Berger: [25],
  Bergheim: [44],
  Bergholz: [36],
  Bergland: [23],
  Bergman: [3],
  Bergoo: [50],
  Bergton: [46],
  Berino: [33],
  Berkeley: [5, 15, -41, -50],
  'Berkeley Heights': [32],
  'Berkeley Springs': [50],
  Berkey: [36],
  Berkley: [20, 23],
  Berks: [-39],
  Berkshire: [20, -20, 35],
  Berlin: [7, 11, 20, 21, 29, 31, 32, 35, 36, 39, 49],
  'Berlin Center': [36],
  'Berlin Heights': [36],
  'Bermuda Run': [28],
  Bern: [14, 17],
  Bernalillo: [33, -33],
  Bernard: [13, 22],
  Bernardston: [20],
  Bernardsville: [32],
  Berne: [16, 35],
  'Bernhards Bay': [35],
  Bernice: [19],
  Bernie: [25],
  Bernville: [39],
  Berrien: [-11, -23],
  'Berrien Center': [23],
  'Berrien Springs': [23],
  Berry: [2, 18],
  'Berry Creek': [5],
  Berrysburg: [39],
  Berryton: [17],
  Berryville: [3, 46],
  Bertha: [24],
  Berthold: [29],
  Berthoud: [6],
  Bertie: [-28],
  Bertram: [44],
  Bertrand: [25, 30],
  Berwick: [13, 15, 19, 22, 39],
  Berwind: [50],
  Berwyn: [15, 39],
  Beryl: [45],
  Bessemer: [2, 23, 39],
  'Bessemer City': [28],
  Bessie: [37],
  Bethalto: [15],
  Bethania: [28],
  Bethany: [7, 15, 19, 25, 37, 50],
  'Bethany Beach': [9],
  Bethel: [1, -1, 7, 9, 22, 24, 25, 28, 35, 36, 37, 39, 47],
  'Bethel Island': [5],
  'Bethel Park': [39],
  'Bethel Springs': [43],
  Bethelridge: [18],
  Bethesda: [21, 36],
  Bethlehem: [7, 11, 16, 18, 21, 31, 39],
  Bethpage: [35, 43],
  Bethune: [6, 41],
  'Betsy Layne': [18],
  Bettendorf: [13],
  Betterton: [21],
  'Bettles Field': [1],
  Bettsville: [36],
  Beulah: [6, 23, 25, 26, 29, 51],
  Beulaville: [28],
  Beverly: [17, 18, 20, 32, 36, 48, 50],
  'Beverly Hills': [5, 10],
  'Beverly Shores': [16],
  Bevier: [25],
  Bevington: [13],
  Bevinsville: [18],
  Bexar: [3, -44],
  Beyer: [39],
  Bibb: [-2, -11],
  'Bible School Park': [35],
  Bickleton: [48],
  Bickmore: [50],
  Bicknell: [16, 45],
  Biddeford: [22],
  'Biddeford Pool': [22],
  Biddle: [27],
  Bidwell: [36],
  Bieber: [5],
  Bienville: [19, -19],
  'Big Arm': [27],
  'Big Bar': [5],
  'Big Bay': [23],
  'Big Bear City': [5],
  'Big Bear Lake': [5],
  'Big Bend': [5, 49, 50],
  'Big Bend National Park': [44],
  'Big Cabin': [37],
  'Big Canoe': [11],
  'Big Clifty': [18],
  'Big Cove Tannery': [39],
  'Big Creek': [5, 18, 26, 50],
  'Big Falls': [24, 49],
  'Big Flat': [3],
  'Big Flats': [35],
  'Big Horn': [-27, 51, -51],
  'Big Indian': [35],
  'Big Island': [46],
  'Big Lake': [1, 24, 44],
  'Big Laurel': [18],
  'Big Oak Flat': [5],
  'Big Pine': [5],
  'Big Pine Key': [10],
  'Big Piney': [51],
  'Big Pool': [21],
  'Big Prairie': [36],
  'Big Rapids': [23],
  'Big Rock': [15, 43, 46],
  'Big Run': [39, 50],
  'Big Sandy': [27, 43, 44, 50],
  'Big Sky': [27],
  'Big Spring': [44],
  'Big Springs': [30, 50],
  'Big Stone': [-24],
  'Big Stone City': [42],
  'Big Stone Gap': [46],
  'Big Sur': [5],
  'Big Timber': [27],
  'Big Wells': [44],
  Bigelow: [3, 24],
  Bigfoot: [44],
  Bigfork: [24, 27],
  Biggers: [3],
  Biggs: [5],
  Biggsville: [15],
  Bighill: [18],
  Bighorn: [27],
  Bigler: [39],
  Biglerville: [39],
  Billerica: [20],
  Billings: [25, 27, -29, 35, 37],
  Billingsley: [2],
  Biloxi: [26],
  Bim: [50],
  Bimble: [18],
  Binford: [29],
  Bingen: [48],
  Binger: [37],
  Bingham: [-14, 15, 22, 30],
  'Bingham Canyon': [45],
  'Bingham Lake': [24],
  Binghamton: [35],
  Biola: [5],
  Bippus: [16],
  'Birch Harbor': [22],
  'Birch River': [50],
  'Birch Run': [23],
  'Birch Tree': [25],
  Birchdale: [24],
  Birchleaf: [46],
  Birchrunville: [39],
  Birchwood: [43, 49],
  'Bird City': [17],
  'Bird In Hand': [39],
  'Bird Island': [24],
  'Birds Landing': [5],
  Birdsboro: [39],
  Birdseye: [16],
  Birdsnest: [46],
  Birmingham: [2, 13, 23, 32, 36],
  Birnamwood: [49],
  Birney: [27],
  Bisbee: [4, 29],
  Biscoe: [3, 28],
  Bishop: [5, 11, 44, 46],
  'Bishop Hill': [15],
  Bishopville: [21, 41],
  Bismarck: [3, 15, 25, 29],
  Bison: [17, 37, 42],
  Bitely: [23],
  Bittinger: [21],
  Bivalve: [21],
  Bivins: [44],
  Biwabik: [24],
  Bixby: [25, 37],
  Blachly: [38],
  Black: [2, 25],
  'Black Canyon City': [4],
  'Black Creek': [28, 35, 49],
  'Black Diamond': [48],
  'Black Eagle': [27],
  'Black Earth': [49],
  'Black Hawk': [6, -13, 42],
  'Black Lick': [39],
  'Black Mountain': [28],
  'Black Oak': [3],
  'Black River': [23, 35],
  'Black River Falls': [49],
  'Black Rock': [3],
  Blackburn: [25],
  Blackduck: [24],
  Blackey: [18],
  Blackfoot: [14],
  Blackford: [-16],
  Blacklick: [36],
  Blacksburg: [41, 46],
  Blackshear: [11],
  Blackstock: [41],
  Blackstone: [15, 20, 46],
  Blacksville: [50],
  Blackville: [41],
  Blackwater: [25, 46],
  Blackwell: [25, 37, 44],
  Blackwood: [32],
  Bladen: [-28, 30],
  Bladenboro: [28],
  Bladensburg: [21, 36],
  Blain: [39],
  Blaine: [-14, 18, 22, 24, -27, -30, 36, -37, 43, 48],
  Blair: [30, 37, -39, 41, 49, 50],
  Blairs: [46],
  'Blairs Mills': [39],
  Blairsburg: [13],
  'Blairsden Graeagle': [5],
  Blairstown: [13, 25, 32],
  Blairsville: [11, 39],
  Blakely: [11],
  'Blakely Island': [48],
  Blakesburg: [13],
  Blakeslee: [36, 39],
  Blanca: [6],
  Blanch: [28],
  Blanchard: [13, 14, 19, 23, 29, 37, 39],
  Blanchardville: [49],
  Blanchester: [36],
  Blanco: [33, 37, 44, -44],
  Bland: [25, 46, -46],
  Blandburg: [39],
  Blandford: [20],
  Blanding: [45],
  Blandinsville: [15],
  Blandon: [39],
  Blanford: [16],
  Blanket: [44],
  Blauvelt: [35],
  Blawenburg: [32],
  Bleckley: [-11],
  Bledsoe: [18, -43, 44],
  Bleiblerville: [44],
  Blencoe: [13],
  Blenheim: [41],
  Blenker: [49],
  Blessing: [44],
  Blevins: [3],
  Bliss: [14, 35],
  Blissfield: [23, 36],
  'Block Island': [40],
  Blocker: [37],
  Blocksburg: [5],
  Blockton: [13],
  Blodgett: [25, 38],
  'Blodgett Mills': [35],
  Blomkest: [24],
  Bloomburg: [44],
  Bloomdale: [36],
  Bloomer: [49],
  Bloomery: [50],
  Bloomfield: [7, 13, 16, 18, 25, 27, 30, 32, 33, 35],
  'Bloomfield Hills': [23],
  'Blooming Glen': [39],
  'Blooming Grove': [35, 44],
  'Blooming Prairie': [24],
  Bloomingburg: [35, 36],
  Bloomingdale: [11, 15, 16, 23, 32, 35, 36],
  Bloomingrose: [50],
  Bloomington: [5, 14, 15, 16, 21, 30, 35, 44, 49],
  'Bloomington Springs': [43],
  Bloomsburg: [39],
  Bloomsbury: [32],
  Bloomsdale: [25],
  Bloomville: [35, 36],
  Blossburg: [39],
  Blossom: [44],
  Blossvale: [35],
  Blount: [-2, -43, 50],
  'Blounts Creek': [28],
  Blountstown: [10],
  Blountsville: [2],
  Blountville: [43],
  'Blowing Rock': [28],
  Bloxom: [46],
  Blue: [4],
  'Blue Ash': [36],
  'Blue Ball': [39],
  'Blue Bell': [39],
  'Blue Creek': [36, 50],
  'Blue Diamond': [34],
  'Blue Earth': [24, -24],
  'Blue Eye': [25],
  'Blue Gap': [4],
  'Blue Grass': [13, 46],
  'Blue Hill': [22, 30],
  'Blue Island': [15],
  'Blue Jay': [5],
  'Blue Lake': [5],
  'Blue Mound': [15, 17],
  'Blue Mounds': [49],
  'Blue Mountain': [3, 26],
  'Blue Mountain Lake': [35],
  'Blue Point': [35],
  'Blue Rapids': [17],
  'Blue Ridge': [11, 44, 46],
  'Blue Ridge Summit': [39],
  'Blue River': [18, 38, 49],
  'Blue Rock': [36],
  'Blue Springs': [25, 26, 30],
  Bluebell: [45],
  Bluefield: [46, 50],
  Bluegrove: [44],
  Bluejacket: [37],
  Bluemont: [46],
  Bluewater: [33],
  Bluff: [45],
  'Bluff City': [3, 17, 43],
  'Bluff Dale': [44],
  'Bluff Springs': [15],
  Bluffs: [15],
  Bluffton: [3, 11, 16, 24, 36, 41, 44],
  Bluford: [15],
  Blum: [44],
  Blunt: [42],
  Bly: [38],
  Blythe: [5, 11],
  Blythedale: [25],
  Blytheville: [3],
  Blythewood: [41],
  Boalsburg: [39],
  'Board Camp': [3],
  Boardman: [36, 38],
  Boaz: [2, 18],
  'Bob White': [50],
  Bobtown: [39],
  'Boca Grande': [10],
  'Boca Raton': [10],
  Bock: [24],
  Bode: [13],
  Bodega: [5],
  'Bodega Bay': [5],
  Bodfish: [5],
  Boelus: [30],
  Boerne: [44],
  Bogalusa: [19],
  Bogard: [25],
  Bogart: [11],
  Bogata: [44],
  Boggstown: [16],
  Bogota: [32, 43],
  Bogue: [17],
  'Bogue Chitto': [26],
  Bohannon: [46],
  Bohemia: [35],
  Boiceville: [35],
  'Boiling Springs': [28, 39, 41],
  'Bois D Arc': [25],
  Boise: [14, -14],
  'Boise City': [37],
  Boissevain: [46],
  Bokchito: [37],
  Bokeelia: [10],
  Bokoshe: [37],
  Bolckow: [25],
  Boles: [3, 15],
  Boley: [37],
  Boligee: [2],
  Bolinas: [5],
  Boling: [44],
  Bolingbroke: [11],
  Bolingbrook: [15],
  Bolivar: [25, -26, 35, 36, 39, 43],
  Bolivia: [28],
  Bollinger: [-25],
  Bolt: [50],
  Bolton: [7, 20, 26, 28],
  'Bolton Landing': [35],
  Bombay: [35],
  Bomont: [50],
  Bomoseen: [47],
  'Bon Air': [2],
  'Bon Aqua': [43],
  'Bon Homme': [-42],
  'Bon Secour': [2],
  'Bon Wier': [44],
  Bonaire: [11],
  Bonanza: [38, 45],
  Bonaparte: [13],
  Boncarbo: [6],
  Bond: [6, -15],
  Bondsville: [20],
  Bonduel: [49],
  Bondurant: [13, 51],
  Bondville: [15, 47],
  'Bone Gap': [15],
  Bonesteel: [42],
  Boneville: [11],
  Bonfield: [15],
  Bonham: [44],
  Bonifay: [10],
  Bonita: [5, 19],
  'Bonita Springs': [10],
  Bonlee: [28],
  'Bonne Terre': [25],
  Bonneau: [41],
  'Bonneau Beach': [41],
  Bonner: [-14, 27],
  'Bonner Springs': [17],
  Bonnerdale: [3],
  'Bonners Ferry': [14],
  Bonneville: [-14],
  'Bonney Lake': [48],
  Bonnie: [15],
  Bonnieville: [18],
  'Bonnots Mill': [25],
  Bonnyman: [18],
  Bono: [3],
  Bonsall: [5],
  Boody: [15],
  Booker: [44],
  Boomer: [28, 50],
  Boon: [23],
  Boone: [-3, 6, 13, -13, -15, -16, -18, -25, 28, -30, -50],
  'Boone Grove': [16],
  'Boones Mill': [46],
  Booneville: [3, 13, 18, 26],
  'Boons Camp': [18],
  Boonsboro: [21],
  Boonton: [32],
  Boonville: [5, 16, 25, 28, 35],
  Booth: [2],
  Boothbay: [22],
  'Boothbay Harbor': [22],
  Boothville: [19],
  Bordelonville: [19],
  Borden: [16, -44],
  Bordentown: [32],
  Borderland: [50],
  Borger: [44],
  Boring: [21, 38],
  Boron: [5],
  'Borrego Springs': [5],
  Borup: [24],
  Boscawen: [31],
  Boscobel: [49],
  Bosler: [51],
  Bosque: [33, -44],
  'Bosque Farms': [33],
  Boss: [25],
  Bossier: [-19],
  'Bossier City': [19],
  Bostic: [28],
  Boston: [11, 16, 18, 20, 35, 46],
  Bostwick: [10, 11],
  Boswell: [16, 37, 39],
  Bosworth: [25],
  Botetourt: [-46],
  Bothell: [48],
  Botkins: [36],
  Botsford: [7],
  Bottineau: [29, -29],
  Bouckville: [35],
  Boulder: [6, -6, 27, 45, 51],
  'Boulder City': [34],
  'Boulder Creek': [5],
  'Boulder Junction': [49],
  Boulevard: [5],
  'Bound Brook': [32],
  Boundary: [-14],
  Bountiful: [45],
  Bourbon: [16, -17, -18, 25],
  Bourbonnais: [15],
  Bourg: [19],
  Bourneville: [36],
  Bouse: [4],
  Bouton: [13],
  Boutte: [19],
  Bovard: [39],
  Bovey: [24],
  Bovill: [14],
  Bovina: [44],
  'Bovina Center': [35],
  Bow: [31, 48],
  Bowbells: [29],
  Bowden: [50],
  Bowdle: [42],
  Bowdoin: [22],
  Bowdoinham: [22],
  Bowdon: [11, 29],
  'Bowdon Junction': [11],
  Bowen: [15],
  Bowers: [39],
  Bowerston: [36],
  Bowersville: [11, 36],
  Bowie: [4, 21, 44, -44],
  Bowlegs: [37],
  Bowler: [49],
  'Bowling Green': [10, 16, 18, 25, 36, 41, 46],
  Bowlus: [24],
  Bowman: [11, 29, -29, 41],
  Bowmanstown: [39],
  Bowmansville: [35, 39],
  Bowstring: [24],
  'Box Butte': [-30],
  'Box Elder': [27, 42, -45],
  'Box Springs': [11],
  Boxborough: [20],
  Boxford: [20],
  Boxholm: [13],
  Boyce: [19, 46],
  Boyceville: [49],
  Boyd: [-18, 24, 27, -30, 44, 49],
  Boyden: [13],
  Boyds: [21],
  Boydton: [46],
  Boyers: [39],
  Boyertown: [39],
  Boyes: [27],
  'Boyes Hot Springs': [5],
  Boykin: [2],
  Boykins: [46],
  Boyle: [-18, 26],
  Boylston: [20],
  'Boyne City': [23],
  'Boyne Falls': [23],
  Boynton: [37, 39],
  'Boynton Beach': [10],
  'Boys Ranch': [44],
  'Boys Town': [30],
  Bozeman: [27],
  Bozman: [21],
  Bozrah: [7],
  Braceville: [15],
  Bracey: [46],
  Bracken: [-18],
  Brackenridge: [39],
  Brackettville: [44],
  Brackney: [39],
  Braddock: [29, 39],
  'Braddock Heights': [21],
  Braddyville: [13],
  Braden: [43],
  Bradenton: [10],
  'Bradenton Beach': [10],
  Bradenville: [39],
  Bradford: [3, -10, 13, 15, 16, 22, 31, 35, 36, 39, -39, 40, 43, 47],
  Bradfordsville: [18],
  Bradfordwoods: [39],
  Bradgate: [13],
  Bradley: [3, -3, 5, 10, 15, 22, 23, 37, 41, 42, -43, 50],
  'Bradley Beach': [32],
  Bradleyville: [25],
  Bradner: [36],
  Bradshaw: [30, 50],
  Brady: [27, 30, 44],
  'Brady Lake': [36],
  Bradyville: [43],
  'Bragg City': [25],
  Braggadocio: [25],
  Braggs: [37],
  Braham: [24],
  Braidwood: [15],
  Brainard: [30, 35],
  Brainardsville: [35],
  Brainerd: [24],
  Braintree: [20],
  Braithwaite: [19],
  Braman: [37],
  Bramwell: [50],
  Branch: [3, 19, 23, -23],
  Branchdale: [39],
  Branchland: [50],
  Branchport: [35],
  Branchton: [39],
  Branchville: [16, 32, 41, 46],
  Brandamore: [39],
  Brandeis: [5],
  Brandenburg: [18],
  Brandon: [10, 13, 24, 26, 42, 44, 47, 49],
  Brandsville: [25],
  Brandt: [42],
  'Brandy Camp': [39],
  'Brandy Station': [46],
  Brandywine: [21, 50],
  Branford: [7, 10],
  Branscomb: [5],
  Branson: [6, 25],
  'Branson West': [25],
  Brant: [23, 35],
  'Brant Lake': [35],
  'Brant Rock': [20],
  Brantingham: [35],
  Brantley: [2, -11],
  Brantwood: [49],
  Braselton: [11],
  Brashear: [25, 44],
  'Brasher Falls': [35],
  Brasstown: [28],
  Brattleboro: [47],
  Brave: [39],
  Brawley: [5],
  Braxton: [26, -50],
  Bray: [37],
  Braymer: [25],
  Brayton: [13],
  Brazeau: [25],
  Brazil: [16],
  Brazoria: [44, -44],
  Brazos: [-44],
  Brea: [5],
  Breaks: [46],
  Breathitt: [-18],
  'Breaux Bridge': [19],
  Breckenridge: [6, 23, 24, 25, 44],
  Breckinridge: [-18],
  Brecksville: [36],
  Breda: [13],
  Breeden: [50],
  Breeding: [18],
  Breedsville: [23],
  Breese: [15],
  Breesport: [35],
  Breezewood: [39],
  'Breezy Point': [35],
  Breinigsville: [39],
  Bremen: [2, 11, 16, 17, 18, 22, 36],
  Bremer: [-13],
  Bremerton: [48],
  'Bremo Bluff': [46],
  Bremond: [44],
  Brenham: [44],
  Brent: [2],
  Brentford: [42],
  Brenton: [50],
  Brentwood: [5, 21, 25, 35, 43],
  Brethren: [23],
  'Bretton Woods': [31],
  Bretz: [50],
  Brevard: [-10, 28],
  'Brevig Mission': [1],
  Brewer: [22],
  Brewerton: [35],
  Brewster: [17, 20, 24, 30, 35, 36, -44, 48],
  Brewton: [2],
  'Brian Head': [45],
  Briar: [25],
  'Briarcliff Manor': [35],
  Brice: [36],
  Bricelyn: [24],
  Briceville: [43],
  Brick: [32],
  Brickeys: [3],
  'Bridal Veil': [38],
  'Bridge City': [19, 44],
  Bridgehampton: [35],
  Bridgeport: [2, 5, 7, 15, 23, 30, 32, 35, 36, 38, 39, 44, 48, 50],
  Bridger: [27],
  Bridgeton: [16, 25, 28, 32],
  Bridgeview: [15],
  Bridgeville: [5, 9, 39],
  Bridgewater: [7, 13, 20, 22, 23, 32, 35, 39, 42, 46, 47],
  'Bridgewater Corners': [47],
  Bridgman: [23],
  Bridgton: [22],
  Bridport: [47],
  Brielle: [32],
  'Brier Hill': [35, 39],
  Brierfield: [2],
  Brigantine: [32],
  Briggs: [44],
  Briggsdale: [6],
  Briggsville: [3, 49],
  'Brigham City': [45],
  Brighton: [6, 13, 15, 20, 23, 25, 43],
  Brightwaters: [35],
  Brightwood: [38, 46],
  Brilliant: [2, 36],
  Brillion: [49],
  Brimfield: [15, 20],
  Brimhall: [33],
  Brimley: [23],
  Brimson: [24],
  Bringhurst: [16],
  Brinkhaven: [36],
  Brinkley: [3],
  Brinklow: [21],
  Brinktown: [25],
  Brinnon: [48],
  Brinson: [11],
  Brisbane: [5],
  Brisbin: [39],
  Briscoe: [44, -44],
  Bristol: [7, 10, 11, 15, 16, -20, 22, 31, 39, 40, -40, 42, 43, 46, 47, 49],
  'Bristol Bay': [-1],
  Bristolville: [36],
  Bristow: [13, 16, 30, 37, 46],
  Britt: [13, 24],
  Brittany: [19],
  Britton: [23, 42],
  Brixey: [25],
  'Broad Brook': [7],
  'Broad Run': [46],
  'Broad Top': [39],
  Broadalbin: [35],
  Broadbent: [38],
  Broaddus: [44],
  Broadford: [46],
  Broadlands: [15],
  Broadus: [27],
  Broadview: [15, 27, 33],
  'Broadview Heights': [36],
  Broadwater: [-27, 30],
  Broadway: [28, 32, 36, 46],
  Brock: [30],
  Brocket: [29],
  Brockport: [35, 39],
  Brockton: [20, 27, 39],
  Brockway: [27, 39],
  Brockwell: [3],
  Brocton: [15, 35],
  Brodhead: [18, 49],
  Brodheadsville: [39],
  Brodnax: [46],
  Brogan: [38],
  Brogue: [39],
  Brohard: [50],
  Brohman: [23],
  Brokaw: [49],
  'Broken Arrow': [37],
  'Broken Bow': [30, 37],
  Bromide: [37],
  Bronaugh: [25],
  Bronson: [10, 13, 17, 23, 44],
  Bronston: [18],
  Bronte: [44],
  Bronwood: [11],
  Bronx: [35, -35],
  Bronxville: [35],
  Brook: [16],
  'Brook Park': [24],
  Brookdale: [5],
  Brooke: [46, -50],
  Brookeland: [44],
  Brooker: [10],
  Brookesmith: [44],
  Brookeville: [21],
  Brookfield: [7, 11, 15, 20, 25, 35, 36, 47, 49],
  Brookhaven: [11, 26, 35, 39],
  Brookings: [38, 42, -42],
  Brookland: [3],
  Brooklandville: [21],
  Brooklet: [11],
  Brooklin: [22],
  Brookline: [20, 25, 31],
  'Brookline Village': [20],
  Brooklyn: [2, 7, 13, 16, 21, 23, 26, 35, 39, 49],
  Brookneal: [46],
  Brookpark: [36],
  Brookport: [15],
  Brooks: [5, 11, -11, 18, 22, 24, -44],
  Brookshire: [44],
  Brookside: [2, 32],
  Brookston: [16, 24, 44],
  Brooksville: [10, 18, 22, 26],
  Brookton: [22],
  Brooktondale: [35],
  Brookville: [16, 17, 36, 39],
  Brookwood: [2],
  Broomall: [39],
  Broome: [-35],
  'Broomes Island': [21],
  Broomfield: [6, -6],
  Brooten: [24],
  Broseley: [25],
  Brothers: [38],
  Broughton: [15],
  Broussard: [19],
  Broward: [-10],
  Browder: [18],
  Browerville: [24],
  Brown: [-15, -16, -17, -24, -30, -36, -42, -44, -49],
  'Brown City': [23],
  Brownell: [17],
  Brownfield: [22, 39, 44],
  Browning: [15, 25, 27],
  Browns: [15],
  'Browns Mills': [32],
  'Browns Summit': [28],
  'Browns Valley': [5, 24],
  Brownsboro: [2, 44],
  Brownsburg: [16, 46],
  Brownsdale: [24],
  Brownstown: [15, 16, 23, 39, 48],
  Brownsville: [5, 16, 18, 21, 24, 36, 38, 39, 43, 44, 47, 49],
  Brownton: [24],
  Browntown: [49],
  Brownville: [22, 30, 35],
  'Brownville Junction': [22],
  Brownwood: [25, 44],
  Broxton: [11],
  Bruce: [26, 42, 49],
  'Bruce Crossing': [23],
  Bruceton: [43],
  'Bruceton Mills': [50],
  Brucetown: [46],
  Bruceville: [16, 44],
  Bruin: [39],
  Bruington: [46],
  Brule: [30, -42, 49],
  Brumley: [25],
  Brundidge: [2],
  Bruneau: [14],
  Bruner: [25],
  Bruni: [44],
  Bruning: [30],
  Bruno: [24, 30, 50],
  Brunson: [41],
  Brunsville: [13],
  Brunswick: [11, 21, 22, 25, 28, -28, 30, 36, 43, -46],
  Brusett: [27],
  Brush: [6],
  'Brush Creek': [43],
  'Brush Prairie': [48],
  'Brush Valley': [39],
  Brushton: [35],
  Brusly: [19],
  Brussels: [15, 49],
  Brutus: [23],
  Bryan: [-11, 36, -37, 44],
  'Bryans Road': [21],
  Bryant: [2, 3, 13, 15, 16, 42, 49],
  'Bryant Pond': [22],
  Bryantown: [21],
  'Bryants Store': [18],
  Bryantsville: [18],
  Bryantville: [20],
  Bryce: [45],
  Bryceville: [10],
  'Bryn Athyn': [39],
  'Bryn Mawr': [5, 39],
  Bryson: [44],
  'Bryson City': [28],
  Buchanan: [11, -13, 23, -25, 29, 35, 43, 46, -46],
  'Buchanan Dam': [44],
  Buchtel: [36],
  'Buck Creek': [16],
  'Buck Hill Falls': [39],
  Buckatunna: [26],
  Buckeye: [4, 13, 50],
  'Buckeye Lake': [36],
  Buckeystown: [21],
  Buckfield: [22],
  Buckhannon: [50],
  Buckhead: [11],
  Buckholts: [44],
  Buckhorn: [18, 33],
  Buckingham: [13, 15, 39, 46, -46],
  Buckland: [1, 20, 36],
  Buckley: [15, 23, 48],
  Bucklin: [17, 25],
  Buckman: [24],
  Buckner: [3, 15, 18, 25],
  Bucks: [2, -39],
  Buckskin: [16],
  Bucksport: [22],
  Bucoda: [48],
  Bucyrus: [17, 25, 36],
  Bud: [50],
  Buda: [15, 44],
  'Budd Lake': [32],
  Bude: [26],
  Buellton: [5],
  Buena: [32, 48],
  'Buena Park': [5],
  'Buena Vista': [6, 11, -13, 33, 39, 43, 46],
  Buffalo: [13, 15, 16, 17, 18, 24, 25, 27, 29, -30, 35, 36, 37, 41, 42, -42, 44, -49, 50, 51],
  'Buffalo Center': [13],
  'Buffalo Creek': [6],
  'Buffalo Gap': [42, 44],
  'Buffalo Grove': [15],
  'Buffalo Junction': [46],
  'Buffalo Lake': [24],
  'Buffalo Mills': [39],
  'Buffalo Prairie': [15],
  'Buffalo Valley': [43],
  Buford: [11, 51],
  Buhl: [2, 14, 24],
  Buhler: [17],
  'Buies Creek': [28],
  Bulan: [18],
  Bulger: [39],
  'Bull Shoals': [3],
  Bullard: [44],
  Bullhead: [42],
  'Bullhead City': [4],
  Bullitt: [-18],
  Bulloch: [-11],
  Bullock: [-2, 28],
  'Bulls Gap': [43],
  Bullville: [35],
  Bulpitt: [15],
  Bulverde: [44],
  Bumpass: [46],
  'Bumpus Mills': [43],
  Buna: [44],
  Bunceton: [25],
  Bunch: [37],
  Buncombe: [15, -28],
  Bunker: [25],
  'Bunker Hill': [15, 16, 17, 50],
  Bunkerville: [34],
  Bunkie: [19],
  Bunn: [28],
  Bunnell: [10],
  Bunnlevel: [28],
  Bunola: [39],
  Buras: [19],
  Burbank: [5, 15, 36, 37, 42, 48],
  Burchard: [30],
  Burden: [17],
  Burdett: [17, 35],
  Burdette: [3],
  Burdick: [17],
  Burdine: [18],
  Bureau: [15, -15],
  Burfordville: [25],
  Burgaw: [28],
  Burgess: [46],
  Burgettstown: [39],
  Burghill: [36],
  Burgin: [18],
  Burgoon: [36],
  Burien: [48],
  Burkburnett: [44],
  Burke: [-11, -28, -29, 35, 42, 46, 47],
  'Burkes Garden': [46],
  Burkesville: [18],
  Burket: [16],
  Burkett: [44],
  Burkettsville: [36],
  Burkeville: [44, 46],
  Burkittsville: [21],
  Burleigh: [-29],
  Burleson: [44, -44],
  Burley: [14, 48],
  Burlingame: [5, 17],
  Burlingham: [35],
  Burlington: [6, 7, 13, 15, 16, 17, 18, 20, 22, 23, 28, 29, 32, -32, 37, 39, 44, 47, 48, 49, 50, 51],
  'Burlington Flats': [35],
  'Burlington Junction': [25],
  Burlison: [43],
  Burna: [18],
  Burnet: [44, -44],
  Burnett: [49, -49],
  Burnettsville: [16],
  Burney: [5],
  Burneyville: [37],
  Burnham: [22, 39],
  Burnips: [23],
  Burns: [6, 17, 38, 43, 51],
  'Burns Flat': [37],
  Burnside: [13, 18, 19, 39],
  Burnsville: [24, 26, 28, 50],
  'Burnt Cabins': [39],
  'Burnt Hills': [35],
  'Burnt Prairie': [15],
  'Burnt Ranch': [5],
  Burnwell: [2],
  Burr: [30],
  'Burr Hill': [46],
  'Burr Oak': [17, 23],
  Burrel: [5],
  Burrows: [16],
  Burrton: [17],
  Burson: [5],
  Burt: [13, 23, -30, 35],
  'Burt Lake': [23],
  Burton: [23, 36, 44, 48, 50],
  Burtonsville: [21],
  Burtrum: [24],
  Burwell: [30],
  Busby: [27],
  Bush: [18, 19],
  Bushkill: [39],
  Bushland: [44],
  Bushnell: [10, 15, 30],
  Bushton: [17],
  Bushwood: [21],
  Buskirk: [35],
  Bussey: [13],
  'Bustins Island': [22],
  Busy: [18],
  Butler: [2, -2, 11, -13, 15, 16, -17, 18, -18, 21, 25, -25, -30, 32, 36, -36, 37, 39, -39, 43, 49],
  Butlerville: [16],
  Butner: [28],
  Butte: [-5, -14, 27, 29, 30, -42],
  'Butte City': [5],
  'Butte Des Morts': [49],
  'Butte Falls': [38],
  Butterfield: [24, 25],
  Butternut: [49],
  Buttonwillow: [5],
  Butts: [-11],
  Buxton: [22, 28, 29, 38],
  'Buzzards Bay': [20],
  Byars: [37],
  Bybee: [43],
  Byers: [6, 17, 44],
  Byesville: [36],
  Byfield: [20],
  Byhalia: [26],
  Bylas: [4],
  Bynum: [2, 27, 28, 44],
  Bypro: [18],
  Byram: [26],
  Byrdstown: [43],
  Byrnedale: [39],
  Byromville: [11],
  Byron: [5, 11, 15, 23, 24, 30, 35, 51],
  'Byron Center': [23],
  Caballo: [33],
  Cabarrus: [-28],
  Cabazon: [5],
  Cabell: [-50],
  Cabery: [15],
  'Cabin Creek': [50],
  'Cabin John': [21],
  Cabins: [50],
  Cable: [36, 49],
  Cabool: [25],
  Cabot: [3, 39, 47],
  Cache: [37, -45],
  'Cache Junction': [45],
  Cactus: [44],
  Caddo: [-19, 37, -37, 44],
  'Caddo Gap': [3],
  'Caddo Mills': [44],
  Cade: [19],
  Cades: [41],
  Cadet: [25],
  Cadillac: [23],
  Cadiz: [18, 36],
  Cadogan: [39],
  Cadott: [49],
  Cadwell: [11],
  Cadyville: [35],
  Cahone: [6],
  Cainsville: [25],
  Cairnbrook: [39],
  Cairo: [11, 15, 25, 30, 35, 36, 50],
  'Cal Nev Ari': [34],
  Calabasas: [5],
  Calabash: [28],
  Calais: [22, 47],
  Calamus: [13],
  Calaveras: [-5],
  Calcasieu: [-19],
  Calcium: [35],
  Calcutta: [36],
  Calder: [14],
  Caldwell: [3, 14, 17, -18, -19, -25, -28, 32, 36, 44, -44, 50],
  Cale: [3],
  Caledonia: [15, 23, 24, 25, 26, 29, 35, 36, -47, 49],
  Calera: [2, 37],
  Calexico: [5],
  Calhan: [6],
  Calhoun: [-2, -3, -10, 11, -11, -13, 15, -15, 18, 19, -23, 25, -26, -41, 43, -44, -50],
  'Calhoun City': [26],
  'Calhoun Falls': [41],
  'Calico Rock': [3],
  Caliente: [5, 34],
  Califon: [32],
  California: [18, 21, 25, 39],
  'California City': [5],
  'California Hot Springs': [5],
  Calimesa: [5],
  Calion: [3],
  Calipatria: [5],
  Calistoga: [5],
  Call: [44],
  Callahan: [5, 10, -44],
  Callands: [46],
  Callao: [25, 46],
  Callaway: [21, 24, -25, 30, 46],
  Callender: [13],
  Callensburg: [39],
  Callery: [39],
  Callicoon: [35],
  'Callicoon Center': [35],
  Calliham: [44],
  Calloway: [-18],
  Calmar: [13],
  Calpella: [5],
  Calpine: [5],
  Calumet: [13, 23, 24, 37, 39, -49],
  'Calumet City': [15],
  Calvary: [11],
  Calvert: [2, -21, 44],
  'Calvert City': [18],
  Calverton: [35, 46],
  Calvin: [18, 19, 29, 37, 39, 50],
  Calypso: [28],
  Camak: [11],
  Camanche: [13],
  'Camano Island': [48],
  Camargo: [15, 37],
  Camarillo: [5],
  Camas: [-14, 48],
  'Camas Valley': [38],
  Cambra: [39],
  Cambria: [5, 15, -39, 49],
  'Cambria Heights': [35],
  Cambridge: [13, 14, 15, 17, 20, 21, 22, 24, 30, 35, 36, 47, 49],
  'Cambridge City': [16],
  'Cambridge Springs': [39],
  Cambridgeport: [47],
  Camby: [16],
  Camden: [2, 3, -11, 15, 16, 22, 23, 25, -25, 26, 28, -28, 32, -32, 35, 36, 41, 43, 44, 50],
  'Camden On Gauley': [50],
  'Camden Point': [25],
  'Camden Wyoming': [9],
  Camdenton: [25],
  Cameron: [4, 15, 19, -19, 25, 27, 28, 35, 36, 37, -39, 41, 44, -44, 49, 50],
  'Cameron Mills': [35],
  'Cameron Park': [5],
  Camilla: [11],
  Camillus: [35],
  Camino: [5],
  Cammal: [39],
  Camp: [3, -44],
  'Camp Creek': [50],
  'Camp Crook': [42],
  'Camp Dennison': [36],
  'Camp Douglas': [49],
  'Camp Grove': [15],
  'Camp H M Smith': [12],
  'Camp Hill': [2, 39],
  'Camp Lake': [49],
  'Camp Lejeune': [28],
  'Camp Meeker': [5],
  'Camp Murray': [48],
  'Camp Nelson': [5],
  'Camp Pendleton': [5],
  'Camp Point': [15],
  'Camp Sherman': [38],
  'Camp Verde': [4],
  'Camp Wood': [44],
  Campaign: [43],
  Campbell: [2, 5, -18, 24, 25, 30, 35, 36, -42, -43, 44, -46, -51],
  'Campbell Hall': [35],
  'Campbell Hill': [15],
  Campbellsburg: [16, 18],
  Campbellsport: [49],
  Campbellsville: [18],
  Campbellton: [10, 44],
  Campbelltown: [39],
  Campo: [5, 6],
  'Campo Seco': [5],
  Campobello: [41],
  Campti: [19],
  Campton: [18, 31],
  Camptonville: [5],
  Camptown: [39],
  Campus: [15],
  Cana: [46],
  Canaan: [7, 16, 22, 31, 35, 47],
  Canada: [18],
  Canadensis: [39],
  Canadian: [37, -37, 44],
  'Canadian Lakes': [23],
  Canadys: [41],
  Canajoharie: [35],
  'Canal Fulton': [36],
  'Canal Point': [10],
  'Canal Winchester': [36],
  Canalou: [25],
  Canandaigua: [35],
  Canaseraga: [35],
  Canastota: [35],
  Canby: [5, 24, 38],
  Candia: [31],
  Candler: [10, -11, 28],
  Cando: [29],
  Candor: [28, 35],
  'Cane Valley': [18],
  Caneadea: [35],
  Canehill: [3],
  Caney: [17, 37],
  Caneyville: [18],
  Canfield: [36],
  Canisteo: [35],
  Canistota: [42],
  Canjilon: [33],
  Canmer: [18],
  'Cannel City': [18],
  Cannelburg: [16],
  Cannelton: [16, 50],
  Cannon: [18, -43],
  'Cannon Afb': [33],
  'Cannon Ball': [29],
  'Cannon Beach': [38],
  'Cannon Falls': [24],
  Cannonsburg: [23],
  Cannonville: [45],
  'Canoga Park': [5],
  Canon: [11],
  'Canon City': [6],
  Canones: [33],
  Canonsburg: [39],
  Canova: [42],
  Canterbury: [7, 31],
  Cantil: [5],
  Canton: [7, 11, 15, 17, 20, 22, 23, 24, 25, 26, 28, 35, 36, 37, 39, 42, 44],
  'Canton Center': [7],
  Cantonment: [10],
  Cantrall: [15],
  Cantril: [13],
  'Cantua Creek': [5],
  Cantwell: [1],
  Canute: [37],
  Canutillo: [44],
  Canvas: [50],
  Canyon: [5, -14, 24, 44],
  'Canyon City': [38],
  'Canyon Country': [5],
  'Canyon Creek': [27],
  'Canyon Dam': [5],
  'Canyon Lake': [5, 44],
  Canyonville: [38],
  Capac: [23],
  Capay: [5],
  'Cape Canaveral': [10],
  'Cape Charles': [46],
  'Cape Coral': [10],
  'Cape Elizabeth': [22],
  'Cape Fair': [25],
  'Cape Girardeau': [25, -25],
  'Cape May': [32, -32],
  'Cape May Court House': [32],
  'Cape May Point': [32],
  'Cape Neddick': [22],
  'Cape Porpoise': [22],
  'Cape Vincent': [35],
  Capeville: [46],
  'Capistrano Beach': [5],
  Capitan: [33],
  Capitol: [27],
  'Capitol Heights': [21],
  Capitola: [5],
  'Caplinger Mills': [25],
  'Capon Bridge': [50],
  'Capon Springs': [50],
  Caprock: [33],
  Capron: [15, 46],
  Capshaw: [2],
  'Captain Cook': [12],
  Captiva: [10],
  Capulin: [6, 33],
  Caputa: [42],
  Caratunk: [22],
  Caraway: [3],
  Carbon: [13, 16, -27, -39, 44, -45, -51],
  'Carbon Cliff': [15],
  'Carbon Hill': [2, 36],
  Carbonado: [48],
  Carbondale: [6, 15, 17, 36, 39],
  Cardale: [39],
  'Cardiff By The Sea': [5],
  Cardin: [37],
  Cardinal: [46],
  Cardington: [36],
  Cardwell: [25, 27],
  Carefree: [4],
  Carencro: [19],
  Caret: [46],
  Carey: [14, 36],
  Careywood: [14],
  Caribou: [-14, 22],
  'Carl Junction': [25],
  'Carle Place': [35],
  Carleton: [23, 30],
  Carlin: [34],
  Carlinville: [15],
  Carlisle: [3, 13, 16, 18, -18, 20, 35, 39, 41],
  Carlock: [15],
  Carlos: [24],
  Carlotta: [5],
  Carlsbad: [5, 33, 44],
  Carlsborg: [48],
  Carlstadt: [32],
  Carlton: [11, 24, -24, 38, 39, 44, 48],
  Carlyle: [15],
  Carman: [15],
  Carmel: [5, 16, 22, 35],
  'Carmel By The Sea': [5],
  'Carmel Valley': [5],
  Carmen: [14, 37],
  Carmi: [15],
  Carmichael: [5],
  Carmichaels: [39],
  Carmine: [44],
  Carnation: [48],
  Carnegie: [37, 39],
  'Carnelian Bay': [5],
  Carnesville: [11],
  Carney: [23, 37],
  Caro: [23],
  'Caroga Lake': [35],
  'Carol Stream': [15],
  Caroleen: [28],
  Carolina: [40, 50],
  'Carolina Beach': [28],
  Caroline: [-21, -46, 49],
  'Carp Lake': [23],
  Carpenter: [13, 42, 51],
  Carpentersville: [15],
  Carpinteria: [5],
  Carpio: [29],
  Carr: [6],
  'Carrabassett Valley': [22],
  Carrabelle: [10],
  Carrboro: [28],
  Carrie: [18],
  Carrier: [37],
  'Carrier Mills': [15],
  Carriere: [26],
  Carrington: [29],
  'Carrizo Springs': [44],
  Carrizozo: [33],
  Carroll: [-3, -11, 13, -13, -15, -16, -18, -21, -25, -26, 30, -31, 36, -36, -43, -46],
  Carrolls: [48],
  Carrollton: [2, 11, 15, 18, 23, 25, 26, 36, 44, 46],
  Carrolltown: [39],
  Carrsville: [46],
  Carson: [5, 13, 26, 29, 33, -44, 46, 48],
  'Carson City': [23, 34, -34],
  Carsonville: [23],
  Carter: [18, -18, -25, 27, -27, 37, -37, -43],
  'Carter Lake': [13],
  Carteret: [-28, 32],
  Cartersville: [11, 46],
  Carterville: [15, 25],
  Carthage: [3, 15, 16, 25, 26, 28, 35, 42, 43, 44],
  Cartwright: [29, 37],
  Caruthers: [5],
  Caruthersville: [25],
  Carver: [20, 24, -24],
  Carversville: [39],
  Carville: [19],
  Cary: [15, 26, 28],
  Caryville: [10, 43],
  Casa: [3],
  'Casa Blanca': [33],
  'Casa Grande': [4],
  Casanova: [46],
  Casar: [28],
  Cascade: [6, 13, 14, 21, 25, 27, -27, 46, 49],
  'Cascade Locks': [38],
  Cascadia: [38],
  Cascilla: [26],
  Casco: [22, 23, 49],
  Caseville: [23],
  Casey: [13, 15, -18],
  Caseyville: [15],
  Cash: [3],
  Cashiers: [28],
  Cashion: [4, 37],
  Cashmere: [48],
  Cashton: [49],
  Cashtown: [39],
  Casmalia: [5],
  Casnovia: [23],
  Cason: [44],
  Caspar: [5],
  Casper: [51],
  Caspian: [23],
  Cass: [-13, -15, -16, -23, -24, -25, -29, -30, -44, 50],
  'Cass City': [23],
  'Cass Lake': [24],
  Cassadaga: [10, 35],
  Cassandra: [39],
  Cassatt: [41],
  Casscoe: [3],
  Cassel: [5],
  Casselberry: [10],
  Casselton: [29],
  Cassia: [-14],
  Cassoday: [17],
  Cassopolis: [23],
  Casstown: [36],
  Cassville: [11, 25, 35, 39, 49, 50],
  Castaic: [5],
  Castalia: [13, 28, 36],
  'Castalian Springs': [43],
  Castana: [13],
  Castanea: [39],
  Castell: [44],
  Castella: [5],
  Castile: [35],
  Castine: [22],
  Castle: [37],
  'Castle Creek': [35],
  'Castle Dale': [45],
  'Castle Hayne': [28],
  'Castle Pines': [6],
  'Castle Point': [35],
  'Castle Rock': [6, 24, 48],
  Castleberry: [2],
  Castleford: [14],
  Castleton: [15, 46, 47],
  'Castleton On Hudson': [35],
  Castlewood: [42, 46],
  Castor: [19],
  Castorland: [35],
  Castro: [-44],
  'Castro Valley': [5],
  Castroville: [5, 44],
  Caswell: [-28],
  'Cat Spring': [44],
  Catahoula: [-19],
  Cataldo: [14],
  Catalina: [4],
  Cataract: [49],
  Catarina: [44],
  Catasauqua: [39],
  Cataula: [11],
  Cataumet: [20],
  Catawba: [28, -28, 36, 41, 46, 49],
  Catawissa: [25, 39],
  Catharine: [17],
  Catharpin: [46],
  Cathay: [29],
  'Cathedral City': [5],
  Catherine: [2],
  'Catheys Valley': [5],
  Cathlamet: [48],
  Catlett: [46],
  Catlettsburg: [18],
  Catlin: [15],
  Cato: [35],
  Catonsville: [21],
  Catoosa: [-11, 37],
  Catron: [25, -33],
  Catskill: [35],
  Cattaraugus: [35, -35],
  Caulfield: [25],
  Causey: [33],
  Cavalier: [29, -29],
  'Cave City': [3, 18],
  'Cave Creek': [4],
  'Cave In Rock': [15],
  'Cave Junction': [38],
  'Cave Spring': [11, 46],
  'Cave Springs': [3],
  Cavendish: [47],
  Cavetown: [21],
  Cavour: [42],
  'Cawker City': [17],
  Cawood: [18],
  Cayce: [41],
  Cayucos: [5],
  Cayuga: [16, 29, 35, -35, 44],
  Cayuta: [35],
  Cazadero: [5],
  Cazenovia: [35, 49],
  Cebolla: [33],
  Cecil: [2, 3, 11, -21, 36, 39, 49],
  Cecilia: [18, 19],
  Cecilton: [21],
  Cedar: [13, -13, 17, 23, 24, -25, -30],
  'Cedar Bluff': [2, 46],
  'Cedar Bluffs': [30],
  'Cedar Brook': [32],
  'Cedar City': [45],
  'Cedar Creek': [30, 44],
  'Cedar Crest': [33],
  'Cedar Falls': [13, 28],
  'Cedar Glen': [5],
  'Cedar Grove': [16, 28, 32, 43, 49, 50],
  'Cedar Hill': [25, 43, 44],
  'Cedar Island': [28],
  'Cedar Key': [10],
  'Cedar Knolls': [32],
  'Cedar Lake': [16, 23],
  'Cedar Lane': [44],
  'Cedar Mountain': [28],
  'Cedar Park': [44],
  'Cedar Point': [15, 17, 28],
  'Cedar Rapids': [13, 30],
  'Cedar Ridge': [5],
  'Cedar Run': [39],
  'Cedar Springs': [11, 23],
  'Cedar Vale': [17],
  'Cedar Valley': [45],
  Cedarbluff: [26],
  Cedarburg: [49],
  Cedarcreek: [25],
  Cedaredge: [6],
  Cedarhurst: [35],
  'Cedarpines Park': [5],
  Cedars: [39],
  Cedartown: [11],
  Cedarvale: [33],
  Cedarville: [3, 5, 15, 23, 32, 36, 50],
  'Cee Vee': [44],
  Celebration: [10],
  Celeste: [44],
  Celestine: [16],
  Celina: [36, 43, 44],
  Celoron: [35],
  Cement: [37],
  'Cement City': [23],
  Centenary: [41],
  Centennial: [6, 51],
  Center: [6, 18, 25, 29, 30, 44],
  'Center Barnstead': [31],
  'Center City': [24],
  'Center Conway': [31],
  'Center Cross': [46],
  'Center Harbor': [31],
  'Center Hill': [10],
  'Center Junction': [13],
  'Center Line': [23],
  'Center Lovell': [22],
  'Center Moriches': [35],
  'Center Ossipee': [31],
  'Center Point': [13, 19, 44, 50],
  'Center Ridge': [3],
  'Center Rutland': [47],
  'Center Sandwich': [31],
  'Center Strafford': [31],
  'Center Tuftonboro': [31],
  'Center Valley': [39],
  Centerbrook: [7],
  Centerburg: [36],
  Centereach: [35],
  Centerfield: [45],
  Centerpoint: [16],
  Centerport: [35, 39],
  Centerton: [3],
  Centertown: [18, 25],
  Centerview: [25],
  Centerville: [3, 9, 11, 13, 16, 17, 19, 20, 25, 35, 36, 39, 42, 43, 44, 45, 48],
  Centrahoma: [37],
  Central: [1, 4, 16, 41, 45],
  'Central Bridge': [35],
  'Central City': [6, 13, 18, 30, 39],
  'Central Falls': [40],
  'Central Islip': [35],
  'Central Lake': [23],
  'Central Point': [38],
  'Central Square': [35],
  'Central Valley': [35],
  'Central Village': [7],
  Centralia: [15, 17, 25, 44, 48],
  Centre: [2, -39],
  'Centre Hall': [39],
  Centreville: [2, 21, 23, 26, 46],
  Centuria: [49],
  Century: [10],
  Ceredo: [50],
  Ceres: [5, 35, 46],
  Ceresco: [23, 30],
  Cerrillos: [33],
  Cerritos: [5],
  Cerro: [33],
  'Cerro Gordo': [-13, 15, 28],
  Cerulean: [18],
  Ceylon: [24],
  Chacon: [33],
  Chadbourn: [28],
  'Chadds Ford': [39],
  Chadron: [30],
  Chadwick: [15, 25],
  Chadwicks: [35],
  Chaffee: [-6, 25, 35],
  'Chagrin Falls': [36],
  Chalfont: [39],
  'Chalk Hill': [39],
  Chalkyitsik: [1],
  Challenge: [5],
  Challis: [14],
  Chalmers: [16],
  Chalmette: [19],
  Chama: [6, 33],
  Chamberino: [33],
  Chamberlain: [22, 42],
  Chambers: [-2, 4, 30, -44],
  Chambersburg: [15, 39],
  Chambersville: [39],
  Chamblee: [11],
  Chamisal: [33],
  Chamois: [25],
  Champaign: [15, -15, -36],
  Champion: [23, 30, 39],
  Champlain: [35, 46],
  Champlin: [24],
  Chana: [15],
  Chancellor: [2, 42],
  Chandler: [4, 16, 24, 37, 44],
  'Chandler Heights': [4],
  'Chandlers Valley': [39],
  Chandlersville: [36],
  Chandlerville: [15],
  Changewater: [32],
  Chanhassen: [24],
  Channahon: [15],
  Channelview: [44],
  Channing: [23, 44],
  Chantilly: [46],
  Chanute: [17],
  Chaparral: [33],
  'Chapel Hill': [28, 43],
  Chapin: [13, 15, 41],
  Chaplin: [7, 18],
  Chapman: [2, 17, 30],
  'Chapman Ranch': [44],
  Chapmansboro: [43],
  Chapmanville: [50],
  Chappaqua: [35],
  Chappell: [18, 30],
  'Chappell Hill': [44],
  Chappells: [41],
  Chaptico: [21],
  Chardon: [36],
  Charenton: [19],
  Chariton: [13, -25],
  Charlemont: [20],
  Charleroi: [39],
  Charles: [-21],
  'Charles City': [13, 46, -46],
  'Charles Mix': [-42],
  'Charles Town': [50],
  Charleston: [3, 15, 22, 25, 26, 41, -41, 43, 50],
  'Charleston Afb': [41],
  Charlestown: [16, 20, 21, 31, 40],
  Charlevoix: [23, -23],
  Charlo: [27],
  Charlotte: [3, -10, 13, 23, 28, 43, 44, -46, 47],
  'Charlotte Court House': [46],
  'Charlotte Hall': [21],
  Charlottesville: [16, 46],
  Charlotteville: [35],
  Charlton: [-11, 20],
  'Charlton City': [20],
  'Charlton Depot': [20],
  'Charlton Heights': [50],
  Charm: [36],
  Charmco: [50],
  'Charter Oak': [13],
  Chartley: [20],
  Chase: [17, -17, 19, 21, 23, -30],
  'Chase City': [46],
  'Chase Mills': [35],
  Chaseburg: [49],
  Chaseley: [29],
  Chaska: [24],
  Chassell: [23],
  Chataignier: [19],
  Chatawa: [26],
  Chateaugay: [35],
  Chatfield: [24, 36, 44],
  Chatham: [-11, 15, 19, 20, 23, 26, -28, 32, 35, 39, 46],
  Chatom: [2],
  Chatsworth: [5, 11, 13, 15, 32],
  Chattahoochee: [10, -11],
  Chattanooga: [37, 43],
  Chattaroy: [48, 50],
  Chattooga: [-11],
  Chaumont: [35],
  Chauncey: [11, 36, 50],
  Chautauqua: [17, -17, 35, -35],
  Chauvin: [19],
  Chaves: [-33],
  Chavies: [18],
  Chazy: [35],
  Cheatham: [-43],
  Chebanse: [15],
  'Chebeague Island': [22],
  Cheboygan: [23, -23],
  Check: [46],
  Checotah: [37],
  Chefornak: [1],
  Chehalis: [48],
  Chelan: [48, -48],
  'Chelan Falls': [48],
  Chelmsford: [20],
  Chelsea: [2, 13, 20, 23, 35, 37, 47],
  Cheltenham: [21, 39],
  Chemult: [38],
  Chemung: [35, -35],
  Chenango: [-35],
  'Chenango Bridge': [35],
  'Chenango Forks': [35],
  Cheney: [17, 48],
  Cheneyville: [19],
  Chenoa: [15],
  Chepachet: [40],
  Cheraw: [6, 41],
  Cheriton: [46],
  Cherokee: [2, -2, -11, 13, -13, 17, -17, 28, -28, 37, -37, -41, 44, -44],
  'Cherokee Village': [3],
  Cherry: [15, -30],
  'Cherry Creek': [35, 42],
  'Cherry Fork': [36],
  'Cherry Hill': [32],
  'Cherry Log': [11],
  'Cherry Plain': [35],
  'Cherry Point': [28],
  'Cherry Tree': [39],
  'Cherry Valley': [3, 15, 20, 35],
  Cherryfield: [22],
  Cherryvale: [17],
  Cherryville: [25, 28, 39],
  Chesaning: [23],
  Chesapeake: [36, 46],
  'Chesapeake Beach': [21],
  'Chesapeake City': [21],
  Cheshire: [7, 20, -31, 36, 38],
  Chesnee: [41],
  'Chest Springs': [39],
  Chester: [3, 5, 7, 11, 13, 14, 15, 20, 21, 27, 30, 31, 32, 35, 36, 37, 39, -39, 41, -41, 42, -43, 44, 45, 46, 47, 50],
  'Chester Gap': [46],
  'Chester Heights': [39],
  'Chester Springs': [39],
  Chesterfield: [15, 20, 25, 31, 32, 41, -41, 46, -46],
  Chesterhill: [36],
  Chesterland: [36],
  Chesterton: [16],
  Chestertown: [21, 35],
  Chesterville: [36],
  Chestnut: [15],
  'Chestnut Hill': [20],
  'Chestnut Mound': [43],
  'Chestnut Mountain': [11],
  'Chestnut Ridge': [39],
  Chestnutridge: [25],
  Cheswick: [39],
  Cheswold: [9],
  Chetek: [49],
  Chetopa: [17],
  Chevak: [1],
  'Chevy Chase': [21],
  Chewalla: [43],
  Chewelah: [48],
  Chewsville: [21],
  Cheyenne: [-6, -17, -30, 37, 51],
  'Cheyenne Wells': [6],
  Cheyney: [39],
  Chicago: [15],
  'Chicago Heights': [15],
  'Chicago Park': [5],
  'Chicago Ridge': [15],
  Chichester: [31, 35],
  Chickamauga: [11],
  Chickasaw: [-13, -26, 36],
  Chickasha: [37],
  Chicken: [1],
  Chico: [5, 44],
  Chicopee: [20],
  Chicora: [39],
  Chicot: [-3],
  Chicota: [44],
  Chidester: [3],
  Chiefland: [10],
  Chignik: [1],
  'Chignik Lagoon': [1],
  'Chignik Lake': [1],
  Chilcoot: [5],
  Childersburg: [2],
  Childress: [44, -44],
  Childs: [21],
  Childwold: [35],
  Chilhowee: [25],
  Chilhowie: [46],
  Chili: [49],
  Chillicothe: [13, 15, 25, 36, 44],
  Chilmark: [20],
  Chilo: [36],
  Chiloquin: [38],
  Chilton: [-2, 44, 49],
  Chimacum: [48],
  Chimayo: [33],
  'Chimney Rock': [28],
  China: [44],
  'China Grove': [28],
  'China Spring': [44],
  'China Village': [22],
  Chinchilla: [39],
  Chincoteague: [46],
  'Chincoteague Island': [46],
  'Chinese Camp': [5],
  Chinle: [4],
  Chino: [5],
  'Chino Hills': [5],
  'Chino Valley': [4],
  Chinook: [27, 48],
  Chinquapin: [28],
  Chipley: [10],
  Chippewa: [-23, -24, -49],
  'Chippewa Bay': [35],
  'Chippewa Falls': [49],
  'Chippewa Lake': [23, 36],
  Chireno: [44],
  Chisago: [-24],
  'Chisago City': [24],
  Chisholm: [24],
  Chitina: [1],
  Chittenango: [35],
  Chittenden: [47, -47],
  Chloe: [50],
  Chloride: [4],
  Choccolocco: [2],
  Chocorua: [31],
  Chocowinity: [28],
  Choctaw: [-2, 3, -26, 37, -37],
  Chokio: [24],
  Chokoloskee: [10],
  Choteau: [27],
  Choudrant: [19],
  Chouteau: [-27, 37],
  Chowan: [-28],
  Chowchilla: [5],
  Chriesman: [44],
  Chrisman: [15],
  Chrisney: [16],
  Christchurch: [46],
  Christian: [-15, -18, -25],
  Christiana: [39, 43],
  Christiansburg: [36, 46],
  Christine: [29, 44],
  Christmas: [10],
  'Christmas Valley': [38],
  Christopher: [15],
  Christoval: [44],
  Chromo: [6],
  Chualar: [5],
  Chuckey: [43],
  Chugach: [-1],
  Chugiak: [1],
  Chugwater: [51],
  Chula: [11, 25],
  'Chula Vista': [5],
  Chunchula: [2],
  Chunky: [26],
  'Church Creek': [21],
  'Church Hill': [21, 43],
  'Church Point': [19],
  'Church Road': [46],
  'Church Rock': [33],
  'Church View': [46],
  Churchill: [-34],
  'Churchs Ferry': [29],
  Churchton: [21],
  Churchville: [21, 35, 46],
  Churdan: [13],
  Churubusco: [16, 35],
  Cibecue: [4],
  Cibola: [4, -33],
  Cibolo: [44],
  Cicero: [15, 16, 35],
  Cima: [5],
  Cimarron: [6, 17, 33, -37],
  Cincinnati: [13, 36],
  Cincinnatus: [35],
  Cinebar: [48],
  Circle: [1, 27],
  'Circle Pines': [24],
  Circleville: [17, 35, 36, 45, 50],
  Cisco: [11, 15, 44, 45],
  Cisne: [15],
  'Cissna Park': [15],
  Citra: [10],
  Citronelle: [2],
  Citrus: [-10],
  'Citrus Heights': [5],
  'Citrus Springs': [10],
  'City Of Industry': [5],
  Clackamas: [38, -38],
  Claflin: [17],
  Claiborne: [-19, 21, -26, -43],
  'Claire City': [42],
  Clairfield: [43],
  Clairton: [39],
  Clallam: [-48],
  'Clallam Bay': [48],
  'Clam Gulch': [1],
  'Clam Lake': [49],
  Clancy: [27],
  Clanton: [2],
  Clara: [26],
  'Clara City': [24],
  Clarcona: [10],
  Clare: [13, 15, 23, -23],
  Claremont: [5, 15, 24, 28, 31, 42, 46],
  Claremore: [37],
  Clarence: [13, 19, 25, 35, 39],
  'Clarence Center': [35],
  Clarendon: [3, 28, 35, 39, -41, 44],
  'Clarendon Hills': [15],
  Claridge: [39],
  Clarinda: [13],
  Clarington: [36, 39],
  Clarion: [13, 39, -39],
  Clarissa: [24],
  Clarita: [37],
  Clark: [-3, 6, -14, -15, -16, -17, -18, 25, -25, 32, -34, -36, 39, 42, -42, -48, -49],
  'Clark Fork': [14],
  'Clark Mills': [35],
  Clarkdale: [4, 11],
  Clarke: [-2, -11, -13, -26, -46],
  Clarkedale: [3],
  Clarkesville: [11],
  Clarkfield: [24],
  Clarkia: [14],
  Clarklake: [23],
  Clarkrange: [43],
  Clarkridge: [3],
  Clarks: [19, 30],
  'Clarks Grove': [24],
  'Clarks Hill': [16, 41],
  'Clarks Mills': [39],
  'Clarks Point': [1],
  'Clarks Summit': [39],
  Clarksboro: [32],
  Clarksburg: [5, 16, 21, 25, 36, 39, 43, 50],
  Clarksdale: [25, 26],
  Clarkson: [18, 30, 35],
  Clarkston: [11, 23, 45, 48],
  Clarksville: [3, 10, 13, 16, 21, 23, 25, 35, 36, 39, 43, 44, 46],
  Clarkton: [25, 28],
  Claryville: [35],
  Clatonia: [30],
  Clatskanie: [38],
  Clatsop: [-38],
  Claude: [44],
  Claudville: [46],
  Claunch: [33],
  Claverack: [35],
  Clawson: [23, 45],
  Claxton: [11],
  Clay: [2, -2, -3, -10, -11, -13, -15, -16, -17, 18, -18, 23, -24, -25, -26, -28, -30, 35, -42, -43, -44, 50, -50],
  'Clay Center': [17, 30, 36],
  'Clay City': [15, 16, 18],
  'Clay Springs': [4],
  Clayhole: [18],
  Claymont: [9],
  Claypool: [4, 16],
  Claysburg: [39],
  Claysville: [39],
  Clayton: [2, 5, 9, 11, -11, -13, 14, 15, 16, 17, 19, 23, 28, 32, 33, 35, 36, 37, 44, 48, 49],
  'Clayton Lake': [22],
  Claytonville: [15],
  Clayville: [35, 40],
  'Cle Elum': [48],
  Clear: [1],
  'Clear Brook': [46],
  'Clear Creek': [-6, 16, 50],
  'Clear Fork': [50],
  'Clear Lake': [13, 24, 42, 49],
  'Clear Spring': [21],
  Clearbrook: [24],
  Clearfield: [13, 18, 39, -39, 45],
  Clearlake: [5, 48],
  'Clearlake Oaks': [5],
  'Clearlake Park': [5],
  Clearmont: [25, 51],
  Clearville: [39],
  Clearwater: [10, -14, 17, 24, -24, 30, 41],
  'Clearwater Beach': [10],
  Cleaton: [18],
  Cleburne: [-2, -3, 44],
  Cleghorn: [13],
  Clementon: [32],
  Clements: [5, 21, 24],
  Clemmons: [28],
  Clemons: [13, 35],
  Clemson: [41],
  Clendenin: [50],
  'Cleo Springs': [37],
  Clermont: [10, 11, 13, 18, -36],
  Cleveland: [2, 3, -3, 11, 24, 25, 26, 28, -28, 29, 33, 35, 36, 37, -37, 41, 43, 44, 45, 46, 49, 50],
  Clever: [25],
  Cleverdale: [35],
  Cleves: [36],
  Clewiston: [10],
  Cliff: [33],
  'Cliff Island': [22],
  Clifford: [16, 23, 29, 39, 46],
  Cliffside: [28],
  'Cliffside Park': [32],
  Cliffwood: [32],
  Clifton: [4, 6, 14, 15, 17, 32, 36, 41, 43, 44, 46],
  'Clifton Forge': [46],
  'Clifton Heights': [39],
  'Clifton Hill': [25],
  'Clifton Park': [35],
  'Clifton Springs': [35],
  'Clifton Township': [39],
  Clifty: [18],
  Climax: [6, 11, 23, 24, 28, 35],
  'Climax Springs': [25],
  'Climbing Hill': [13],
  Clinch: [-11],
  Clinchco: [46],
  Clinchfield: [11],
  'Clines Corners': [33],
  Clint: [44],
  Clinton: [2, 3, 7, 13, -13, 15, -15, 16, -16, 18, -18, 19, 20, 21, 22, 23, -23, 24, 25, -25, 26, 27, 28, 32, 35, -35, 36, -36, 37, 39, -39, 41, 43, 48, 49],
  'Clinton Corners': [35],
  'Clinton Township': [23],
  Clintondale: [35],
  Clintonville: [39, 49],
  Clintwood: [46],
  Clio: [2, 5, 13, 23, 41],
  'Clipper Mills': [5],
  Clitherall: [24],
  Clive: [13],
  Clockville: [35],
  Clontarf: [24],
  Clopton: [2],
  Cloquet: [24],
  Closplint: [18],
  Closter: [32],
  Clothier: [50],
  Cloud: [-17],
  Cloudcroft: [33],
  Cloutierville: [19],
  Clover: [41, 46],
  Cloverdale: [2, 5, 16, 23, 36, 38, 46],
  Cloverport: [18],
  Clovis: [5, 33],
  Clubb: [25],
  Clune: [39],
  'Cluster Springs': [46],
  Clute: [44],
  Clutier: [13],
  Clyde: [17, 25, 28, 35, 36, 44],
  'Clyde Park': [27],
  Clyman: [49],
  Clymer: [35, 39],
  Clyo: [11],
  Coachella: [5],
  Coahoma: [26, -26, 44],
  Coal: [-37],
  'Coal Center': [39],
  'Coal City': [15, 16, 50],
  'Coal Creek': [6],
  'Coal Hill': [3],
  'Coal Mountain': [50],
  'Coal Run': [36],
  'Coal Township': [39],
  'Coal Valley': [15],
  Coaldale: [6, 39],
  Coalfield: [43],
  Coalgate: [37],
  Coalgood: [18],
  Coaling: [2],
  Coalinga: [5],
  Coalmont: [6, 16, 43],
  Coalport: [39],
  Coalton: [36, 50],
  Coalville: [45],
  Coarsegold: [5],
  Coatesville: [16, 39],
  Coats: [17, 28],
  Coatsburg: [15],
  Coatsville: [25],
  Cobalt: [7, 14],
  Cobb: [5, 11, -11, 49],
  'Cobb Island': [21],
  'Cobbs Creek': [46],
  Cobbtown: [11],
  Cobden: [15],
  Cobleskill: [35],
  Coburn: [39],
  Cochecton: [35],
  Cochise: [4, -4],
  'Cochiti Lake': [33],
  'Cochiti Pueblo': [33],
  Cochran: [11, -44],
  Cochrane: [49],
  Cochranton: [39],
  Cochranville: [39],
  Cocke: [-43],
  Cockeysville: [21],
  Cocoa: [10],
  'Cocoa Beach': [10],
  Cocolalla: [14],
  Cocolamus: [39],
  Coconino: [-4],
  'Coconut Creek': [10],
  'Coconut Grove': [10],
  Coden: [2],
  Codington: [-42],
  Codorus: [39],
  Cody: [30, 51],
  Coeburn: [46],
  Coello: [15],
  'Coeur d\u2019Alene': [14],
  Coeymans: [35],
  'Coeymans Hollow': [35],
  Coffee: [-2, -11, -43],
  'Coffee Creek': [27],
  'Coffee Springs': [2],
  Coffeen: [15],
  Coffeeville: [2, 26],
  Coffey: [-17, 25],
  Coffeyville: [17],
  'Coffman Cove': [1],
  Cofield: [28],
  'Cogan Station': [39],
  Coggon: [13],
  Cogswell: [29],
  Cohagen: [27],
  Cohasset: [20, 24],
  Cohoctah: [23],
  Cohocton: [35],
  Cohoes: [35],
  Cohutta: [11],
  Coila: [26],
  Coin: [13],
  Coinjock: [28],
  Cokato: [24],
  Coke: [-44],
  Cokeburg: [39],
  Coker: [2],
  'Coker Creek': [43],
  Cokeville: [51],
  Colbert: [-2, 11, 37, 48],
  Colburn: [14],
  Colby: [17, 49],
  Colchester: [7, 15, 47],
  Colcord: [37, 50],
  'Cold Bay': [1],
  'Cold Brook': [35],
  'Cold Spring': [18, 24, 35],
  'Cold Spring Harbor': [35],
  Colden: [35],
  Coldiron: [18],
  Coldspring: [44],
  Coldwater: [17, 23, 26, 36],
  Cole: [-25],
  'Cole Camp': [25],
  Colebrook: [7, 31],
  Coleharbor: [29],
  Coleman: [10, 11, 23, 37, 44, -44, 49],
  'Coleman Falls': [46],
  Colerain: [28, 36],
  Coleraine: [24],
  Coleridge: [30],
  Coles: [-15],
  'Coles Point': [46],
  Colesburg: [13],
  Coleville: [5],
  Colfax: [5, 13, 15, 16, 19, 28, 29, -30, -33, 48, 49, 50],
  Colgate: [49],
  Collbran: [6],
  'College Corner': [36],
  'College Grove': [43],
  'College Park': [21],
  'College Place': [48],
  'College Point': [35],
  'College Springs': [13],
  'College Station': [3, 44],
  Collegedale: [43],
  Collegeport: [44],
  Collegeville: [24, 39],
  Colleton: [-41],
  Collettsville: [28],
  Colleyville: [44],
  Collier: [-10],
  Colliers: [50],
  Colliersville: [35],
  Collierville: [43],
  Collin: [-44],
  Collingswood: [32],
  Collingsworth: [-44],
  Collins: [11, 13, 25, 26, 35, 36, 49],
  'Collins Center': [35],
  Collinston: [19, 45],
  Collinsville: [2, 7, 15, 26, 36, 37, 44, 46],
  Collinwood: [43],
  Collison: [15],
  Collyer: [17],
  Colman: [42],
  Colmar: [39],
  Colmesneil: [44],
  Colo: [13],
  Cologne: [24, 32],
  Coloma: [5, 23, 49],
  Colome: [42],
  Colon: [23, 30],
  Colona: [15],
  Colonia: [32],
  'Colonial Beach': [46],
  'Colonial Heights': [46],
  Colony: [17, 37],
  Colora: [21],
  Colorado: [-44],
  'Colorado City': [4, 6, 44],
  'Colorado Springs': [6],
  Colp: [15],
  Colquitt: [11, -11],
  Colrain: [20],
  Colstrip: [27],
  Colt: [3],
  Colton: [5, 35, 36, 38, 42, 48],
  'Coltons Point': [21],
  'Colts Neck': [32],
  Columbia: [2, -3, 5, 7, -10, -11, 13, 15, 18, 19, 21, 25, 26, 28, 32, -35, -38, 39, -39, 41, 42, 43, 46, -48, -49],
  'Columbia City': [16, 38],
  'Columbia Cross Roads': [39],
  'Columbia Falls': [22, 27],
  'Columbia Station': [36],
  Columbiana: [2, 36, -36],
  Columbiaville: [23, 35],
  Columbus: [3, 11, 16, 17, 18, 23, 26, 27, 28, -28, 29, 30, 32, 33, 36, 39, 44, 49],
  'Columbus City': [13],
  'Columbus Grove': [36],
  'Columbus Junction': [13],
  'Columbus-Muscogee': [-11],
  Colusa: [5, -5, 15],
  Colver: [39],
  Colville: [48],
  Colwell: [13],
  Colwich: [17],
  Comal: [-44],
  Comanche: [-17, 37, -37, 44, -44],
  Combes: [44],
  'Combined Locks': [49],
  Combs: [3, 18],
  Comer: [11],
  Comfort: [28, 44, 50],
  Comfrey: [24],
  Comins: [23],
  Commack: [35],
  Commerce: [11, 25, 37, 44],
  'Commerce City': [6],
  'Commerce Township': [23],
  'Commercial Point': [36],
  Commiskey: [16],
  Commodore: [39],
  Como: [6, 26, 28, 43, 44],
  Comptche: [5],
  Compton: [3, 5, 15, 21],
  Comstock: [23, 24, 30, 35, 44, 49],
  'Comstock Park': [23],
  Conasauga: [43],
  Concan: [44],
  Concepcion: [44],
  Conception: [25],
  'Conception Junction': [25],
  'Conchas Dam': [33],
  Concho: [4, 37, -44],
  Conconully: [48],
  Concord: [3, 5, 11, 15, 20, 23, 28, 30, 31, 39, 44, 46, 47],
  'Concord Township': [36],
  Concordia: [17, -19, 25],
  Concordville: [39],
  Concrete: [48],
  Conde: [42],
  Condon: [27, 38],
  Conecuh: [-2],
  Conehatta: [26],
  Conejos: [6, -6],
  Conestee: [41],
  Conestoga: [39],
  Conesus: [35],
  Conesville: [13, 36],
  Conetoe: [28],
  'Conewango Valley': [35],
  Confluence: [39],
  Conger: [24],
  Congers: [35],
  Congerville: [15],
  Congress: [4],
  Conifer: [6],
  Conklin: [23, 35],
  Conley: [11],
  Conneaut: [36],
  'Conneaut Lake': [39],
  Conneautville: [39],
  Connell: [48],
  Connellsville: [39],
  Connelly: [35],
  'Connelly Springs': [28],
  Conner: [27],
  Connersville: [16],
  Connerville: [37],
  Connoquenessing: [39],
  Conover: [28, 36, 49],
  Conowingo: [21],
  Conrad: [13, 27],
  Conrath: [49],
  Conroe: [44],
  Conroy: [13],
  Conshohocken: [39],
  Constable: [35],
  Constableville: [35],
  Constantia: [35],
  Constantine: [23],
  Continental: [36],
  'Continental Divide': [33],
  Contoocook: [31],
  'Contra Costa': [-5],
  Convent: [19],
  'Convent Station': [32],
  Converse: [16, 19, 41, 44, -51],
  Convoy: [36],
  Conway: [3, -3, 20, 23, 25, 28, 31, 39, 41, 48],
  'Conway Springs': [17],
  Conyers: [11],
  Conyngham: [39],
  Cook: [-11, -15, 24, -24, 30],
  'Cook Springs': [2],
  'Cook Sta': [25],
  Cooke: [-44],
  'Cooke City': [27],
  Cookeville: [43],
  Cooks: [23],
  Cooksburg: [39],
  Cookson: [37],
  Cookstown: [32],
  Cooksville: [15, 21],
  Cookville: [44],
  Cool: [5],
  'Cool Ridge': [50],
  Cooleemee: [28],
  Coolidge: [4, 11, 17, 44],
  Coolin: [14],
  Coolspring: [39],
  Coolville: [36],
  'Coon Rapids': [13, 24],
  'Coon Valley': [49],
  Cooper: [13, -25, 44],
  'Cooper Landing': [1],
  'Coopers Mills': [22],
  'Coopers Plains': [35],
  Coopersburg: [39],
  Cooperstown: [29, 35, 39],
  Coopersville: [23],
  Coos: [-38],
  'Coos Bay': [38],
  Coosa: [-2, 11],
  Coosada: [2],
  Coosawhatchie: [41],
  Cooter: [25],
  Copake: [35],
  'Copake Falls': [35],
  'Copalis Beach': [48],
  'Copalis Crossing': [48],
  Copan: [37],
  Cope: [6, 41],
  Copeland: [10, 17],
  Copemish: [23],
  Copen: [50],
  Copenhagen: [35],
  Copeville: [44],
  Copiague: [35],
  Copiah: [-26],
  Coplay: [39],
  Coppell: [44],
  'Copper Center': [1],
  'Copper City': [23],
  'Copper Harbor': [23],
  'Copper Hill': [46],
  'Copper River': [-1],
  'Copperas Cove': [44],
  Copperhill: [43],
  Copperopolis: [5],
  Coquille: [38],
  Cora: [50, 51],
  Coral: [23, 39],
  'Coral Gables': [10],
  'Coral Springs': [10],
  Coralville: [13],
  Coram: [27, 35],
  Coraopolis: [39],
  Corapeake: [28],
  Corbett: [38],
  Corbettsville: [35],
  Corbin: [18, 46],
  Corcoran: [5],
  Cord: [3],
  Cordele: [11],
  Cordell: [37],
  Corder: [25],
  Cordesville: [41],
  Cordova: [1, 2, 15, 21, 28, 30, 33, 41, 43],
  Corea: [22],
  Corfu: [35],
  Corinna: [22],
  Corinne: [45, 50],
  Corinth: [18, 22, 26, 35, 47],
  Corn: [37],
  Cornelia: [11],
  Cornelius: [28, 38],
  Cornell: [15, 23, 49],
  Cornersville: [43],
  Cornettsville: [18],
  Corning: [3, 5, 13, 17, 35, 36],
  Cornish: [22, 31, 45],
  'Cornish Flat': [31],
  Cornland: [15],
  Cornucopia: [49],
  Cornville: [4],
  Cornwall: [7, 35, 39],
  'Cornwall Bridge': [7],
  'Cornwall On Hudson': [35],
  Cornwallville: [35],
  Corolla: [28],
  Corona: [5, 33, 35, 42],
  'Corona Del Mar': [5],
  Coronado: [5],
  'Corpus Christi': [44],
  Corral: [14],
  Corrales: [33],
  Correctionville: [13],
  Correll: [24],
  Corrigan: [44],
  Corriganville: [21],
  Corry: [39],
  Corryton: [43],
  Corsica: [39, 42],
  Corsicana: [44],
  Corson: [-42],
  Cortaro: [4],
  'Corte Madera': [5],
  Cortez: [6, 10],
  Cortland: [15, 16, 30, 35, -35, 36],
  'Cortlandt Manor': [35],
  Corunna: [16, 23],
  Corvallis: [27, 38],
  Corwith: [13],
  Cory: [6, 16],
  Corydon: [13, 16, 18],
  Coryell: [-44],
  'Cos Cob': [7],
  Cosby: [25, 43],
  Coshocton: [36, -36],
  Cosmopolis: [48],
  Cosmos: [24],
  Cossayuna: [35],
  Cost: [44],
  Costa: [50],
  'Costa Mesa': [5],
  Costilla: [-6, 33],
  Cotati: [5],
  Cotopaxi: [6],
  'Cottage Grove': [24, 38, 43, 49],
  'Cottage Hills': [15],
  Cottageville: [41, 50],
  Cottekill: [35],
  Cotter: [3],
  Cottle: [-44],
  Cottleville: [25],
  Cotton: [11, 24, -37],
  'Cotton Center': [44],
  'Cotton Plant': [3],
  'Cotton Valley': [19],
  Cottondale: [2, 10],
  Cottonport: [19],
  Cottonton: [2],
  Cottontown: [43],
  Cottonwood: [2, 4, 5, 14, 24, -24],
  'Cottonwood Falls': [17],
  'Cottonwood Heights': [45],
  Cotuit: [20],
  Cotulla: [44],
  Couch: [25],
  Couderay: [49],
  Coudersport: [39],
  Cougar: [48],
  'Coulee City': [48],
  'Coulee Dam': [48],
  Coulter: [13],
  Coulters: [39],
  Coulterville: [5, 15],
  Counce: [43],
  Council: [14, 28],
  'Council Bluffs': [13],
  'Council Grove': [17],
  'Council Hill': [37],
  Counselor: [33],
  'Country Club Hills': [15],
  Countyline: [37],
  Coupeville: [48],
  Coupland: [44],
  Coupon: [39],
  Courtenay: [29],
  Courtland: [2, 5, 17, 24, 26, 46],
  Coushatta: [19],
  Cove: [3, 38],
  'Cove City': [28],
  Covel: [50],
  Covelo: [5],
  Coventry: [7, 40, 47],
  Covert: [23],
  Covesville: [46],
  Covina: [5],
  Covington: [-2, 11, 16, 18, 19, 23, -26, 36, 37, 39, 43, 44, 46],
  Cowan: [43],
  Cowansville: [39],
  Coward: [41],
  Cowarts: [2],
  Cowden: [15],
  Cowdrey: [6],
  Cowen: [50],
  Coweta: [-11, 37],
  Cowgill: [25],
  Cowiche: [48],
  Cowlesville: [35],
  Cowley: [-17, 51],
  Cowlitz: [-48],
  Cowpens: [41],
  'Coxs Creek': [18],
  'Coxs Mills': [50],
  Coxsackie: [35],
  Coy: [2, 3],
  Coyanosa: [44],
  Coyle: [37],
  Coyote: [5, 33],
  'Coyote Springs': [34],
  Cozad: [30],
  Coös: [-31],
  'Crab Orchard': [18, 30, 43, 50],
  Crabtree: [38, 39],
  Craddockville: [46],
  Craftsbury: [47],
  'Craftsbury Common': [47],
  Cragford: [2],
  Cragsmoor: [35],
  Craig: [1, 6, 25, 30, -37, -46],
  Craighead: [-3],
  Craigmont: [14],
  Craigsville: [46, 50],
  Craigville: [16],
  Craley: [39],
  Cramerton: [28],
  Cranberry: [39],
  'Cranberry Isles': [22],
  'Cranberry Lake': [35],
  'Cranberry Township': [39],
  Cranbury: [32],
  Crandall: [11, 16, 44],
  Crandon: [49],
  Crane: [16, 25, 27, 38, 44, -44],
  'Crane Hill': [2],
  'Crane Lake': [24],
  Cranesville: [39],
  'Cranfills Gap': [44],
  Cranford: [32],
  Cranks: [18],
  Cranston: [40],
  Crapo: [21],
  Crary: [29],
  Craryville: [35],
  'Crater Lake': [38],
  Craven: [-28],
  Crawford: [-3, 6, 11, -11, -13, -15, -16, -17, -23, -25, 26, 30, -36, 37, -39, 43, 44, -49, 50],
  Crawfordsville: [3, 13, 16, 38],
  Crawfordville: [10, 11],
  Crawley: [50],
  Crayne: [18],
  'Creal Springs': [15],
  'Cream Ridge': [32],
  Creamery: [39],
  Creede: [6],
  Creedmoor: [28],
  Creek: [-37],
  Creekside: [39],
  Creighton: [25, 30, 39],
  Crenshaw: [-2, 26],
  Creola: [2, 36],
  Creole: [19],
  Cresbard: [42],
  Crescent: [11, 13, 37, 38, 39],
  'Crescent City': [5, 10, 15],
  'Crescent Mills': [5],
  'Crescent Valley': [34],
  Cresco: [13, 39],
  Cressey: [5],
  Cresskill: [32],
  Cresson: [39, 44],
  Cressona: [39],
  'Crest Hill': [15],
  'Crested Butte': [6],
  Crestline: [5, 17, 36],
  Creston: [5, 13, 15, 28, 30, 36, 48, 50],
  Crestone: [6],
  Crestview: [10],
  Crestwood: [15, 18],
  Creswell: [28, 38],
  Crete: [15, 30],
  'Creve Coeur': [15, 25],
  Crewe: [46],
  Criders: [46],
  Crimora: [46],
  'Cripple Creek': [6, 46],
  Crisfield: [21],
  Crisp: [-11],
  Crittenden: [-3, 18, -18, 35],
  Critz: [46],
  Crivitz: [49],
  Crocheron: [21],
  Crocker: [25],
  Crockett: [5, 18, -43, 44, -44, 46],
  'Crockett Mills': [43],
  'Crocketts Bluff': [3],
  Crocketville: [41],
  Crofton: [18, 21, 30],
  Croghan: [35],
  Cromona: [18],
  Crompond: [35],
  Cromwell: [7, 13, 16, 18, 24, 37],
  Crook: [6, -38, -51],
  'Crooked Creek': [1],
  Crooks: [42],
  Crookston: [24, 30],
  Crooksville: [36],
  Cropsey: [15],
  Cropseyville: [35],
  Cropwell: [2],
  Crosby: [24, 26, 29, 39, 44, -44],
  Crosbyton: [44],
  Cross: [-3, 41],
  'Cross Anchor': [41],
  'Cross City': [10],
  'Cross Fork': [39],
  'Cross Hill': [41],
  'Cross Junction': [46],
  'Cross Plains': [16, 43, 44, 49],
  'Cross River': [35],
  'Cross Timbers': [25],
  'Cross Village': [23],
  Crossett: [3],
  Crosslake: [24],
  Crossnore: [28],
  Crossroads: [33],
  Crossville: [2, 15, 43],
  Croswell: [23],
  Crothersville: [16],
  Croton: [36],
  'Croton Falls': [35],
  'Croton On Hudson': [35],
  Crouse: [28],
  Crouseville: [22],
  'Crow Agency': [27],
  'Crow Wing': [-24],
  Crowder: [26, 37],
  Crowell: [44],
  Crowheart: [51],
  Crowley: [6, -6, 19, 44],
  Crown: [39],
  'Crown City': [36],
  'Crown King': [4],
  'Crown Point': [16, 35],
  Crownpoint: [33],
  Crownsville: [21],
  'Crows Landing': [5],
  Crowville: [19],
  Croydon: [39, 45],
  Crozet: [46],
  Crozier: [46],
  Crucible: [39],
  Cruger: [26],
  Crum: [50],
  'Crum Lynne': [39],
  Crump: [43],
  Crumpler: [28],
  Crumpton: [21],
  Crumrod: [3],
  Crystal: [23, 29],
  'Crystal Bay': [24, 34],
  'Crystal Beach': [10, 44],
  'Crystal City': [25, 44],
  'Crystal Falls': [23],
  'Crystal Hill': [46],
  'Crystal Lake': [13, 15],
  'Crystal River': [10],
  'Crystal Spring': [39],
  'Crystal Springs': [10, 26],
  'Cub Run': [18],
  Cuba: [2, 15, 17, 25, 33, 35, 36],
  'Cuba City': [49],
  Cubero: [33],
  Cuchara: [6],
  Cucumber: [50],
  Cudahy: [49],
  Cuddebackville: [35],
  Cuddy: [39],
  'Cudjoe Key': [10],
  Cuero: [44],
  Cuervo: [33],
  Culberson: [28, -44],
  Culbertson: [27, 30],
  Culdesac: [14],
  Cullen: [19, 46],
  Culleoka: [43],
  Cullman: [2, -2],
  Culloden: [11, 50],
  Cullom: [15],
  Cullowhee: [28],
  Culpeper: [46, -46],
  Culver: [16, 38],
  'Culver City': [5],
  Cumberland: [13, -15, 18, -18, 21, -22, 28, -28, -32, 36, -39, 40, -43, 46, -46, 49],
  'Cumberland Center': [22],
  'Cumberland City': [43],
  'Cumberland Foreside': [22],
  'Cumberland Furnace': [43],
  'Cumberland Gap': [43],
  Cumbola: [39],
  Cumby: [44],
  Cuming: [-30],
  Cummaquid: [20],
  Cumming: [11, 13],
  Cummings: [17, 29],
  Cummington: [20],
  Cumnock: [28],
  Cuney: [44],
  Cunningham: [17, 18, 43, 44],
  Cupertino: [5],
  Curdsville: [18],
  Curlew: [13, 48],
  Curllsville: [39],
  Curran: [23],
  Currie: [24, 28],
  Currituck: [28, -28],
  Curry: [-33, -38],
  Curryville: [25, 39],
  Curtice: [36],
  Curtis: [3, 23, 30, 48],
  'Curtis Bay': [21],
  Curtiss: [49],
  Curtisville: [39],
  Curwensville: [39],
  Cushing: [13, 22, 24, 37, 44, 49],
  Cushman: [3],
  Cusick: [48],
  Cusseta: [2, 11],
  Custar: [36],
  Custer: [-6, -14, 18, 23, 27, -27, -30, -37, 42, -42, 48, 49],
  'Custer City': [37, 39],
  'Cut Bank': [27],
  'Cut Off': [19],
  Cutchogue: [35],
  Cuthbert: [11],
  Cutler: [5, 15, 16, 22, 36],
  Cutten: [5],
  Cuttingsville: [47],
  Cuttyhunk: [20],
  Cuyahoga: [-36],
  'Cuyahoga Falls': [36],
  Cyclone: [39, 50],
  Cygnet: [36],
  Cylinder: [13],
  Cynthiana: [16, 18, 36],
  Cypress: [5, 10, 15, 44],
  'Cypress Inn': [43],
  Cyril: [37],
  Cyrus: [24],
  'D Hanis': [44],
  'D Lo': [26],
  Dacoma: [37],
  Dacono: [6],
  Dacula: [11],
  Dade: [-10, -11, -25],
  'Dade City': [10],
  Dadeville: [2, 25],
  Dafter: [23],
  Daggett: [5, 23, -45],
  Dagmar: [27],
  Dagsboro: [9],
  'Dagus Mines': [39],
  Dahinda: [15],
  Dahlen: [29],
  Dahlgren: [15, 46],
  Dahlonega: [11],
  Dailey: [50],
  Daingerfield: [44],
  Dairy: [38],
  Daisetta: [44],
  Daisy: [11, 25, 37],
  Daisytown: [39],
  Dakota: [15, 24, -24, -30],
  'Dakota City': [13, 30],
  'Dakota Dunes': [42],
  Dalbo: [24],
  Dale: [-2, 15, 16, 35, 41, 44, 49],
  Daleville: [2, 16, 26, 46],
  Dalhart: [44],
  Dallam: [-44],
  Dallardsville: [44],
  Dallas: [-2, -3, 11, -13, -25, 28, 38, 39, 42, 44, -44, 49, 50],
  'Dallas Center': [13],
  'Dallas City': [15],
  Dallastown: [39],
  Dallesport: [48],
  Dalmatia: [39],
  Dalton: [11, 20, 24, 25, 30, 35, 36, 39, 49],
  'Dalton City': [15],
  'Daly City': [5],
  Dalzell: [15, 41],
  Damar: [17],
  Damariscotta: [22],
  Damascus: [3, 11, 21, 36, 38, 39, 46],
  Dameron: [21],
  'Dammeron Valley': [45],
  Damon: [44],
  Dana: [13, 15, 16, 18, 28],
  'Dana Point': [5],
  Danboro: [39],
  Danbury: [7, 13, 28, 30, 31, 44, 49],
  Danby: [47],
  Danciger: [44],
  Dandridge: [43],
  Dane: [49, -49],
  Danese: [50],
  Danevang: [44],
  Danforth: [15, 22],
  Dania: [10],
  Daniel: [51],
  'Daniel Island': [41],
  Daniels: [-27, 50],
  Danielson: [7],
  Danielsville: [11, 39],
  Dannebrog: [30],
  Dannemora: [35],
  Dansville: [23, 35],
  Dante: [42, 46],
  Danube: [24],
  Danvers: [15, 20, 24],
  Danville: [2, 3, 5, 11, 13, 15, 16, 17, 18, 22, 31, 36, 39, 46, 47, 48, 50],
  Daphne: [2],
  Darby: [27, 39],
  Dardanelle: [3],
  Darden: [43],
  Dare: [-28],
  Darfur: [24],
  Darien: [7, 11, 15, 49],
  'Darien Center': [35],
  Darke: [-36],
  Darling: [26],
  Darlington: [16, 21, 25, 39, 41, -41, 49],
  Darragh: [39],
  Darrington: [48],
  Darrouzett: [44],
  Darrow: [19],
  Dartmouth: [20],
  Darwin: [5, 24],
  Dassel: [24],
  Dateland: [4],
  Datil: [33],
  Datto: [3],
  'Daufuskie Island': [41],
  Dauphin: [39, -39],
  'Dauphin Island': [2],
  Davenport: [5, 10, 13, 29, 30, 35, 37, 46, 48],
  'Davenport Center': [35],
  Davey: [30],
  David: [18],
  'David City': [30],
  Davidson: [28, -28, 37, -43],
  Davidsonville: [21],
  Davidsville: [39],
  Davie: [10, -28],
  Daviess: [-16, -18, -25],
  Davilla: [44],
  Davin: [50],
  Davis: [5, -13, 15, 28, 37, 42, -45, 50],
  'Davis City': [13],
  'Davis Creek': [5],
  'Davis Junction': [15],
  'Davis Station': [41],
  'Davis Wharf': [46],
  Davisboro: [11],
  Davisburg: [23],
  Davison: [23, -42],
  Daviston: [2],
  Davisville: [25, 50],
  Davy: [50],
  Dawes: [-30, 50],
  Dawn: [25, 44],
  Dawson: [2, 11, -11, 13, 15, 24, -27, 29, 30, -30, 39, 44, -44],
  'Dawson Springs': [18],
  Dawsonville: [11],
  Day: [10, -42],
  Dayhoit: [18],
  Daykin: [30],
  'Days Creek': [38],
  Dayton: [13, 14, 16, 18, 21, 24, 27, 32, 34, 35, 36, 38, 39, 43, 44, 46, 48, 51],
  'Daytona Beach': [10],
  Dayville: [7, 38],
  Dazey: [29],
  'De Armanville': [2],
  'De Baca': [-33],
  'De Beque': [6],
  'De Berry': [44],
  'De Borgia': [27],
  'De Graff': [36],
  'De Kalb': [-2, 25, -25, 26, -43, 44],
  'De Kalb Junction': [35],
  'De Lancey': [39],
  'De Land': [15],
  'De Leon': [44],
  'De Leon Springs': [10],
  'De Mossville': [18],
  'De Pere': [49],
  'De Peyster': [35],
  'De Queen': [3],
  'De Ruyter': [35],
  'De Smet': [42],
  'De Soto': [11, 13, 15, 17, -19, 25, -26, 49],
  'De Tour Village': [23],
  'De Valls Bluff': [3],
  'De Witt': [3, 13, 25, 30, -44],
  'De Young': [39],
  DeKalb: [-11, -15, -16],
  DeSoto: [-10],
  DeWitt: [-15],
  Deadwood: [38, 42],
  'Deaf Smith': [-44],
  Deal: [32],
  'Deal Island': [21],
  Deale: [21],
  Deane: [18],
  Deansboro: [35],
  Deanville: [44],
  Dearborn: [-16, 23, 25],
  'Dearborn Heights': [23],
  Dearing: [11, 17],
  Deary: [14],
  'Death Valley': [5],
  Deatsville: [2],
  Deaver: [51],
  Debary: [10],
  Debord: [18],
  Decatur: [2, 3, 11, -11, 13, -13, 15, 16, -16, -17, 23, 26, 30, 36, 43, -43, 44],
  Decaturville: [43],
  Decherd: [43],
  Decker: [16, 23, 27],
  Deckerville: [23],
  Declo: [14],
  Decorah: [13],
  Dedham: [13, 20],
  Deedsville: [16],
  'Deep Gap': [28],
  'Deep River': [7, 13],
  'Deep Run': [28],
  'Deep Water': [50],
  Deepwater: [25, 32],
  Deer: [3],
  'Deer Creek': [15, 24, 37],
  'Deer Grove': [15],
  'Deer Harbor': [48],
  'Deer Island': [38],
  'Deer Isle': [22],
  'Deer Lodge': [27, -27, 43],
  'Deer Park': [2, 5, 35, 44, 48, 49],
  'Deer River': [24, 35],
  'Deer Trail': [6],
  Deerbrook: [49],
  Deerfield: [15, 17, 20, 23, 25, 31, 36, 46, 49],
  'Deerfield Beach': [10],
  'Deerfield Street': [32],
  Deering: [1, 25, 29],
  Deersville: [36],
  Deerton: [23],
  Deerwood: [24],
  Deeth: [34],
  Deferiet: [35],
  Defiance: [13, 25, 36, -36, 39],
  Deford: [23],
  Deforest: [49],
  'Defuniak Springs': [10],
  Dekalb: [15],
  'Del Mar': [5],
  'Del Norte': [-5, 6],
  'Del Rey': [5],
  'Del Rio': [43, 44],
  'Del Valle': [44],
  Delafield: [49],
  Delancey: [35],
  Deland: [10],
  Delano: [5, 24, 39, 43],
  Delanson: [35],
  Delaplaine: [3],
  Delaplane: [46],
  Delavan: [15, 24, 49],
  Delaware: [3, 13, -13, -16, 32, -35, 36, -36, 37, -37, -39],
  'Delaware City': [9],
  'Delaware Water Gap': [39],
  Delbarton: [50],
  Delcambre: [19],
  Delco: [28],
  Delevan: [35],
  Delhi: [5, 13, 19, 35],
  Delia: [17],
  Delight: [3],
  Dell: [3, 27],
  'Dell City': [44],
  'Dell Rapids': [42],
  Dellrose: [43],
  Dellroy: [36],
  Dellslow: [50],
  Delmar: [2, 9, 13, 21, 35],
  Delmita: [44],
  Delmont: [32, 39, 42],
  Deloit: [13],
  Delong: [16],
  Delphi: [16],
  'Delphi Falls': [35],
  Delphia: [18],
  Delphos: [17, 36],
  Delray: [50],
  'Delray Beach': [10],
  Delta: [2, 6, -6, 13, 19, -23, 25, 36, 39, -44, 45],
  'Delta City': [26],
  'Delta Junction': [1],
  Deltaville: [46],
  Delton: [23],
  Deltona: [10],
  Dema: [18],
  Demarest: [32],
  Deming: [33, 48],
  Demopolis: [2],
  Demorest: [11],
  Demotte: [16],
  Denair: [5],
  Denali: [-1],
  'Denali National Park': [1],
  Denbo: [39],
  Dendron: [46],
  'Denham Springs': [19],
  Denhoff: [29],
  Denio: [34],
  Denison: [13, 17, 44],
  Denmark: [13, 22, 35, 41, 43, 49],
  Dennard: [3],
  Dennehotso: [4],
  Dennis: [17, 20, 26, 44],
  'Dennis Port': [20],
  Dennison: [15, 24, 36],
  Denniston: [18],
  Dennisville: [32],
  Dennysville: [22],
  Dent: [24, -25],
  Denton: [11, 17, 18, 21, 27, 28, 30, 44, -44],
  Denver: [6, -6, 13, 16, 25, 28, 35, 39],
  'Denver City': [44],
  Denville: [32],
  Depauville: [35],
  Depauw: [16],
  Depew: [35, 37],
  'Depoe Bay': [38],
  Deport: [44],
  Deposit: [35],
  Depue: [15],
  Deputy: [16],
  Dequincy: [19],
  Derby: [7, 13, 16, 17, 35, 36, 47],
  'Derby Line': [47],
  Deridder: [19],
  Derma: [26],
  Dermott: [3],
  'Derrick City': [39],
  Derry: [31, 33, 39],
  Derwent: [36],
  Derwood: [21],
  'Des Allemands': [19],
  'Des Arc': [3, 25],
  'Des Lacs': [29],
  'Des Moines': [13, -13, 33],
  'Des Plaines': [15],
  Descanso: [5],
  Deschutes: [-38],
  Desdemona: [44],
  'Desert Center': [5],
  'Desert Hot Springs': [5],
  Desha: [3, -3],
  Deshler: [30, 36],
  Desloge: [25],
  Desmet: [14],
  Desoto: [44],
  Destin: [10],
  Destrehan: [19],
  Detroit: [2, 22, 23, 38, 44],
  'Detroit Lakes': [24],
  Deuel: [-30, -42],
  Devault: [39],
  Devens: [20],
  Devers: [44],
  Deville: [19],
  'Devils Elbow': [25],
  'Devils Lake': [29],
  'Devils Tower': [51],
  Devine: [44],
  Devol: [37],
  Devon: [39],
  Dewar: [13, 37],
  Dewart: [39],
  Deweese: [30],
  Dewey: [4, 15, 37, -37, -42],
  Deweyville: [44, 45],
  Dewitt: [15, 18, 23, 46],
  Dewittville: [35],
  'Dewy Rose': [11],
  Dexter: [11, 13, 17, 18, 22, 23, 24, 25, 33, 35, 38],
  'Dexter City': [36],
  Dhs: [21, 46],
  Diablo: [5],
  Diagonal: [13],
  Diamond: [25, 36, 38],
  'Diamond Bar': [5],
  'Diamond City': [3],
  'Diamond Point': [35],
  'Diamond Springs': [5],
  Diamondhead: [26],
  Diamondville: [51],
  Diana: [44, 50],
  Diaz: [3],
  Dibble: [37],
  Diberville: [26],
  Diboll: [44],
  Dice: [18],
  Dickens: [13, 30, 44, -44],
  Dickenson: [-46],
  Dickerson: [21],
  'Dickerson Run': [39],
  Dickey: [29, -29],
  Dickeyville: [49],
  Dickinson: [2, -13, -17, -23, 29, 44],
  'Dickinson Center': [35],
  Dickson: [43, -43],
  Dierks: [3],
  Dieterich: [15],
  Dietrich: [14],
  Diggins: [25],
  Diggs: [46],
  Dighton: [17, 20],
  Dike: [13, 44],
  'Dill City': [37],
  Dillard: [11, 38],
  Dille: [50],
  Diller: [30],
  Dilley: [44],
  Dilliner: [39],
  Dillingham: [1, -1],
  Dillon: [6, 27, 41, -41],
  'Dillon Beach': [5],
  Dillonvale: [36],
  Dillsboro: [16, 28],
  Dillsburg: [39],
  Dilltown: [39],
  Dillwyn: [46],
  Dilworth: [24],
  'Dime Box': [44],
  Dimmit: [-44],
  Dimmitt: [44],
  Dimock: [39, 42],
  Dimondale: [23],
  Dinero: [44],
  Dingess: [50],
  Dingle: [14],
  'Dingmans Ferry': [39],
  Dinosaur: [6],
  Dinuba: [5],
  Dinwiddie: [46, -46],
  'Discovery Bay': [5],
  Disney: [37],
  Disputanta: [46],
  Distant: [39],
  'District Heights': [21],
  'District of Columbia': [-8],
  Dittmer: [25],
  Divernon: [15],
  Divide: [6, 27, -29],
  'Dividing Creek': [32],
  Dix: [15, 30],
  Dixfield: [22],
  Dixie: [-10, 11, 48, 50],
  Dixmont: [22],
  Dixon: [5, 13, 15, 18, 25, 27, 30, -30, 33, 51],
  'Dixon Springs': [43],
  'Dixons Mills': [2],
  Dixonville: [39],
  Dobbin: [44],
  Dobbins: [5],
  'Dobbs Ferry': [35],
  Dobson: [28],
  Docena: [2],
  'Doctors Inlet': [10],
  'Dodd City': [44],
  Doddridge: [3, -50],
  Doddsville: [26],
  Dodge: [-11, -24, 29, 30, -30, 44, 49, -49],
  'Dodge Center': [24],
  'Dodge City': [17],
  Dodgeville: [23, 49],
  Dodson: [19, 27, 44],
  'Doe Hill': [46],
  'Doe Run': [25],
  Doerun: [11],
  Dogue: [46],
  Dola: [36],
  'Dolan Springs': [4],
  Doland: [42],
  Dolgeville: [35],
  'Dollar Bay': [23],
  Dolliver: [13],
  Dolomite: [2],
  Dolores: [6, -6],
  Dolph: [3],
  Dolphin: [46],
  Dolton: [15],
  'Dona Ana': [33],
  Donahue: [13],
  Donald: [38],
  Donalds: [41],
  Donaldson: [3, 16, 24],
  Donaldsonville: [19],
  Donalsonville: [11],
  Donegal: [39],
  Dongola: [15],
  Donie: [44],
  Doniphan: [-17, 25, 30],
  Donley: [-44],
  Donna: [44],
  Donnellson: [13, 15],
  Donnelly: [14, 24],
  Donnelsville: [36],
  Donner: [19],
  Donnybrook: [29],
  Donora: [39],
  Donovan: [15],
  Doole: [44],
  Dooly: [-11],
  Doon: [13],
  Door: [-49],
  Dora: [2, 25, 33],
  Doral: [10],
  Doran: [46],
  Dorchester: [13, 20, -21, 30, 32, 41, -41, 49],
  'Dorchester Center': [20],
  Dorena: [38],
  Dormansville: [35],
  Dornsife: [39],
  Dorothy: [32, 50],
  Dorr: [23],
  Dorrance: [17],
  Dorris: [5],
  Dorset: [36, 47],
  Dorsey: [15],
  Dorton: [18],
  'Dos Palos': [5],
  'Dos Rios': [5],
  Doss: [44],
  Doswell: [46],
  Dothan: [2, 50],
  Doty: [48],
  'Double Springs': [2],
  Doucette: [44],
  Douds: [13],
  Dougherty: [-11, 13, 37, 44],
  Douglas: [1, 2, 4, -6, 11, -11, -15, -17, 20, 23, -24, -25, 29, 30, -30, -34, 37, -38, -42, -48, -49, 51],
  'Douglas City': [5],
  'Douglas Flat': [5],
  Douglass: [17, 44],
  Douglassville: [39, 44],
  Douglasville: [11],
  Dousman: [49],
  'Dove Creek': [6],
  Dover: [3, 9, 10, 11, 14, 15, 17, 18, 20, 24, 25, 28, 31, 32, 36, 37, 39, 43],
  'Dover Afb': [9],
  'Dover Foxcroft': [22],
  'Dover Plains': [35],
  Dovray: [24],
  Dow: [15],
  'Dow City': [13],
  Dowagiac: [23],
  Dowell: [15, 21],
  Dowelltown: [43],
  Dowling: [23],
  'Downers Grove': [15],
  Downey: [5, 14],
  Downieville: [5],
  Downing: [25, 49],
  Downingtown: [39],
  Downs: [15, 17],
  Downsville: [19, 35, 49],
  Dows: [13],
  Doyle: [5, 43],
  Doylesburg: [39],
  Doylestown: [36, 39, 49],
  Doyline: [19],
  Dozier: [2],
  'Do\u00f1a Ana': [-33],
  Dracut: [20],
  Dragoon: [4],
  Drain: [38],
  Drake: [6, 29],
  'Drakes Branch': [46],
  Drakesboro: [18],
  Drakesville: [13],
  Draper: [42, 45, 46],
  Drasco: [3],
  Dravosburg: [39],
  Drayden: [21],
  Drayton: [29, 41],
  'Drayton Plains': [23],
  Drennen: [50],
  Dresden: [17, 22, 35, 36, 43],
  Dresher: [39],
  Dresser: [49],
  Drew: [-3, 26],
  Drewryville: [46],
  Drewsey: [38],
  Drewsville: [31],
  Drexel: [25, 28],
  'Drexel Hill': [39],
  Drift: [18],
  Drifting: [39],
  Drifton: [39],
  Driftwood: [39, 44],
  Driggs: [14],
  'Dripping Springs': [44],
  Driscoll: [29, 44],
  Driver: [3],
  Drummond: [27, 37, 49],
  'Drummond Island': [23],
  Drummonds: [43],
  Drumore: [39],
  Drumright: [37],
  Drums: [39],
  Drury: [20, 25],
  'Dry Branch': [11],
  'Dry Creek': [19, 50],
  'Dry Fork': [46],
  'Dry Prong': [19],
  'Dry Ridge': [18],
  'Dry Run': [39],
  Drybranch: [50],
  Dryden: [22, 23, 35, 44, 46, 48],
  Dryfork: [50],
  Drytown: [5],
  'Du Bois': [15, 30, 39],
  'Du Pont': [11],
  'Du Quoin': [15],
  DuPage: [-15],
  Duanesburg: [35],
  Duarte: [5],
  Dubach: [19],
  Dubberly: [19],
  Dublin: [5, 11, 16, 26, 28, 31, 36, 39, 44, 46],
  Dubois: [14, 16, -16, 51],
  Dubuque: [13, -13],
  Duchesne: [45, -45],
  Duck: [28, 50],
  'Duck Creek Village': [45],
  'Duck Hill': [26],
  'Duck River': [43],
  Ducktown: [43],
  Duckwater: [34],
  Ducor: [5],
  Dudley: [11, 20, 25, 28, 39],
  'Due West': [41],
  Duenweg: [25],
  Duff: [43],
  Duffield: [46],
  Dufur: [38],
  Dugger: [16],
  Dugspur: [46],
  Dugway: [45],
  Duke: [25, 37],
  'Duke Center': [39],
  Dukedom: [43],
  Dukes: [-20],
  Dulac: [19],
  Dulce: [33],
  Dulles: [46],
  Duluth: [11, 24],
  Dulzura: [5],
  Dumas: [3, 26, 44],
  Dumfries: [46],
  Dumont: [6, 13, 24, 32],
  Dunbar: [18, 30, 39, 49, 50],
  Dunbarton: [31],
  Dunbridge: [36],
  Duncan: [4, 26, 30, 37, 41],
  'Duncan Falls': [36],
  Duncannon: [39],
  'Duncans Mills': [5],
  Duncansville: [39],
  Duncanville: [2, 44],
  Duncombe: [13],
  Dundalk: [21],
  Dundas: [15, 24, 46],
  Dundee: [10, 13, 15, 18, 23, 26, 35, 36, 38],
  Dundy: [-30],
  Dunedin: [10],
  Dunellen: [32],
  Dunfermline: [15],
  Dungannon: [46],
  Dunkerton: [13],
  Dunkirk: [16, 21, 35, 36],
  Dunklin: [-25],
  Dunlap: [5, 13, 15, 43],
  Dunlevy: [39],
  Dunlo: [39],
  Dunlow: [50],
  Dunmor: [18],
  Dunmore: [39, 50],
  Dunn: [28, -29, 44, -49],
  'Dunn Center': [29],
  'Dunn Loring': [46],
  Dunnegan: [25],
  Dunnell: [24],
  Dunnellon: [10],
  Dunnigan: [5],
  Dunning: [30],
  Dunnsville: [46],
  Dunnville: [18],
  Dunreith: [16],
  Dunseith: [29],
  Dunsmuir: [5],
  Dunstable: [20],
  Dunwoody: [11],
  Duplessis: [19],
  Duplin: [-28],
  Dupo: [15],
  Dupont: [6, 16, 19, 36, 48],
  Dupree: [42],
  Dupuyer: [27],
  Duquesne: [39],
  Durand: [15, 23, 49],
  Durango: [6, 13],
  Durant: [10, 13, 26, 37],
  Durbin: [50],
  Durham: [5, 7, 17, 22, 25, 28, -28, 31, 35, 37, 39],
  Durhamville: [35],
  Durkee: [38],
  Duryea: [39],
  Dushore: [39],
  Duson: [19],
  Dustin: [37],
  'Dutch Flat': [5],
  'Dutch Harbor': [1],
  'Dutch John': [45],
  Dutchess: [-35],
  Dutchtown: [25],
  Dutton: [2, 27, 46],
  Dutzow: [25],
  Duval: [-10, -44],
  Duvall: [48],
  Duxbury: [20],
  Dwale: [18],
  Dwarf: [18],
  Dwight: [15, 17, 30],
  Dycusburg: [18],
  Dyer: [3, 16, 34, 43, -43],
  Dyersburg: [43],
  Dyersville: [13],
  Dyess: [3],
  'Dyess Afb': [44],
  Dyke: [46],
  Dysart: [13, 39],
  Eads: [6, 43],
  Eagan: [24, 43],
  Eagar: [4],
  Eagarville: [15],
  Eagle: [1, 6, -6, 14, 23, 30, 49],
  'Eagle Bay': [35],
  'Eagle Bend': [24],
  'Eagle Bridge': [35],
  'Eagle Butte': [42],
  'Eagle Creek': [38],
  'Eagle Grove': [13],
  'Eagle Lake': [10, 22, 24, 44],
  'Eagle Mountain': [45],
  'Eagle Nest': [33],
  'Eagle Pass': [44],
  'Eagle Point': [38],
  'Eagle River': [1, 49],
  'Eagle Rock': [25, 46],
  'Eagle Springs': [28],
  'Eagles Mere': [39],
  Eagletown: [37],
  Eagleville: [5, 25, 39, 43],
  Eakly: [37],
  Earl: [28],
  'Earl Park': [16],
  Earle: [3],
  Earleton: [10],
  Earleville: [21],
  Earlham: [13],
  Earlimart: [5],
  Earling: [13],
  Earlington: [18, 39],
  Earlsboro: [37],
  Earlton: [35],
  Earlville: [13, 15, 35, 39],
  Early: [-11, 13, 44],
  'Early Branch': [41],
  Earlysville: [46],
  Earp: [5],
  Earth: [44],
  'Earth City': [25],
  Easley: [41],
  'East Alton': [15],
  'East Amherst': [35],
  'East Andover': [22, 31],
  'East Arlington': [47],
  'East Aurora': [35],
  'East Baldwin': [22],
  'East Bank': [50],
  'East Barre': [47],
  'East Baton Rouge': [-19],
  'East Bend': [28],
  'East Berkshire': [47],
  'East Berlin': [7, 39],
  'East Bernard': [44],
  'East Berne': [35],
  'East Bernstadt': [18],
  'East Bethany': [35],
  'East Bloomfield': [35],
  'East Blue Hill': [22],
  'East Boothbay': [22],
  'East Boston': [20],
  'East Brady': [39],
  'East Branch': [35],
  'East Bridgewater': [20],
  'East Brookfield': [20],
  'East Brunswick': [32],
  'East Burke': [47],
  'East Butler': [39],
  'East Calais': [47],
  'East Canaan': [7],
  'East Candia': [31],
  'East Canton': [36],
  'East Carbon': [45],
  'East Carondelet': [15],
  'East Carroll': [-19],
  'East Charleston': [47],
  'East Chatham': [35],
  'East Chicago': [16],
  'East China': [23],
  'East Claridon': [36],
  'East Concord': [35],
  'East Corinth': [47],
  'East Dennis': [20],
  'East Derry': [31],
  'East Dixfield': [22],
  'East Dorset': [47],
  'East Dover': [47],
  'East Dublin': [11],
  'East Dubuque': [15],
  'East Durham': [35],
  'East Earl': [39],
  'East Ellijay': [11],
  'East Elmhurst': [35],
  'East Enterprise': [16],
  'East Fairfield': [47],
  'East Falmouth': [20],
  'East Feliciana': [-19],
  'East Flat Rock': [28],
  'East Freedom': [39],
  'East Freetown': [20],
  'East Fultonham': [36],
  'East Galesburg': [15],
  'East Glacier Park': [27],
  'East Glastonbury': [7],
  'East Granby': [7],
  'East Grand Forks': [24],
  'East Greenbush': [35],
  'East Greenville': [39],
  'East Greenwich': [40],
  'East Haddam': [7],
  'East Hampstead': [31],
  'East Hampton': [7, 35],
  'East Hanover': [32],
  'East Hardwick': [47],
  'East Hartford': [7],
  'East Hartland': [7],
  'East Haven': [7, 47],
  'East Helena': [27],
  'East Hickory': [39],
  'East Homer': [35],
  'East Irvine': [5],
  'East Islip': [35],
  'East Jewett': [35],
  'East Jordan': [23],
  'East Killingly': [7],
  'East Kingston': [31],
  'East Lansing': [23],
  'East Leroy': [23],
  'East Liberty': [36],
  'East Livermore': [22],
  'East Liverpool': [36],
  'East Longmeadow': [20],
  'East Lyme': [7],
  'East Lynn': [15, 50],
  'East Lynne': [25],
  'East Machias': [22],
  'East Marion': [35],
  'East McKeesport': [39],
  'East Meadow': [35],
  'East Meredith': [35],
  'East Middlebury': [47],
  'East Millinocket': [22],
  'East Millsboro': [39],
  'East Moline': [15],
  'East Montpelier': [47],
  'East Moriches': [35],
  'East Nassau': [35],
  'East New Market': [21],
  'East Newport': [22],
  'East Northport': [35],
  'East Norwich': [35],
  'East Olympia': [48],
  'East Orange': [32],
  'East Orland': [22],
  'East Orleans': [20],
  'East Otis': [20],
  'East Otto': [35],
  'East Palatka': [10],
  'East Palestine': [36],
  'East Parsonsfield': [22],
  'East Pembroke': [35],
  'East Peoria': [15],
  'East Petersburg': [39],
  'East Pharsalia': [35],
  'East Pittsburgh': [39],
  'East Point': [18],
  'East Poland': [22],
  'East Poultney': [47],
  'East Prairie': [25],
  'East Prospect': [39],
  'East Providence': [40],
  'East Quogue': [35],
  'East Randolph': [35, 47],
  'East Rochester': [35, 36],
  'East Rockaway': [35],
  'East Rutherford': [32],
  'East Ryegate': [47],
  'East Saint Johnsbury': [47],
  'East Saint Louis': [15],
  'East Sandwich': [20],
  'East Schodack': [35],
  'East Setauket': [35],
  'East Smethport': [39],
  'East Smithfield': [39],
  'East Sparta': [36],
  'East Spencer': [28],
  'East Springfield': [35, 36, 39],
  'East Stone Gap': [46],
  'East Stroudsburg': [39],
  'East Syracuse': [35],
  'East Tallassee': [2],
  'East Taunton': [20],
  'East Tawas': [23],
  'East Templeton': [20],
  'East Texas': [39],
  'East Thetford': [47],
  'East Troy': [49],
  'East Vandergrift': [39],
  'East Vassalboro': [22],
  'East Wakefield': [31],
  'East Wallingford': [47],
  'East Walpole': [20],
  'East Wareham': [20],
  'East Waterboro': [22],
  'East Waterford': [39],
  'East Wenatchee': [48],
  'East Weymouth': [20],
  'East Williamson': [35],
  'East Wilton': [22],
  'East Windsor': [7],
  'East Windsor Hill': [7],
  'East Winthrop': [22],
  'East Woodstock': [7],
  'East Worcester': [35],
  Eastaboga: [2],
  Eastabuchie: [26],
  Eastanollee: [11],
  Eastchester: [35],
  Eastern: [18],
  Eastford: [7],
  Eastham: [20],
  Easthampton: [20],
  Eastlake: [6, 23, 36],
  'Eastlake Weir': [10],
  Eastland: [44, -44],
  Eastman: [11, 49],
  Easton: [7, 15, 17, 20, 21, 22, 24, 25, 39, 44, 48],
  Eastover: [41],
  Eastpoint: [10],
  Eastpointe: [23],
  Eastport: [14, 22, 23, 35],
  Eastsound: [48],
  Eastvale: [5],
  Eastview: [18],
  Eastville: [46],
  Eastwood: [18],
  Eaton: [6, 16, -23, 35, 36, 43],
  'Eaton Center': [31],
  'Eaton Park': [10],
  'Eaton Rapids': [23],
  Eatonton: [11],
  Eatontown: [32],
  Eatonville: [48],
  'Eau Claire': [23, 39, 49, -49],
  'Eau Galle': [49],
  'Eben Junction': [23],
  Ebensburg: [39],
  Ebervale: [39],
  Ebony: [46],
  Ebro: [10],
  Eccles: [50],
  Echo: [19, 24, 38, 45],
  'Echo Lake': [5],
  Echola: [2],
  Echols: [-11],
  Eckerman: [23],
  Eckert: [6],
  Eckerty: [16],
  'Eckhart Mines': [21],
  Eckley: [6],
  Eckman: [50],
  Eclectic: [2],
  Ecleto: [44],
  Economy: [16],
  Ecorse: [23],
  Ecru: [26],
  Ector: [44, -44],
  Edcouch: [44],
  Eddington: [22],
  Eddy: [-29, -33, 44],
  Eddyville: [13, 15, 18, 30, 38],
  Edelstein: [15],
  Eden: [4, 11, 14, 21, 28, 35, 42, 44, 45, 47, 49],
  'Eden Mills': [47],
  'Eden Prairie': [24],
  'Eden Valley': [24],
  Edenton: [28],
  Edenville: [23],
  Edgar: [-15, 27, 30, 49],
  'Edgar Springs': [25],
  Edgard: [19],
  Edgarton: [50],
  Edgartown: [20],
  Edgecomb: [22],
  Edgecombe: [-28],
  Edgefield: [41, -41],
  Edgeley: [29],
  Edgemont: [3, 39, 42],
  Edgemoor: [41],
  Edgerton: [17, 24, 25, 36, 49, 51],
  Edgewater: [10, 21, 32],
  Edgewood: [13, 15, 21, 33, 44],
  Edina: [24, 25],
  Edinboro: [39],
  Edinburg: [15, 29, 39, 44, 46],
  Edinburgh: [16],
  Edison: [5, 11, 30, 32, 36],
  'Edisto Beach': [41],
  'Edisto Island': [41],
  Edmeston: [35],
  Edmond: [37, 50],
  Edmonds: [48],
  Edmondson: [3],
  Edmonson: [-18, 44],
  Edmonton: [18],
  Edmore: [23, 29],
  Edmund: [49],
  Edmunds: [-42],
  Edna: [17, 44],
  Edneyville: [28],
  Edon: [36],
  Edroy: [44],
  Edson: [17],
  Edwall: [48],
  Edward: [28],
  Edwards: [5, 6, 15, -15, -17, 25, 26, 35, -44],
  Edwardsburg: [23],
  Edwardsport: [16],
  Edwardsville: [2, 15, 17, 46],
  Eek: [1],
  Effie: [19, 24],
  Effingham: [-11, 15, -15, 17, 31, 41],
  Effort: [39],
  Efland: [28],
  Egan: [19, 42],
  Egegik: [1],
  Egeland: [29],
  'Egg Harbor': [49],
  'Egg Harbor City': [32],
  'Egg Harbor Township': [32],
  Eggleston: [46],
  'Eglin Afb': [10],
  Eglon: [50],
  Egnar: [6],
  Egypt: [3, 44],
  Ehrenberg: [4],
  Ehrhardt: [41],
  Eidson: [43],
  'Eielson Afb': [1],
  'Eight Mile': [2],
  'Eighty Eight': [18],
  'Eighty Four': [39],
  Eitzen: [24],
  Ekalaka: [27],
  Ekron: [18],
  Ekwok: [1],
  'El Cajon': [5],
  'El Campo': [44],
  'El Centro': [5],
  'El Cerrito': [5],
  'El Dorado': [3, 5, -5, 17],
  'El Dorado Hills': [5],
  'El Dorado Springs': [25],
  'El Granada': [5],
  'El Indio': [44],
  'El Jobean': [10],
  'El Mirage': [4],
  'El Monte': [5],
  'El Nido': [5],
  'El Paso': [3, -6, 15, 44, -44],
  'El Portal': [5],
  'El Prado': [33],
  'El Reno': [37],
  'El Rito': [33],
  'El Segundo': [5],
  'El Sobrante': [5],
  'El Toro': [5],
  'El Verano': [5],
  Elaine: [3],
  Eland: [49],
  Elba: [2, 30, 35],
  Elbe: [48],
  Elberfeld: [16],
  Elberon: [13, 46],
  Elbert: [6, -6, -11, 50],
  Elberta: [2, 23, 45],
  Elberton: [11],
  Elbing: [17],
  'Elbow Lake': [24],
  Elbridge: [35],
  Elburn: [15],
  Elcho: [49],
  Elco: [39],
  Eldena: [15],
  Elderon: [49],
  Eldersburg: [21],
  Elderton: [39],
  Eldon: [13, 25],
  Eldora: [13],
  Eldorado: [15, 36, 37, 44, 49],
  'Eldorado Springs': [6],
  Eldred: [15, 35, 39],
  Eldridge: [2, 5, 13, 25],
  Eleanor: [50],
  Electra: [44],
  'Electric City': [48],
  Eleele: [12],
  'Elephant Butte': [33],
  Eleroy: [15],
  Eleva: [49],
  Elfers: [10],
  'Elfin Cove': [1],
  Elfrida: [4],
  Elgin: [4, 13, 15, 24, 29, 30, 36, 37, 38, 39, 41, 43, 44],
  Elida: [33],
  Elim: [1],
  Eliot: [22],
  Elizabeth: [3, 6, 15, 16, 19, 24, 32, 39, 50],
  'Elizabeth City': [28],
  Elizabethport: [32],
  Elizabethton: [43],
  Elizabethtown: [15, 16, 18, 28, 35, 39],
  Elizabethville: [39],
  Elizaville: [18, 35],
  Elk: [5, -17, -39, 48],
  'Elk City': [14, 17, 37],
  'Elk Creek': [5, 25, 30, 46],
  'Elk Falls': [17],
  'Elk Garden': [50],
  'Elk Grove': [5],
  'Elk Grove Village': [15],
  'Elk Horn': [13, 18],
  'Elk Mills': [21],
  'Elk Mound': [49],
  'Elk Mountain': [51],
  'Elk Park': [28],
  'Elk Point': [42],
  'Elk Rapids': [23],
  'Elk River': [14, 24],
  'Elka Park': [35],
  Elkader: [13],
  Elkfork: [18],
  Elkhart: [13, 15, 16, -16, 17, 44],
  'Elkhart Lake': [49],
  Elkhorn: [30, 49, 50],
  'Elkhorn City': [18],
  Elkin: [28],
  Elkins: [3, 31, 50],
  'Elkins Park': [39],
  Elkland: [25, 39],
  Elkmont: [2],
  Elko: [11, 34, -34, 41],
  'Elko New Market': [24],
  Elkport: [13],
  Elkridge: [21],
  Elkton: [10, 18, 21, 23, 24, 36, 38, 42, 43, 46],
  Elkview: [50],
  Elkville: [15],
  Elkwood: [46],
  Ellabell: [11],
  Ellamore: [50],
  Ellaville: [11],
  Ellenboro: [28, 50],
  Ellenburg: [35],
  'Ellenburg Center': [35],
  'Ellenburg Depot': [35],
  Ellendale: [9, 24, 29, 43],
  Ellensburg: [48],
  Ellenton: [10, 11],
  Ellenville: [35],
  Ellenwood: [11],
  Ellerbe: [28],
  Ellerslie: [11, 21],
  Ellery: [15],
  Ellettsville: [16],
  'Ellicott City': [21],
  Ellicottville: [35],
  Ellijay: [11],
  Ellinger: [44],
  Ellington: [7, 25, 35],
  Ellinwood: [17],
  Elliott: [13, 15, -18, 26, 41],
  Elliottsburg: [39],
  Elliottville: [18],
  Ellis: [14, 17, -17, -37, -44],
  'Ellis Grove': [15],
  Ellisburg: [35],
  'Ellison Bay': [49],
  Elliston: [27, 46],
  Ellisville: [15, 26],
  Elloree: [41],
  Ellsinore: [25],
  Ellston: [13],
  Ellsworth: [13, 15, 17, -17, 22, 23, 24, 30, 36, 39, 49],
  'Ellsworth Afb': [42],
  'Ellwood City': [39],
  Elm: [39],
  'Elm City': [28],
  'Elm Creek': [30],
  'Elm Grove': [19, 49],
  'Elm Hall': [23],
  'Elm Mott': [44],
  'Elm Springs': [3],
  Elma: [13, 35, 48],
  Elmaton: [44],
  Elmdale: [17],
  Elmendorf: [44],
  Elmer: [19, 25, 32, 37],
  'Elmer City': [48],
  Elmhurst: [15, 35, 39],
  Elmira: [5, 23, 35, 38],
  Elmo: [25, 27, 44, 45],
  Elmont: [35],
  Elmora: [39],
  Elmore: [2, -2, -14, 24, 36],
  'Elmore City': [37],
  Elmsford: [35],
  Elmwood: [15, 20, 30, 43, 49],
  'Elmwood Park': [15, 32],
  Elnora: [16],
  Elon: [28],
  Elora: [43],
  Eloy: [4],
  Elrama: [39],
  Elrod: [2],
  Elrosa: [24],
  Elroy: [49],
  Elsa: [44],
  Elsah: [15],
  Elsberry: [25],
  Elsie: [23, 30],
  Elsinore: [45],
  Elsmere: [30],
  Elsmore: [17],
  Elton: [19, 39, 49],
  Eltopia: [48],
  Elvaston: [15],
  Elverson: [39],
  Elverta: [5],
  Elwell: [23],
  Elwin: [15],
  Elwood: [15, 16, 17, 30, 32],
  Ely: [13, 24, 34],
  Elyria: [30, 36],
  Elysburg: [39],
  Elysian: [24],
  'Elysian Fields': [44],
  Emanuel: [-11],
  Embarrass: [24, 49],
  Emblem: [51],
  Embudo: [33],
  Emden: [15, 25],
  Emeigh: [39],
  Emelle: [2],
  Emerado: [29],
  'Emerald Isle': [28],
  Emerson: [3, 11, 13, 18, 30, 32],
  Emery: [42, 45, -45],
  Emeryville: [5],
  Emigrant: [27],
  'Emigrant Gap': [5],
  Emigsville: [39],
  Emily: [24],
  Eminence: [16, 18, 25],
  Emington: [15],
  Emlenton: [39],
  Emlyn: [18],
  Emma: [15, 25],
  Emmalena: [18],
  Emmaus: [39],
  Emmet: [3, -13, -23, 30],
  Emmetsburg: [13],
  Emmett: [14, 17, 23],
  Emmitsburg: [21],
  Emmonak: [1],
  Emmons: [24, -29],
  Emory: [44, 46],
  Empire: [2, 5, 6, 19, 23, 34, 36],
  Emporia: [17, 46],
  Emporium: [39],
  Encampment: [51],
  Encinal: [44],
  Encinitas: [5],
  Encino: [5, 33, 44],
  Endeavor: [39, 49],
  Enderlin: [29],
  Enders: [30],
  Endicott: [30, 35, 48],
  Endwell: [35],
  Energy: [15, 44],
  Enfield: [7, 15, 28, 31],
  'Enfield Center': [31],
  Engadine: [23],
  Engelhard: [28],
  England: [3],
  Englewood: [6, 10, 17, 32, 36, 43],
  'Englewood Cliffs': [32],
  English: [16],
  Englishtown: [32],
  Enid: [26, 37],
  Enigma: [11],
  Enka: [28],
  Enloe: [44],
  Ennice: [28],
  Enning: [42],
  Ennis: [27, 44],
  Enochs: [44],
  Enola: [3, 39],
  Enon: [36],
  'Enon Valley': [39],
  Enoree: [41],
  Enosburg: [47],
  'Enosburg Falls': [47],
  Ensign: [17],
  Enterprise: [2, 17, 19, 26, 38, 45, 50],
  Entiat: [48],
  Entriken: [39],
  Enumclaw: [48],
  Enville: [43],
  Eola: [15, 44],
  Eolia: [18, 25],
  Epes: [2],
  Ephraim: [45, 49],
  Ephrata: [39, 48],
  Epping: [29, 31],
  Epps: [19],
  Epsom: [31],
  Epworth: [11, 13],
  Equality: [2, 15],
  Equinunk: [39],
  Era: [44],
  Erath: [19, -44],
  Erbacon: [50],
  Erhard: [24],
  Erick: [37],
  Ericson: [30],
  Erie: [6, 15, 17, 23, 29, -35, -36, 39, -39],
  Erieville: [35],
  Erin: [35, 43],
  Erlanger: [18],
  Ermine: [18],
  Ernest: [39],
  Ernul: [28],
  Eros: [19],
  Errol: [31],
  Erskine: [24],
  Erving: [20],
  Erwin: [28, 42, 43],
  Erwinna: [39],
  Erwinville: [19],
  Esbon: [17],
  Escalante: [45],
  Escalon: [5],
  Escambia: [-2, -10],
  Escanaba: [23],
  Escatawpa: [26],
  Escondido: [5],
  Eskdale: [50],
  Esko: [24],
  Eskridge: [17],
  Esmeralda: [-34],
  Esmond: [15, 29],
  Esmont: [46],
  'Esom Hill': [11],
  Esopus: [35],
  Espanola: [33],
  Esparto: [5],
  Esperance: [35],
  Essex: [5, 7, 13, 15, 20, -20, 21, 25, 27, -32, 35, -35, -46, 47, -47],
  'Essex Fells': [32],
  'Essex Junction': [47],
  Essexville: [23],
  Essie: [18],
  Essig: [24],
  Essington: [39],
  Estacada: [38],
  Estancia: [33],
  'Estcourt Station': [22],
  'Estell Manor': [32],
  Estelline: [42, 44],
  Ester: [1],
  Estero: [10],
  'Estes Park': [6],
  Estherville: [13],
  Estherwood: [19],
  Estill: [-18, 41],
  'Estill Springs': [43],
  Estillfork: [2],
  Ethan: [42],
  Ethel: [3, 19, 25, 26, 48, 50],
  Ethelsville: [2],
  Ether: [28],
  Ethridge: [27, 43],
  Etlan: [46],
  Etna: [5, 22, 31, 35, 36, 51],
  'Etna Green': [16],
  Etoile: [18, 44],
  Eton: [11],
  Etowah: [-2, 3, 28, 43],
  Etta: [26],
  Etters: [39],
  Ettrick: [49],
  Eubank: [18],
  Eucha: [37],
  Euclid: [24, 36],
  Eudora: [3, 17],
  Eufaula: [2, 37],
  Eugene: [25, 38],
  Euless: [44],
  Eunice: [19, 25, 33],
  Eupora: [26],
  Eure: [28],
  Eureka: [5, 15, 17, 23, 25, 27, 34, -34, 42, 44, 45],
  'Eureka Springs': [3],
  Eustace: [44],
  Eustis: [10, 22, 30],
  Eutaw: [2],
  Eutawville: [41],
  Eva: [2, 43],
  Evadale: [44],
  Evangeline: [19, -19],
  Evans: [6, 11, -11, 19, 48, 50],
  'Evans City': [39],
  'Evans Mills': [35],
  Evansdale: [13],
  Evansport: [36],
  Evanston: [15, 16, 51],
  Evansville: [3, 15, 16, 24, 49, 51],
  Evant: [44],
  Evart: [23],
  Evarts: [18],
  Eveleth: [24],
  'Evening Shade': [3],
  Evensville: [43],
  Everest: [17],
  Everett: [20, 39, 48],
  Everetts: [28],
  'Everglades City': [10],
  Evergreen: [2, 6, 19, 28, 46],
  'Evergreen Park': [15],
  Everly: [13],
  Everson: [39, 48],
  Everton: [3, 25],
  Evington: [46],
  Evinston: [10],
  'Ewa Beach': [12],
  Ewan: [32],
  Ewell: [21],
  Ewen: [23],
  Ewing: [15, 18, 25, 30, 32, 46],
  Excel: [2],
  Excello: [25],
  Excelsior: [24],
  'Excelsior Springs': [25],
  Exchange: [50],
  Exeland: [49],
  Exeter: [5, 22, 25, 30, 31, 40],
  Exira: [13],
  Exline: [13],
  Exmore: [46],
  Experiment: [11],
  Export: [39],
  Exton: [39],
  Eyota: [24],
  Ezel: [18],
  Fabens: [44],
  Faber: [46],
  Fabius: [35],
  Fabyan: [7],
  Fackler: [2],
  Factoryville: [39],
  'Fair Bluff': [28],
  'Fair Grove': [25],
  'Fair Haven': [23, 32, 35, 47],
  'Fair Lawn': [32],
  'Fair Oaks': [5, 16],
  'Fair Oaks Ranch': [44],
  'Fair Play': [25, 41],
  Fairacres: [33],
  Fairbank: [13, 39],
  Fairbanks: [1, 16, 19],
  'Fairbanks North Star': [-1],
  Fairborn: [36],
  Fairburn: [11, 42],
  Fairbury: [15, 30],
  Fairchance: [39],
  Fairchild: [49],
  'Fairchild Air Force Base': [48],
  Fairdale: [18, 29, 50],
  Fairdealing: [25],
  Fairfax: [5, 13, 24, 25, 37, 41, 42, 46, -46, 47],
  'Fairfax Station': [46],
  Fairfield: [2, 5, 7, -7, 10, 13, 14, 15, 18, 22, 27, 28, 29, 30, 32, 36, -36, 39, -41, 44, 46, 47, 48],
  'Fairfield Bay': [3],
  Fairforest: [41],
  Fairgrove: [23],
  Fairhaven: [20],
  Fairhope: [2, 39],
  Fairland: [16, 37],
  Fairlawn: [36],
  Fairlea: [50],
  Fairlee: [47],
  'Fairless Hills': [39],
  Fairmont: [24, 28, 30, 37, 50],
  Fairmount: [11, 15, 16, 29],
  'Fairmount City': [39],
  Fairplay: [6, 21],
  Fairpoint: [36],
  Fairport: [35],
  Fairton: [32],
  Fairview: [15, 17, 18, 23, 25, 27, 28, 32, 36, 37, 38, 39, 42, 43, 45, 50, 51],
  'Fairview Heights': [15],
  'Fairview Village': [39],
  Fairwater: [49],
  Faison: [28],
  Faith: [28, 42],
  Falcon: [18, 25, 28],
  'Falcon Heights': [44],
  Falconer: [35],
  Falfurrias: [44],
  Falkland: [28],
  Falkner: [26],
  Falkville: [2],
  'Fall Branch': [43],
  'Fall City': [48],
  'Fall Creek': [38, 49],
  'Fall River': [17, 20, -42, 49],
  'Fall River Mills': [5],
  'Fall Rock': [18],
  Fallbrook: [5],
  Fallentimber: [39],
  'Falling Rock': [50],
  'Falling Waters': [50],
  Fallon: [27, -27, 34],
  Falls: [39, -44],
  'Falls Church': [46],
  'Falls City': [30, 38, 44],
  'Falls Creek': [39],
  'Falls Mills': [46],
  'Falls Of Rough': [18],
  'Falls Village': [7],
  Fallsburg: [35],
  Fallston: [21, 28],
  Falmouth: [16, 18, 20, 22, 23],
  'False Pass': [1],
  Falun: [17],
  Fancher: [35],
  'Fancy Farm': [18],
  'Fancy Gap': [46],
  Fannettsburg: [39],
  Fannin: [-11, 44, -44],
  Fanrock: [50],
  Fanshawe: [37],
  Fanwood: [32],
  'Far Hills': [32],
  'Far Rockaway': [35],
  Farber: [25],
  Fargo: [11, 29, 37],
  Faribault: [24, -24],
  Farina: [15],
  Farley: [13, 25],
  Farlington: [17],
  Farmdale: [36],
  Farmer: [36],
  'Farmer City': [15],
  Farmers: [18],
  Farmersburg: [13, 16],
  Farmersville: [5, 15, 36, 44],
  'Farmersville Station': [35],
  Farmerville: [19],
  Farmingdale: [22, 32, 35],
  Farmington: [3, 5, 7, 11, 13, 15, 18, 22, 23, 24, 25, 31, 33, 35, 39, 45, 48, 50],
  'Farmington Falls': [22],
  Farmingville: [35],
  Farmland: [16],
  Farmville: [28, 46],
  Farnam: [30],
  Farner: [43],
  Farnham: [35, 46],
  Farnhamville: [13],
  Farnsworth: [44],
  'Farr West': [45],
  Farragut: [13, 43],
  Farrar: [25],
  Farrell: [26, 39],
  Farson: [51],
  Farwell: [23, 24, 30, 44],
  Fate: [44],
  Faucett: [25],
  Faulk: [-42],
  Faulkner: [-3, 21],
  Faulkton: [42],
  Faunsdale: [2],
  Fauquier: [-46],
  'Fawn Grove': [39],
  Fawnskin: [5],
  Faxon: [37],
  Fay: [37],
  Fayette: [2, -2, -11, 13, -13, -15, -16, -18, 25, 26, 35, 36, -36, -39, -43, -44, 45, -50],
  'Fayette City': [39],
  Fayetteville: [2, 3, 11, 28, 35, 36, 39, 43, 44, 50],
  Fayville: [20],
  Faywood: [33],
  'Fe Warren Afb': [51],
  'Feasterville Trevose': [39],
  'Feather Falls': [5],
  'Federal Dam': [24],
  'Federal Way': [48],
  Federalsburg: [21],
  Fedora: [42],
  Fedscreek: [18],
  'Feeding Hills': [20],
  Feesburg: [36],
  Felch: [23],
  Felda: [10],
  Felicity: [36],
  Fellows: [5],
  Fellsmere: [10],
  Felt: [14, 37],
  Felton: [5, 9, 11, 24, 39],
  'Felts Mills': [35],
  Fence: [49],
  'Fence Lake': [33],
  Fenelton: [39],
  Fenn: [14],
  Fennimore: [49],
  Fennville: [23],
  Fenton: [13, 15, 19, 23, 25],
  Fentress: [-43, 44],
  Fenwick: [23, 50],
  'Fenwick Island': [9],
  Ferdinand: [14, 16],
  Fergus: [-27],
  'Fergus Falls': [24],
  Ferguson: [13, 18, 28],
  'Fernandina Beach': [10],
  Ferndale: [5, 10, 23, 35, 39, 48],
  Ferney: [42],
  Fernley: [34],
  Fernwood: [14, 26],
  Ferriday: [19],
  Ferris: [15, 44],
  Ferrisburgh: [47],
  Ferron: [45],
  Ferrum: [46],
  Ferry: [-48],
  Ferrysburg: [23],
  Ferryville: [49],
  Fertile: [13, 24],
  Fessenden: [29],
  Festus: [25],
  'Feura Bush': [35],
  Fiatt: [15],
  Fiddletown: [5],
  Fidelity: [15],
  Fieldale: [46],
  Fielding: [45],
  Fieldon: [15],
  Fields: [38],
  'Fields Landing': [5],
  Fieldton: [44],
  Fife: [48],
  'Fife Lake': [23],
  Fifield: [49],
  'Fifty Lakes': [24],
  'Fifty Six': [3],
  Filer: [14],
  'Filer City': [23],
  Filion: [23],
  Filley: [30],
  Fillmore: [5, 15, 16, -24, 25, -30, 35, 45],
  Fincastle: [46],
  Finchville: [18],
  Findlay: [15, 36],
  'Findley Lake': [35],
  Fine: [35],
  Fingal: [29],
  Finger: [43],
  Fingerville: [41],
  Finksburg: [21],
  Finland: [24],
  Finlayson: [24],
  Finley: [5, 29, 37, 43],
  Finleyville: [39],
  Finly: [16],
  Finney: [-17],
  Firebaugh: [5],
  Firestone: [6],
  Firth: [14, 30],
  Fischer: [44],
  'Fish Camp': [5],
  'Fish Creek': [49],
  'Fish Haven': [14],
  Fisher: [3, 15, 19, 24, 39, -44, 50],
  Fishers: [16, 35],
  'Fishers Hill': [46],
  'Fishers Island': [35],
  'Fishers Landing': [35],
  Fishersville: [46],
  Fishertown: [39],
  Fisherville: [18],
  'Fishing Creek': [21],
  Fishkill: [35],
  'Fishs Eddy': [35],
  Fishtail: [27],
  Fisk: [25],
  Fiskdale: [20],
  Fiskeville: [40],
  Fisty: [18],
  Fitchburg: [20],
  Fithian: [15],
  Fittstown: [37],
  Fitzgerald: [11],
  Fitzhugh: [37],
  Fitzpatrick: [2],
  Fitzwilliam: [31],
  'Five Points': [2, 5, 43],
  'Flag Pond': [43],
  Flagler: [6, -10],
  'Flagler Beach': [10],
  Flagstaff: [4],
  Flagtown: [32],
  Flanagan: [15],
  Flanders: [32],
  Flandreau: [42],
  Flasher: [29],
  Flat: [44],
  'Flat Lick': [18],
  'Flat Rock': [2, 15, 16, 23, 28, 36],
  'Flat Top': [50],
  Flatgap: [18],
  Flathead: [-27],
  Flatonia: [44],
  Flatwoods: [18, 19, 50],
  Flaxton: [29],
  Flaxville: [27],
  Fleetville: [39],
  Fleetwood: [28, 39],
  Fleischmanns: [35],
  Fleming: [6, 11, -18, 36, 39],
  'Fleming Island': [10],
  Flemingsburg: [18],
  Flemington: [25, 32, 50],
  Flensburg: [24],
  Fletcher: [25, 28, 36, 37],
  Flint: [23, 44],
  'Flint Hill': [46],
  Flinthill: [25],
  Flinton: [39],
  Flintstone: [11, 21],
  Flintville: [43],
  Flippin: [3],
  Flom: [24],
  Flomaton: [2],
  Flomot: [44],
  Floodwood: [24],
  Flora: [15, 16, 19, 26],
  'Flora Vista': [33],
  Florahome: [10],
  Floral: [3],
  'Floral City': [10],
  'Floral Park': [35],
  Florala: [2],
  Florence: [2, 4, 6, 16, 17, 18, 20, 25, 26, 27, 32, 38, 41, -41, 42, 44, 47, 49, -49],
  Floresville: [44],
  'Florham Park': [32],
  Florida: [35],
  Florien: [19],
  Floris: [13],
  Florissant: [6, 25],
  Floriston: [5],
  Flossmoor: [15],
  Flournoy: [5],
  Flourtown: [39],
  Flovilla: [11],
  'Flower Mound': [44],
  Floweree: [27],
  'Flowery Branch': [11],
  Flowood: [26],
  Floyd: [-11, 13, -13, -16, -18, 33, -44, 46, -46],
  Floydada: [44],
  'Floyds Knobs': [16],
  Fluker: [19],
  Flushing: [23, 35, 36],
  Fluvanna: [44, -46],
  'Fly Creek': [35],
  Flynn: [44],
  Foard: [-44],
  Fogelsville: [39],
  Folcroft: [39],
  Foley: [2, 24, 25],
  Folkston: [11],
  Follansbee: [50],
  Follett: [44],
  'Folly Beach': [41],
  Folsom: [5, 19, 33, 39, 50],
  Fombell: [39],
  'Fond Du Lac': [49],
  'Fond du Lac': [-49],
  Fonda: [13, 35],
  Fontana: [5, 17, 49],
  'Fontana Dam': [28],
  Fontanelle: [13],
  Fontanet: [16],
  Foosland: [15],
  'Foothill Ranch': [5],
  Footville: [49],
  Forbes: [24, 29],
  'Forbes Road': [39],
  Forbestown: [5],
  Force: [39],
  Ford: [-15, 17, -17, 46, 48],
  'Ford City': [39],
  'Ford Cliff': [39],
  Fordland: [25],
  Fordoche: [19],
  Fords: [32],
  'Fords Branch': [18],
  Fordsville: [18],
  Fordville: [29],
  Fordyce: [3, 30],
  Foreman: [3],
  Forest: [16, 19, 26, 36, -39, 46, -49],
  'Forest City': [13, 15, 25, 28, 39],
  'Forest Dale': [47],
  'Forest Falls': [5],
  'Forest Grove': [27, 38, 39],
  'Forest Hill': [19, 21, 50],
  'Forest Hills': [18, 35],
  'Forest Home': [2],
  'Forest Junction': [49],
  'Forest Knolls': [5],
  'Forest Lake': [24],
  'Forest Lakes': [4],
  'Forest Park': [11, 15],
  'Forest Ranch': [5],
  'Forest River': [29],
  Forestburg: [44],
  Forestburgh: [35],
  Forestdale: [20, 40],
  Foresthill: [5],
  Foreston: [24],
  Forestport: [35],
  Forestville: [5, 23, 35, 39, 49],
  Forgan: [37],
  Foristell: [25],
  Fork: [21, 41],
  'Fork Union': [46],
  'Forked River': [32],
  Forkland: [2],
  Forks: [48],
  'Forks Of Salmon': [5],
  Forksville: [39],
  Forman: [29],
  Formoso: [17],
  Forney: [44],
  Forrest: [15, -26],
  'Forrest City': [3],
  Forreston: [15, 44],
  Forsan: [44],
  Forsyth: [11, -11, 15, 25, 27, -28],
  'Fort Ann': [35],
  'Fort Apache': [4],
  'Fort Ashby': [50],
  'Fort Atkinson': [13, 49],
  'Fort Bayard': [33],
  'Fort Belvoir': [46],
  'Fort Bend': [-44],
  'Fort Benning': [11],
  'Fort Benton': [27],
  'Fort Bidwell': [5],
  'Fort Blackmore': [46],
  'Fort Bliss': [44],
  'Fort Bragg': [5, 28],
  'Fort Branch': [16],
  'Fort Bridger': [51],
  'Fort Calhoun': [30],
  'Fort Campbell': [18],
  'Fort Cobb': [37],
  'Fort Collins': [6],
  'Fort Covington': [35],
  'Fort Davis': [2, 44],
  'Fort Defiance': [4, 46],
  'Fort Deposit': [2],
  'Fort Dick': [5],
  'Fort Dodge': [13, 17],
  'Fort Drum': [35],
  'Fort Duchesne': [45],
  'Fort Edward': [35],
  'Fort Eustis': [46],
  'Fort Fairfield': [22],
  'Fort Gaines': [11],
  'Fort Garland': [6],
  'Fort Gay': [50],
  'Fort George G Meade': [21],
  'Fort Gibson': [37],
  'Fort Gratiot': [23],
  'Fort Greely': [1],
  'Fort Hall': [14],
  'Fort Hancock': [44],
  'Fort Harrison': [27],
  'Fort Hill': [39],
  'Fort Hood': [44],
  'Fort Howard': [21],
  'Fort Huachuca': [4],
  'Fort Hunter': [35],
  'Fort Irwin': [5],
  'Fort Jennings': [36],
  'Fort Johnson': [35],
  'Fort Jones': [5],
  'Fort Kent': [22],
  'Fort Kent Mills': [22],
  'Fort Klamath': [38],
  'Fort Knox': [18],
  'Fort Laramie': [51],
  'Fort Lauderdale': [10],
  'Fort Lawn': [41],
  'Fort Leavenworth': [17],
  'Fort Lee': [32, 46],
  'Fort Leonard Wood': [25],
  'Fort Littleton': [39],
  'Fort Loramie': [36],
  'Fort Loudon': [39],
  'Fort Lupton': [6],
  'Fort Lyon': [6],
  'Fort Madison': [13],
  'Fort McCoy': [10],
  'Fort McKavett': [44],
  'Fort Mcdowell': [4],
  'Fort Meade': [10, 42],
  'Fort Mill': [41],
  'Fort Mitchell': [2, 18, 46],
  'Fort Mohave': [4],
  'Fort Monmouth': [32],
  'Fort Monroe': [46],
  'Fort Montgomery': [35],
  'Fort Morgan': [6],
  'Fort Myer': [46],
  'Fort Myers': [10],
  'Fort Myers Beach': [10],
  'Fort Necessity': [19],
  'Fort Ogden': [10],
  'Fort Oglethorpe': [11],
  'Fort Payne': [2],
  'Fort Peck': [27],
  'Fort Pierce': [10],
  'Fort Pierre': [42],
  'Fort Plain': [35],
  'Fort Polk': [19],
  'Fort Ransom': [29],
  'Fort Recovery': [36],
  'Fort Riley': [17],
  'Fort Ripley': [24],
  'Fort Rock': [38],
  'Fort Rucker': [2],
  'Fort Scott': [17],
  'Fort Shafter': [12],
  'Fort Shaw': [27],
  'Fort Sheridan': [15],
  'Fort Sill': [37],
  'Fort Smith': [3, 27],
  'Fort Stanton': [33],
  'Fort Stewart': [11],
  'Fort Stockton': [44],
  'Fort Sumner': [33],
  'Fort Supply': [37],
  'Fort Thomas': [4, 18],
  'Fort Thompson': [42],
  'Fort Totten': [29],
  'Fort Towson': [37],
  'Fort Valley': [11, 46],
  'Fort Wainwright': [1],
  'Fort Walton Beach': [10],
  'Fort Washakie': [51],
  'Fort Washington': [21, 39],
  'Fort Wayne': [16],
  'Fort White': [10],
  'Fort Wingate': [33],
  'Fort Worth': [44],
  'Fort Wright': [18],
  'Fort Yates': [29],
  'Fort Yukon': [1],
  Fortescue: [32],
  Fortine: [27],
  Fortson: [11],
  Fortuna: [5, 25, 29],
  Fortville: [16],
  Foss: [37],
  Fossil: [38],
  Fosston: [24],
  Foster: [18, 25, -29, 37, 38, 40, 46, 50],
  'Foster City': [23],
  Fosters: [2],
  Fosterville: [43],
  Fostoria: [13, 17, 23, 36],
  Fouke: [3],
  Fountain: [6, 10, -16, 23, 24, 28],
  'Fountain City': [16, 49],
  'Fountain Green': [45],
  'Fountain Hill': [3],
  'Fountain Hills': [4],
  'Fountain Inn': [41],
  'Fountain Run': [18],
  'Fountain Valley': [5],
  Fountaintown: [16],
  Fountainville: [39],
  'Four Corners': [51],
  'Four Lakes': [48],
  'Four Oaks': [28],
  'Four States': [50],
  Fourmile: [18],
  Fowler: [5, 6, 15, 16, 17, 23, 36],
  Fowlerton: [16, 44],
  Fowlerville: [23],
  Fowlstown: [11],
  Fox: [3, 37],
  'Fox Island': [48],
  'Fox Lake': [15, 49],
  'Fox River Grove': [15],
  'Fox Valley': [15],
  Foxboro: [49],
  Foxborough: [20],
  Foxburg: [39],
  Foxhome: [24],
  Foxworth: [26],
  Foyil: [37],
  Frackville: [39],
  Frakes: [18],
  Frametown: [50],
  Framingham: [20],
  Francestown: [31],
  Francesville: [16],
  Francis: [37],
  'Francis Creek': [49],
  Francisco: [16],
  Francitas: [44],
  Franconia: [31],
  Frankenmuth: [23],
  Frankewing: [43],
  Frankford: [9, 25, 50],
  Frankfort: [15, 16, 17, 18, 22, 23, 35, 36, 42],
  'Frankfort Heights': [15],
  Franklin: [2, -2, 3, -3, -10, 11, -11, -13, 14, -14, 15, -15, 16, -16, 17, -17, 18, -18, 19, -19, 20, -20, 22, -22, 23, 24, 25, -25, -26, 28, -28, 30, -30, 31, 32, 35, -35, 36, -36, 39, -39, 43, -43, 44, -44, 46, -46, 47, -47, -48, 49, 50],
  'Franklin Furnace': [36],
  'Franklin Grove': [15],
  'Franklin Lakes': [32],
  'Franklin Park': [15, 32],
  'Franklin Springs': [11, 35],
  'Franklin Square': [35],
  Franklinton: [19, 28],
  Franklintown: [39],
  Franklinville: [28, 32, 35],
  Frankston: [44],
  Franksville: [49],
  Frankton: [16],
  Franktown: [6, 46],
  Frankville: [2],
  Frannie: [51],
  Fraser: [6, 23],
  Frazee: [24],
  Frazer: [27],
  Frazeysburg: [36],
  'Frazier Park': [5],
  'Fraziers Bottom': [50],
  Fred: [44],
  Frederic: [23, 49],
  Frederica: [9],
  Frederick: [6, 15, 21, -21, 37, 39, 42, -46],
  Fredericksburg: [13, 16, 36, 39, 44, 46],
  Fredericktown: [25, 36, 39],
  Frederika: [13],
  Fredonia: [4, 17, 18, 29, 35, 39, 44, 49],
  'Free Soil': [23],
  'Free Union': [46],
  Freeborn: [24, -24],
  Freeburg: [15, 25, 39],
  Freeburn: [18],
  Freedom: [5, 16, 22, 31, 35, 37, 39, 49, 51],
  Freehold: [32, 35],
  Freeland: [21, 23, 39, 48],
  Freelandville: [16],
  Freeman: [25, 42, 46, 50],
  'Freeman Spur': [15],
  Freeport: [10, 15, 17, 22, 23, 24, 35, 36, 39, 44],
  Freer: [44],
  Freestone: [-44],
  Freetown: [16],
  Freeville: [35],
  Freistatt: [25],
  Fremont: [5, -6, 13, -13, -14, 16, 23, 25, 28, 30, 31, 36, 49, -51],
  'Fremont Center': [35],
  'French Camp': [5, 26],
  'French Creek': [50],
  'French Gulch': [5],
  'French Lick': [16],
  'French Settlement': [19],
  'French Village': [25],
  Frenchboro: [22],
  Frenchburg: [18],
  Frenchglen: [38],
  'Frenchmans Bayou': [3],
  Frenchton: [50],
  Frenchtown: [27, 32],
  Frenchville: [22, 39],
  'Fresh Meadows': [35],
  Fresno: [5, -5, 36, 44],
  Frewsburg: [35],
  Friant: [5],
  'Friars Point': [26],
  'Friday Harbor': [48],
  Friedens: [39],
  Friedensburg: [39],
  Friedheim: [25],
  Friend: [30],
  Friendly: [50],
  Friendship: [3, 16, 21, 22, 35, 36, 43, 49],
  Friendsville: [21, 39, 43],
  Friendswood: [44],
  Frierson: [19],
  Fries: [46],
  Friesland: [49],
  Frio: [-44],
  Friona: [44],
  Frisco: [6, 28, 44],
  'Frisco City': [2],
  Fritch: [44],
  Frohna: [25],
  Froid: [27],
  Fromberg: [27],
  'Front Royal': [46],
  Frontenac: [17, 24, 25],
  Frontier: [23, -30, 51],
  Frost: [24, 44],
  Frostburg: [21],
  Frostproof: [10],
  Fruita: [6],
  Fruitdale: [2],
  Fruithurst: [2],
  Fruitland: [13, 14, 21, 33, 45, 48],
  'Fruitland Park': [10],
  Fruitport: [23],
  Fruitvale: [43, 44],
  Fryburg: [39],
  Fryeburg: [22],
  'Ft Mitchell': [18],
  Fulda: [16, 24],
  'Fulks Run': [46],
  Fullerton: [5, 29, 30],
  Fulshear: [44],
  Fulton: [2, 3, -3, 5, -11, 15, -15, 16, -16, 17, 18, -18, 21, 23, 25, 26, 35, -35, 36, -36, -39, 42, 44],
  Fultondale: [2],
  Fultonham: [35, 36],
  Fultonville: [35],
  Fults: [15],
  Funk: [30],
  Funkstown: [21],
  Funston: [11],
  'Fuquay Varina': [28],
  Furlong: [39],
  Furman: [2, 41],
  Furnas: [-30],
  Fyffe: [2],
  Gaastra: [23],
  Gabbs: [34],
  Gable: [41],
  Gabriels: [35],
  Gackle: [29],
  Gadsden: [2, -10, 41, 43],
  Gaffney: [41],
  Gage: [-30, 37],
  Gagetown: [23],
  Gail: [44],
  Gaines: [23, 39, -44],
  Gainesboro: [43],
  Gainestown: [2],
  Gainesville: [2, 10, 11, 25, 35, 44, 46],
  Gaithersburg: [21],
  Gakona: [1],
  Galata: [27],
  Galatia: [15],
  Galax: [46],
  Galena: [1, 15, 17, 21, 25, 36],
  'Galena Park': [44],
  'Gales Creek': [38],
  'Gales Ferry': [7],
  Galesburg: [15, 17, 23, 29],
  Galesville: [21, 49],
  Galeton: [6, 39],
  Galien: [23],
  Galion: [36],
  'Galivants Ferry': [41],
  Gallagher: [50],
  Gallant: [2],
  Gallatin: [-15, -18, 25, -27, 43, 44],
  'Gallatin Gateway': [27],
  Gallaway: [43],
  Gallia: [-36],
  Galliano: [19],
  Gallina: [33],
  Gallion: [2],
  Gallipolis: [36],
  'Gallipolis Ferry': [50],
  Gallitzin: [39],
  Gallman: [26],
  Galloway: [36, 50],
  Gallup: [33],
  Gallupville: [35],
  Galt: [5, 13, 15, 25],
  Galva: [13, 15, 17],
  Galveston: [16, 44, -44],
  Galvin: [48],
  Galway: [35],
  Gamaliel: [3, 18],
  Gambell: [1],
  Gambier: [36],
  Gambrills: [21],
  Gamerco: [33],
  Ganado: [4, 44],
  Gandeeville: [50],
  'Gann Valley': [42],
  Gans: [37, 39],
  Gansevoort: [35],
  Gantt: [2],
  Gap: [39],
  'Gap Mills': [50],
  'Garards Fort': [39],
  Garber: [13, 37],
  Garberville: [5],
  Garciasville: [44],
  Garden: [23, -30],
  'Garden City': [2, 13, 14, 17, 19, 23, 24, 25, 35, 42, 44, 45],
  'Garden Grove': [5, 13],
  'Garden Plain': [17],
  'Garden Prairie': [15],
  'Garden Ridge': [44],
  'Garden Valley': [5, 14],
  Gardena: [5],
  Gardendale: [2, 44],
  Gardiner: [22, 27, 35, 38],
  Gardner: [6, 15, 17, 19, 20, 29],
  Gardners: [39],
  Gardnerville: [34],
  Garfield: [3, -6, 11, 17, 18, 24, -27, -30, 32, 33, -37, -45, 48, -48],
  Garibaldi: [38],
  Garita: [33],
  Garland: [-3, 17, 22, 28, 30, 39, 44, 45],
  'Garland City': [3],
  Garnavillo: [13],
  Garner: [3, 13, 18, 28],
  Garnerville: [35],
  'Garnet Valley': [39],
  Garnett: [17, 41],
  Garrard: [18, -18],
  Garrattsville: [35],
  Garretson: [42],
  Garrett: [16, 18, -21, 39, 51],
  'Garrett Park': [21],
  Garrettsville: [36],
  Garrison: [13, 18, 24, 25, 27, 29, 35, 44, 45],
  Garrisonville: [46],
  Garryowen: [27],
  Garvin: [24, 37, -37],
  Garwin: [13],
  Garwood: [32, 44],
  Gary: [16, 24, 42, 44, 50],
  Garysburg: [28],
  Garyville: [19],
  Garza: [-44],
  Gas: [17],
  'Gas City': [16],
  Gasburg: [46],
  Gasconade: [25, -25],
  Gasport: [35],
  Gasquet: [5],
  Gassaway: [50],
  Gassville: [3],
  Gaston: [16, 28, -28, 38, 41],
  Gastonia: [28],
  Gastonville: [39],
  Gate: [37],
  'Gate City': [46],
  Gates: [28, -28, 38, 43],
  'Gates Mills': [36],
  Gatesville: [28, 44],
  Gateway: [3, 6],
  Gatewood: [25],
  Gatlinburg: [43],
  Gattman: [26],
  Gatzke: [24],
  'Gauley Bridge': [50],
  Gause: [44],
  Gautier: [26],
  Gay: [11, 50],
  Gaylesville: [2],
  Gaylord: [17, 23, 24],
  Gaylordsville: [7],
  Gays: [15],
  'Gays Creek': [18],
  'Gays Mills': [49],
  Gaysville: [47],
  Gayville: [42],
  Gazelle: [5],
  Gearhart: [38],
  Geary: [-17, 37],
  Geauga: [-36],
  Geddes: [42],
  Geff: [15],
  Geigertown: [39],
  Geismar: [19],
  Gem: [-14, 17],
  'Gene Autry': [37],
  Genesee: [14, 23, -23, -35, 39],
  'Genesee Depot': [49],
  Geneseo: [15, 17, 35],
  Geneva: [2, -2, 10, 11, 13, 14, 15, 16, 24, 30, 35, 36],
  Genoa: [3, 6, 15, 30, 34, 35, 36, 49, 50],
  'Genoa City': [49],
  Gentry: [3, 25, -25],
  Gentryville: [16],
  George: [13, -26, 48],
  'George West': [44],
  'Georges Mills': [31],
  Georgetown: [5, 6, 7, 9, 10, 11, 14, 15, 16, 18, 19, 20, 21, 22, 24, 26, 35, 36, 39, 41, -41, 43, 44],
  Georgiana: [2],
  Gepp: [3],
  Gerald: [25],
  Geraldine: [2, 27],
  Gerber: [5],
  Gering: [30],
  Gerlach: [34],
  Gerlaw: [15],
  'German Valley': [15],
  Germansville: [39],
  Germanton: [28],
  Germantown: [15, 18, 21, 35, 36, 43, 49],
  Germfask: [23],
  Geronimo: [37, 44],
  Gerrardstown: [50],
  Gerry: [35],
  Gerton: [28],
  Gervais: [38],
  Gettysburg: [36, 39, 42],
  Getzville: [35],
  'Geuda Springs': [17],
  Geyser: [27],
  Geyserville: [5],
  Gheens: [19],
  Ghent: [18, 24, 35, 50],
  Gibbon: [24, 30],
  'Gibbon Glade': [39],
  Gibbonsville: [14],
  Gibbs: [25],
  Gibbsboro: [32],
  Gibbstown: [32],
  Gibsland: [19],
  Gibson: [11, 13, -16, 19, 25, 28, 39, 43, -43],
  'Gibson City': [15],
  'Gibson Island': [21],
  Gibsonburg: [36],
  Gibsonia: [39],
  Gibsonton: [10],
  Gibsonville: [28],
  Giddings: [44],
  Gideon: [25],
  Gifford: [13, 15, 39, 41, 48],
  'Gig Harbor': [48],
  Gila: [-4, 33],
  'Gila Bend': [4],
  Gilbert: [3, 4, 13, 19, 24, 39, 41, 50],
  Gilberton: [39],
  Gilbertown: [2],
  Gilberts: [15],
  Gilbertsville: [18, 35, 39],
  Gilbertville: [13, 20],
  Gilboa: [35, 50],
  Gilby: [29],
  Gilchrist: [-10, 38, 44],
  Gilcrest: [6],
  Gildford: [27],
  Gile: [49],
  Gilead: [30],
  Giles: [-43, -46],
  Gilford: [31],
  Gill: [6, 20],
  Gillespie: [15, -44],
  Gillett: [3, 39, 44, 49],
  'Gillett Grove': [13],
  Gillette: [32, 51],
  Gillham: [3],
  Gilliam: [19, 25, -38],
  Gillsville: [11],
  Gilman: [7, 13, 15, 24, 47, 49],
  'Gilman City': [25],
  Gilmanton: [31, 49],
  'Gilmanton Iron Works': [31],
  Gilmer: [-11, 44, -50],
  Gilmore: [3],
  'Gilmore City': [13],
  Gilpin: [-6],
  Gilroy: [5],
  Gilson: [15],
  Gilsum: [31],
  Giltner: [30],
  Gipsy: [25, 39],
  Girard: [11, 15, 17, 36, 39, 44],
  Girardville: [39],
  Girdler: [18],
  Girdletree: [21],
  Girdwood: [1],
  Girvin: [44],
  Given: [50],
  Glacier: [-27],
  Gladbrook: [13],
  Glade: [17],
  'Glade Hill': [46],
  'Glade Park': [6],
  'Glade Spring': [46],
  'Glade Valley': [28],
  Glades: [-10],
  Gladeville: [43],
  Gladewater: [44],
  Gladstone: [15, 23, 25, 29, 32, 33, 38, 46],
  Gladwin: [23, -23],
  Gladwyne: [39],
  Glady: [50],
  Gladys: [46],
  Glandorf: [36],
  Glasco: [17, 35],
  Glascock: [-11],
  Glasford: [15],
  Glasgow: [18, 25, 27, 39, 46, 50],
  Glassboro: [32],
  Glasscock: [-44],
  Glasser: [32],
  Glassport: [39],
  Glasston: [29],
  Glastonbury: [7],
  Gleason: [43, 49],
  Glen: [26, 27, 31, 50],
  'Glen Allan': [26],
  'Glen Allen': [2, 46],
  'Glen Alpine': [28],
  'Glen Arbor': [23],
  'Glen Arm': [21],
  'Glen Aubrey': [35],
  'Glen Burnie': [21],
  'Glen Campbell': [39],
  'Glen Carbon': [15],
  'Glen Cove': [35],
  'Glen Dale': [50],
  'Glen Daniel': [50],
  'Glen Easton': [50],
  'Glen Echo': [21],
  'Glen Elder': [17],
  'Glen Ellen': [5],
  'Glen Ellyn': [15],
  'Glen Ferris': [50],
  'Glen Flora': [44, 49],
  'Glen Fork': [50],
  'Glen Gardner': [32],
  'Glen Haven': [6, 49],
  'Glen Head': [35],
  'Glen Hope': [39],
  'Glen Jean': [50],
  'Glen Lyn': [46],
  'Glen Lyon': [39],
  'Glen Mills': [39],
  'Glen Oaks': [35],
  'Glen Richey': [39],
  'Glen Riddle Lima': [39],
  'Glen Ridge': [32],
  'Glen Rock': [32, 39],
  'Glen Rogers': [50],
  'Glen Rose': [44],
  'Glen Saint Mary': [10],
  'Glen Spey': [35],
  'Glen Ullin': [29],
  'Glen White': [50],
  'Glen Wild': [35],
  'Glen Wilton': [46],
  Glenallen: [25],
  Glenarm: [15],
  Glenbeulah: [49],
  Glenbrook: [34],
  Glenburn: [29],
  Glencliff: [31],
  Glencoe: [3, 5, 15, 18, 24, 33, 36, 37],
  Glencross: [42],
  Glendale: [4, 5, 18, 20, 38, 40, 41, 45],
  'Glendale Heights': [15],
  'Glendale Springs': [28],
  Glendive: [27],
  Glendo: [51],
  Glendora: [5, 26, 32],
  'Gleneden Beach': [38],
  Glenelg: [21],
  Glenfield: [29, 35],
  Glenford: [35, 36],
  Glengary: [50],
  Glenham: [35, 42],
  Glenhaven: [5],
  Glenmont: [35, 36],
  Glenmoore: [39],
  Glenmora: [19],
  Glenn: [5, -5, 23],
  'Glenn Dale': [21],
  Glennallen: [1],
  Glennie: [23],
  'Glenns Ferry': [14],
  Glennville: [5, 11],
  Glenolden: [39],
  Glenoma: [48],
  Glenpool: [37],
  Glenrock: [51],
  'Glens Falls': [35],
  'Glens Fork': [18],
  Glenshaw: [39],
  Glenside: [39],
  Glentana: [27],
  Glenview: [15, 18],
  Glenvil: [30],
  Glenville: [24, 28, 39, 50],
  Glenwood: [2, 3, 10, 11, 13, 15, 16, 21, 24, 25, 28, 32, 33, 35, 45, 48, 50],
  'Glenwood City': [49],
  'Glenwood Landing': [35],
  'Glenwood Springs': [6],
  Glidden: [13, 44, 49],
  Glide: [38],
  Globe: [4],
  Glorieta: [33],
  Gloster: [19, 26],
  Gloucester: [20, 28, -32, 46, -46],
  'Gloucester City': [32],
  'Gloucester Point': [46],
  Glouster: [36],
  Glover: [47],
  Gloversville: [35],
  Gloverville: [41],
  Glyndon: [21, 24],
  Glynn: [-11, 19],
  Gnadenhutten: [36],
  Gober: [44],
  Gobler: [25],
  Gobles: [23],
  Goddard: [17],
  Godfrey: [15],
  Godley: [44],
  Godwin: [28],
  Goehner: [30],
  Goessel: [17],
  Goetzville: [23],
  Goff: [17],
  Goffstown: [31],
  Gogebic: [-23],
  Golconda: [15, 34],
  'Gold Bar': [48],
  'Gold Beach': [38],
  'Gold Canyon': [4],
  'Gold Creek': [27],
  'Gold Hill': [28, 38],
  'Gold Run': [5],
  Golden: [6, 15, 25, 26, 37, 44],
  'Golden City': [25],
  'Golden Eagle': [15],
  'Golden Gate': [15],
  'Golden Meadow': [19],
  'Golden Valley': [4, -27, 29, -29],
  Goldendale: [48],
  Goldenrod: [10],
  'Goldens Bridge': [35],
  Goldfield: [13, 34],
  Goldonna: [19],
  Goldsboro: [21, 28, 39, 44],
  Goldsmith: [16, 44],
  Goldston: [28],
  Goldthwaite: [44],
  Goldvein: [46],
  Goleta: [5],
  Golf: [15],
  Goliad: [44, -44],
  Goltry: [37],
  Golva: [29],
  Gomer: [36],
  Gonvick: [24],
  Gonzales: [5, 19, 44, -44],
  Gonzalez: [10],
  Goochland: [46, -46],
  'Good Hart': [23],
  'Good Hope': [11, 15],
  'Good Thunder': [24],
  Goode: [46],
  Goodell: [13],
  Goodells: [23],
  'Goodfellow Afb': [44],
  Goodfield: [15],
  Goodhue: [24, -24],
  Gooding: [14, -14],
  Goodland: [10, 16, 17, 24],
  Goodlettsville: [43],
  Goodman: [25, 26, 49],
  'Goodnews Bay': [1],
  Goodrich: [23, 29, 44],
  Goodridge: [24],
  Goodspring: [43],
  Goodsprings: [2],
  Goodview: [46],
  Goodville: [39],
  Goodwater: [2],
  Goodwell: [37],
  Goodwin: [3, 42],
  Goodwine: [15],
  Goodyear: [4],
  'Goodyears Bar': [5],
  'Goose Creek': [41],
  'Goose Lake': [13],
  'Goose Rock': [18],
  Gordo: [2],
  Gordon: [2, 11, -11, 18, 30, 39, 44, 49, 50],
  Gordonsville: [43, 46],
  Gordonville: [25, 39, 44],
  Gore: [37, 46],
  'Gore Springs': [26],
  Goree: [44],
  Goreville: [15],
  Gorham: [15, 17, 22, 31, 35],
  Gorin: [25],
  Gorman: [44],
  Gormania: [50],
  Gorum: [19],
  Goshen: [2, 3, 5, 7, 16, 18, 20, 31, 32, 35, 36, 45, 46, -51],
  Gosnell: [3],
  Gosper: [-30],
  Gosport: [16],
  Gotebo: [37],
  Gotha: [10],
  Gotham: [49],
  Gothenburg: [30],
  Gough: [11],
  Gould: [3, 37],
  'Gould City': [23],
  Gouldbusk: [44],
  Gouldsboro: [22, 39],
  Gouverneur: [35],
  Gove: [17, -17],
  'Government Camp': [38],
  Gowanda: [35],
  Gowen: [23, 37],
  Gower: [25],
  Gowrie: [13],
  Grabill: [16],
  Grace: [14, 26],
  'Grace City': [29],
  Gracemont: [37],
  Graceville: [10, 24],
  Gracewood: [11],
  Gracey: [18],
  Grady: [2, 3, -11, 33, -37],
  Gradyville: [18, 39],
  Graeagle: [5],
  Graettinger: [13],
  Graff: [25],
  Graford: [44],
  Grafton: [13, 15, 20, 29, 30, 31, -31, 35, 36, 47, 49, 50],
  Graham: [2, -4, 10, -17, 18, 25, 28, -28, 37, 44, 48],
  Grahamsville: [35],
  Grahn: [18],
  'Grain Valley': [25],
  Grainfield: [17],
  Grainger: [-43],
  Grambling: [19],
  Gramercy: [19],
  Gramling: [41],
  Grammer: [16],
  Grampian: [39],
  Granada: [6, 24],
  'Granada Hills': [5],
  Granbury: [44],
  Granby: [6, 7, 20, 25, 47],
  Grand: [-6, -45],
  'Grand Bay': [2],
  'Grand Blanc': [23],
  'Grand Cane': [19],
  'Grand Canyon': [4],
  'Grand Chain': [15],
  'Grand Chenier': [19],
  'Grand Coteau': [19],
  'Grand Coulee': [48],
  'Grand Forks': [29, -29],
  'Grand Forks Afb': [29],
  'Grand Gorge': [35],
  'Grand Haven': [23],
  'Grand Island': [10, 30, 35],
  'Grand Isle': [19, 22, 47, -47],
  'Grand Junction': [6, 13, 23, 43],
  'Grand Lake': [6],
  'Grand Lake Stream': [22],
  'Grand Ledge': [23],
  'Grand Marais': [23, 24],
  'Grand Marsh': [49],
  'Grand Meadow': [24],
  'Grand Mound': [13],
  'Grand Portage': [24],
  'Grand Prairie': [44],
  'Grand Rapids': [23, 24, 36],
  'Grand Ridge': [10, 15],
  'Grand River': [13, 36],
  'Grand Rivers': [18],
  'Grand Ronde': [38],
  'Grand Saline': [44],
  'Grand Terrace': [5],
  'Grand Tower': [15],
  'Grand Traverse': [-23],
  'Grand Valley': [39],
  'Grand View': [14, 49],
  Grandfalls: [44],
  Grandfield: [37],
  Grandin: [10, 25, 29],
  Grandview: [13, 16, 25, 43, 44, 48],
  Grandville: [23],
  Grandy: [24, 28],
  Granger: [13, 16, 25, 44, 48, 51],
  Grangeville: [14],
  Granite: [6, -27, 37],
  'Granite Bay': [5],
  'Granite Canon': [51],
  'Granite City': [15],
  'Granite Falls': [24, 28, 48],
  'Granite Quarry': [28],
  'Granite Shoals': [44],
  'Granite Springs': [35],
  Graniteville: [41, 47],
  Grannis: [3],
  Grant: [2, -3, 6, 10, 13, -16, -17, -18, 19, -19, 23, -24, -29, 30, -30, -33, 37, -37, -38, -42, -48, -49, -50],
  'Grant City': [25],
  'Grant Park': [15],
  'Grant Town': [50],
  Grantham: [31, 39],
  Granton: [49],
  Grants: [33],
  'Grants Pass': [38],
  Grantsboro: [28],
  Grantsburg: [15, 16, 49],
  Grantsdale: [27],
  Grantsville: [21, 45, 50],
  Grantville: [11, 17, 39],
  Granville: [13, 15, 20, -28, 29, 35, 36, 39, 43, 47, 50],
  'Granville Summit': [39],
  Grapeland: [44],
  Grapeview: [48],
  Grapeville: [39],
  Grapevine: [3, 44],
  Grasonville: [21],
  'Grass Creek': [16],
  'Grass Lake': [23],
  'Grass Range': [27],
  'Grass Valley': [5, 38],
  Grassflat: [39],
  Grasston: [24],
  'Grassy Butte': [29],
  'Grassy Creek': [28],
  'Grassy Meadows': [50],
  Gratiot: [-23, 36, 49],
  Gratis: [36],
  Graton: [5],
  Gratz: [39],
  'Gravel Switch': [18],
  Gravelly: [3],
  Graves: [-18],
  Gravette: [3],
  Gravity: [13],
  'Gravois Mills': [25],
  Grawn: [23],
  Gray: [11, 13, -17, 18, 19, 22, 39, -44],
  'Gray Court': [41],
  'Gray Hawk': [18],
  'Gray Mountain': [4],
  'Gray Summit': [25],
  Grayland: [48],
  Grayling: [1, 23],
  Graymont: [15],
  Grayridge: [25],
  'Grays Harbor': [-48],
  'Grays Knob': [18],
  'Grays River': [48],
  Grayslake: [15],
  Grayson: [11, 18, -18, 19, -44, -46],
  Graysville: [2, 11, 16, 36, 39, 43],
  Graytown: [36],
  Grayville: [15],
  'Great Barrington': [20],
  'Great Bend': [17, 35, 39],
  'Great Cacapon': [50],
  'Great Falls': [27, 41, 46],
  'Great Lakes': [15],
  'Great Meadows': [32],
  'Great Mills': [21],
  'Great Neck': [35],
  'Great River': [35],
  'Great Valley': [35],
  Greeley: [6, 13, 17, -17, 30, -30, 39],
  Greeleyville: [41],
  Green: [17, -18, 36, -49],
  'Green Bank': [50],
  'Green Bay': [46, 49],
  'Green Brook': [32],
  'Green Camp': [36],
  'Green Castle': [25],
  'Green City': [25],
  'Green Cove Springs': [10],
  'Green Creek': [32],
  'Green Forest': [3],
  'Green Harbor': [20],
  'Green Isle': [24],
  'Green Lake': [49, -49],
  'Green Lane': [39],
  'Green Mountain': [28],
  'Green Mountain Falls': [6],
  'Green Pond': [2, 41],
  'Green Ridge': [25],
  'Green River': [45, 51],
  'Green Road': [18],
  'Green Sea': [41],
  'Green Spring': [50],
  'Green Springs': [36],
  'Green Sulphur Springs': [50],
  'Green Valley': [4, 15, 49],
  'Green Valley Lake': [5],
  'Green Village': [32],
  Greenacres: [10, 48],
  Greenback: [43],
  Greenbackville: [46],
  Greenbank: [48],
  Greenbelt: [21],
  Greenboro: [11],
  Greenbrae: [5],
  Greenbrier: [3, 43, -50],
  Greenbush: [20, 22, 23, 24, 46, 49],
  Greencastle: [16, 39],
  Greencreek: [14],
  Greendale: [16, 49],
  Greendell: [32],
  Greene: [-2, -3, -11, 13, -13, -15, -16, 22, -25, -26, -28, 35, -35, -36, -39, 40, -43, -46],
  Greeneville: [43],
  Greenfield: [5, 13, 15, 16, 20, 25, 31, 36, 37, 43, 49],
  'Greenfield Center': [35],
  'Greenfield Park': [35],
  Greenford: [36],
  Greenhurst: [35],
  Greenland: [3, 23, 31],
  Greenlawn: [35],
  Greenleaf: [14, 17, 49],
  Greenlee: [-4],
  Greenock: [39],
  Greenport: [35],
  'Greens Farms': [7],
  'Greens Fork': [16],
  Greensboro: [2, 10, 11, 16, 21, 28, 39, 47],
  'Greensboro Bend': [47],
  Greensburg: [16, 17, 18, 19, 39],
  Greensville: [-46],
  Greentop: [25],
  Greentown: [16, 36, 39],
  Greenup: [15, 18, -18],
  Greenvale: [35],
  Greenview: [5, 15],
  Greenville: [2, 5, 10, 11, 13, 15, 16, 18, 22, 23, 25, 26, 28, 31, 35, 36, 39, 40, 41, -41, 44, 45, 46, 49, 50],
  'Greenville Junction': [22],
  Greenwald: [24],
  Greenway: [3, 46],
  'Greenwell Springs': [19],
  Greenwich: [7, 17, 32, 35, 36, 45],
  Greenwood: [3, 5, 9, 10, 16, -17, 19, 22, 25, 26, 30, 35, 41, -41, 44, 46, 49],
  'Greenwood Lake': [35],
  'Greenwood Springs': [26],
  'Greenwood Village': [6],
  Greer: [4, -37, 41],
  'Greers Ferry': [3],
  Gregg: [-44],
  Gregory: [3, 23, 42, -42, 44],
  Greig: [35],
  Grelton: [36],
  Grenada: [5, 26, -26],
  Grenloch: [32],
  Grenola: [17],
  Grenora: [29],
  Grenville: [33, 42],
  Gresham: [30, 38, 41, 49],
  Grethel: [18],
  Gretna: [10, 19, 30, 46],
  'Grey Eagle': [24],
  Greybull: [51],
  Greycliff: [27],
  Gridley: [5, 15, 17],
  Griffin: [11, 16],
  Griffith: [16],
  Griffithsville: [50],
  Griffithville: [3],
  Grifton: [28],
  Griggs: [-29],
  Griggsville: [15],
  Grimes: [5, 13, -44],
  Grimesland: [28],
  Grimsley: [43],
  Grimstead: [46],
  Grindstone: [39],
  Grinnell: [13, 17],
  'Grissom Arb': [16],
  Griswold: [13],
  'Grizzly Flats': [5],
  Groesbeck: [44],
  Groom: [44],
  'Grosse Ile': [23],
  'Grosse Pointe': [23],
  'Grosse Pointe Farms': [23],
  'Grosse Pointe Park': [23],
  'Grosse Pointe Woods': [23],
  'Grosse Tete': [19],
  'Grosvenor Dale': [7],
  Groton: [7, 20, 35, 42, 47],
  Grottoes: [46],
  'Grouse Creek': [45],
  Grove: [37],
  'Grove City': [24, 36, 39],
  'Grove Hill': [2],
  Groveland: [5, 10, 15, 20, 35],
  Groveoak: [2],
  Groveport: [36],
  Grover: [6, 28, 39, 41, 51],
  'Grover Beach': [5],
  'Grover Hill': [36],
  Grovertown: [16],
  Groves: [44],
  Grovespring: [25],
  Groveton: [31, 44],
  Grovetown: [11],
  Grubbs: [3],
  Grubville: [25],
  'Gruetli Laager': [43],
  Grulla: [44],
  Grundy: [-13, -15, -25, -43, 46],
  'Grundy Center': [13],
  Gruver: [44],
  Grygla: [24],
  Guadalupe: [5, -33, -44],
  Guadalupita: [33],
  Gualala: [5],
  Guasti: [5],
  Guatay: [5],
  Guerneville: [5],
  Guernsey: [13, -36, 51],
  Guerra: [44],
  Gueydan: [19],
  Guffey: [6],
  'Guide Rock': [30],
  Guild: [31, 43],
  Guilderland: [35],
  'Guilderland Center': [35],
  Guildhall: [47],
  Guilford: [7, 16, 22, 25, -28, 35],
  Guin: [2],
  Guinda: [5],
  Guion: [3],
  Gulf: [-10, 28],
  'Gulf Breeze': [10],
  'Gulf Hammock': [10],
  'Gulf Shores': [2],
  Gulfport: [26],
  Gulliver: [23],
  Gully: [24],
  Gulston: [18],
  'Gum Spring': [46],
  'Gun Barrel City': [44],
  Gunlock: [18, 45],
  Gunnison: [6, -6, 26, 45],
  Gunpowder: [21],
  Gunter: [44],
  Guntersville: [2],
  Guntown: [26],
  Gurdon: [3],
  Gurley: [2, 30],
  Gurnee: [15],
  Gustavus: [1],
  Gustine: [5, 44],
  Guston: [18],
  Guthrie: [-13, 18, 37, 44],
  'Guthrie Center': [13],
  Guttenberg: [13],
  Guy: [3, 44],
  Guymon: [37],
  Guys: [43],
  'Guys Mills': [39],
  Guysville: [36],
  Guyton: [11],
  Gwinn: [23],
  Gwinner: [29],
  Gwinnett: [-11],
  Gwynedd: [39],
  'Gwynedd Valley': [39],
  Gwynn: [46],
  'Gwynn Oak': [21],
  Gwynneville: [16],
  Gypsum: [6, 17, 36],
  Gypsy: [50],
  Haakon: [-42],
  Habersham: [-11],
  Hachita: [33],
  'Hacienda Heights': [5],
  Hackberry: [4, 19],
  Hackensack: [24, 32],
  'Hacker Valley': [50],
  Hackett: [3],
  Hackettstown: [32],
  Hackleburg: [2],
  Hacksneck: [46],
  Hadar: [30],
  Haddam: [7, 17],
  Haddock: [11],
  'Haddon Heights': [32],
  Haddonfield: [32],
  Hadensville: [46],
  Hadley: [20, 23, 35, 39],
  Hadlyme: [7],
  Hagaman: [35],
  Hagan: [11],
  'Hagar Shores': [23],
  Hagarville: [3],
  'Hager City': [49],
  Hagerhill: [18],
  Hagerman: [14, 33],
  Hagerstown: [16, 21],
  Hague: [29, 35, 46],
  Hahira: [11],
  Hahnville: [19],
  Haigler: [30],
  Haiku: [12],
  Hailesboro: [35],
  Hailey: [14],
  Haileyville: [37],
  Haines: [1, -1, 38],
  'Haines City': [10],
  'Haines Falls': [35],
  Hainesport: [32],
  Hakalau: [12],
  Halbur: [13],
  Halcottsville: [35],
  Hale: [-2, 23, 25, -44],
  'Hale Center': [44],
  Haledon: [32],
  Haleiwa: [12],
  'Hales Corners': [49],
  Halethorpe: [21],
  Haleyville: [2],
  'Half Moon Bay': [5],
  'Half Way': [25],
  Halfmoon: [35],
  Halfway: [38],
  Halifax: [20, 28, -28, 39, 46, -46],
  Hall: [-11, 27, -30, 35, -44],
  'Hall Summit': [19],
  Hallam: [30],
  Hallandale: [10],
  Hallett: [37],
  Hallettsville: [44],
  Halliday: [29],
  Hallie: [18],
  Hallieford: [46],
  Hallock: [24],
  Hallowell: [22],
  Halls: [43],
  Hallsboro: [28],
  Hallstead: [39],
  Hallsville: [25, 36, 44],
  Halltown: [25, 50],
  Hallwood: [46],
  Halma: [24],
  Halsey: [30, 38],
  Halstad: [24],
  Halstead: [17],
  'Haltom City': [44],
  Hamblen: [-43],
  Hambleton: [50],
  Hamburg: [3, 13, 15, 19, 23, 24, 32, 35, 39],
  Hamden: [7, 35, 36],
  Hamel: [15, 24],
  Hamer: [14, 41],
  Hamersville: [36],
  Hamill: [42],
  Hamilton: [2, 6, -10, 11, 13, -13, 15, -15, 16, -16, 17, -17, 20, 23, 25, 26, 27, 28, 29, -30, 35, -35, 36, -36, 39, -43, 44, -44, 46, 48],
  'Hamilton City': [5],
  'Hamilton Township': [32],
  Hamler: [36],
  Hamlet: [16, 28],
  Hamlin: [13, 35, 39, -42, 44, 50],
  Hammett: [14],
  Hammon: [37],
  Hammond: [15, 16, 19, 27, 35, 38, 49],
  Hammondsport: [35],
  Hammondsville: [36],
  Hammonton: [32],
  Hampden: [20, -20, 22, 29],
  'Hampden Sydney': [46],
  'Hampden Township': [39],
  Hampshire: [15, -20, 43, -50],
  Hampstead: [21, 28, 31],
  Hampton: [3, 7, 10, 11, 13, 15, 18, 24, 30, 31, 32, 35, 41, -41, 43, 46],
  'Hampton Bays': [35],
  'Hampton Falls': [31],
  Hamptonville: [28],
  Hamshire: [44],
  Hamtramck: [23],
  Hana: [12],
  Hanahan: [41],
  Hanalei: [12],
  Hanamaulu: [12],
  Hanapepe: [12],
  Hanceville: [2],
  Hancock: [-11, 13, -13, -15, -16, -18, 21, 22, -22, 23, 24, -26, 31, 35, -36, -43, 47, 49, -50],
  'Hancocks Bridge': [32],
  Hand: [-42],
  Handley: [50],
  Hanford: [5],
  Hankamer: [44],
  Hankins: [35],
  Hankinson: [29],
  Hanksville: [45],
  'Hanley Falls': [24],
  Hanlontown: [13],
  Hanna: [16, 37, 45, 51],
  'Hanna City': [15],
  Hannacroix: [35],
  Hannaford: [29],
  Hannah: [29],
  Hannastown: [39],
  'Hannawa Falls': [35],
  Hannibal: [25, 35, 36],
  Hanover: [7, 15, 16, 17, 20, 21, 22, 23, 24, 31, 33, 39, 46, -46, 49, 50],
  'Hanover Park': [15],
  Hanoverton: [36],
  Hansboro: [29],
  'Hanscom Afb': [20],
  Hansen: [14],
  Hansford: [-44, 50],
  Hanska: [24],
  Hanson: [18, 20, -42],
  Hanston: [17],
  Hansville: [48],
  Happy: [18, 44],
  'Happy Camp': [5],
  'Happy Jack': [4],
  'Happy Valley': [38],
  Haralson: [11, -11],
  Harbert: [23],
  Harbeson: [9],
  Harbinger: [28],
  'Harbor Beach': [23],
  'Harbor City': [5],
  'Harbor Springs': [23],
  'Harbor View': [36],
  Harborcreek: [39],
  Harborside: [22],
  Harborton: [46],
  Harcourt: [13],
  Hardaway: [2],
  Hardee: [-10],
  Hardeeville: [41],
  Hardeman: [-43, -44],
  Hardenville: [25],
  Hardesty: [37],
  Hardin: [-13, 15, -15, 18, -18, 25, 27, -36, -43, 44, -44],
  Harding: [-33, -42],
  Hardinsburg: [16, 18],
  Hardtner: [17],
  Hardwick: [11, 20, 24, 47],
  Hardy: [3, 13, 18, 30, 46, -50],
  Hardyville: [18, 46],
  Harford: [-21, 35, 39],
  Hargill: [44],
  'Harker Heights': [44],
  'Harkers Island': [28],
  Harlan: [13, 16, 18, -18, -30],
  Harleigh: [39],
  Harlem: [11, 27],
  Harleton: [44],
  Harleysville: [39],
  Harleyville: [41],
  Harlingen: [44],
  Harlowton: [27],
  Harman: [50],
  Harmans: [21],
  Harmon: [15, -37],
  Harmonsburg: [39],
  Harmony: [5, 16, 22, 24, 28, 39, 40],
  Harned: [18],
  Harnett: [-28],
  Harney: [-38],
  Harold: [18],
  Harper: [13, 17, -17, -37, 38, 44, 50],
  'Harper Woods': [23],
  'Harpers Ferry': [13, 50],
  Harpersfield: [35],
  Harpersville: [2],
  Harperville: [26],
  Harpster: [36],
  Harpswell: [22],
  Harpursville: [35],
  Harrah: [37, 48],
  Harrell: [3],
  Harrells: [28],
  Harrellsville: [28],
  Harriet: [3],
  Harrietta: [23],
  Harriman: [35, 43],
  Harrington: [9, 22, 48],
  'Harrington Park': [32],
  Harris: [-11, 13, 23, 24, 25, 28, 35, -44],
  Harrisburg: [3, 15, 25, 28, 30, 36, 38, 39, 42],
  Harrison: [3, 11, -13, 14, -16, -18, 22, 23, -25, -26, 27, 30, 32, 35, 36, -36, 42, 43, -44, -50],
  'Harrison City': [39],
  'Harrison Township': [23],
  'Harrison Valley': [39],
  Harrisonburg: [19, 46],
  Harrisonville: [25, 32, 39],
  Harristown: [15],
  Harrisville: [23, 26, 31, 35, 36, 39, 40, 50],
  Harrod: [36],
  'Harrods Creek': [18],
  Harrodsburg: [16, 18],
  Harrogate: [43],
  Harrold: [42, 44],
  'Harsens Island': [23],
  Harshaw: [49],
  Hart: [-11, -18, 23, 44],
  Hartfield: [46],
  Hartford: [2, 3, 7, -7, 13, 15, 17, 18, 23, 35, 36, 42, 43, 47, 49, 50],
  'Hartford City': [16],
  Hartington: [30],
  Hartland: [22, 23, 24, 47, 49],
  'Hartland Four Corners': [47],
  Hartleton: [39],
  Hartley: [13, 44, -44],
  Hartline: [48],
  Hartly: [9],
  Hartman: [3, 6],
  Harts: [50],
  Hartsburg: [15, 25],
  Hartsdale: [35],
  Hartsel: [6],
  Hartselle: [2],
  Hartsfield: [11],
  Hartshorn: [25],
  Hartshorne: [37],
  Hartstown: [39],
  Hartsville: [16, 41, 43],
  Hartville: [25, 36, 51],
  Hartwell: [11],
  Hartwick: [13, 35],
  Hartwood: [46],
  Harvard: [14, 15, 20, 30],
  Harvel: [15],
  Harvest: [2],
  Harvey: [3, 13, 15, -17, 19, 29],
  'Harveys Lake': [39],
  Harveysburg: [36],
  Harveyville: [17],
  Harviell: [25],
  Harwich: [20],
  'Harwich Port': [20],
  Harwick: [39],
  Harwinton: [7],
  Harwood: [21, 25, 29, 44],
  'Harwood Heights': [15],
  'Hasbrouck Heights': [32],
  Haskell: [-17, 32, 37, -37, 44, -44],
  Haskins: [36],
  Haslet: [44],
  Haslett: [23],
  Hassell: [28],
  Hastings: [10, 13, 23, 24, 30, 35, 37, 39],
  'Hastings On Hudson': [35],
  Hasty: [3, 6],
  Haswell: [6],
  'Hat Creek': [5],
  Hatboro: [39],
  Hatch: [33, 45],
  Hatchechubbee: [2],
  Hatfield: [3, 16, 20, 25, 39],
  Hathaway: [27],
  'Hathaway Pines': [5],
  Hathorne: [20],
  Hatley: [49],
  Hatteras: [28],
  Hattiesburg: [26],
  Hattieville: [3],
  Hatton: [29],
  Haubstadt: [16],
  Haugan: [27],
  Haugen: [49],
  Haughton: [19],
  Hauppauge: [35],
  Hauula: [12],
  Havana: [3, 10, 15, 17, 29],
  Havelock: [13, 28],
  Haven: [17],
  Havensville: [17],
  Haverford: [39],
  Haverhill: [13, 20, 31, 36],
  Haverstraw: [35],
  Havertown: [39],
  Haviland: [17, 36],
  Havre: [27],
  'Havre De Grace': [21],
  'Haw River': [28],
  Hawaii: [-12],
  'Hawaii National Park': [12],
  'Hawaiian Gardens': [5],
  Hawarden: [13],
  Hawesville: [18],
  Hawi: [12],
  'Hawk Point': [25],
  'Hawk Run': [39],
  'Hawk Springs': [51],
  Hawkeye: [13],
  Hawkins: [-43, 44, 49],
  Hawkinsville: [11],
  Hawks: [23],
  Hawley: [24, 39, 44],
  Hawleyville: [7],
  Haworth: [32, 37],
  Hawthorn: [39],
  Hawthorne: [5, 10, 32, 34, 35, 49],
  Haxtun: [6],
  Hay: [48],
  'Hay Springs': [30],
  Hayden: [2, 4, 6, 14, 16],
  Haydenville: [20, 36],
  Hayes: [19, -30, 42, 46],
  'Hayes Center': [30],
  Hayesville: [13, 28, 36],
  Hayfield: [24],
  Hayfork: [5],
  Haymarket: [46],
  Haynes: [3],
  Haynesville: [19, 46],
  Hayneville: [2],
  Hays: [17, 27, 28, -44],
  Haysi: [46],
  Haysville: [17],
  Hayti: [25, 42],
  Hayward: [5, 24, 49],
  Haywood: [-28, -43, 46, 50],
  Hazard: [18, 30],
  Hazel: [18, 42],
  'Hazel Crest': [15],
  'Hazel Green': [2, 18, 49],
  'Hazel Hurst': [39],
  'Hazel Park': [23],
  Hazelhurst: [49],
  Hazelton: [14, 17, 29],
  Hazelwood: [25, 28],
  Hazen: [3, 29],
  'Hazle Township': [39],
  Hazlehurst: [11, 26],
  Hazlet: [32],
  Hazleton: [13, 16, 39],
  'Head Waters': [46],
  Headland: [2],
  Headrick: [37],
  'Heafford Junction': [49],
  Healdsburg: [5],
  Healdton: [37],
  Healy: [1, 17],
  Heard: [-11],
  Hearne: [44],
  'Heart Butte': [27],
  Heartwell: [30],
  Heaters: [50],
  Heath: [20, 36, 44],
  'Heath Springs': [41],
  Heathsville: [46],
  Heavener: [37],
  Hebbronville: [44],
  Heber: [4, 5],
  'Heber City': [45],
  'Heber Springs': [3],
  Hebo: [38],
  Hebron: [7, 15, 16, 18, 21, 22, 29, 30, 31, 36],
  Hecker: [15],
  Hecla: [42],
  Hector: [3, 24, 35],
  Hedgesville: [50],
  Hedley: [44],
  Hedrick: [13],
  Heflin: [2, 19],
  Hegins: [39],
  Heidelberg: [26],
  Heidenheimer: [44],
  Heidrick: [18],
  Heilwood: [39],
  Heiskell: [43],
  Heislerville: [32],
  Heisson: [48],
  Helen: [11, 21, 50],
  Helena: [2, 3, 11, 25, 27, 35, 36, 37],
  Helendale: [5],
  Helenville: [49],
  Helenwood: [43],
  Helix: [38],
  Hellertown: [39],
  Hellier: [18],
  Helm: [5],
  Helmetta: [32],
  Helmsburg: [16],
  Helmville: [27],
  Helotes: [44],
  Helper: [45],
  Helton: [18],
  Heltonville: [16],
  Helvetia: [50],
  Hematite: [25],
  Hemet: [5],
  Hemingford: [30],
  Hemingway: [41],
  Hemlock: [16, 23, 35],
  Hemphill: [44, -44],
  Hempstead: [-3, 35, 44],
  Henagar: [2],
  Henderson: [3, 6, 13, 15, -15, 18, -18, 21, 23, 24, 28, -28, 30, 34, 35, 43, -43, 44, -44, 50],
  'Henderson Harbor': [35],
  Hendersonville: [28, 39, 43],
  Hendley: [30],
  Hendricks: [-16, 24, 50],
  Hendrix: [37],
  Hendrum: [24],
  Hendry: [-10],
  Henefer: [45],
  Henlawson: [50],
  Henley: [25],
  Hennepin: [15, -24, 37],
  Hennessey: [37],
  Henniker: [31],
  Henning: [15, 24, 43],
  Henrico: [28, 46, -46],
  Henrietta: [25, 28, 35, 44],
  Henriette: [24],
  Henrieville: [45],
  Henry: [-2, -11, -13, 15, -15, -16, -18, -25, -36, 42, 43, -43, 46, -46],
  Henryetta: [37],
  Henryville: [16, 39],
  Hensel: [29],
  Hensley: [3, 50],
  Hensonville: [35],
  Hephzibah: [11],
  Hepler: [17],
  Heppner: [38],
  Hepzibah: [50],
  Herald: [5],
  Herbster: [49],
  Herculaneum: [25],
  Hercules: [5],
  Hereford: [4, 6, 38, 39, 44],
  Herington: [17],
  Herkimer: [35, -35],
  Herlong: [5],
  Herman: [24, 30, 39],
  Hermann: [25],
  Hermansville: [23],
  Hermantown: [24],
  Hermanville: [26],
  Herminie: [39],
  Hermiston: [38],
  Hermitage: [3, 25, 39, 43],
  Hermleigh: [44],
  Hermon: [35],
  Hermosa: [42],
  'Hermosa Beach': [5],
  Hernandez: [33],
  Hernando: [10, -10, 26],
  Herndon: [17, 18, 39, 46, 50],
  Hernshaw: [50],
  Herod: [15],
  Heron: [27],
  'Heron Lake': [24],
  Herreid: [42],
  Herrick: [15, 42],
  'Herrick Center': [39],
  Herriman: [45],
  Herrin: [15],
  Herron: [23],
  Herscher: [15],
  Hersey: [23],
  Hershey: [30, 39],
  Hertel: [49],
  Hertford: [28, -28],
  Hesperia: [5, 23],
  Hesperus: [6],
  Hessel: [23],
  Hessmer: [19],
  Hesston: [17, 39],
  Hestand: [18],
  Hester: [19],
  Heth: [3],
  Hettick: [15],
  Hettinger: [29, -29],
  Heuvelton: [35],
  Hewett: [50],
  Hewitt: [24, 32, 44, 49],
  Hewlett: [35],
  Hext: [44],
  Heyburn: [14],
  Heyworth: [15],
  'Hi Hat': [18],
  Hialeah: [10],
  Hiawassee: [11],
  Hiawatha: [13, 17, 50],
  Hibbing: [24],
  Hibbs: [39],
  Hibernia: [32],
  Hickman: [5, 18, -18, 30, 43, -43],
  Hickory: [18, -25, 26, 28, 39],
  'Hickory Corners': [23],
  'Hickory Flat': [26],
  'Hickory Grove': [41],
  'Hickory Hills': [15],
  'Hickory Plains': [3],
  'Hickory Ridge': [3],
  'Hickory Valley': [43],
  Hicksville: [35, 36],
  Hico: [44, 50],
  Hidalgo: [15, -33, 44, -44],
  'Hidden Valley': [39],
  'Hidden Valley Lake': [5],
  Hiddenite: [28],
  Higbee: [25],
  Higden: [3],
  Higdon: [2],
  Higganum: [7],
  Higgins: [44],
  'Higgins Lake': [23],
  Higginson: [3],
  Higginsport: [36],
  Higginsville: [25],
  'High Bridge': [32, 49],
  'High Falls': [35],
  'High Hill': [25],
  'High Island': [44],
  'High Point': [25, 28],
  'High Ridge': [25],
  'High Rolls Mountain Park': [33],
  'High Shoals': [11, 28],
  'High Springs': [10],
  'High View': [50],
  Highfalls: [28],
  'Highgate Center': [47],
  'Highgate Springs': [47],
  Highland: [3, 5, 15, 16, 17, 21, 23, 35, 36, -36, -46, 49],
  'Highland City': [10],
  'Highland Falls': [35],
  'Highland Heights': [18, 36],
  'Highland Home': [2],
  'Highland Lake': [35],
  'Highland Lakes': [32],
  'Highland Mills': [35],
  'Highland Park': [15, 23, 32],
  'Highland Village': [44],
  Highlands: [-10, 28, 32, 44],
  'Highlands Ranch': [6],
  Highlandville: [13, 25],
  Highmore: [42],
  Highmount: [35],
  Highspire: [39],
  Hightstown: [32],
  Highwood: [15, 27],
  Higley: [4],
  Hiko: [34],
  Hiland: [51],
  Hilbert: [49],
  Hilda: [41],
  Hildale: [45],
  Hildebran: [28],
  Hildreth: [30],
  Hilger: [27],
  Hilham: [43],
  Hill: [-27, 31, -44],
  'Hill Afb': [45],
  'Hill City': [14, 17, 24, 42],
  Hillburn: [35],
  Hiller: [39],
  Hilliard: [10, 36],
  Hilliards: [39],
  Hillister: [44],
  Hillman: [23, 24],
  Hillpoint: [49],
  Hillrose: [6],
  Hills: [13, 24],
  Hillsboro: [2, 11, 13, 15, 16, 17, 18, 21, 25, 26, 29, 33, 36, 38, 43, 44, 49, 50],
  Hillsborough: [-10, 28, 31, -31, 32],
  Hillsdale: [15, 16, 17, 23, -23, 32, 35, 37, 39, 51],
  Hillsgrove: [39],
  Hillside: [6, 15, 32],
  Hillsville: [39, 46],
  Hilltop: [50],
  Hilltown: [39],
  Hillview: [15, 18],
  Hilmar: [5],
  Hilo: [12],
  Hilton: [35],
  'Hilton Head': [41],
  'Hilton Head Island': [41],
  Hiltons: [46],
  Hima: [18],
  Himrod: [35],
  Hinckley: [15, 22, 24, 35, 36, 45],
  Hindman: [18],
  Hinds: [-26],
  Hindsboro: [15],
  Hindsville: [3],
  Hines: [15, 24, 38],
  Hinesburg: [47],
  Hineston: [19],
  Hinesville: [11],
  Hingham: [20, 27, 49],
  Hinkle: [18],
  Hinkley: [5],
  Hinsdale: [-6, 15, 20, 27, 31, 35],
  Hinton: [13, 37, 46, 50],
  Hiram: [11, 22, 36],
  Hiseville: [18],
  Hitchcock: [-30, 37, 42, 44],
  Hitchins: [18],
  Hitchita: [37],
  Hitterdal: [24],
  Hiwasse: [3],
  Hiwassee: [46],
  Hixson: [43],
  Hixton: [49],
  'Ho Ho Kus': [32],
  Hoagland: [16],
  Hobart: [16, 35, 37, 48],
  Hobbs: [16, 33],
  Hobbsville: [28],
  'Hobe Sound': [10],
  Hobgood: [28],
  Hoboken: [11, 32],
  Hobson: [27, 44],
  Hobucken: [28],
  Hochheim: [44],
  Hockessin: [9],
  Hocking: [-36],
  Hockingport: [36],
  Hockley: [44, -44],
  Hodge: [19],
  Hodgeman: [-17],
  Hodgen: [37],
  Hodgenville: [18],
  Hodges: [2, 41],
  Hoehne: [6],
  Hoffman: [15, 24, 28],
  'Hoffman Estates': [15],
  Hoffmeister: [35],
  Hogansburg: [35],
  Hogansville: [11],
  Hogeland: [27],
  Hohenwald: [43],
  Hoisington: [17],
  Hokah: [24],
  Hoke: [-28],
  Holabird: [42],
  Holbrook: [4, 14, 20, 30, 35, 39],
  Holcomb: [15, 17, 25, 26],
  Holcombe: [49],
  Holden: [19, 20, 22, 25, 45, 50],
  Holdenville: [37],
  Holder: [10],
  Holderness: [31],
  Holdingford: [24],
  Holdrege: [30],
  Holgate: [36],
  Holicong: [39],
  Holiday: [10],
  Holladay: [43],
  Holland: [13, 16, 18, 20, 23, 24, 25, 35, 36, 44],
  'Holland Patent': [35],
  Hollandale: [24, 26, 49],
  Hollansburg: [36],
  Hollenberg: [17],
  Holley: [35],
  Holliday: [25, 44],
  Hollidaysburg: [39],
  Hollins: [2],
  Hollis: [31, 35, 37],
  'Hollis Center': [22],
  Hollister: [5, 10, 25, 28, 37],
  Holliston: [20],
  'Holloman Air Force Base': [33],
  'Hollow Rock': [43],
  Holloway: [24, 36],
  Hollowville: [35],
  Hollsopple: [39],
  Holly: [6, 23],
  'Holly Bluff': [26],
  'Holly Grove': [3],
  'Holly Hill': [41],
  'Holly Lake Ranch': [44],
  'Holly Pond': [2],
  'Holly Ridge': [28],
  'Holly Springs': [11, 26, 28],
  Hollytree: [2],
  Hollywood: [2, 10, 21, 41],
  Holman: [33],
  Holmdel: [32],
  Holmen: [49],
  Holmes: [-10, -26, 35, -36, 39],
  'Holmes Beach': [10],
  'Holmes City': [24],
  'Holmes Mill': [18],
  Holmesville: [36],
  Holstein: [13, 30],
  Holt: [5, 10, 23, 25, -25, -30],
  Holton: [16, 17, 23],
  'Holts Summit': [25],
  Holtsville: [35],
  Holtville: [5],
  Holtwood: [39],
  Holualoa: [12],
  'Holy City': [5],
  'Holy Cross': [1, 13],
  'Holy Trinity': [2],
  Holyoke: [6, 20, 24],
  Holyrood: [17],
  Home: [17, 39],
  Homedale: [14],
  Homelake: [6],
  Homeland: [5, 10],
  Homer: [1, 11, 15, 16, 19, 23, 24, 30, 35, 36],
  'Homer City': [39],
  'Homer Glen': [15],
  Homerville: [11, 36],
  Homestead: [10, 13, 27, 39],
  Hometown: [15, 50],
  Homewood: [5, 15],
  Homeworth: [36],
  Hominy: [37],
  Homosassa: [10],
  'Homosassa Springs': [10],
  Honaker: [46],
  Honaunau: [12],
  Hondo: [33, 44],
  'Honea Path': [41],
  Honeoye: [35],
  'Honeoye Falls': [35],
  Honesdale: [39],
  'Honey Brook': [39],
  'Honey Creek': [13, 49],
  'Honey Grove': [39, 44],
  Honeydew: [5],
  Honeyville: [45],
  Honobia: [37],
  Honokaa: [12],
  Honolulu: [12, -12],
  Honomu: [12],
  Honor: [23],
  Honoraville: [2],
  Hood: [5, -44, 46],
  'Hood River': [38, -38],
  Hoodsport: [48],
  Hooker: [-30, 37],
  Hookerton: [28],
  Hooks: [44],
  Hooksett: [31],
  Hookstown: [39],
  Hoolehua: [12],
  Hoonah: [1],
  'Hoonah-Angoon': [-1],
  Hoopa: [5],
  Hooper: [6, 30, 45, 48],
  'Hooper Bay': [1],
  Hoopeston: [15],
  Hoople: [29],
  Hooppole: [15],
  Hoosick: [35],
  'Hoosick Falls': [35],
  Hooven: [36],
  Hoover: [2],
  Hooversville: [39],
  'Hop Bottom': [39],
  Hopatcong: [32],
  Hope: [1, 3, 14, 16, 17, 18, 22, 23, 24, 29, 32, 33, 40],
  'Hope Hull': [2],
  'Hope Mills': [28],
  'Hope Valley': [40],
  Hopedale: [15, 20, 36],
  Hopeland: [39],
  Hopeton: [37],
  Hopewell: [32, 36, 39, 46],
  'Hopewell Junction': [35],
  Hopkins: [-18, 23, 24, 25, 41, -44],
  'Hopkins Park': [15],
  Hopkinsville: [18],
  Hopkinton: [13, 20, 40],
  Hopland: [5],
  Hopwood: [39],
  Hoquiam: [48],
  Horace: [29],
  Horatio: [3, 41],
  Hordville: [30],
  Horicon: [49],
  'Horn Lake': [26],
  Hornbeak: [43],
  Hornbeck: [19],
  Hornbrook: [5],
  Hornell: [35],
  Horner: [50],
  Hornersville: [25],
  Hornick: [13],
  Hornitos: [5],
  Hornsby: [43],
  Horntown: [46],
  Horry: [-41],
  'Horse Branch': [18],
  'Horse Cave': [18],
  'Horse Creek': [51],
  'Horse Shoe': [28],
  Horseheads: [35],
  Horsepen: [46],
  'Horseshoe Bay': [44],
  'Horseshoe Beach': [10],
  'Horseshoe Bend': [3, 14],
  Horsham: [39],
  Hortense: [11],
  Horton: [2, 17, 23],
  Hortonville: [35, 49],
  Hoschton: [11],
  Hosford: [10],
  Hoskins: [30],
  Hoskinston: [18],
  Hosmer: [42],
  Hospers: [13],
  Hosston: [19],
  Hostetter: [39],
  'Hot Spring': [-3],
  'Hot Springs': [3, 27, 28, 42, 46, -51],
  'Hot Springs National Park': [3],
  'Hot Springs Village': [3],
  'Hot Sulphur Springs': [6],
  Hotchkiss: [6],
  Hotevilla: [4],
  Houck: [4],
  Houghton: [13, 23, -23, 35, 42],
  'Houghton Lake': [23],
  'Houghton Lake Heights': [23],
  Houlka: [26],
  Houlton: [22, 49],
  Houma: [19],
  Housatonic: [20],
  House: [33],
  'House Springs': [25],
  Houston: [1, 2, -2, 3, 9, -11, 24, -24, 25, 26, 36, 39, -43, 44, -44],
  Houstonia: [25],
  Houtzdale: [39],
  Hoven: [42],
  Hovland: [24],
  Howard: [-3, 6, 11, -13, -16, 17, -21, -25, -30, 35, 36, 39, 42, -44],
  'Howard Beach': [35],
  'Howard City': [23],
  'Howard Lake': [24],
  Howardsville: [46],
  Howe: [14, 16, 37, 44],
  Howell: [23, -25, 32, 45],
  Howells: [30, 35],
  Howes: [42],
  'Howes Cave': [35],
  'Howey In The Hills': [10],
  Howland: [22],
  Hoxie: [3, 17],
  Hoyleton: [15],
  Hoyt: [17, 37],
  'Hoyt Lakes': [24],
  Hoytville: [36],
  'Huachuca City': [4],
  Hualapai: [4],
  Hubbard: [13, -24, 30, 36, 38, 44],
  'Hubbard Lake': [23],
  Hubbardston: [20, 23],
  Hubbardsville: [35],
  Hubbell: [23, 30],
  Hubert: [28],
  Hubertus: [49],
  Huddleston: [46],
  Huddy: [18],
  Hudgins: [46],
  Hudson: [6, 10, 13, 15, 16, 17, 18, 20, 22, 23, 28, 31, -32, 35, 36, 42, 49, 51],
  'Hudson Falls': [35],
  'Hudson Oaks': [44],
  Hudsonville: [23],
  Hudspeth: [-44],
  Huerfano: [-6],
  Huey: [15],
  Hueysville: [18],
  Huffman: [44],
  Hufsmith: [44],
  Huger: [41],
  Huggins: [25],
  Hughes: [1, 3, -37, -42],
  'Hughes Springs': [44],
  Hugheston: [50],
  Hughesville: [21, 25, 39],
  Hughson: [5],
  Hughsonville: [35],
  Hugo: [6, 24, 37],
  Hugoton: [17],
  Huguenot: [35],
  Hulbert: [23, 37],
  Hulen: [18],
  Hulett: [51],
  'Huletts Landing': [35],
  Hull: [11, 13, 15, 20, 44],
  'Hulls Cove': [22],
  Humansville: [25],
  Humarock: [20],
  Humbird: [49],
  Humble: [44],
  Humboldt: [4, -5, 13, -13, 15, 17, 24, 30, -34, 42, 43],
  Hume: [5, 15, 25, 35, 46],
  Humeston: [13],
  'Hummels Wharf': [39],
  Hummelstown: [39],
  Humnoke: [3],
  Humphrey: [3, 30],
  Humphreys: [25, -26, -43],
  Humptulips: [48],
  Hundred: [50],
  Hungerford: [44],
  'Hungry Horse': [27],
  Hunker: [39],
  'Hunlock Creek': [39],
  Hunnewell: [25],
  Hunt: [35, 44, -44],
  'Hunt Valley': [21],
  Hunter: [3, 17, 29, 35, 37],
  Hunterdon: [-32],
  Hunters: [48],
  Huntersville: [28],
  Huntertown: [16],
  Huntingburg: [16],
  Huntingdon: [39, -39, 43],
  'Huntingdon Valley': [39],
  Huntington: [3, 16, -16, 20, 35, 38, 44, 45, 47, 50],
  'Huntington Beach': [5],
  'Huntington Mills': [39],
  'Huntington Park': [5],
  'Huntington Station': [35],
  'Huntington Woods': [23],
  Huntingtown: [21],
  Huntland: [43],
  Huntley: [15, 24, 27, 51],
  Huntly: [46],
  Huntsburg: [36],
  Huntsville: [2, 3, 15, 25, 36, 43, 44, 45],
  Hurdland: [25],
  'Hurdle Mills': [28],
  Hurdsfield: [29],
  'Hurlburt Field': [10],
  Hurley: [25, 26, 33, 35, 42, 46, 49],
  Hurleyville: [35],
  Hurlock: [21],
  Huron: [5, 16, -23, 36, -36, 42, 43],
  Hurricane: [45, 50],
  'Hurricane Mills': [43],
  Hurst: [15, 44],
  Hurt: [46],
  Hurtsboro: [2],
  Huslia: [1],
  Huson: [27],
  Husser: [19],
  Hustisford: [49],
  Hustle: [46],
  Hustler: [49],
  Huston: [14],
  Hustontown: [39],
  Hustonville: [18],
  Husum: [48],
  Hutchins: [44],
  Hutchinson: [17, 24, 39, -42, -44],
  Hutsonville: [15],
  Huttig: [3],
  Hutto: [44],
  Huttonsville: [50],
  Huxford: [2],
  Huxley: [13],
  Hyampom: [5],
  Hyannis: [20, 30],
  'Hyannis Port': [20],
  Hyattsville: [21],
  Hyattville: [51],
  Hydaburg: [1],
  Hyde: [-28, 39, -42],
  'Hyde Park': [20, 35, 39, 45, 47],
  Hyden: [18],
  Hyder: [1],
  Hydes: [21],
  Hydesville: [5],
  Hydetown: [39],
  Hydeville: [47],
  Hydro: [37],
  Hye: [44],
  Hygiene: [6],
  Hymera: [16],
  Hyndman: [39],
  Hyrum: [45],
  Hysham: [27],
  Iaeger: [50],
  Ibapah: [45],
  Iberia: [-19, 25, 36],
  Iberville: [-19],
  Icard: [28],
  Ickesburg: [39],
  Ida: [3, -13, 19, 23],
  'Ida Grove': [13],
  Idabel: [37],
  Idaho: [-14],
  'Idaho City': [14],
  'Idaho Falls': [14],
  'Idaho Springs': [6],
  Idalia: [6],
  Idalou: [44],
  Idamay: [50],
  Idanha: [38],
  Idaville: [16, 39],
  Ideal: [11, 42],
  Ider: [2],
  Idledale: [6],
  Idlewild: [23, 43],
  'Idleyld Park': [38],
  Idyllwild: [5],
  Ignacio: [6],
  Igo: [5],
  Ihlen: [24],
  Ijamsville: [21],
  'Ikes Fork': [50],
  Ila: [11],
  Ilfeld: [33],
  Iliamna: [1],
  Iliff: [6],
  Ilion: [35],
  'Illinois City': [15],
  Illiopolis: [15],
  Ilwaco: [48],
  Imbler: [38],
  Imboden: [3],
  Imlay: [34],
  'Imlay City': [23],
  Imlaystown: [32],
  Imler: [39],
  Immaculata: [39],
  Immokalee: [10],
  Imnaha: [38],
  Imogene: [13],
  Imperial: [5, -5, 25, 30, 39, 44],
  'Imperial Beach': [5],
  Ina: [15],
  Inavale: [30],
  Inchelium: [48],
  'Incline Village': [34],
  Independence: [-3, 5, 13, 17, 18, 19, 25, 26, 36, 38, 46, 49, 50],
  Index: [48],
  Indiahoma: [37],
  Indialantic: [10],
  Indian: [1],
  'Indian Head': [21, 39],
  'Indian Hills': [6],
  'Indian Lake': [35, 39],
  'Indian Lake Estates': [10],
  'Indian Land': [41],
  'Indian Mound': [43],
  'Indian Orchard': [20],
  'Indian River': [-10, 23],
  'Indian Rocks Beach': [10],
  'Indian Springs': [34],
  'Indian Trail': [28],
  'Indian Valley': [14, 46],
  'Indian Wells': [4, 5],
  Indiana: [39, -39],
  Indianapolis: [16],
  Indianola: [13, 15, 26, 30, 37, 39, 48],
  Indiantown: [10],
  Indio: [5],
  Indore: [50],
  Industrial: [50],
  Industry: [15, 39, 44],
  Inez: [18, 44],
  Ingalls: [16, 17, 23],
  Ingham: [-23],
  Inglefield: [16],
  Ingleside: [15, 21, 44],
  Inglewood: [5],
  Inglis: [10],
  Ingomar: [27, 39],
  Ingraham: [15],
  Ingram: [18, 44],
  Inkom: [14],
  Inkster: [23, 29],
  Inland: [30],
  Inlet: [35],
  'Inlet Beach': [10],
  Inman: [17, 30, 41],
  Innis: [19],
  Innsbrook: [25],
  Inola: [37],
  Institute: [50],
  'Intercession City': [10],
  Intercourse: [39],
  Interior: [42],
  Interlachen: [10],
  Interlaken: [35],
  Interlochen: [23],
  'International Falls': [24],
  Intervale: [31],
  'Inver Grove Heights': [24],
  Inverness: [5, 10, 26, 27],
  Inwood: [13, 35, 50],
  Inyo: [-5],
  Inyokern: [5],
  Iola: [17, 44, 49],
  Iona: [14, 24],
  Ione: [5, 38, 48],
  Ionia: [13, 23, -23, 25, 35],
  Iosco: [-23],
  Iota: [19],
  Iowa: [-13, 19, -49],
  'Iowa City': [13],
  'Iowa Falls': [13],
  'Iowa Park': [44],
  Ipava: [15],
  Ipswich: [20, 42],
  Ira: [13, 44],
  Iraan: [44],
  Irasburg: [47],
  Iredell: [-28, 44],
  Ireland: [16, 50],
  Irene: [42, 44],
  Ireton: [13],
  Irion: [-44],
  Irma: [49],
  Irmo: [41],
  Iron: [-23, 24, -25, -45, -49],
  'Iron Belt': [49],
  'Iron City': [11, 43],
  'Iron Gate': [46],
  'Iron Mountain': [23],
  'Iron Ridge': [49],
  'Iron River': [23, 49],
  'Iron Station': [28],
  Irondale: [25, 36],
  Ironia: [32],
  Irons: [23],
  Ironside: [38],
  Ironsides: [21],
  Ironton: [24, 25, 36],
  Ironwood: [23],
  Iroquois: [15, -15, 42],
  Irrigon: [38],
  Irvine: [5, 18, 39],
  Irving: [15, 35, 44],
  Irvington: [2, 15, 18, 32, 35, 46],
  Irvona: [39],
  Irwin: [-11, 13, 14, 36, 39],
  Irwinton: [11],
  Irwinville: [11],
  Isaban: [50],
  Isabel: [17, 42],
  Isabella: [-23, 24, 25, 37, 39],
  Isanti: [24, -24],
  Iselin: [32],
  Ishpeming: [23],
  Islamorada: [10],
  Island: [18, -48],
  'Island Falls': [22],
  'Island Grove': [10],
  'Island Heights': [32],
  'Island Lake': [15],
  'Island Park': [14, 35],
  'Island Pond': [47],
  Islandia: [35],
  Islandton: [41],
  Isle: [24],
  'Isle Au Haut': [22],
  'Isle La Motte': [47],
  'Isle Of Palms': [41],
  'Isle Of Springs': [22],
  'Isle Of Wight': [46],
  'Isle Saint George': [36],
  'Isle of Wight': [-46],
  Islesboro: [22],
  Islesford: [22],
  Isleta: [33],
  Isleton: [5],
  Islip: [35],
  'Islip Terrace': [35],
  Ismay: [27],
  Isola: [26],
  Isom: [18],
  Isonville: [18],
  Issaquah: [48],
  Issaquena: [-26],
  Issue: [21],
  Istachatta: [10],
  Italy: [44],
  Itasca: [15, -24, 44],
  Itawamba: [-26],
  Ithaca: [23, 30, 35],
  Itmann: [50],
  'Itta Bena': [26],
  Iuka: [15, 17, 26],
  Iva: [41],
  Ivan: [3],
  Ivanhoe: [5, 24, 28, 44, 46],
  Ivel: [18],
  Ivesdale: [15],
  Ivins: [45],
  Ivor: [46],
  Ivoryton: [7],
  Ivy: [46],
  Ivydale: [50],
  Ixonia: [49],
  Izard: [-3],
  Jachin: [2],
  Jack: [2, -44],
  Jackhorn: [18],
  Jackman: [22],
  Jackpot: [34],
  'Jacks Creek': [43],
  Jacksboro: [43, 44],
  Jackson: [2, -2, -3, 5, -6, -10, 11, -11, -13, -15, -16, -17, 18, -18, 19, -19, 23, -23, 24, -24, 25, -25, 26, -26, 27, 28, -28, 30, 31, 32, 36, -36, -37, -38, 39, 41, -42, 43, -43, -44, 49, -49, -50, 51],
  'Jackson Center': [36, 39],
  'Jackson Heights': [35],
  'Jackson Springs': [28],
  Jacksonboro: [41],
  Jacksonburg: [50],
  Jacksonport: [3],
  'Jacksons Gap': [2],
  Jacksontown: [36],
  Jacksonville: [2, 3, 10, 11, 15, 25, 28, 35, 36, 38, 44, 47],
  'Jacksonville Beach': [10],
  Jacob: [15],
  'Jacobs Creek': [39],
  Jacobsburg: [36],
  Jacobson: [24],
  Jacumba: [5],
  Jadwin: [25],
  Jaffrey: [31],
  Jakin: [11],
  Jal: [33],
  Jamaica: [13, 35, 46, 47],
  'Jamaica Plain': [20],
  'James City': [39, -46],
  'James Creek': [39],
  Jameson: [25],
  Jamesport: [25, 35],
  Jamestown: [5, 6, 16, 17, 18, 19, 23, 25, 28, 29, 33, 35, 36, 39, 40, 41, 43, 46],
  Jamesville: [28, 35, 46],
  Jamieson: [38],
  Jamison: [39],
  Jamul: [5],
  'Jane Lew': [50],
  Janesville: [5, 13, 24, 49],
  Jansen: [30],
  Jarales: [33],
  Jarbidge: [34],
  Jaroso: [6],
  Jarratt: [46],
  Jarreau: [19],
  Jarrell: [44],
  Jarrettsville: [21],
  Jarvisburg: [28],
  Jasonville: [16],
  Jasper: [2, 3, 10, 11, -11, -13, -15, 16, -16, 23, 24, 25, -25, -26, 35, 36, -41, 43, 44, -44],
  Java: [42, 46],
  'Java Center': [35],
  'Java Village': [35],
  Jay: [10, -16, 22, 35, 37],
  'Jay Em': [51],
  Jayess: [26],
  Jayton: [44],
  Jber: [1],
  Jbphh: [12],
  'Jbsa Ft Sam Houston': [44],
  'Jbsa Lackland': [44],
  'Jbsa Randolph': [44],
  Jean: [34],
  Jeanerette: [19],
  Jeannette: [39],
  Jeddo: [23],
  Jeff: [18],
  'Jeff Davis': [-11, -44],
  Jeffers: [24],
  Jefferson: [2, -2, 3, -3, 6, -6, -10, 11, -11, 13, -13, -14, -15, -16, -17, -18, -19, 20, 21, 22, -25, -26, -27, 28, -30, 31, 35, -35, 36, -36, -37, 38, -38, 39, -39, 41, 42, -43, 44, -44, -48, 49, -49, -50],
  'Jefferson City': [25, 27, 43],
  'Jefferson Davis': [-19, -26],
  'Jefferson Valley': [35],
  Jeffersonton: [46],
  Jeffersonville: [11, 16, 18, 35, 36, 47],
  Jeffrey: [50],
  'Jeffrey City': [51],
  'Jekyll Island': [11],
  Jellico: [43],
  Jelm: [51],
  'Jemez Pueblo': [33],
  'Jemez Springs': [33],
  Jemison: [2],
  Jena: [19],
  Jenera: [36],
  Jenison: [23],
  Jenkinjones: [50],
  Jenkins: [-11, 18, 24],
  'Jenkins Bridge': [46],
  Jenkinsburg: [11],
  Jenkinsville: [41],
  Jenkintown: [39],
  Jenks: [37],
  Jenner: [5],
  Jenners: [39],
  Jennerstown: [39],
  Jennings: [10, -16, 17, 19, 37],
  Jensen: [45],
  'Jensen Beach': [10],
  Jerauld: [-42],
  Jeremiah: [18],
  Jericho: [35, 47],
  'Jerico Springs': [25],
  Jermyn: [39, 44],
  Jerome: [4, 14, -14, 23, 25, 39],
  Jeromesville: [36],
  'Jerry City': [36],
  Jersey: [3, 11, -15, 46],
  'Jersey City': [32],
  'Jersey Mills': [39],
  'Jersey Shore': [39],
  'Jersey Village': [44],
  Jerseyville: [15],
  Jerusalem: [3, 36],
  Jessamine: [-18],
  Jesse: [50],
  Jessie: [29],
  Jessieville: [3],
  Jessup: [21, 39],
  Jesup: [11, 13],
  Jet: [37],
  Jetersville: [46],
  Jetmore: [17],
  Jetson: [18],
  Jewell: [11, 13, 17, -17, 36],
  'Jewell Ridge': [46],
  Jewett: [15, 35, 36, 44],
  'Jewett City': [7],
  Jigger: [19],
  'Jim Falls': [49],
  'Jim Hogg': [-44],
  'Jim Thorpe': [39],
  'Jim Wells': [-44],
  'Jo Daviess': [-15],
  Joanna: [41],
  Joaquin: [44],
  Jobstown: [32],
  Joelton: [43],
  Joes: [6],
  Joffre: [39],
  Johannesburg: [5, 23],
  'John Day': [38],
  'Johns Creek': [11],
  'Johns Island': [41],
  Johnsburg: [35],
  Johnson: [3, -3, -11, -13, -15, -16, 17, -17, -18, -25, 30, -30, 35, -43, -44, 47, -51],
  'Johnson City': [35, 43, 44],
  'Johnson Creek': [49],
  Johnsonburg: [32, 39],
  Johnsonville: [15, 35, 41],
  Johnston: [13, -28, -37, 40, 41],
  'Johnston City': [15],
  Johnstown: [6, 30, 35, 36, 39],
  Joice: [13],
  Joiner: [3],
  Joinerville: [44],
  'Joint Base Mdl': [32],
  Joliet: [15, 27],
  Jolley: [13],
  Jolo: [50],
  Jolon: [5],
  Jonancy: [18],
  'Jonas Ridge': [28],
  Jones: [2, -11, -13, 19, 23, -26, -28, 37, -42, -44],
  'Jones Mills': [39],
  Jonesboro: [3, 11, 15, 16, 19, 22, 44],
  Jonesborough: [43],
  Jonesburg: [25],
  Jonesport: [22],
  Jonestown: [26, 39],
  Jonesville: [16, 18, 19, 23, 28, 41, 44, 46, 47],
  Joplin: [25, 27],
  Joppa: [2, 15, 21],
  Jordan: [24, 27, 35],
  'Jordan Valley': [38],
  Jordanville: [35],
  Joseph: [38, 45],
  'Joseph City': [4],
  Josephine: [-38, 39, 44, 50],
  Joshua: [44],
  'Joshua Tree': [5],
  Jourdanton: [44],
  Joy: [15],
  Joyce: [19, 48],
  Juab: [-45],
  Jud: [29],
  Juda: [49],
  'Judith Basin': [-27],
  'Judith Gap': [27],
  Judson: [44],
  Judsonia: [3],
  Julesburg: [6],
  Juliaetta: [14],
  Julian: [5, 28, 30, 39, 50],
  Juliette: [11],
  Juliustown: [32],
  'Jumping Branch': [50],
  Junction: [15, 44, 45],
  'Junction City': [3, 5, 11, 17, 18, 36, 38, 49],
  'June Lake': [5],
  Juneau: [1, -1, 49, -49],
  Junedale: [39],
  Juniata: [30, -39],
  Junior: [50],
  Juntura: [38],
  Jupiter: [10],
  'Jurupa Valley': [5],
  Justice: [15, 50],
  Justiceburg: [44],
  Justin: [44],
  Kaaawa: [12],
  Kabetogama: [24],
  Kadoka: [42],
  Kahlotus: [48],
  Kahoka: [25],
  Kahuku: [12],
  Kahului: [12],
  Kaibeto: [4],
  Kailua: [12],
  'Kailua Kona': [12],
  Kaiser: [25],
  Kake: [1],
  Kaktovik: [1],
  Kalaheo: [12],
  Kalama: [48],
  Kalamazoo: [23, -23],
  Kalaupapa: [12],
  Kalawao: [-12],
  Kaleva: [23],
  Kalida: [36],
  Kalispell: [27],
  Kalkaska: [23, -23],
  Kalona: [13],
  Kalskag: [1],
  Kaltag: [1],
  Kamas: [45],
  Kamay: [44],
  Kamiah: [14],
  Kampsville: [15],
  Kamrar: [13],
  Kamuela: [12],
  Kanab: [45],
  Kanabec: [-24],
  Kanaranzi: [24],
  Kanarraville: [45],
  Kanawha: [13, -50],
  'Kanawha Falls': [50],
  'Kanawha Head': [50],
  Kandiyohi: [24, -24],
  Kane: [15, -15, 39, -45],
  Kaneohe: [12],
  Kaneville: [15],
  Kankakee: [15, -15],
  Kannapolis: [28],
  Kanona: [35],
  Kanopolis: [17],
  Kanorado: [17],
  Kanosh: [45],
  Kansas: [2, 15, 36, 37],
  'Kansas City': [17, 25],
  Kansasville: [49],
  Kantner: [39],
  Kapaa: [12],
  Kapaau: [12],
  Kaplan: [19],
  Kapolei: [12],
  Kapowsin: [48],
  Karlsruhe: [29],
  Karlstad: [24],
  Karluk: [1],
  Karnack: [44],
  Karnak: [15],
  Karnes: [-44],
  'Karnes City': [44],
  'Karns City': [39],
  Karthaus: [39],
  Karval: [6],
  Kasbeer: [15],
  Kasigluk: [1],
  Kasilof: [1],
  Kasota: [24],
  Kasson: [24],
  Kathleen: [10, 11],
  Kathryn: [29],
  Katonah: [35],
  'Kattskill Bay': [35],
  Katy: [44],
  Kauai: [-12],
  Kaufman: [44, -44],
  Kaukauna: [49],
  Kaumakani: [12],
  Kaunakakai: [12],
  'Kauneonga Lake': [35],
  'Kaw City': [37],
  Kaweah: [5],
  Kawkawlin: [23],
  Kay: [-37],
  Kaycee: [51],
  Kayenta: [4],
  Kaylor: [42],
  Kaysville: [45],
  Keaau: [12],
  Kealakekua: [12],
  Kealia: [12],
  'Keams Canyon': [4],
  Keansburg: [32],
  Kearney: [25, 30, -30],
  Kearneysville: [50],
  Kearny: [4, -17, 32],
  Kearsarge: [23, 31],
  Keasbey: [32],
  Keatchie: [19],
  Keaton: [18],
  Keauhou: [12],
  Keavy: [18],
  Kechi: [17],
  Keedysville: [21],
  'Keego Harbor': [23],
  Keeler: [5],
  Keeling: [46],
  'Keen Mountain': [46],
  Keene: [5, 18, 29, 31, 35, 36, 44, 46],
  'Keene Valley': [35],
  Keenes: [15],
  Keenesburg: [6],
  Keensburg: [15],
  Keeseville: [35],
  Keewatin: [24],
  Keezletown: [46],
  Kegley: [50],
  Keiser: [3],
  Keisterville: [39],
  Keith: [-30],
  Keithsburg: [15],
  Keithville: [19],
  Keizer: [38],
  Kekaha: [12],
  Kelayres: [39],
  Keldron: [42],
  Kelford: [28],
  Kell: [15],
  Keller: [44, 46, 48],
  Kellerton: [13],
  Kelley: [13],
  'Kelleys Island': [36],
  Kelliher: [24],
  Kellnersville: [49],
  Kellogg: [13, 14, 24],
  Kelly: [19, 28, 51],
  Kellysville: [50],
  Kellyton: [2],
  Kellyville: [37],
  Kelseyville: [5],
  Kelso: [25, 43, 48],
  Kelton: [39],
  Kemah: [44],
  Kemblesville: [39],
  Kemmerer: [51],
  Kemp: [37, 44],
  Kemper: [-26],
  Kempner: [44],
  Kempton: [15, 16, 39],
  Kenai: [1],
  'Kenai Peninsula': [-1],
  Kenansville: [10, 28],
  Kenbridge: [46],
  Kendalia: [44],
  Kendall: [-15, 17, 23, 35, -44, 49],
  'Kendall Park': [32],
  Kendallville: [16],
  Kendleton: [44],
  Kendrick: [14],
  Kenduskeag: [22],
  Kenedy: [44, -44],
  Kenefic: [37],
  Kenesaw: [30],
  Kenilworth: [15, 32, 45],
  Kenly: [28],
  Kenmare: [29],
  Kenmore: [48],
  Kenna: [33, 50],
  Kennan: [49],
  Kennard: [16, 30, 44],
  Kennebec: [-22, 42],
  Kennebunk: [22],
  Kennebunkport: [22],
  Kennedale: [44],
  Kennedy: [2, 24, 35],
  Kennedyville: [21],
  Kenner: [19],
  Kennerdell: [39],
  Kennesaw: [11],
  Kenneth: [24],
  Kennett: [25],
  'Kennett Square': [39],
  Kennewick: [48],
  Kenney: [15, 44],
  Keno: [38],
  Kenosha: [49, -49],
  Kenova: [50],
  'Kenoza Lake': [35],
  Kensal: [29],
  Kensett: [3, 13],
  Kensington: [17, 21, 24, 36],
  Kent: [2, 7, -9, 15, -21, -23, 24, 35, 36, 38, 39, -40, -44, 48],
  'Kent City': [23],
  Kentfield: [5],
  Kentland: [16],
  Kenton: [9, 18, -18, 36, 37, 43],
  'Kents Hill': [22],
  'Kents Store': [46],
  Kentwood: [19, 23],
  Kenvil: [32],
  Kenvir: [18],
  Kenwood: [5],
  Kenyon: [24, 40],
  Keo: [3],
  Keokee: [46],
  Keokuk: [13, -13],
  Keosauqua: [13],
  Keota: [13, 37],
  Kerby: [38],
  Kerens: [44, 50],
  Kerhonkson: [35],
  Kerkhoven: [24],
  Kerman: [5],
  Kermit: [44, 50],
  Kern: [-5],
  Kernersville: [28],
  Kernville: [5],
  Kerr: [36, -44],
  Kerrick: [24, 44],
  Kerrville: [44],
  Kersey: [6, 39],
  Kershaw: [41, -41],
  Keshena: [49],
  'Keslers Cross Lanes': [50],
  Kesley: [13],
  Keswick: [13, 46],
  Ketchikan: [1],
  'Ketchikan Gateway': [-1],
  Ketchum: [14, 37],
  'Kettle Falls': [48],
  'Kettle Island': [18],
  'Kettle River': [24],
  'Kettleman City': [5],
  Kettlersville: [36],
  'Keuka Park': [35],
  Kevil: [18],
  Kevin: [27],
  'Kew Gardens': [35],
  Kewadin: [23],
  Kewanee: [15, 25],
  Kewanna: [16],
  Kewaskum: [49],
  Kewaunee: [49, -49],
  Keweenaw: [-23],
  'Key Biscayne': [10],
  'Key Colony Beach': [10],
  'Key Largo': [10],
  'Key West': [10],
  'Keya Paha': [-30],
  Keyes: [5, 37],
  Keyesport: [15],
  Keymar: [21],
  Keyport: [32, 48],
  Keyser: [50],
  Keystone: [13, 16, 30, 42],
  'Keystone Heights': [10],
  Keysville: [11, 46],
  Keytesville: [25],
  Kiahsville: [50],
  'Kiamesha Lake': [35],
  Kiana: [1],
  Kidder: [25, -29],
  Kidron: [36],
  Kiefer: [37],
  Kiel: [49],
  Kieler: [49],
  Kiester: [24],
  Kihei: [12],
  Kila: [27],
  Kilauea: [12],
  Kilbourne: [15, 19, 36],
  Kildare: [44],
  Kilgore: [30, 44],
  Kilkenny: [24],
  'Kill Buck': [35],
  'Kill Devil Hills': [28],
  Killarney: [10],
  Killawog: [35],
  Killbuck: [36],
  Killdeer: [29],
  Killduff: [13],
  Killeen: [44],
  Killen: [2],
  Killingly: [7],
  Killington: [47],
  Killingworth: [7],
  Kilmarnock: [46],
  Kilmichael: [26],
  Kiln: [26],
  Kim: [6],
  Kimball: [24, 30, -30, 42, 43, 50],
  Kimballton: [13],
  'Kimberling City': [25],
  Kimberly: [2, 14, 38, 49, 50],
  Kimberton: [39],
  Kimble: [-44],
  Kimbolton: [36],
  Kimmell: [16],
  Kimmswick: [25],
  Kimper: [18],
  Kinards: [41],
  Kincaid: [15, 17, 50],
  Kincheloe: [23],
  Kinde: [23],
  Kinder: [19],
  Kinderhook: [15, 35],
  Kindred: [29],
  King: [28, -44, -48, 49],
  'King And Queen Court House': [46],
  'King City': [5, 25],
  'King Cove': [1],
  'King Ferry': [35],
  'King George': [46, -46],
  'King Hill': [14],
  'King Of Prussia': [39],
  'King Salmon': [1],
  'King William': [46, -46],
  'King and Queen': [-46],
  'Kingdom City': [25],
  Kingfield: [22],
  Kingfisher: [37, -37],
  Kingman: [4, 16, 17, -17, 22],
  Kingmont: [50],
  Kings: [-5, -35],
  'Kings Bay': [11],
  'Kings Beach': [5],
  'Kings Canyon National Pk': [5],
  'Kings Mills': [36],
  'Kings Mountain': [18, 28],
  'Kings Park': [35],
  Kingsburg: [5],
  Kingsbury: [16, -42, 44],
  Kingsford: [23],
  'Kingsford Heights': [16],
  Kingsland: [3, 11, 44],
  Kingsley: [13, 23, 39],
  Kingsport: [43],
  Kingston: [3, 11, 14, 15, 20, 23, 25, 31, 32, 35, 36, 37, 39, 40, 43, 45, 48, 49],
  'Kingston Mines': [15],
  'Kingston Springs': [43],
  Kingstree: [41],
  Kingsville: [21, 25, 36, 44],
  Kingwood: [44, 50],
  Kinmundy: [15],
  Kinnear: [51],
  Kinney: [24, -44],
  Kinross: [23],
  Kinsale: [46],
  Kinsey: [27],
  Kinsley: [17],
  Kinsman: [15, 36],
  Kinston: [2, 28],
  Kinta: [37],
  Kintnersville: [39],
  Kintyre: [29],
  Kinzers: [39],
  Kiowa: [6, -6, 17, -17, 37, -37],
  Kipling: [28, 36],
  Kipnuk: [1],
  Kipton: [36],
  Kirby: [3, 36, 51],
  Kirbyville: [25, 44],
  Kirk: [6],
  Kirkersville: [36],
  Kirkland: [4, 15, 48],
  Kirklin: [16],
  Kirkman: [13],
  Kirksey: [18],
  Kirksville: [25],
  Kirkville: [13, 35],
  Kirkwood: [5, 9, 15, 25, 35, 39],
  Kiron: [13],
  Kirtland: [33],
  'Kirtland Afb': [33],
  Kirvin: [44],
  Kirwin: [17],
  Kismet: [17],
  'Kissee Mills': [25],
  Kissimmee: [10],
  Kistler: [50],
  'Kit Carson': [5, 6, -6],
  Kite: [11, 18],
  Kitsap: [-48],
  Kittanning: [39],
  Kittery: [22],
  'Kittery Point': [22],
  Kittitas: [48, -48],
  Kittredge: [6],
  Kittrell: [28],
  'Kitts Hill': [36],
  Kittson: [-24],
  'Kitty Hawk': [28],
  Kitzmiller: [21],
  Kivalina: [1],
  Klamath: [5, -38],
  'Klamath Falls': [38],
  'Klamath River': [5],
  Klawock: [1],
  Kleberg: [-44],
  Kleinfeltersville: [39],
  Klemme: [13],
  Klickitat: [48, -48],
  Klingerstown: [39],
  Klondike: [44],
  Knapp: [49],
  Kneeland: [5],
  Knickerbocker: [44],
  Knierim: [13],
  'Knife River': [24],
  Knifley: [18],
  Knightdale: [28],
  'Knights Landing': [5],
  Knightsen: [5],
  Knightstown: [16],
  Knightsville: [16],
  Knippa: [44],
  'Knob Lick': [18, 25],
  'Knob Noster': [25],
  Knobel: [3],
  Knott: [-18, 44],
  'Knotts Island': [28],
  Knowlesville: [35],
  Knox: [-15, 16, -16, -18, -22, -25, 29, -30, 35, -36, 39, -43, -44],
  'Knox City': [25, 44],
  'Knox Dale': [39],
  Knoxboro: [35],
  Knoxville: [2, 3, 11, 13, 15, 21, 39, 43],
  Kobuk: [1],
  Kodak: [43],
  Kodiak: [1],
  'Kodiak Island': [-1],
  Koeltztown: [25],
  Kohler: [49],
  Kokomo: [16, 26],
  Koloa: [12],
  Konawa: [37],
  Kongiganak: [1],
  Koochiching: [-24],
  Koosharem: [45],
  Kooskia: [14],
  Kootenai: [14, -14],
  Koppel: [39],
  Kopperl: [44],
  Kopperston: [50],
  Korbel: [5],
  Kosciusko: [-16, 26],
  Koshkonong: [25],
  Kosse: [44],
  Kossuth: [-13, 39],
  Kotlik: [1],
  Kotzebue: [1],
  Kountze: [44],
  Kouts: [16],
  Koyuk: [1],
  Koyukuk: [1],
  Kraemer: [19],
  Krakow: [49],
  Kramer: [29],
  Kranzburg: [42],
  Kreamer: [39],
  Krebs: [37],
  Kremlin: [27, 37],
  Kremmling: [6],
  Kresgeville: [39],
  Kress: [44],
  'Krotz Springs': [19],
  Krum: [44],
  Krypton: [18],
  Kualapuu: [12],
  Kula: [12],
  Kulm: [29],
  Kulpmont: [39],
  Kulpsville: [39],
  Kuna: [14],
  Kunia: [12],
  Kunkle: [36],
  Kunkletown: [39],
  'Kure Beach': [28],
  Kurten: [44],
  Kurthwood: [19],
  Kurtistown: [12],
  Kurtz: [16],
  Kusilvak: [-1],
  Kuttawa: [18],
  Kutztown: [39],
  Kwethluk: [1],
  Kwigillingok: [1],
  Kyburz: [5],
  'Kykotsmovi Village': [4],
  Kyle: [42, 44, 50],
  Kylertown: [39],
  'Kyles Ford': [43],
  'La Barge': [51],
  'La Belle': [25, 39],
  'La Blanca': [44],
  'La Ca\u00f1ada Flintridge': [5],
  'La Center': [18, 48],
  'La Conner': [48],
  'La Coste': [44],
  'La Crescent': [24],
  'La Crescenta': [5],
  'La Crosse': [10, 16, 17, 46, 49, -49],
  'La Cygne': [17],
  'La Farge': [49],
  'La Fargeville': [35],
  'La Fayette': [11, 15, 18, 35],
  'La Feria': [44],
  'La Follette': [43],
  'La Fontaine': [16],
  'La France': [41],
  'La Grande': [38, 48],
  'La Grange': [3, 5, 15, 18, 25, 28, 43, 44],
  'La Grange Park': [15],
  'La Habra': [5],
  'La Harpe': [15, 17],
  'La Honda': [5],
  'La Jara': [6, 33],
  'La Jolla': [5],
  'La Jose': [39],
  'La Joya': [33, 44],
  'La Junta': [6],
  'La Loma': [33],
  'La Luz': [33],
  'La Madera': [33],
  'La Marque': [44],
  'La Mesa': [5, 33],
  'La Mirada': [5],
  'La Moille': [15],
  'La Monte': [25],
  'La Motte': [13],
  'La Moure': [-29],
  'La Palma': [5],
  'La Paz': [-4],
  'La Pine': [38],
  'La Place': [15, 19],
  'La Plata': [-6, 21, 25, 33],
  'La Plume': [39],
  'La Pointe': [49],
  'La Porte': [16, 44],
  'La Porte City': [13],
  'La Prairie': [15],
  'La Pryor': [44],
  'La Puente': [5],
  'La Push': [48],
  'La Quinta': [5],
  'La Rose': [15],
  'La Rue': [36],
  'La Russell': [25],
  'La Sal': [45],
  'La Salle': [6, 15, 23, 24, 44, -44],
  'La Valle': [49],
  'La Vergne': [43],
  'La Verkin': [45],
  'La Verne': [5],
  'La Vernia': [44],
  'La Veta': [6],
  'La Villa': [44],
  'La Vista': [30],
  'La Ward': [44],
  LaPorte: [-16],
  LaSalle: [-15, -19],
  Labadie: [25],
  Labadieville: [19],
  Labelle: [10],
  Labette: [-17],
  Labolt: [42],
  'Lac Du Flambeau': [49],
  'Lac Lede': [-25],
  Lacarne: [36],
  Lacassine: [19],
  Lacey: [48],
  'Lacey Spring': [46],
  'Laceys Spring': [2],
  Laceyville: [39],
  Lachine: [23],
  Lackawanna: [-39],
  Lackawaxen: [39],
  Lackey: [18, 46],
  Laclede: [14, 25],
  Lacombe: [19],
  Lacon: [15],
  Lacona: [13, 35],
  Laconia: [16, 31, 43],
  Lacoochee: [10],
  Lacota: [23],
  'Lacqui Parle': [-24],
  Lacrosse: [48],
  Ladd: [15],
  Laddonia: [25],
  'Ladera Ranch': [5],
  Ladiesburg: [21],
  Ladoga: [16],
  Ladonia: [44],
  Ladora: [13],
  Ladson: [41],
  'Lady Lake': [10],
  Ladysmith: [46, 49],
  Lafayette: [2, -3, 5, 6, -10, 16, 19, -19, 24, -25, -26, 32, 36, 38, 43, -49],
  'Lafayette Hill': [39],
  Lafe: [3],
  Lafferty: [36],
  Lafitte: [19],
  Lafourche: [-19],
  Lafox: [15],
  Lagrange: [11, 16, -16, 22, 36, 51],
  Lagrangeville: [35],
  Lagro: [16],
  Laguna: [33],
  'Laguna Beach': [5],
  'Laguna Hills': [5],
  'Laguna Niguel': [5],
  'Laguna Park': [44],
  'Laguna Vista': [44],
  'Laguna Woods': [5],
  Lagunitas: [5],
  Lahaina: [12],
  Lahaska: [39],
  Lahmansville: [50],
  Lahoma: [37],
  Laie: [12],
  Laings: [36],
  Laingsburg: [23],
  Laird: [6],
  'Laird Hill': [44],
  Lairdsville: [39],
  Lake: [-5, -6, -10, -15, -16, 23, -23, -24, 26, -27, -36, -38, -42, -43, 50],
  'Lake Alfred': [10],
  'Lake Almanor': [5],
  'Lake Andes': [42],
  'Lake Ann': [23],
  'Lake Ariel': [39],
  'Lake Arrowhead': [5],
  'Lake Arthur': [19, 33],
  'Lake Benton': [24],
  'Lake Bluff': [15],
  'Lake Bronson': [24],
  'Lake Butler': [10],
  'Lake Carroll': [15],
  'Lake Charles': [19],
  'Lake Cicott': [16],
  'Lake City': [3, 5, 6, 10, 13, 17, 23, 24, 39, 41, 42, 44],
  'Lake Clear': [35],
  'Lake Como': [10, 39],
  'Lake Cormorant': [26],
  'Lake Creek': [44],
  'Lake Crystal': [24],
  'Lake Dallas': [44],
  'Lake Delton': [49],
  'Lake Elmo': [24],
  'Lake Elmore': [47],
  'Lake Elsinore': [5],
  'Lake Forest': [5, 15],
  'Lake Fork': [14, 15],
  'Lake Geneva': [10, 49],
  'Lake George': [6, 23, 24, 35],
  'Lake Grove': [35],
  'Lake Hamilton': [10],
  'Lake Harbor': [10],
  'Lake Harmony': [39],
  'Lake Havasu City': [4],
  'Lake Helen': [10],
  'Lake Hiawatha': [32],
  'Lake Hill': [35],
  'Lake Hopatcong': [32],
  'Lake Hubert': [24],
  'Lake Hughes': [5],
  'Lake Huntington': [35],
  'Lake In The Hills': [15],
  'Lake Isabella': [5],
  'Lake Jackson': [44],
  'Lake Junaluska': [28],
  'Lake Katrine': [35],
  'Lake Kiowa': [44],
  'Lake Leelanau': [23],
  'Lake Lillian': [24],
  'Lake Linden': [23],
  'Lake Lure': [28],
  'Lake Luzerne': [35],
  'Lake Lynn': [39],
  'Lake Mary': [10],
  'Lake McDonald': [27],
  'Lake Mills': [13, 49],
  'Lake Milton': [36],
  'Lake Minchumina': [1],
  'Lake Monroe': [10],
  'Lake Montezuma': [4],
  'Lake Nebagamon': [49],
  'Lake Norden': [42],
  'Lake Odessa': [23],
  'Lake Orion': [23],
  'Lake Oswego': [38],
  'Lake Ozark': [25],
  'Lake Panasoffkee': [10],
  'Lake Park': [11, 13, 24],
  'Lake Peekskill': [35],
  'Lake Placid': [10, 35],
  'Lake Pleasant': [20, 35],
  'Lake Powell': [45],
  'Lake Preston': [42],
  'Lake Providence': [19],
  'Lake Quivira': [17],
  'Lake Saint Louis': [25],
  'Lake Spring': [25],
  'Lake Station': [16],
  'Lake Stevens': [48],
  'Lake Success': [35],
  'Lake Tomahawk': [49],
  'Lake Toxaway': [28],
  'Lake View': [13, 35, 41],
  'Lake Villa': [15],
  'Lake Village': [3, 16],
  'Lake Waccamaw': [28],
  'Lake Wales': [10],
  'Lake Wilson': [24],
  'Lake Winola': [39],
  'Lake Worth': [10],
  'Lake Worth Beach': [10],
  'Lake Wylie': [41],
  'Lake Zurich': [15],
  'Lake of the Woods': [-24],
  'Lakeand Peninsula': [-1],
  Lakebay: [48],
  Lakefield: [24],
  Lakehead: [5],
  Lakehills: [44],
  Lakehurst: [32],
  Lakeland: [10, 11, 19, 23, 24],
  Lakemont: [11, 35],
  Lakemore: [36],
  Lakeport: [5],
  Lakeshore: [5, 10, 26],
  Lakeside: [4, 5, 7, 23, 27, 30, 38],
  'Lakeside Marblehead': [36],
  Laketon: [16],
  Laketown: [45],
  Lakeview: [3, 23, 28, 36, 38, 44],
  Lakeville: [7, 16, 20, 23, 24, 35, 36, 39],
  Lakeway: [44],
  Lakewood: [5, 6, 15, 32, 33, 35, 36, 39, 48, 49],
  'Lakewood Ranch': [10],
  Lakin: [17],
  Lakota: [13, 29],
  Lamar: [-2, 3, 6, -11, 16, 25, 26, -26, 37, 39, 41, -44],
  Lamartine: [39],
  Lamb: [-44],
  Lambert: [26, 27],
  'Lambert Lake': [22],
  Lamberton: [24],
  Lambertville: [23, 32],
  Lambrook: [3],
  Lambsburg: [46],
  'Lame Deer': [27],
  Lamesa: [44],
  Lamoille: [34, -47],
  Lamona: [48],
  Lamoni: [13],
  Lamont: [5, 10, 13, 17, 23, 37, 48],
  Lamoure: [29],
  Lampasas: [44, -44],
  Lampe: [25],
  Lampeter: [39],
  Lamy: [33],
  Lanagan: [25],
  'Lanai City': [12],
  Lanark: [15, 50],
  'Lanark Village': [10],
  Lancaster: [5, 17, 18, 20, 24, 25, -30, 31, 35, 36, 39, -39, 41, -41, 43, 44, 46, -46, 49],
  'Lance Creek': [51],
  Lancing: [43],
  'Land O\u2019Lakes': [10, 49],
  Landenberg: [39],
  Lander: [-34, 51],
  Landers: [5],
  Landing: [32],
  Landis: [28],
  Landisburg: [39],
  Landisville: [32, 39],
  Lando: [41],
  Landrum: [41],
  Lane: [15, 17, -17, 37, -38, 41, 42],
  'Lane City': [44],
  Lanesboro: [13, 24, 39],
  Lanesborough: [20],
  Lanesville: [16, 35],
  Lanett: [2],
  Laneview: [46],
  Laneville: [44],
  Lanexa: [46],
  Langdon: [29],
  Langeloth: [39],
  Langford: [42],
  Langhorne: [39],
  Langlade: [-49],
  Langley: [3, 18, 37, 41, 48],
  Langlois: [38],
  Langston: [2, 37],
  Langsville: [36],
  Langtry: [44],
  Langworthy: [13],
  Lanham: [21],
  Lanier: [-11],
  Lankin: [29],
  Lannon: [49],
  'Lanoka Harbor': [32],
  Lansdale: [39],
  Lansdowne: [39],
  Lanse: [23, 39],
  Lansford: [29, 39],
  Lansing: [13, 15, 17, 23, 24, 28, 35, 36, 50],
  Lantry: [42],
  Laona: [49],
  Laotto: [16],
  Lapaz: [16],
  Lapeer: [23, -23],
  Lapel: [16],
  Lapine: [2],
  Lapoint: [45],
  Laporte: [6, 24, 39],
  Lapwai: [14],
  Laquey: [25],
  Laramie: [51, -51],
  Larchmont: [35],
  Larchwood: [13],
  Laredo: [25, 44],
  Largo: [10],
  Larimer: [-6, 39],
  Larimore: [29],
  Larkspur: [5, 6],
  Larned: [17],
  Larose: [19],
  Larrabee: [13],
  Larsen: [49],
  'Larsen Bay': [1],
  Larslan: [27],
  Larue: [-18, 44],
  Larwill: [16],
  'Las Animas': [6, -6],
  'Las Cruces': [33],
  'Las Vegas': [33, 34],
  Lasara: [44],
  Lascassas: [43],
  Lashmeet: [50],
  Lassen: [-5],
  Lastrup: [24],
  Latah: [-14, 48],
  Latexo: [44],
  Latham: [15, 17, 25, 35, 36],
  Lathrop: [5, 25],
  Latimer: [13, -37],
  Laton: [5],
  Latonia: [18],
  Latrobe: [39],
  Latta: [41],
  'Lattimer Mines': [39],
  Lattimore: [28],
  Latty: [36],
  Lauderdale: [-2, 26, -26, -43],
  Laughlin: [34],
  'Laughlin Afb': [44],
  Laughlintown: [39],
  Laupahoehoe: [12],
  Laura: [15, 36],
  Laurel: [9, 10, 13, 16, -18, 21, 26, 27, 30, 35],
  'Laurel Bloomery': [43],
  'Laurel Fork': [46],
  'Laurel Hill': [10, 28],
  'Laurel Springs': [28],
  Laureldale: [39],
  Laurelton: [39],
  Laurelville: [36],
  Laurens: [-11, 13, 35, 41, -41],
  Laurie: [25],
  Laurier: [48],
  Laurinburg: [28],
  'Laurys Station': [39],
  'Lava Hot Springs': [14],
  Lavaca: [3, -44],
  Lavalette: [50],
  Lavallette: [32],
  Laveen: [4],
  Lavelle: [39],
  Laverne: [37],
  Lavina: [27],
  Lavinia: [43],
  Lavon: [44],
  Lavonia: [11],
  Lawai: [12],
  Lawler: [13],
  Lawley: [2],
  Lawn: [39, 44],
  Lawndale: [5, 15, 28],
  Lawnside: [32],
  Lawrence: [-2, -3, -15, -16, 17, -18, 20, 23, -25, 26, -26, 30, 35, -36, 39, -39, -42, -43],
  'Lawrence Township': [32],
  Lawrenceburg: [16, 18, 43],
  Lawrenceville: [11, 15, 35, 39, 46],
  Lawson: [3, 25],
  Lawsonville: [28],
  Lawtell: [19],
  Lawtey: [10],
  Lawton: [13, 23, 29, 37, 39],
  Lawtons: [35],
  Layland: [50],
  Layton: [32, 45],
  Laytonville: [5],
  Lazbuddie: [44],
  Lazear: [6],
  'Le Center': [24],
  'Le Claire': [13],
  'Le Flore': [-37],
  'Le Grand': [5, 13],
  'Le Mars': [13],
  'Le Raysville': [39],
  'Le Roy': [15, 17, 24, 35, 50],
  'Le Sueur': [24, -24],
  Lea: [-33],
  Leachville: [3],
  Lead: [42],
  'Lead Hill': [3, 25],
  Leadore: [14],
  Leadville: [6],
  Leadwood: [25],
  'Leaf River': [15],
  'League City': [44],
  Leake: [-26],
  Leakesville: [26],
  Leakey: [44],
  Leamington: [45],
  Leander: [44],
  Leary: [11],
  Leasburg: [25, 28],
  Leavenworth: [16, 17, -17, 48],
  Leavittsburg: [36],
  Leawood: [17],
  Lebam: [48],
  Lebanon: [7, 11, 15, 16, 17, 18, 22, 25, 30, 31, 32, 36, 37, 38, 39, -39, 42, 43, 46, 49],
  'Lebanon Junction': [18],
  Lebeau: [19],
  Lebec: [5],
  Leblanc: [19],
  Lebo: [17],
  Leburn: [18],
  Lecanto: [10],
  'Leck Kill': [39],
  Leckrone: [39],
  Lecompte: [19],
  Lecompton: [17],
  'Lecontes Mills': [39],
  Ledbetter: [18, 44],
  Lederach: [39],
  Ledger: [27],
  Ledgewood: [32],
  Ledyard: [7, 13],
  Lee: [-2, -3, 10, -10, -11, -13, 15, -15, -18, 20, 22, -26, -28, 31, -41, -44, -46],
  'Lee Center': [15, 35],
  'Lee Vining': [5],
  Leechburg: [39],
  Leedey: [37],
  Leeds: [2, 20, 22, 29, 35, 45],
  'Leeds Point': [32],
  Leelanau: [-23],
  Leeper: [39],
  'Lees Summit': [25],
  Leesburg: [2, 10, 11, 16, 32, 36, 44, 46],
  Leesport: [39],
  Leesville: [19, 36, 41, 44],
  Leeton: [25],
  Leetonia: [36],
  Leetsdale: [39],
  Leflore: [-26, 37],
  Lefor: [29],
  Lefors: [44],
  'Left Hand': [50],
  Leggett: [5, 44],
  Lehi: [45],
  Lehigh: [13, 17, 37, -39],
  'Lehigh Acres': [10],
  'Lehigh Valley': [39],
  Lehighton: [39],
  Lehman: [39],
  Lehr: [29],
  Leicester: [20, 28, 35],
  Leigh: [30],
  Leighton: [2, 13],
  Leipsic: [36],
  Leisenring: [39],
  Leitchfield: [18],
  Leiter: [51],
  'Leiters Ford': [16],
  Leivasy: [50],
  Lejunior: [18],
  Leland: [13, 15, 23, 26, 28],
  'Lelia Lake': [44],
  Lemasters: [39],
  Lemhi: [14, -14],
  Leming: [44],
  Lemitar: [33],
  Lemmon: [42],
  'Lemon Cove': [5],
  'Lemon Grove': [5],
  'Lemon Springs': [28],
  Lemont: [15, 39],
  'Lemont Furnace': [39],
  Lemoore: [5],
  Lemoyne: [30, 36, 39],
  Lempster: [31],
  Lena: [15, 19, 26, 49],
  Lenapah: [37],
  Lenawee: [-23],
  Lenexa: [17],
  Lengby: [24],
  Lenhartsville: [39],
  Lenni: [39],
  Lennon: [23],
  Lennox: [42],
  Lenoir: [28, -28],
  'Lenoir City': [43],
  Lenora: [17],
  Lenorah: [44],
  Lenore: [14, 50],
  Lenox: [2, 11, 13, 20, 25, 43],
  'Lenox Dale': [20],
  Lenoxville: [39],
  Lentner: [25],
  Lenzburg: [15],
  Leo: [16],
  Leola: [3, 39, 42],
  Leoma: [43],
  Leominster: [20],
  Leon: [-10, 13, 17, 35, 37, -44, 46, 50],
  Leona: [44],
  Leonard: [23, 24, 25, 29, 37, 44],
  Leonardo: [32],
  Leonardsville: [35],
  Leonardtown: [21],
  Leonardville: [17],
  Leonia: [32],
  Leonidas: [23],
  Leonore: [15],
  Leonville: [19],
  Leopold: [16, 25],
  Leopolis: [49],
  Leota: [24],
  Leoti: [17],
  Lepanto: [3],
  Lequire: [37],
  Lerna: [15],
  Lerona: [50],
  Leroy: [2, 16, 23, 44],
  Lesage: [50],
  Leslie: [3, 11, -18, 23, 25, 50],
  Lester: [2, 13, 50],
  'Lester Prairie': [24],
  Lesterville: [25, 42],
  Letart: [50],
  Letcher: [18, -18, 42],
  Letha: [14],
  Letohatchee: [2],
  Letona: [3],
  Letts: [13],
  Lettsworth: [19],
  Leupp: [4],
  Levan: [45],
  Levant: [17, 22],
  Levasy: [25],
  Levelland: [44],
  Levelock: [1],
  Levels: [50],
  Leverett: [20],
  Levering: [23],
  Levittown: [35, 39],
  Levy: [-10],
  Lewellen: [30],
  Lewes: [9],
  Lewis: [6, 13, -14, 16, 17, -18, -25, 35, -35, -43, -48, -50],
  'Lewis Center': [36],
  'Lewis Run': [39],
  'Lewis and Clark': [-27],
  Lewisberry: [39],
  Lewisburg: [18, 36, 39, 43, 50],
  Lewisport: [18],
  Lewiston: [5, 14, 22, 23, 24, 30, 35, 45],
  'Lewiston Woodville': [28],
  Lewistown: [15, 25, 27, 36, 39],
  Lewisville: [3, 14, 16, 24, 28, 36, 39, 44],
  Lexa: [3],
  Lexington: [2, 11, 15, 16, 18, 20, 23, 25, 26, 28, 30, 35, 36, 37, 38, 41, -41, 43, 44, 46],
  'Lexington Park': [21],
  Libby: [27],
  Liberal: [17, 25],
  Liberty: [-10, -11, 15, 16, 17, 18, 22, 25, 26, -27, 28, 30, 35, 39, 41, 43, 44, -44, 50],
  'Liberty Center': [13, 16, 36],
  'Liberty Corner': [32],
  'Liberty Hill': [41, 44],
  'Liberty Lake': [48],
  'Liberty Mills': [16],
  'Liberty Township': [36],
  Libertytown: [21],
  Libertyville: [13, 15],
  Libuse: [19],
  'Lick Creek': [18],
  Licking: [25, -36],
  Lickingville: [39],
  Lidderdale: [13],
  Lidgerwood: [29],
  Liebenthal: [17],
  Lightfoot: [46],
  'Lighthouse Point': [10],
  Lignite: [29],
  Lignum: [46],
  Ligonier: [16, 39],
  Liguori: [25],
  Lihue: [12],
  Likely: [5],
  Lilbourn: [25],
  Lilburn: [11],
  Lilesville: [28],
  Lillian: [2, 44],
  Lillie: [19],
  Lillington: [28],
  Lilliwaup: [48],
  Lilly: [11, 39],
  Lily: [18],
  'Lily Dale': [35],
  Lima: [15, 27, 35, 36],
  Limaville: [36],
  'Lime Ridge': [49],
  'Lime Springs': [13],
  Limekiln: [39],
  Limeport: [39],
  Limerick: [22, 35],
  Limestone: [-2, 22, 35, 39, 43, -44],
  Limington: [22],
  Limon: [6],
  Linch: [51],
  Lincoln: [2, 3, -3, 5, -6, 9, -11, 13, -14, 15, 17, -17, -18, -19, 20, 22, -22, 23, -24, 25, -25, -26, 27, -27, -28, 30, -30, 31, 33, -33, -34, -37, -38, 40, -42, -43, 44, 46, 48, -48, -49, -50, -51],
  'Lincoln City': [16, 38],
  'Lincoln Park': [23, 32],
  'Lincoln University': [39],
  Lincolndale: [35],
  'Lincolns New Salem': [15],
  Lincolnshire: [15],
  Lincolnton: [11, 28],
  Lincolnville: [17, 22],
  'Lincolnville Center': [22],
  Lincolnwood: [15],
  Lincroft: [32],
  Lind: [48],
  Lindale: [11, 44],
  Linden: [2, 5, 13, 16, 23, 28, 32, 39, 43, 44, 46, 49],
  Lindenhurst: [35],
  Lindenwood: [15],
  Lindley: [35],
  Lindon: [6, 45],
  Lindrith: [33],
  Lindsay: [5, 27, 30, 37, 44],
  Lindsborg: [17],
  Lindsey: [36],
  Lindside: [50],
  Lindstrom: [24],
  'Line Lexington': [39],
  Lineboro: [21],
  Linefork: [18],
  Linesville: [39],
  Lineville: [2, 13],
  Lingle: [51],
  Lingleville: [44],
  Lingo: [33],
  Linkwood: [21],
  Linn: [-13, 17, -17, 25, -25, -38, 44, 50],
  'Linn Creek': [25],
  'Linn Grove': [13, 16],
  Linneus: [25],
  'Linthicum Heights': [21],
  Linton: [16, 29],
  Linville: [28, 46],
  'Linville Falls': [28],
  Linwood: [17, 20, 23, 28, 30, 32, 35],
  Lionville: [39],
  Lipan: [44],
  Lipscomb: [44, -44],
  Lisbon: [13, 19, 21, 22, 29, 31, 35, 36],
  'Lisbon Falls': [22],
  Lisco: [30],
  Liscomb: [13],
  Lisle: [15, 35],
  Lisman: [2],
  Lismore: [24],
  Lissie: [44],
  Listie: [39],
  Litchfield: [5, 7, -7, 15, 22, 23, 24, 30, 31, 36],
  'Litchfield Park': [4],
  Litchville: [29],
  Literberry: [15],
  Lithia: [10],
  'Lithia Springs': [11],
  Lithonia: [11],
  Lithopolis: [36],
  Lititz: [39],
  Littcarr: [18],
  'Little America': [51],
  'Little Birch': [50],
  'Little Canada': [24],
  'Little Cedar': [13],
  'Little Chute': [49],
  'Little Compton': [40],
  'Little Creek': [9],
  'Little Deer Isle': [22],
  'Little Eagle': [42],
  'Little Elm': [44],
  'Little Falls': [24, 32, 35],
  'Little Ferry': [32],
  'Little Genesee': [35],
  'Little Hocking': [36],
  'Little Lake': [5, 23],
  'Little Meadows': [39],
  'Little Mountain': [41],
  'Little Neck': [35],
  'Little Orleans': [21],
  'Little Plymouth': [46],
  'Little River': [2, -3, 5, 17, 41],
  'Little River Academy': [44],
  'Little Rock': [3, 13, 26, 41],
  'Little Rock Air Force Base': [3],
  'Little Silver': [32],
  'Little Sioux': [13],
  'Little Suamico': [49],
  'Little Switzerland': [28],
  'Little Valley': [35],
  'Little York': [15, 32, 35],
  Littlefield: [4, 44],
  Littlefork: [24],
  Littlerock: [5, 48],
  Littlestown: [39],
  Littleton: [6, 15, 20, 28, 31, 50],
  'Live Oak': [5, 10, -44],
  Lively: [46],
  Livermore: [5, 6, 13, 18, 22],
  'Livermore Falls': [22],
  Liverpool: [15, 35, 39, 44],
  Livingston: [2, 5, 15, -15, 18, -18, 19, -19, -23, -25, 27, 32, 35, -35, 43, 44, 49],
  'Livingston Manor': [35],
  Livonia: [19, 23, 25, 35],
  'Livonia Center': [35],
  Lizella: [11],
  Lizemores: [50],
  Lizton: [16],
  Llano: [5, 33, 44, -44],
  Llewellyn: [39],
  Lloyd: [10, 27],
  Loa: [45],
  Loachapoka: [2],
  Loami: [15],
  Lobeco: [41],
  Lobelville: [43],
  'Loch Lloyd': [25],
  'Loch Sheldrake': [35],
  Lochgelly: [50],
  Lochloosa: [10],
  Lochmere: [31],
  'Lock Haven': [39],
  'Lock Springs': [25],
  Lockbourne: [36],
  Locke: [35],
  Lockeford: [5],
  Lockesburg: [3],
  Lockhart: [2, 41, 44],
  Lockney: [44],
  Lockport: [15, 18, 19, 35],
  Lockridge: [13],
  Lockwood: [5, 25, 35],
  Loco: [37],
  'Loco Hills': [33],
  Locust: [28],
  'Locust Dale': [46],
  'Locust Fork': [2],
  'Locust Gap': [39],
  'Locust Grove': [3, 11, 37, 46],
  'Locust Hill': [46],
  'Locust Valley': [35],
  Locustdale: [39],
  Locustville: [46],
  Loda: [15],
  Lodge: [41],
  'Lodge Grass': [27],
  Lodgepole: [30, 42],
  Lodi: [5, 25, 32, 35, 36, 44, 49],
  'Log Lane Village': [6],
  Logan: [2, -3, -6, 13, 15, -15, 17, -17, -18, -29, -30, 33, 36, -36, -37, 45, 50, -50],
  Logandale: [34],
  Logansport: [16, 19],
  Loganton: [39],
  Loganville: [11, 39, 49],
  Logsden: [38],
  Lohman: [25],
  Lohn: [44],
  Lohrville: [13],
  Loleta: [5],
  Lolita: [44],
  Lolo: [27],
  Loma: [6, 27],
  'Loma Linda': [5],
  'Loma Mar': [5],
  Loman: [24],
  Lomax: [15],
  Lombard: [15],
  Lometa: [44],
  Lomira: [49],
  Lomita: [5],
  Lompoc: [5],
  Lonaconing: [21],
  London: [3, 18, 36, 44, 50],
  'London Mills': [15],
  Londonderry: [31, 36, 47],
  Lone: [18],
  'Lone Grove': [37],
  'Lone Jack': [25],
  'Lone Mountain': [43],
  'Lone Oak': [44],
  'Lone Pine': [5],
  'Lone Rock': [13, 49],
  'Lone Star': [44],
  'Lone Tree': [6, 13],
  'Lone Wolf': [37],
  Lonedell: [25],
  Lonepine: [27],
  Lonetree: [51],
  Long: [-11],
  'Long Barn': [5],
  'Long Beach': [5, 26, 35, 48],
  'Long Bottom': [36],
  'Long Branch': [32, 44],
  'Long Creek': [38, 41],
  'Long Eddy': [35],
  'Long Green': [21],
  'Long Grove': [13, 15],
  'Long Island': [17, 22, 46],
  'Long Island City': [35],
  'Long Key': [10],
  'Long Lake': [23, 24, 35, 42, 49],
  'Long Lane': [25],
  'Long Pine': [30],
  'Long Point': [15],
  'Long Pond': [39],
  'Long Prairie': [24],
  'Long Valley': [32, 42],
  'Longboat Key': [10],
  Longbranch: [48],
  Longdale: [37],
  Longford: [17],
  Longleaf: [19],
  Longmeadow: [20],
  Longmire: [48],
  Longmont: [6],
  Longport: [32],
  Longs: [41],
  Longstreet: [19],
  Longton: [17],
  Longview: [15, 44, 48],
  Longville: [19, 24],
  Longwood: [10, 28],
  Lonoke: [3, -3],
  Lonsdale: [3, 24],
  Loogootee: [16],
  Lookeba: [37],
  Lookout: [5, 18, 50],
  'Lookout Mountain': [11, 43],
  Loomis: [5, 30, 48],
  'Loon Lake': [48],
  Looneyville: [50],
  Loop: [44],
  'Loose Creek': [25],
  Lopeno: [44],
  Lopez: [39],
  'Lopez Island': [48],
  Lorado: [50],
  Lorain: [36, -36],
  Loraine: [15, 44],
  Lorane: [38],
  Loranger: [19],
  'Lords Valley': [39],
  Lordsburg: [33],
  'Lore City': [36],
  Loreauville: [19],
  Lorena: [44],
  Lorentz: [50],
  Lorenzo: [44],
  Loretto: [18, 23, 24, 39, 43, 46],
  Lorida: [10],
  Lorimor: [13],
  Loring: [27],
  Loris: [41],
  Lorman: [26],
  Lorraine: [17, 35],
  Lorton: [30, 46],
  'Los Alamitos': [5],
  'Los Alamos': [5, 33, -33],
  'Los Altos': [5],
  'Los Angeles': [5, -5],
  'Los Banos': [5],
  'Los Ebanos': [44],
  'Los Fresnos': [44],
  'Los Gatos': [5],
  'Los Indios': [44],
  'Los Lunas': [33],
  'Los Molinos': [5],
  'Los Ojos': [33],
  'Los Olivos': [5],
  'Los Osos': [5],
  Losantville: [16],
  'Lost City': [50],
  'Lost Creek': [18, 39, 50],
  'Lost Hills': [5],
  'Lost Nation': [13],
  'Lost Springs': [17, 51],
  Lostant: [15],
  Lostine: [38],
  Lothair: [27],
  Lothian: [21],
  Lott: [44],
  Lottie: [19],
  Lottsburg: [46],
  Lotus: [5],
  Louann: [3],
  Loudon: [31, 43, -43],
  Loudonville: [36],
  Loudoun: [-46],
  Loughman: [10],
  Louin: [26],
  Louisa: [-13, 18, 46, -46],
  Louisburg: [17, 25, 28],
  Louise: [26, 44],
  Louisiana: [25],
  Louisville: [2, 6, 11, 15, 18, 26, 30, 36, 43],
  Loup: [-30],
  'Loup City': [30],
  Louvale: [11],
  Louviers: [6],
  Love: [-37],
  Lovejoy: [11, 15],
  Lovelaceville: [18],
  Lovelady: [44],
  Loveland: [6, 36, 37],
  Lovell: [22, 51],
  Lovelock: [34],
  Lovely: [18],
  'Loves Park': [15],
  Lovettsville: [46],
  Loveville: [21],
  Lovilia: [13],
  Loving: [33, 44, -44],
  Lovingston: [46],
  Lovington: [15, 33],
  'Low Moor': [13, 46],
  Lowake: [44],
  Lowber: [39],
  Lowden: [13],
  Lowder: [15],
  Lowell: [3, 10, 16, 20, 23, 28, 36, 38, 47, 49],
  Lowellville: [36],
  'Lower Brule': [42],
  'Lower Kalskag': [1],
  'Lower Lake': [5],
  'Lower Makefield Township': [39],
  'Lower Peach Tree': [2],
  'Lower Salem': [36],
  'Lower Waterford': [47],
  Lowes: [18],
  Lowgap: [28],
  Lowland: [28, 43],
  Lowman: [14, 35],
  Lowmansville: [18],
  Lowndes: [-2, -11, 25, -26],
  Lowndesboro: [2],
  Lowndesville: [41],
  Lowpoint: [15],
  Lowry: [24, 46],
  'Lowry City': [25],
  Lowville: [35],
  Loxahatchee: [10],
  Loxley: [2],
  Loyal: [37, 49],
  Loyalhanna: [39],
  Loyall: [18],
  Loyalton: [5],
  Loysburg: [39],
  Loysville: [39],
  Lozano: [44],
  'Lu Verne': [13],
  Luana: [13],
  Lubbock: [44, -44],
  Lubec: [22],
  Lublin: [49],
  Lucama: [28],
  Lucan: [24],
  Lucas: [13, -13, 17, 18, 36, -36],
  Lucasville: [36],
  Luce: [-23],
  Lucedale: [26],
  Lucerne: [5, 6, 16, 25],
  'Lucerne Valley': [5],
  Lucernemines: [39],
  Lucien: [37],
  Lucile: [14],
  Lucinda: [39],
  Luck: [49],
  Luckey: [36],
  Ludell: [17],
  Ludington: [23],
  Ludlow: [5, 15, 20, 25, 26, 39, 42, 47],
  'Ludlow Falls': [36],
  Ludowici: [11],
  Luebbering: [25],
  Lueders: [44],
  Lufkin: [44],
  Lugoff: [41],
  Lukachukai: [4],
  Luke: [21],
  'Luke Air Force Base': [4],
  Lukeville: [4],
  Lula: [11, 26],
  Luling: [19, 44],
  Lulu: [10],
  'Lumber Bridge': [28],
  'Lumber City': [11],
  Lumberport: [50],
  Lumberton: [26, 28, 32, 44],
  Lumberville: [39],
  'Lummi Island': [48],
  Lumpkin: [11, -11],
  Luna: [33, -33],
  'Luna Pier': [23],
  Lund: [34],
  Lunenburg: [20, 46, -46, 47],
  Luning: [34],
  Lupton: [4, 23],
  'Lupton City': [43],
  Luray: [17, 25, 41, 43, 46],
  Lurgan: [39],
  Lusby: [21],
  Lusk: [51],
  Lutcher: [19],
  Luther: [13, 23, 37],
  Luthersburg: [39],
  Luthersville: [11],
  Lutherville: [21],
  'Lutherville Timonium': [21],
  Lutsen: [24],
  Luttrell: [43],
  Lutts: [43],
  Lutz: [10],
  Luverne: [2, 24, 29],
  Luxemburg: [13, 49],
  Luxor: [39],
  Luxora: [3],
  Luzerne: [13, 23, 39, -39],
  Lyburn: [50],
  Lycoming: [35, -39],
  Lydia: [19, 41],
  Lyerly: [11],
  Lyford: [44],
  Lykens: [39],
  Lyle: [24, 48],
  Lyles: [43],
  Lyman: [30, 41, -42, 45, 48, 51],
  Lyme: [31],
  'Lyme Center': [31],
  Lynbrook: [35],
  Lynch: [18, 30],
  'Lynch Station': [46],
  Lynchburg: [25, 36, 41, 43, 46],
  Lynco: [50],
  Lynd: [24],
  Lyndeborough: [31],
  Lyndell: [39],
  Lynden: [48],
  Lyndhurst: [32, 46],
  Lyndon: [15, 17, 47],
  'Lyndon Center': [47],
  'Lyndon Station': [49],
  Lyndonville: [35, 47],
  Lyndora: [39],
  Lynn: [2, 3, 16, 20, 28, -44],
  'Lynn Center': [15],
  'Lynn Haven': [10],
  Lynndyl: [45],
  Lynnfield: [20],
  Lynnville: [13, 16, 18, 43],
  Lynnwood: [48],
  Lynwood: [5],
  Lynx: [36],
  Lyon: [-13, -17, -18, -24, 26, -34],
  'Lyon Mountain': [35],
  'Lyon Station': [39],
  Lyons: [6, 11, 15, 16, 17, 23, 30, 32, 35, 36, 38, 42, 44, 49],
  'Lyons Falls': [35],
  Lysite: [51],
  Lytle: [44],
  'Lytle Creek': [5],
  Lytton: [13],
  Mabank: [44],
  Mabel: [24],
  Mabelvale: [3],
  Maben: [26, 50],
  Mabie: [50],
  Mableton: [11],
  Mabscott: [50],
  Mabton: [48],
  MacArthur: [50],
  Macatawa: [23],
  Macclenny: [10],
  Macclesfield: [28],
  Macdoel: [5],
  Macdona: [44],
  Macedon: [35],
  Macedonia: [13, 15, 36],
  Maceo: [18],
  Macfarlan: [50],
  'Machesney Park': [15],
  Machias: [22, 35],
  Machiasport: [22],
  Machipongo: [46],
  Mack: [6],
  Mackay: [14],
  Mackey: [16],
  Mackeyville: [39],
  Mackinac: [-23],
  'Mackinac Island': [23],
  Mackinaw: [15],
  'Mackinaw City': [23],
  'Macks Creek': [25],
  'Macks Inn': [14],
  Macksburg: [13, 36],
  Macksville: [17],
  Mackville: [18],
  Macomb: [15, 23, -23, 25, 37],
  Macon: [-2, 11, -11, 15, -15, 25, -25, 26, 28, -28, 43, -43],
  Macoupin: [-15],
  Macungie: [39],
  Macy: [16, 30],
  'Mad River': [5],
  Madawaska: [22],
  Madbury: [31],
  Madden: [26],
  Maddock: [29],
  Madelia: [24],
  Madeline: [5],
  Madera: [5, -5, 39],
  Madill: [37],
  Madison: [2, -2, 3, -3, 5, 7, 10, -10, 11, -11, -13, -14, 15, -15, 16, -16, 17, -18, -19, 21, 22, 24, 25, -25, 26, -26, -27, 28, -28, 30, -30, 31, 32, 35, -35, 36, -36, 39, 42, 43, -43, -44, 46, -46, 49, 50],
  'Madison Heights': [23, 46],
  'Madison Lake': [24],
  Madisonburg: [39],
  Madisonville: [18, 19, 43, 44],
  Madras: [38],
  Madrid: [13, 30, 35],
  Maeystown: [15],
  Magalia: [5],
  Magazine: [3],
  Magdalena: [33],
  Magee: [26],
  'Maggie Valley': [28],
  Magna: [45],
  Magness: [3],
  Magnet: [30],
  'Magnetic Springs': [36],
  Magnolia: [2, 3, 9, 13, 15, 18, 24, 26, 28, 32, 36, 44],
  'Magnolia Springs': [2],
  Magoffin: [-18],
  Mahaffey: [39],
  'Mahanoy City': [39],
  'Mahanoy Plane': [39],
  Mahaska: [-13, 17],
  Mahnomen: [24, -24],
  Mahomet: [15],
  Mahoning: [-36],
  Mahopac: [35],
  'Mahopac Falls': [35],
  Mahwah: [32],
  Maida: [29],
  Maiden: [28],
  'Maiden Rock': [49],
  Maidens: [46],
  Maidsville: [50],
  Maine: [35],
  Mainesburg: [39],
  Maineville: [36],
  Mainland: [39],
  Maitland: [10, 25],
  Maize: [17],
  Majestic: [18],
  Major: [-37],
  Makanda: [15],
  Makawao: [12],
  Makaweli: [12],
  Makinen: [24],
  Makoti: [29],
  Malabar: [10],
  'Malad City': [14],
  Malaga: [32, 33, 36, 48],
  Malakoff: [44],
  Malcolm: [2, 30],
  Malcom: [13],
  Malden: [15, 20, 25, 48],
  'Malden Bridge': [35],
  'Malden On Hudson': [35],
  Malheur: [-38],
  Malibu: [5],
  Malin: [38],
  Malinta: [36],
  Maljamar: [33],
  Mallard: [13],
  Mallie: [18],
  Mallory: [35, 50],
  Malmo: [30],
  'Malmstrom Afb': [27],
  Malo: [48],
  Malone: [10, 18, 35, 44, 48, 49],
  Malott: [48],
  Malta: [14, 15, 27, 36],
  'Malta Bend': [25],
  Malvern: [2, 3, 13, 36, 39],
  Malverne: [35],
  Mamaroneck: [35],
  Mamers: [28],
  Mammoth: [4, 39, 50],
  'Mammoth Cave': [18],
  'Mammoth Lakes': [5],
  'Mammoth Spring': [3],
  Mamou: [19],
  Man: [50],
  Manahawkin: [32],
  'Manakin Sabot': [46],
  'Manalapan ': [32],
  Manasquan: [32],
  Manassa: [6],
  Manassas: [11, 46],
  'Manassas Park': [46],
  Manatee: [-10],
  Manawa: [49],
  Mancelona: [23],
  Manchaca: [44],
  Manchaug: [20],
  Manchester: [5, 7, 11, 13, 15, 18, 20, 21, 22, 23, 31, 35, 36, 37, 39, 43, 47, 48],
  'Manchester Center': [47],
  'Manchester Township': [32],
  Mancos: [6],
  Mandan: [29],
  Mandaree: [29],
  Manderson: [42, 51],
  Mandeville: [19],
  Mangham: [19],
  Mango: [10],
  Mangum: [37],
  Manhasset: [35],
  Manhattan: [15, 17, 27, 34],
  'Manhattan Beach': [5],
  Manheim: [39],
  Manila: [3, 45],
  Manilla: [13, 16],
  Manistee: [23, -23],
  Manistique: [23],
  Manito: [15],
  Manitou: [18, 37],
  'Manitou Beach': [23],
  'Manitou Springs': [6],
  'Manitowish Waters': [49],
  Manitowoc: [49, -49],
  Mankato: [17, 24],
  Manley: [30],
  'Manley Hot Springs': [1],
  Manlius: [15, 35],
  Manly: [13],
  Mannboro: [46],
  Mannford: [37],
  Manning: [13, 29, 38, 41],
  Mannington: [50],
  'Manns Choice': [39],
  'Manns Harbor': [28],
  Mannsville: [18, 35, 37],
  Manokin: [21],
  Manokotak: [1],
  Manomet: [20],
  Manor: [11, 39, 44],
  Manorville: [35, 39],
  Manquin: [46],
  Mansfield: [3, 7, 11, 15, 19, 20, 25, 36, 39, 42, 43, 44, 48],
  'Mansfield Center': [7],
  'Mansfield Depot': [7],
  Manson: [13, 28, 48],
  Mansura: [19],
  Mantachie: [26],
  Mantador: [29],
  Manteca: [5],
  Mantee: [26],
  Manteno: [15],
  Manteo: [28],
  Manter: [17],
  Manti: [45],
  Mantoloking: [32],
  Manton: [5, 23],
  Mantorville: [24],
  Mantua: [32, 36, 45],
  Manvel: [29, 44],
  Manville: [32, 40, 51],
  Many: [19],
  'Many Farms': [4],
  Manzanita: [38],
  Manzanola: [6],
  Mapaville: [25],
  Maple: [28, 44, 49],
  'Maple City': [17, 23],
  'Maple Falls': [48],
  'Maple Glen': [39],
  'Maple Grove': [24],
  'Maple Heights': [36],
  'Maple Hill': [17, 28],
  'Maple Lake': [24],
  'Maple Mount': [18],
  'Maple Park': [15],
  'Maple Plain': [24],
  'Maple Rapids': [23],
  'Maple Shade': [32],
  'Maple Springs': [35],
  'Maple Valley': [48],
  'Maple View': [35],
  Maplecrest: [35],
  Maplesville: [2],
  Mapleton: [13, 15, 17, 22, 24, 29, 38, 45],
  'Mapleton Depot': [39],
  Mapleville: [40],
  Maplewood: [32, 36, 49],
  Mappsville: [46],
  Maquoketa: [13],
  Maquon: [15],
  'Mar Lin': [39],
  Maramec: [37],
  Marana: [4],
  Marathon: [10, 13, 35, 44, 49, -49],
  'Marathon Shores': [10],
  Marble: [24, 28, 39],
  'Marble Canyon': [4],
  'Marble City': [37],
  'Marble Falls': [3, 44],
  'Marble Hill': [11, 25],
  'Marble Rock': [13],
  Marblehead: [20],
  Marblemount: [48],
  Marbury: [2, 21],
  Marceline: [25],
  Marcell: [24],
  Marcella: [3],
  Marcellus: [23, 35],
  'March Air Reserve Base': [5],
  Marchand: [39],
  'Marco Island': [10],
  Marcola: [38],
  Marcus: [13, 48],
  'Marcus Hook': [39],
  Marcy: [35],
  'Mardela Springs': [21],
  Marengo: [-2, 13, 15, 16, 36, 49],
  Marenisco: [23],
  Marfa: [44],
  Margaret: [2],
  Margarettsville: [28],
  Margaretville: [35],
  Margate: [10],
  'Margate City': [32],
  Margie: [24],
  'Maria Stein': [36],
  'Mariah Hill': [16],
  Marianna: [3, 10, 39, 50],
  Maribel: [49],
  Maricopa: [4, -4, 5],
  Marienthal: [17],
  Marienville: [39],
  Maries: [-25],
  Marietta: [11, 15, 24, 26, 28, 35, 36, 37, 39, 41, 44],
  Marilla: [35],
  Marin: [-5],
  Marina: [5],
  'Marina Del Rey': [5],
  Marine: [15],
  'Marine City': [23],
  'Marine On Saint Croix': [24],
  Marinette: [49, -49],
  Maringouin: [19],
  Marion: [2, -2, 3, -3, 7, -10, -11, 13, -13, 15, -15, 16, -16, 17, -17, 18, -18, 19, 20, 23, -25, 26, -26, 27, 28, 29, 35, 36, -36, -38, 39, 41, -41, 42, -43, 44, -44, 46, 49, -50],
  'Marion Center': [39],
  'Marion Heights': [39],
  'Marion Junction': [2],
  'Marion Station': [21],
  Marionville: [25, 46],
  Mariposa: [5, -5],
  Marissa: [15],
  Mark: [15],
  'Mark Center': [36],
  'Marked Tree': [3],
  Markesan: [49],
  Markham: [15, 44, 46],
  Markle: [16],
  Markleeville: [5],
  Markleton: [39],
  Markleville: [16],
  Markleysburg: [39],
  Marks: [26],
  Marksville: [19],
  Marland: [37],
  Marlboro: [32, 35, -41, 47],
  Marlborough: [7, 20, 31],
  Marlette: [23],
  Marlin: [44, 48],
  Marlinton: [50],
  Marlow: [31, 37],
  Marlton: [32],
  Marmaduke: [3],
  Marmarth: [29],
  Marmora: [32],
  Marne: [13, 23],
  Maroa: [15],
  Marquand: [25],
  Marquette: [13, 17, 23, -23, 30, 49, -49],
  Marquez: [44],
  Marrero: [19],
  Marriottsville: [21],
  Marrowbone: [18],
  Mars: [39],
  'Mars Hill': [22, 28],
  Marseilles: [15],
  Marshall: [1, -2, 3, 5, -13, 15, -15, 16, -16, -17, -18, 23, 24, -24, 25, -26, 28, 29, 37, -37, -42, -43, 44, 46, 48, 49, -50],
  Marshallberg: [28],
  'Marshalls Creek': [39],
  Marshalltown: [13],
  Marshallville: [11, 36],
  'Marshes Siding': [18],
  Marshfield: [20, 25, 47, 49],
  'Marshfield Hills': [20],
  Marshville: [28],
  Marsing: [14],
  Marsland: [30],
  Marsteller: [39],
  Marston: [25, 28],
  'Marstons Mills': [20],
  Mart: [44],
  Martel: [36],
  Martell: [5, 30],
  Martelle: [13],
  Martensdale: [13],
  Martha: [18, 37],
  Marthasville: [25],
  Marthaville: [19],
  Martin: [-10, 11, -16, 18, -18, 23, -24, -28, 29, 36, 39, 41, 42, 43, -44],
  'Martin City': [27],
  Martindale: [39, 44],
  Martinez: [5, 11],
  'Martins Creek': [39],
  'Martins Ferry': [36],
  Martinsburg: [13, 25, 35, 36, 39, 50],
  Martinsdale: [27],
  Martinsville: [15, 16, 25, 32, 36, 44, 46],
  Martinton: [15],
  Martville: [35],
  Marty: [42],
  Marvel: [6],
  Marvell: [3],
  Marvin: [42],
  'Mary Alice': [18],
  'Mary D': [39],
  'Mary Esther': [10],
  Marydel: [9, 21],
  Maryknoll: [35],
  Maryland: [35],
  'Maryland Heights': [25],
  'Maryland Line': [21],
  Marylhurst: [38],
  Maryneal: [44],
  Marysvale: [45],
  Marysville: [5, 16, 17, 23, 27, 36, 39, 48],
  Maryus: [46],
  Maryville: [15, 25, 43],
  Mascot: [43, 46],
  Mascotte: [10],
  Mascoutah: [15],
  Mashantucket: [7],
  Mashpee: [20],
  Maskell: [30],
  Mason: [15, -15, 18, -18, 23, -23, 36, 43, 44, -44, -48, 49, 50, -50],
  'Mason City': [13, 15, 30],
  'Masonic Home': [18],
  Masontown: [39, 50],
  Masonville: [6, 13, 35],
  Maspeth: [35],
  'Mass City': [23],
  Massac: [-15],
  Massapequa: [35],
  'Massapequa Park': [35],
  Massena: [13, 35],
  Massey: [21],
  Massillon: [36],
  Masterson: [44],
  Mastic: [35],
  'Mastic Beach': [35],
  Masury: [36],
  Matador: [44],
  Matagorda: [44, -44],
  Matamoras: [39],
  'Matanuska-Susitna': [-1],
  Matawan: [32],
  Matewan: [50],
  'Matfield Green': [17],
  Matheny: [50],
  Mather: [5, 39, 49],
  Matherville: [15],
  Matheson: [6],
  Mathews: [2, 19, 46, -46],
  Mathias: [50],
  Mathis: [44],
  Mathiston: [26],
  Matinicus: [22],
  Matlock: [13, 48],
  Matoaka: [50],
  Mattapan: [20],
  Mattapoisett: [20],
  Mattaponi: [46],
  Mattawa: [48],
  Mattawamkeag: [22],
  Mattawan: [23],
  Mattawana: [39],
  Matteson: [15],
  Matthews: [11, 16, 25, 28],
  Mattituck: [35],
  Mattoon: [15, 49],
  Mauckport: [16],
  Maud: [37, 44],
  Maugansville: [21],
  Maui: [-12],
  Mauk: [11],
  Mauldin: [41],
  Maumee: [36],
  Maumelle: [3],
  Maunaloa: [12],
  Maunie: [15],
  Maupin: [38],
  Maurepas: [19],
  Maurertown: [46],
  Maurice: [13, 19],
  Mauricetown: [32],
  Mauriceville: [44],
  Maury: [28, -43],
  'Maury City': [43],
  Mauston: [49],
  Maverick: [-44],
  Mavisdale: [46],
  Max: [24, 29, 30],
  'Max Meadows': [46],
  Maxatawny: [39],
  Maxbass: [29],
  Maxeys: [11],
  Maxie: [46],
  Maximo: [36],
  Maxton: [28],
  Maxwell: [5, 13, 16, 30, 33, 44],
  Maxwelton: [50],
  May: [14, 37, 44],
  Maybee: [23],
  Maybell: [6],
  Maybeury: [50],
  Maybrook: [35],
  Maydelle: [44],
  Mayer: [4, 24],
  Mayersville: [26],
  Mayes: [-37],
  Mayesville: [41],
  Mayetta: [17],
  Mayfield: [17, 18, 23, 35, 45],
  Mayflower: [3],
  Mayhew: [26],
  Mayhill: [33],
  Mayking: [18],
  Maynard: [3, 13, 20, 24, 36],
  Maynardville: [43],
  Mayo: [10, 21, 41],
  Mayodan: [28],
  Maypearl: [44],
  Mayport: [39],
  Mays: [16],
  'Mays Landing': [32],
  Maysel: [50],
  Mayslick: [18],
  Maysville: [3, 11, 18, 25, 28, 37, 50],
  Maytown: [39],
  Mayview: [25],
  Mayville: [23, 29, 35, 49],
  Maywood: [5, 15, 25, 30, 32],
  Mazama: [48],
  Mazeppa: [24],
  Mazie: [18],
  Mazomanie: [49],
  Mazon: [15],
  'Mc Dowell': [-50],
  McAdams: [26],
  McAdenville: [28],
  McAfee: [32],
  McAlisterville: [39],
  McAllister: [27],
  McAlpin: [10],
  McAndrews: [18],
  McArthur: [36],
  McBain: [23],
  McBee: [41],
  McBride: [25],
  'McCall Creek': [26],
  McCalla: [2],
  McCallsburg: [13],
  McCamey: [44],
  McCarley: [26],
  McCarr: [18],
  McCaskill: [3],
  McCaulley: [44],
  McCausland: [13],
  McCaysville: [11],
  McClain: [-37],
  McClave: [6],
  McClelland: [13],
  McClellandtown: [39],
  McClellanville: [41],
  McClure: [15, 36, 39],
  McColl: [41],
  McComb: [36],
  McCone: [-27],
  McConnell: [15],
  McConnells: [41],
  McConnellsburg: [39],
  McConnellstown: [39],
  McConnellsville: [35],
  McCook: [30, -42],
  McCool: [26],
  'McCool Junction': [30],
  McCormick: [41, -41],
  McCoy: [6],
  McCracken: [17, -18],
  McCreary: [-18],
  McCrory: [3],
  McCulloch: [-44],
  McCune: [17],
  McCurtain: [-37],
  McCutchenville: [36],
  McDade: [44],
  McDaniels: [18],
  McDavid: [10],
  McDermitt: [34],
  McDermott: [36],
  McDonald: [17, -25, 36, 39, 43],
  McDonough: [-15, 35],
  McDougal: [3],
  McDowell: [18, -28],
  McDuffie: [-11],
  McElhattan: [39],
  McEwen: [43],
  McEwensville: [39],
  McFall: [25],
  McFarlan: [28],
  McFarland: [5, 17],
  McGee: [25],
  McGehee: [3],
  McGill: [34],
  McGirk: [25],
  McGrady: [28],
  McGrann: [39],
  McGrath: [1, 24],
  McGraw: [35],
  McGraws: [50],
  McGregor: [13, 44],
  McGuffey: [36],
  McHenry: [-15, 18, 21, 26, -29],
  'McIndoe Falls': [47],
  McIntire: [13],
  McIntosh: [2, 10, -11, -29, -37, 42],
  McIntyre: [11, 39],
  McKean: [39, -39],
  McKee: [18],
  'McKees Rocks': [39],
  McKenzie: [2, -29, 43],
  McKinley: [-33],
  McKinney: [18],
  McKinnon: [51],
  McKittrick: [5],
  McKnightstown: [39],
  McLain: [26],
  McLaughlin: [42],
  McLean: [15, -15, -18, -29, 35],
  McLeansboro: [15],
  McLeansville: [28],
  McLemoresville: [43],
  McLennan: [-44],
  McLeod: [-24, 27, 44],
  McLouth: [17],
  McMillan: [23],
  McMinn: [-43],
  McMullen: [-44],
  McMurray: [39],
  McNabb: [15],
  McNairy: [-43],
  McNaughton: [49],
  McNeal: [4],
  McNeil: [3, 44],
  McNeill: [26],
  McPherson: [-17, -30, -42],
  McQuady: [18],
  McQueeney: [44],
  McRae: [3, 11],
  'McRae Helena': [11],
  McRoberts: [18],
  McShan: [2],
  McSherrystown: [39],
  McVeytown: [39],
  McWilliams: [2],
  Mcadoo: [39, 44],
  Mcalester: [37],
  Mcalister: [33],
  Mcallen: [44],
  Mcarthur: [5],
  'Mcbh Kaneohe Bay': [12],
  Mcbrides: [23],
  Mccall: [14],
  Mccammon: [14],
  'Mcchord Afb': [48],
  Mccleary: [48],
  Mcclellan: [5],
  Mccloud: [5],
  Mcclure: [46],
  Mcclusky: [29],
  Mccomb: [26],
  'Mcconnell Afb': [17],
  Mcconnelsville: [36],
  Mccordsville: [16],
  Mccoy: [46],
  Mccurtain: [37],
  'Mccutcheon Field': [28],
  Mcdaniel: [21],
  Mcdonald: [33],
  Mcdonough: [11],
  Mcdowell: [46],
  Mcfaddin: [44],
  Mcfarland: [49],
  Mcgaheysville: [46],
  Mcgregor: [24, 29],
  Mcgrew: [30],
  Mchenry: [15, 29],
  Mcintosh: [24, 33],
  Mckeesport: [39],
  Mckenna: [48],
  Mckenney: [46],
  Mckinleyville: [5],
  Mckinney: [44],
  Mclean: [30, 44, 46],
  Mcleod: [29],
  Mcloud: [37],
  Mcmechen: [50],
  Mcminnville: [38, 43],
  Mcnary: [4],
  Mcpherson: [17],
  Mcville: [29],
  Meacham: [38],
  Mead: [6, 30, 37, 48],
  Meade: [17, -17, -18, -42],
  Meadow: [42, 44, 45],
  'Meadow Bridge': [50],
  'Meadow Creek': [50],
  'Meadow Grove': [30],
  'Meadow Lands': [39],
  'Meadow Valley': [5],
  'Meadow Vista': [5],
  Meadowbrook: [50],
  Meadowlands: [24],
  'Meadows Of Dan': [46],
  Meadowview: [46],
  Meadview: [4],
  Meadville: [25, 26, 39],
  Meagher: [-27],
  Meally: [18],
  Means: [18],
  Meansville: [11],
  Mears: [23, 46],
  Mebane: [28],
  Mecca: [5, 16],
  'Mechanic Falls': [22],
  Mechanicsburg: [15, 36, 39],
  Mechanicstown: [36],
  Mechanicsville: [13, 21, 39, 46],
  Mechanicville: [35],
  Mecklenburg: [-28, 35, -46],
  Mecosta: [23, -23],
  Medanales: [33],
  Medaryville: [16],
  Meddybemps: [22],
  Medfield: [20],
  Medford: [20, 24, 32, 35, 37, 38, 49],
  Media: [15, 39],
  Mediapolis: [13],
  'Medical Lake': [48],
  'Medicine Bow': [51],
  'Medicine Lake': [27],
  'Medicine Lodge': [17],
  'Medicine Park': [37],
  Medimont: [14],
  Medina: [29, 35, 36, -36, 43, 44, -44, 48],
  Medinah: [15],
  Medon: [43],
  Medora: [15, 16, 29],
  Medusa: [35],
  Medway: [20, 22, 36],
  Meeker: [6, -24, 37],
  Meers: [37],
  Meeteetse: [51],
  Megargel: [2, 44],
  Mehama: [38],
  Meherrin: [46],
  Mehoopany: [39],
  Meigs: [11, -36, -43],
  Mekinock: [29],
  Mekoryuk: [1],
  Melba: [14],
  Melber: [18],
  Melbeta: [30],
  Melbourne: [3, 10, 13, 18],
  'Melbourne Beach': [10],
  'Melcher Dallas': [13],
  Melcroft: [39],
  Meldrim: [11],
  Melfa: [46],
  Melissa: [44],
  Mellen: [49],
  Mellenville: [35],
  Mellette: [42, -42],
  Mellott: [16],
  Mellwood: [3],
  Melmore: [36],
  Melrose: [10, 13, 19, 20, 24, 27, 33, 35, 36, 49],
  'Melrose Park': [15],
  Melrude: [24],
  Melstone: [27],
  Melvern: [17],
  Melville: [19, 27, 35],
  Melvin: [2, 13, 15, 18, 23, 44],
  'Melvin Village': [31],
  Melvindale: [23],
  Memphis: [16, 23, 25, 26, 30, 35, 43, 44],
  'Memphis Stc': [26],
  Mena: [3],
  Menahga: [24],
  Menan: [14],
  Menard: [15, -15, 44, -44],
  Menasha: [49],
  Mendenhall: [26, 39],
  Mendham: [32],
  Mendocino: [5, -5],
  Mendon: [15, 20, 23, 25, 35, 36, 45],
  Mendota: [5, 15, 24, 46],
  Menemsha: [20],
  Menifee: [3, 5, -18],
  Menlo: [11, 13, 48],
  'Menlo Park': [5],
  Menno: [42],
  Meno: [37],
  Menoken: [29],
  Menominee: [23, -23, -49],
  'Menomonee Falls': [49],
  Menomonie: [49],
  Mentcle: [39],
  Mentmore: [33],
  Mentone: [2, 5, 16, 44],
  Mentor: [24, 36],
  Mequon: [49],
  'Mer Rouge': [19],
  Meraux: [19],
  Merced: [5, -5],
  Mercedes: [44],
  Mercer: [-15, -18, 25, -25, 29, -29, -32, -36, 39, -39, 43, 49, -50],
  'Mercer Island': [48],
  Mercersburg: [39],
  Merchantville: [32],
  Mercury: [34],
  Meredith: [6, 31],
  Meredithville: [46],
  Meredosia: [15],
  Mereta: [44],
  Meridale: [35],
  Meriden: [7, 13, 17, 31, 51],
  Meridian: [5, 11, 14, 26, 35, 37, 44],
  Meridianville: [2],
  Merigold: [26],
  Merino: [6],
  'Merion Station': [39],
  Merit: [44],
  Meriwether: [-11],
  Merkel: [44],
  Merlin: [38],
  Mermentau: [19],
  Merna: [15, 30],
  Merom: [16],
  Merrick: [-30, 35],
  Merrifield: [24, 46],
  Merrill: [13, 23, 38, 49],
  Merrillan: [49],
  Merrillville: [16],
  Merrimac: [20, 49],
  Merrimack: [31, -31],
  Merriman: [30],
  Merritt: [23, 28],
  'Merritt Island': [10],
  Merrittstown: [39],
  'Merry Hill': [28],
  'Merry Point': [46],
  Merryville: [19],
  Mershon: [11],
  Mertens: [44],
  Merton: [49],
  Mertzon: [44],
  Mertztown: [39],
  Mesa: [4, 6, -6, 14, 48],
  'Mesa Verde National Park': [6],
  Mescalero: [33],
  Mesena: [11],
  Meservey: [13],
  Meshoppen: [39],
  Mesick: [23],
  Mesilla: [33],
  'Mesilla Park': [33],
  Mesopotamia: [36],
  Mesquite: [33, 34, 44],
  Meta: [25],
  Metairie: [19],
  Metaline: [48],
  'Metaline Falls': [48],
  Metamora: [15, 16, 23, 36],
  Metcalf: [15],
  Metcalfe: [-18, 26],
  Methow: [48],
  Methuen: [20],
  Metlakatla: [1],
  Metropolis: [15],
  Metter: [11],
  Metuchen: [32],
  Metz: [25, 50],
  Mexia: [2, 44],
  'Mexican Hat': [45],
  'Mexican Springs': [33],
  Mexico: [16, 22, 25, 35, 39],
  'Mexico Beach': [10],
  'Meyers Chuck': [1],
  Meyersdale: [39],
  Meyersville: [44],
  'Mi Wuk Village': [5],
  Miami: [4, 10, 16, -16, -17, 25, 33, -36, 37, 44, 50],
  'Miami Beach': [10],
  'Miami Gardens': [10],
  'Miami Lakes': [10],
  'Miami Shores': [10],
  'Miami-Dade': [-10],
  Miamisburg: [36],
  Miamitown: [36],
  Miamiville: [36],
  Mica: [48],
  Micanopy: [10],
  Micaville: [28],
  Michael: [15],
  Michie: [43],
  Michigamme: [23],
  Michigan: [29],
  'Michigan Center': [23],
  'Michigan City': [16, 26],
  Michigantown: [16],
  Mickleton: [32],
  Mico: [44],
  Micro: [28],
  'Mid Florida': [10],
  'Middle Amana': [13],
  'Middle Bass': [36],
  'Middle Brook': [25],
  'Middle Falls': [35],
  'Middle Granville': [35],
  'Middle Grove': [35],
  'Middle Haddam': [7],
  'Middle Island': [35],
  'Middle Point': [36],
  'Middle River': [21, 24],
  'Middle Village': [35],
  Middleboro: [20],
  Middlebourne: [50],
  Middlebranch: [36],
  Middlebrook: [46],
  Middleburg: [10, 18, 28, 36, 39, 46],
  Middleburgh: [35],
  Middlebury: [7, 16, 47],
  'Middlebury Center': [39],
  Middlefield: [7, 20, 36],
  Middleport: [35, 36, 39],
  Middlesboro: [18],
  Middlesex: [-7, -20, 28, 32, -32, 35, -46],
  Middleton: [14, 20, 23, 43, 49],
  Middletown: [5, 7, 9, 13, 15, 16, 21, 25, 32, 35, 36, 39, 40, 46],
  'Middletown Springs': [47],
  Middleville: [23, 32, 35],
  Midfield: [44],
  Midkiff: [44, 50],
  Midland: [3, 11, 16, 21, 23, -23, 28, 36, 38, 39, 42, 44, -44, 46],
  'Midland City': [2],
  'Midland Park': [32],
  Midlothian: [15, 21, 44, 46],
  Midnight: [26],
  Midpines: [5],
  Midvale: [14, 36, 45],
  Midville: [11],
  Midway: [2, 3, 10, 11, 18, 39, 43, 44, 45, 50],
  'Midway City': [5],
  'Midway Park': [28],
  Midwest: [51],
  Mifflin: [39, -39],
  Mifflinburg: [39],
  Mifflintown: [39],
  Mifflinville: [39],
  Mikado: [23],
  Mikana: [49],
  Mikkalo: [38],
  Milaca: [24],
  Milam: [44, -44, 50],
  Milan: [11, 15, 16, 17, 23, 24, 25, 31, 33, 36, 39, 43],
  Milano: [44],
  Milanville: [39],
  Milbank: [42],
  Milbridge: [22],
  Milburn: [18, 37],
  Mildred: [27, 39],
  Miles: [13, 44],
  'Miles City': [27],
  Milesburg: [39],
  Milesville: [42],
  Miley: [41],
  Milfay: [37],
  Milford: [5, 7, 9, 13, 15, 16, 17, 18, 20, 22, 23, 25, 30, 31, 32, 35, 36, 39, 44, 45, 46],
  'Milford Center': [36],
  'Milford Square': [39],
  Mililani: [12],
  'Mill City': [38],
  'Mill Creek': [5, 16, 37, 39, 48, 50],
  'Mill Hall': [39],
  'Mill Neck': [35],
  'Mill River': [20],
  'Mill Run': [39],
  'Mill Shoals': [15],
  'Mill Spring': [25, 28],
  'Mill Valley': [5],
  'Mill Village': [39],
  Milladore: [49],
  Millard: [-45],
  Millboro: [46],
  Millbrae: [5],
  Millbrook: [2, 15, 35],
  Millburn: [32],
  Millbury: [20, 36],
  Millcreek: [15, 45],
  Milldale: [7],
  'Mille Lacs': [-24],
  Milledgeville: [11, 15, 36, 43],
  Millen: [11],
  Miller: [-3, -11, 25, -25, 30, 42],
  'Miller City': [15, 36],
  'Miller Place': [35],
  'Millers Creek': [28],
  'Millers Falls': [20],
  'Millers Tavern': [46],
  Millersburg: [13, 16, 18, 23, 36, 39],
  Millersport: [36],
  Millerstown: [39],
  Millersview: [44],
  Millersville: [21, 25, 39],
  Millerton: [13, 35, 37, 39],
  Millerville: [2],
  Millfield: [36],
  Millheim: [39],
  Millican: [44],
  Milligan: [10, 30],
  'Milligan College': [43],
  Milliken: [6],
  Millington: [15, 21, 23, 32, 43],
  Millinocket: [22],
  Millis: [20],
  Millmont: [39],
  Millport: [2, 35],
  Millrift: [39],
  Millry: [2],
  Mills: [-13, 30, 33, 39, -44, 51],
  'Mills River': [28],
  Millsap: [44],
  Millsboro: [9, 39],
  Millstadt: [15],
  Millston: [49],
  Millstone: [18, 50],
  'Millstone Township': [32],
  Milltown: [16, 27, 32, 49],
  Millville: [5, 9, 20, 24, 32, 39, 45, 50],
  Millwood: [11, 18, 35, 46, 50],
  Milmay: [32],
  Milmine: [15],
  Milner: [11],
  Milnesand: [33],
  Milnesville: [39],
  Milnor: [29],
  Milo: [13, 22, 25],
  Milpitas: [5],
  Milroy: [16, 24, 39],
  Milton: [9, 10, 13, 15, 16, 17, 18, 19, 20, 28, 29, 31, 35, 39, 43, 47, 48, 49, 50],
  'Milton Center': [36],
  'Milton Freewater': [38],
  'Milton Mills': [31],
  'Milton Village': [20],
  Miltona: [24],
  Miltonvale: [17],
  Milwaukee: [49, -49],
  Milwaukie: [38],
  Mimbres: [33],
  Mims: [10],
  Mina: [34],
  Minatare: [30],
  Minburn: [13],
  Minco: [37],
  Minden: [13, 19, 30, 34, 44, 50],
  'Minden City': [23],
  Mindenmines: [25],
  Mindoro: [49],
  'Mine Hill': [32],
  Mineola: [13, 35, 44],
  Miner: [-42],
  Mineral: [5, -6, 15, -27, -34, 44, 46, 48, -50],
  'Mineral Bluff': [11],
  'Mineral City': [36],
  'Mineral Point': [25, 39, 49],
  'Mineral Ridge': [36],
  'Mineral Springs': [3, 28, 39],
  'Mineral Wells': [44, 50],
  Minersville: [39, 45],
  Minerva: [18, 35, 36],
  Minetto: [35],
  Mineville: [35],
  Minford: [36],
  Mingo: [13, 36, -50],
  'Mingo Junction': [36],
  Mingoville: [39],
  Mingus: [44],
  Minidoka: [14, -14],
  Minier: [15],
  'Minisink Hills': [39],
  Minneapolis: [17, 24, 28],
  Minnehaha: [-42],
  Minneola: [10, 17],
  Minneota: [24],
  'Minnesota City': [24],
  'Minnesota Lake': [24],
  Minnetonka: [24],
  'Minnetonka Beach': [24],
  Minnewaukan: [29],
  Minnie: [18],
  Minoa: [35],
  Minocqua: [49],
  Minong: [49],
  Minonk: [15],
  Minooka: [15],
  'Minor Hill': [43],
  Minot: [20, 22, 29],
  'Minot Afb': [29],
  Minotola: [32],
  Minster: [36],
  'Mint Spring': [46],
  Minter: [2],
  'Minter City': [26],
  Minto: [1, 29],
  Minturn: [3, 6],
  Mio: [23],
  'Mira Loma': [5],
  Miracle: [18],
  Miramar: [10],
  'Miramar Beach': [10],
  Miramonte: [5],
  Miranda: [5],
  'Mirando City': [44],
  'Mirror Lake': [31],
  Misenheimer: [28],
  Mishawaka: [16],
  Mishicot: [49],
  Missaukee: [-23],
  Mission: [17, 42, 44],
  'Mission Hill': [42],
  'Mission Hills': [5],
  'Mission Viejo': [5],
  Mississippi: [-3, -25],
  'Mississippi State': [26],
  Missoula: [27, -27],
  'Missouri City': [25, 44],
  'Missouri Valley': [13],
  Mistletoe: [18],
  Mitchell: [11, -11, -13, 16, -17, -28, 30, 38, 42, -44],
  Mitchells: [46],
  Mitchellsburg: [18],
  Mitchellville: [13, 43],
  Mittie: [19],
  Mize: [18, 26],
  Mizpah: [24, 32],
  Moab: [45],
  Moapa: [34],
  Moatsville: [50],
  Mobeetie: [44],
  Moberly: [25],
  Mobile: [2, -2],
  Mobridge: [42],
  Moccasin: [5, 27],
  Mocksville: [28],
  Moclips: [48],
  Modale: [13],
  Mode: [15],
  Model: [6],
  'Model City': [35],
  Modena: [35, 39, 45],
  'Modest Town': [46],
  Modesto: [5, 15],
  Modoc: [-5, 15, 16, 41],
  Moffat: [6, -6],
  Moffett: [37],
  Moffit: [29],
  Mogadore: [36],
  Mohall: [29],
  Mohave: [-4],
  'Mohave Valley': [4],
  Mohawk: [23, 35, 43, 50],
  'Mohegan Lake': [35],
  Mohler: [48],
  Mohnton: [39],
  Mohrsville: [39],
  Moira: [35],
  Mojave: [5],
  Mokane: [25],
  'Mokelumne Hill': [5],
  Mokena: [15],
  Molalla: [38],
  Molena: [11],
  Molina: [6],
  Moline: [15, 17, 23],
  Molino: [10],
  Mollusk: [46],
  Molt: [27],
  Momence: [15],
  Mona: [45],
  Monaca: [39],
  Monahans: [44],
  Monarch: [6, 27],
  'Moncks Corner': [41],
  Monclova: [36],
  Moncure: [28],
  Mondamin: [13],
  Mondovi: [49],
  Monee: [15],
  Monessen: [39],
  Moneta: [46],
  Monett: [25],
  Monetta: [41],
  Monette: [3],
  'Mongaup Valley': [35],
  Mongo: [16],
  Monhegan: [22],
  Moniteau: [-25],
  Monitor: [48],
  Monkton: [21, 47],
  Monmouth: [13, 15, 22, -32, 38],
  'Monmouth Beach': [32],
  'Monmouth Junction': [32],
  Mono: [-5],
  'Mono Hot Springs': [5],
  Monon: [16],
  Monona: [13, -13, 49],
  Monongahela: [39],
  Monongalia: [-50],
  Monponsett: [20],
  Monroe: [-2, 3, -3, 7, -10, 11, -11, 13, -13, -15, 16, -16, -18, 19, 22, 23, -23, -25, -26, 28, 30, 31, 35, -35, 36, -36, 37, 38, -39, 42, 43, -43, 45, 46, 48, 49, -49, -50],
  'Monroe Bridge': [20],
  'Monroe Center': [15],
  'Monroe City': [16, 25],
  'Monroe Township': [32],
  Monroeton: [39],
  Monroeville: [2, 16, 32, 36, 39],
  Monrovia: [5, 16, 21],
  Monsey: [35],
  Monson: [20, 22],
  'Mont Alto': [39],
  'Mont Belvieu': [44],
  'Mont Clare': [39],
  'Mont Vernon': [31],
  Montague: [5, 20, 23, 32, 44, -44],
  Montalba: [44],
  'Montana Mines': [50],
  Montandon: [39],
  Montara: [5],
  Montauk: [35],
  Montcalm: [-23, 50],
  Montchanin: [9],
  Montclair: [5, 32],
  'Monte Rio': [5],
  'Monte Vista': [6],
  Monteagle: [43],
  Montebello: [5, 46],
  Montegut: [19],
  Montello: [34, 49],
  Monterey: [5, -5, 16, 19, 20, 43, 46],
  'Monterey Park': [5],
  Monterville: [50],
  Montesano: [48],
  Montevallo: [2],
  Montevideo: [24],
  Monteview: [14],
  Montezuma: [-6, 11, 13, 16, 17, 28, 33, 35, 36],
  'Montezuma Creek': [45],
  Montfort: [49],
  Montgomery: [2, -2, -3, -11, -13, 15, -15, 16, -16, -17, -18, 19, -21, 23, 24, -25, -26, -28, 35, -35, -36, 39, -39, -43, 44, -44, -46, 47, 50],
  'Montgomery Center': [47],
  'Montgomery City': [25],
  'Montgomery Creek': [5],
  'Montgomery Village': [21],
  Montgomeryville: [39],
  Monticello: [3, 10, 11, 13, 15, 16, 18, 22, 24, 25, 26, 33, 35, 45, 49],
  Montier: [25],
  Montmorenci: [16, 41],
  Montmorency: [-23],
  Montour: [13, -39],
  'Montour Falls': [35],
  Montoursville: [39],
  Montpelier: [13, 14, 16, 26, 29, 36, 46, 47],
  'Montpelier Station': [46],
  Montreal: [25, 49],
  Montreat: [28],
  Montrose: [2, 3, 5, 6, -6, 11, 13, 15, 23, 24, 25, 35, 39, 42, 50],
  Montross: [46],
  Montvale: [32, 46],
  Montverde: [10],
  Montville: [7, 32, 36],
  Monument: [6, 17, 33, 38],
  'Monument Beach': [20],
  'Monument Valley': [45],
  Moodus: [7],
  Moody: [2, 22, 25, -42, 44],
  'Moody Afb': [11],
  Moodys: [37],
  Mooers: [35],
  'Mooers Forks': [35],
  Moon: [46],
  Moonachie: [32],
  Moorcroft: [51],
  Moore: [14, 27, -28, 37, 41, -43, 44, -44],
  'Moore Haven': [10],
  Moorefield: [18, 30, 50],
  Mooreland: [16, 37],
  'Moores Hill': [16],
  Mooresboro: [28],
  Mooresburg: [43],
  Moorestown: [32],
  Mooresville: [2, 16, 25, 28],
  Mooreton: [29],
  Mooreville: [26],
  Moorhead: [13, 24, 26],
  Mooringsport: [19],
  Moorland: [13],
  Moorpark: [5],
  Moose: [51],
  'Moose Lake': [24],
  'Moose Pass': [1],
  Mooseheart: [15],
  Moosic: [39],
  Moosup: [7],
  Mora: [19, 24, 25, 33, -33],
  Moraga: [5],
  Moran: [17, 23, 44, 51],
  Morann: [39],
  Morattico: [46],
  Moravia: [13, 35],
  'Moravian Falls': [28],
  Moreauville: [19],
  Morehead: [18],
  'Morehead City': [28],
  Morehouse: [-19, 25],
  Moreland: [11, 14],
  Morenci: [4, 23],
  'Moreno Valley': [5],
  Moretown: [47],
  Morgan: [-2, -6, 11, -11, -15, -16, -18, 24, -25, -36, 39, -43, 44, 45, -45, 47, -50],
  'Morgan City': [19, 26],
  'Morgan Hill': [5],
  'Morgan Mill': [44],
  Morganfield: [18],
  Morganton: [11, 28],
  Morgantown: [16, 18, 39, 50],
  Morganville: [17, 32],
  Morganza: [19, 21],
  Moriah: [35],
  'Moriah Center': [35],
  Moriarty: [33],
  Moriches: [35],
  Morland: [17],
  Morley: [13, 23, 25],
  'Mormon Lake': [4],
  'Morning Sun': [13],
  'Morning View': [18],
  Moro: [3, 15, 38],
  Morocco: [16],
  'Morongo Valley': [5],
  Moroni: [45],
  Morral: [36],
  Morrice: [23],
  Morrill: [17, 22, 30, -30],
  Morrilton: [3],
  Morris: [2, 7, 11, 15, 16, -17, 24, -32, 35, 37, 39, -44],
  'Morris Chapel': [43],
  'Morris Plains': [32],
  'Morris Run': [39],
  Morrisdale: [39],
  Morrison: [6, 13, 15, -24, 25, 37, 43],
  Morrisonville: [15, 35, 49],
  Morriston: [10],
  Morristown: [4, 16, 24, 32, 35, 36, 42, 43],
  Morrisville: [25, 28, 35, 39, 47],
  'Morro Bay': [5],
  Morrow: [3, 11, 19, 36, -36, -38],
  Morrowville: [17],
  Morse: [19, 44],
  'Morse Bluff': [30],
  'Morse Mill': [25],
  Morton: [15, -17, 24, 26, -29, 35, 39, 44, 48],
  'Morton Grove': [15],
  'Mortons Gap': [18],
  Morven: [11, 28],
  Mosby: [25, 27],
  Mosca: [6],
  Moscow: [3, 13, 14, 17, 23, 36, 39, 43, 44, 47],
  'Moscow Mills': [25],
  Moseley: [46],
  Moselle: [26],
  'Moses Lake': [48],
  Moshannon: [39],
  Mosheim: [43],
  Mosherville: [23],
  Mosier: [38],
  Mosinee: [49],
  Mosquero: [33],
  Moss: [26, 43],
  'Moss Beach': [5],
  'Moss Landing': [5],
  'Moss Point': [26],
  Mossville: [15],
  'Mossy Head': [10],
  Mossyrock: [48],
  Motley: [24, -44],
  Mott: [29],
  Mottville: [35],
  Moulton: [2, 13, 44],
  Moultonborough: [31],
  Moultrie: [11, -15],
  Mound: [24, 44],
  'Mound Bayou': [26],
  'Mound City': [15, 17, 25, 42],
  'Mound Valley': [17],
  Moundridge: [17],
  Mounds: [15, 37],
  Moundsville: [50],
  Moundville: [2, 25],
  'Mount Aetna': [39],
  'Mount Airy': [11, 19, 21, 28],
  'Mount Alto': [50],
  'Mount Angel': [38],
  'Mount Arlington': [32],
  'Mount Auburn': [13, 15],
  'Mount Aukum': [5],
  'Mount Ayr': [13, 16],
  'Mount Berry': [11],
  'Mount Bethel': [39],
  'Mount Blanchard': [36],
  'Mount Braddock': [39],
  'Mount Calm': [44],
  'Mount Calvary': [49],
  'Mount Carbon': [50],
  'Mount Carmel': [15, 39, 41, 43, 45],
  'Mount Carroll': [15],
  'Mount Clare': [50],
  'Mount Clemens': [23],
  'Mount Cory': [36],
  'Mount Crawford': [46],
  'Mount Croghan': [41],
  'Mount Desert': [22],
  'Mount Dora': [10],
  'Mount Eaton': [36],
  'Mount Eden': [18],
  'Mount Enterprise': [44],
  'Mount Ephraim': [32],
  'Mount Erie': [15],
  'Mount Freedom': [32],
  'Mount Gay': [50],
  'Mount Gilead': [28, 36],
  'Mount Gretna': [39],
  'Mount Hamilton': [5],
  'Mount Hermon': [5, 18, 19],
  'Mount Holly': [3, 28, 32, 46, 47],
  'Mount Holly Springs': [39],
  'Mount Hood Parkdale': [38],
  'Mount Hope': [2, 17, 36, 49, 50],
  'Mount Horeb': [49],
  'Mount Ida': [3],
  'Mount Jackson': [46],
  'Mount Jewett': [39],
  'Mount Joy': [39],
  'Mount Judea': [3],
  'Mount Juliet': [43],
  'Mount Kisco': [35],
  'Mount Laguna': [5],
  'Mount Laurel': [32],
  'Mount Lemmon': [4],
  'Mount Liberty': [36],
  'Mount Lookout': [50],
  'Mount Marion': [35],
  'Mount Meigs': [2],
  'Mount Morris': [15, 23, 35, 39],
  'Mount Mourne': [28],
  'Mount Nebo': [50],
  'Mount Olive': [2, 15, 26, 28, 50],
  'Mount Olivet': [18],
  'Mount Orab': [36],
  'Mount Perry': [36],
  'Mount Pleasant': [3, 13, 23, 26, 28, 36, 39, 41, 43, 44, 45, 49],
  'Mount Pleasant Mills': [39],
  'Mount Pocono': [39],
  'Mount Prospect': [15],
  'Mount Pulaski': [15],
  'Mount Rainier': [21],
  'Mount Royal': [32],
  'Mount Saint Francis': [16],
  'Mount Saint Joseph': [36],
  'Mount Savage': [21],
  'Mount Shasta': [5],
  'Mount Sherman': [18],
  'Mount Sidney': [46],
  'Mount Sinai': [35],
  'Mount Solon': [46],
  'Mount Sterling': [13, 15, 18, 25, 36, 49],
  'Mount Storm': [50],
  'Mount Summit': [16],
  'Mount Tabor': [32],
  'Mount Tremper': [35],
  'Mount Ulla': [28],
  'Mount Union': [13, 39],
  'Mount Upton': [35],
  'Mount Vernon': [2, 3, 11, 13, 15, 16, 18, 22, 25, 35, 36, 38, 42, 44, 46, 48],
  'Mount Victoria': [21],
  'Mount Victory': [36],
  'Mount Vision': [35],
  'Mount Washington': [18, 31],
  'Mount Wilson': [5],
  'Mount Wolf': [39],
  'Mount Zion': [11, 50],
  Mountain: [29, 49],
  'Mountain Center': [5],
  'Mountain City': [11, 34, 43],
  'Mountain Dale': [35],
  'Mountain Green': [45],
  'Mountain Grove': [25],
  'Mountain Home': [3, 14, 28, 43, 44, 45],
  'Mountain Home Afb': [14],
  'Mountain Iron': [24],
  'Mountain Lake': [24],
  'Mountain Lakes': [32],
  'Mountain Park': [37],
  'Mountain Pass': [5],
  'Mountain Pine': [3],
  'Mountain Ranch': [5],
  'Mountain Rest': [41],
  'Mountain Top': [39],
  'Mountain View': [3, 5, 12, 25, 37, 51],
  'Mountain Village': [1, 6],
  Mountainair: [33],
  Mountainburg: [3],
  Mountainhome: [39],
  Mountainside: [32],
  Mountainville: [35],
  'Mountlake Terrace': [48],
  Mountrail: [-29],
  Mountville: [39, 41],
  Mousie: [18],
  'Mouth Of Wilson': [46],
  Mouthcard: [18],
  Moville: [13],
  Moweaqua: [15],
  Mower: [-24],
  Mowrystown: [36],
  Moxahala: [36],
  Moxee: [48],
  Moyers: [37],
  'Moyie Springs': [14],
  Moyock: [28],
  Mozelle: [18],
  Mozier: [15],
  'Mt Baldy': [5],
  'Mt Zion': [15],
  'Mud Butte': [42],
  Muddy: [15],
  Muenster: [44],
  Muhlenberg: [-18],
  Muir: [23, 39],
  Mukilteo: [48],
  Mukwonago: [49],
  Mulberry: [3, 10, 16, 17, 43],
  'Mulberry Grove': [15],
  Muldoon: [44],
  Muldraugh: [18],
  Muldrow: [37],
  'Mule Creek': [33],
  Muleshoe: [44],
  Mulga: [2],
  Mulhall: [37],
  Mulino: [38],
  Mulkeytown: [15],
  Mullan: [14],
  Mullen: [30],
  Mullens: [50],
  'Mullett Lake': [23],
  'Mullica Hill': [32],
  Mulliken: [23],
  Mullin: [44],
  Mullins: [41],
  Mullinville: [17],
  Multnomah: [-38],
  Mulvane: [17],
  Mumford: [35, 44],
  Muncie: [15, 16],
  Muncy: [39],
  'Muncy Valley': [39],
  Munday: [44, 50],
  Mundelein: [15],
  Munden: [17],
  'Munds Park': [4],
  Munford: [2, 43],
  Munfordville: [18],
  Munger: [23],
  Munich: [29],
  Munising: [23],
  Munith: [23],
  Munnsville: [35],
  'Munroe Falls': [36],
  Munson: [39],
  Munster: [16],
  Murchison: [44],
  Murdo: [42],
  Murdock: [10, 15, 17, 24, 30],
  Murfreesboro: [3, 28, 43],
  Murphy: [14, 28, 38],
  Murphys: [5],
  Murphysboro: [15],
  Murray: [-11, 13, 14, 18, -24, 30, -37],
  'Murray City': [36],
  Murrayville: [11, 15],
  'Murrells Inlet': [41],
  Murrieta: [5],
  Murrysville: [39],
  Murtaugh: [14],
  Muscadine: [2],
  Muscatine: [13, -13],
  'Muscle Shoals': [2],
  Muscoda: [49],
  Muscogee: [-11],
  Muscotah: [17],
  Muse: [37, 39],
  Musella: [11],
  Muskego: [49],
  Muskegon: [23, -23],
  Muskingum: [-36],
  Muskogee: [37, -37],
  Musselshell: [27, -27],
  Mustang: [37],
  Mutual: [37],
  'Myakka City': [10],
  'Myers Flat': [5],
  Myerstown: [39],
  Myersville: [21],
  Mylo: [29],
  Myra: [18, 44, 50],
  Myrtle: [25, 26],
  'Myrtle Beach': [41],
  'Myrtle Creek': [38],
  'Myrtle Point': [38],
  Myrtlewood: [2],
  Mystic: [7, 11, 13],
  Myton: [45],
  Naalehu: [12],
  Nabb: [16],
  Naches: [48],
  Nachusa: [15],
  Naco: [4],
  Nacogdoches: [44, -44],
  Nada: [44],
  Nadeau: [23],
  Nageezi: [33],
  'Nags Head': [28],
  Nahant: [20],
  Nahcotta: [48],
  Nahma: [23],
  Nahunta: [11],
  Nakina: [28],
  Naknek: [1],
  Nalcrest: [10],
  Nallen: [50],
  Nampa: [14],
  Nanafalia: [2],
  Nance: [-30],
  Nancy: [18],
  Nanjemoy: [21],
  Nankin: [36],
  Nanticoke: [21, 39],
  Nantucket: [20, -20],
  'Nanty Glo': [39],
  Nanuet: [35],
  Naoma: [50],
  Napa: [5, -5],
  Napakiak: [1],
  Napanoch: [35],
  Napavine: [48],
  Naper: [30],
  Naperville: [15],
  Napier: [50],
  Naples: [10, 14, 22, 28, 35, 44],
  Napoleon: [16, 23, 25, 29, 36],
  Napoleonville: [19],
  Naponee: [30],
  Nappanee: [16],
  'Nara Visa': [33],
  Narberth: [39],
  Nardin: [37],
  Narka: [17],
  Narragansett: [40],
  Narrows: [46],
  Narrowsburg: [35],
  Naruna: [46],
  Narvon: [39],
  Naselle: [48],
  Nash: [-28, 37, 44],
  Nashoba: [37],
  Nashotah: [49],
  Nashport: [36],
  Nashua: [13, 24, 27, 31],
  Nashville: [3, 11, 15, 16, 17, 23, 28, 36, 43],
  Nashwauk: [24],
  Nason: [15],
  Nassau: [9, -10, 35, -35],
  Nassawadox: [46],
  Natalbany: [19],
  Natalia: [44],
  Natchez: [19, 26],
  Natchitoches: [19, -19],
  Nathalie: [46],
  Nathrop: [6],
  Natick: [20],
  'National City': [5, 23],
  'National Harbor': [21],
  'National Mine': [23],
  'National Park': [32],
  'National Stock Yards': [15],
  Natoma: [17],
  Natrona: [51, -51],
  'Natrona Heights': [39],
  'Natural Bridge': [2, 35, 46],
  'Natural Bridge Station': [46],
  'Natural Dam': [3],
  Naturita: [6],
  Naubinway: [23],
  Naugatuck: [7, 50],
  Nauvoo: [2, 15],
  Navajo: [-4, 33],
  'Navajo Dam': [33],
  'Naval Air Station Jrb': [44],
  'Naval Anacost Annex': [8],
  Navarre: [10, 36],
  Navarro: [5, -44],
  Navasota: [44],
  Navesink: [32],
  Naylor: [11, 25],
  Naytahwaush: [24],
  Nazareth: [18, 23, 39, 44],
  Nazlini: [4],
  'Neah Bay': [48],
  Neal: [17],
  Neapolis: [36],
  Neavitt: [21],
  Nebo: [15, 18, 28, 50],
  'Nebraska City': [30],
  Necedah: [49],
  Neche: [29],
  Neches: [44],
  'Neck City': [25],
  Nederland: [6, 44],
  Nedrow: [35],
  Needham: [2, 16, 20],
  'Needham Heights': [20],
  Needles: [5],
  Needmore: [39],
  Needville: [44],
  Neely: [26],
  Neelyton: [39],
  Neelyville: [25],
  Neenah: [49],
  Neeses: [41],
  Neffs: [36, 39],
  Negaunee: [23],
  Negley: [36],
  Negreet: [19],
  Nehalem: [38],
  Nehawka: [30],
  Neihart: [27],
  Neillsville: [49],
  Neilton: [48],
  Nekoma: [17, 29],
  Nekoosa: [49],
  Neligh: [30],
  Nellis: [50],
  'Nellis Afb': [34],
  Nelliston: [35],
  Nellysford: [46],
  Nelson: [5, 11, -18, 24, 25, -29, 30, 31, 39, 46, -46, 49],
  Nelsonia: [46],
  Nelsonville: [36, 49],
  Nemacolin: [39],
  Nemaha: [13, -17, 30, -30],
  Nemo: [42, 44],
  Nemours: [50],
  Nenana: [1],
  Nenzel: [30],
  Neodesha: [17],
  Neoga: [15],
  Neola: [13, 45],
  Neon: [18],
  Neopit: [49],
  Neosho: [-17, 25, 49],
  'Neosho Falls': [17],
  'Neosho Rapids': [17],
  Neotsu: [38],
  Nephi: [45],
  Neponset: [15],
  Neptune: [32],
  'Neptune Beach': [10],
  Nerinx: [18],
  Nerstrand: [24],
  Nesbit: [26],
  Nesconset: [35],
  Nescopeck: [39],
  'Neshanic Station': [32],
  Neshkoro: [49],
  Neshoba: [-26],
  Neskowin: [38],
  Nesmith: [41],
  Nespelem: [48],
  Nesquehoning: [39],
  Ness: [-17],
  'Ness City': [17],
  Netarts: [38],
  Netawaka: [17],
  Netcong: [32],
  'Nett Lake': [24],
  Nettie: [50],
  Nettleton: [26],
  Nevada: [-3, -5, 13, 25, 36, 44],
  'Nevada City': [5],
  Neversink: [35],
  Neville: [36],
  Nevis: [24],
  'New Albany': [16, 26, 36, 39],
  'New Albin': [13],
  'New Alexandria': [39],
  'New Almaden': [5],
  'New Athens': [15, 36],
  'New Auburn': [24, 49],
  'New Augusta': [26],
  'New Baden': [15, 44],
  'New Baltimore': [23, 35, 39],
  'New Bavaria': [36],
  'New Bedford': [15, 20, 39],
  'New Berlin': [15, 35, 39, 49],
  'New Berlinville': [39],
  'New Bern': [28],
  'New Bethlehem': [39],
  'New Blaine': [3],
  'New Bloomfield': [25, 39],
  'New Bloomington': [36],
  'New Boston': [15, 23, 25, 31, 44],
  'New Braintree': [20],
  'New Braunfels': [44],
  'New Bremen': [36],
  'New Brighton': [24, 39],
  'New Britain': [7],
  'New Brockton': [2],
  'New Brunswick': [32],
  'New Buffalo': [23, 39],
  'New Burnside': [15],
  'New Cambria': [17, 25],
  'New Canaan': [7],
  'New Caney': [44],
  'New Canton': [15, 46],
  'New Carlisle': [16, 36],
  'New Castle': [2, 6, 9, -9, 16, 18, 31, 39, 46],
  'New Century': [17],
  'New Church': [46],
  'New City': [35],
  'New Columbia': [39],
  'New Concord': [18, 36],
  'New Cordell': [37],
  'New Creek': [50],
  'New Cumberland': [39, 50],
  'New Cuyama': [5],
  'New Deal': [44],
  'New Derry': [39],
  'New Douglas': [15],
  'New Durham': [31],
  'New Eagle': [39],
  'New Edinburg': [3],
  'New Effington': [42],
  'New Egypt': [32],
  'New Ellenton': [41],
  'New England': [29],
  'New Enterprise': [39],
  'New Era': [23],
  'New Fairfield': [7],
  'New Florence': [25, 39],
  'New Franken': [49],
  'New Franklin': [25],
  'New Freedom': [39],
  'New Freeport': [39],
  'New Galilee': [39],
  'New Geneva': [39],
  'New Germantown': [39],
  'New Germany': [24],
  'New Glarus': [49],
  'New Gloucester': [22],
  'New Goshen': [16],
  'New Gretna': [32],
  'New Hampshire': [36],
  'New Hampton': [13, 25, 31, 35],
  'New Hanover': [-28],
  'New Harbor': [22],
  'New Harmony': [16, 45],
  'New Hartford': [7, 13, 35],
  'New Haven': [7, -7, 15, 16, 18, 23, 25, 35, 36, 47, 50],
  'New Hill': [28],
  'New Holland': [15, 36, 39, 42],
  'New Holstein': [49],
  'New Home': [44],
  'New Hope': [2, 18, 39, 46],
  'New Hudson': [23],
  'New Hyde Park': [35],
  'New Iberia': [19],
  'New Ipswich': [31],
  'New Johnsonville': [43],
  'New Kensington': [39],
  'New Kent': [46, -46],
  'New Kingston': [35],
  'New Kingstown': [39],
  'New Knoxville': [36],
  'New Laguna': [33],
  'New Lebanon': [35, 36],
  'New Leipzig': [29],
  'New Lenox': [15],
  'New Lexington': [36],
  'New Liberty': [13, 18],
  'New Limerick': [22],
  'New Lisbon': [16, 32, 35, 49],
  'New Llano': [19],
  'New London': [7, -7, 13, 24, 25, 28, 31, 36, 39, 44, 49],
  'New Lothrop': [23],
  'New Madison': [36],
  'New Madrid': [25, -25],
  'New Manchester': [50],
  'New Market': [2, 13, 16, 21, 43, 46],
  'New Marshfield': [36],
  'New Martinsville': [50],
  'New Matamoras': [36],
  'New Meadows': [14],
  'New Melle': [25],
  'New Memphis': [15],
  'New Middletown': [16, 36],
  'New Midway': [21],
  'New Milford': [7, 32, 35, 39],
  'New Millport': [39],
  'New Milton': [50],
  'New Munich': [24],
  'New Munster': [49],
  'New Orleans': [19],
  'New Oxford': [39],
  'New Palestine': [16],
  'New Paltz': [35],
  'New Paris': [16, 36, 39],
  'New Park': [39],
  'New Philadelphia': [36, 39],
  'New Pine Creek': [5],
  'New Plymouth': [14, 36],
  'New Point': [16, 46],
  'New Port Richey': [10],
  'New Portland': [22],
  'New Prague': [24],
  'New Preston Marble Dale': [7],
  'New Providence': [13, 32, 39],
  'New Raymer': [6],
  'New Richland': [24],
  'New Richmond': [16, 36, 49, 50],
  'New Riegel': [36],
  'New Ringgold': [39],
  'New River': [4, 46],
  'New Roads': [19],
  'New Rochelle': [35],
  'New Rockford': [29],
  'New Ross': [16],
  'New Rumley': [36],
  'New Russia': [35],
  'New Salem': [15, 20, 29, 39],
  'New Salisbury': [16],
  'New Sarpy': [19],
  'New Sharon': [13, 22],
  'New Site': [26],
  'New Smyrna Beach': [10],
  'New Springfield': [36],
  'New Stanton': [39],
  'New Straitsville': [36],
  'New Strawn': [17],
  'New Stuyahok': [1],
  'New Suffolk': [35],
  'New Summerfield': [44],
  'New Sweden': [22],
  'New Tazewell': [43],
  'New Town': [20, 29],
  'New Trenton': [16],
  'New Tripoli': [39],
  'New Troy': [23],
  'New Ulm': [24, 44],
  'New Underwood': [42],
  'New Vernon': [32],
  'New Vienna': [13, 36],
  'New Vineyard': [22],
  'New Virginia': [13],
  'New Washington': [16, 36],
  'New Waterford': [36],
  'New Waverly': [16, 44],
  'New Weston': [36],
  'New Wilmington': [39],
  'New Windsor': [15, 21, 35],
  'New Woodstock': [35],
  'New York': [-35],
  'New York City': [35],
  'New York Mills': [24, 35],
  'New Zion': [41],
  Newalla: [37],
  Newark: [3, 5, 9, 15, 21, 25, 32, 35, 36, 44],
  'Newark Valley': [35],
  Newaygo: [23, -23],
  Newberg: [38],
  Newbern: [2, 43, 46],
  Newberry: [10, 16, 23, 41, -41],
  'Newberry Springs': [5],
  Newborn: [11],
  Newburg: [21, 25, 29, 39, 49, 50],
  Newburgh: [16, 35],
  Newbury: [20, 31, 36, 47],
  'Newbury Park': [5],
  Newburyport: [20],
  Newcastle: [5, 22, 30, 37, 44, 45, 51],
  Newcomb: [21, 33, 35, 43],
  Newcomerstown: [36],
  Newdale: [14],
  Newell: [13, 28, 39, 42, 50],
  Newellton: [19],
  Newfane: [35, 47],
  Newfield: [22, 32, 35],
  Newfields: [31],
  Newfolden: [24],
  Newfoundland: [32, 39],
  Newhall: [5, 13, 50],
  Newhebron: [26],
  Newhope: [3],
  Newington: [7, 11, 46],
  Newkirk: [33, 37],
  Newland: [28],
  Newman: [5, 15],
  'Newman Grove': [30],
  'Newman Lake': [48],
  Newmanstown: [39],
  Newmarket: [31],
  Newnan: [11],
  Newport: [3, 16, 18, 22, 23, 24, 28, 30, 31, 32, 35, 36, 38, 39, 40, -40, 43, 46, 47, 48],
  'Newport Beach': [5],
  'Newport Center': [47],
  'Newport Coast': [5],
  'Newport News': [46],
  Newry: [22, 39, 41],
  Newsoms: [46],
  Newton: [2, -3, 11, -11, 13, 15, -16, 17, 20, -25, 26, -26, 28, 31, 32, 44, -44, 45, 49, 50],
  'Newton Center': [20],
  'Newton Falls': [35, 36],
  'Newton Grove': [28],
  'Newton Hamilton': [39],
  'Newton Highlands': [20],
  'Newton Junction': [31],
  'Newton Lower Falls': [20],
  'Newton Upper Falls': [20],
  Newtonia: [25],
  Newtonsville: [36],
  Newtonville: [20, 32, 35],
  Newtown: [7, 16, 25, 39, 46, 50],
  'Newtown Square': [39],
  Newville: [2, 39],
  Ney: [36],
  'Nez Perce': [-14],
  Nezperce: [14],
  Niagara: [29, -35, 49],
  'Niagara Falls': [35],
  'Niagara University': [35],
  Niangua: [25],
  Niantic: [7, 15],
  Nicasio: [5],
  Nice: [5],
  Niceville: [10],
  Nicholas: [-18, -50],
  Nicholasville: [18],
  Nicholls: [11],
  Nichols: [10, 13, 35, 41, 49],
  Nicholson: [11, 26, 39],
  Nicholville: [35],
  Nickelsville: [46],
  Nickerson: [17, 30],
  Nicktown: [39],
  Nicolaus: [5],
  Nicollet: [24, -24],
  'Nicoma Park': [37],
  Nielsville: [24],
  Nightmute: [1],
  Nikiski: [1],
  Nikolai: [1],
  Nikolski: [1],
  Niland: [5],
  Niles: [15, 23, 36],
  Nilwood: [15],
  Nimitz: [50],
  Ninde: [46],
  'Nine Mile Falls': [48],
  'Ninety Six': [41],
  Nineveh: [16, 35, 39],
  Ninilchik: [1],
  Ninnekah: [37],
  Ninole: [12],
  Niobe: [35],
  Niobrara: [30, -51],
  Niota: [15, 43],
  Niotaze: [17],
  Nipomo: [5],
  Nipton: [5],
  Niskayuna: [35],
  Nisland: [42],
  Nisswa: [24],
  Nisula: [23],
  Nitro: [50],
  Niverville: [35],
  Niwot: [6],
  Nixa: [25],
  Nixon: [34, 44],
  Noatak: [1],
  Noble: [15, -16, 19, 25, -36, 37, -37],
  Nobleboro: [22],
  Nobles: [-24],
  Noblesville: [16],
  Nobleton: [10],
  Nocatee: [10],
  Nocona: [44],
  Nodaway: [13, -25],
  Noel: [25],
  Nogal: [33],
  Nogales: [4],
  Nokesville: [46],
  Nokomis: [10, 15],
  Nolan: [44, -44],
  Nolanville: [44],
  Nolensville: [43],
  Noma: [10],
  Nome: [1, -1, 29, 44],
  Nonantum: [20],
  Nondalton: [1],
  Nooksack: [48],
  Noonan: [29],
  Noorvik: [1],
  Nora: [15, 46],
  'Nora Springs': [13],
  Norborne: [25],
  Norcatur: [17],
  Norco: [5, 19],
  Norcross: [11, 24],
  Norden: [5],
  Nordheim: [44],
  Nordland: [48],
  Nordman: [14],
  Norene: [43],
  Norfolk: [7, 20, -20, 30, 35, 46],
  Norfork: [3],
  Norge: [46],
  Norlina: [28],
  Norma: [32],
  Normal: [2, 15],
  Normalville: [39],
  Norman: [3, 16, -24, 28, 37],
  'Norman Park': [11],
  Normandy: [43],
  'Normandy Beach': [32],
  Normangee: [44],
  Normanna: [44],
  Normantown: [50],
  Norphlet: [3],
  Norridgewock: [22],
  Norris: [15, 27, 41, 42, 43],
  'Norris City': [15],
  Norristown: [11, 39],
  North: [41, 46],
  'North Abington Township': [39],
  'North Adams': [20, 23],
  'North Amherst': [20],
  'North Andover': [20],
  'North Anson': [22],
  'North Apollo': [39],
  'North Arlington': [32],
  'North Attleboro': [20],
  'North Augusta': [41],
  'North Aurora': [15],
  'North Babylon': [35],
  'North Baltimore': [36],
  'North Bangor': [35],
  'North Bay': [35],
  'North Bay Village': [10],
  'North Beach': [21],
  'North Bend': [30, 36, 38, 39, 48],
  'North Bennington': [47],
  'North Benton': [36],
  'North Bergen': [32],
  'North Berwick': [22],
  'North Billerica': [20],
  'North Blenheim': [35],
  'North Bloomfield': [36],
  'North Bonneville': [48],
  'North Boston': [35],
  'North Branch': [23, 24, 35],
  'North Branford': [7],
  'North Bridgton': [22],
  'North Brookfield': [20, 35],
  'North Brunswick': [32],
  'North Buena Vista': [13],
  'North Canton': [7, 36],
  'North Carrollton': [26],
  'North Carver': [20],
  'North Charleston': [41],
  'North Chatham': [20, 35],
  'North Chelmsford': [20],
  'North Chesterfield': [46],
  'North Chicago': [15],
  'North Chili': [35],
  'North Clarendon': [47],
  'North Collins': [35],
  'North Concord': [47],
  'North Conway': [31],
  'North Creek': [35],
  'North Dartmouth': [20],
  'North Dighton': [20],
  'North East': [21, 39],
  'North Eastham': [20],
  'North Easton': [20],
  'North Egremont': [20],
  'North English': [13],
  'North Evans': [35],
  'North Fairfield': [36],
  'North Falmouth': [20],
  'North Ferrisburgh': [47],
  'North Fork': [5, 14],
  'North Fort Myers': [10],
  'North Franklin': [7],
  'North Freedom': [49],
  'North Garden': [46],
  'North Georgetown': [36],
  'North Grafton': [20],
  'North Granby': [7],
  'North Granville': [35],
  'North Greece': [35],
  'North Grosvenordale': [7],
  'North Hampton': [31, 36],
  'North Hartland': [47],
  'North Hatfield': [20],
  'North Haven': [7, 22],
  'North Haverhill': [31],
  'North Henderson': [15],
  'North Hero': [47],
  'North Highlands': [5],
  'North Hills': [5],
  'North Hollywood': [5],
  'North Hoosick': [35],
  'North Houston': [44],
  'North Hudson': [35],
  'North Hyde Park': [47],
  'North Jackson': [36],
  'North Java': [35],
  'North Jay': [22],
  'North Judson': [16],
  'North Kingstown': [40],
  'North Kingsville': [36],
  'North Lake': [49],
  'North Lakewood': [48],
  'North Las Vegas': [34],
  'North Lawrence': [35, 36],
  'North Lewisburg': [36],
  'North Liberty': [13, 16],
  'North Lima': [36],
  'North Little Rock': [3],
  'North Loup': [30],
  'North Manchester': [16],
  'North Mankato': [24],
  'North Marshfield': [20],
  'North Matewan': [50],
  'North Metro': [11],
  'North Miami': [37],
  'North Miami Beach': [10],
  'North Middletown': [18],
  'North Monmouth': [22],
  'North Montpelier': [47],
  'North Myrtle Beach': [41],
  'North Newton': [17],
  'North Norwich': [35],
  'North Oaks': [24],
  'North Ogden': [45],
  'North Olmsted': [36],
  'North Oxford': [20],
  'North Palm Beach': [10],
  'North Palm Springs': [5],
  'North Pembroke': [20],
  'North Philipsburg': [39],
  'North Pitcher': [35],
  'North Plains': [38],
  'North Platte': [30],
  'North Pole': [1],
  'North Pomfret': [47],
  'North Port': [10],
  'North Powder': [38],
  'North Pownal': [47],
  'North Prairie': [49],
  'North Providence': [40],
  'North Reading': [20],
  'North Richland Hills': [44],
  'North Ridgeville': [36],
  'North Rim': [4],
  'North River': [35],
  'North Robinson': [36],
  'North Rose': [35],
  'North Royalton': [36],
  'North Salem': [16, 31, 35],
  'North Salt Lake': [45],
  'North San Juan': [5],
  'North Sandwich': [31],
  'North Scituate': [20, 40],
  'North Sioux City': [42],
  'North Slope': [-1],
  'North Smithfield': [40],
  'North Spring': [50],
  'North Springfield': [39, 47],
  'North St Paul': [24],
  'North Star': [23, 36],
  'North Stonington': [7],
  'North Stratford': [31],
  'North Street': [23],
  'North Sutton': [31],
  'North Tazewell': [46],
  'North Thetford': [47],
  'North Tonawanda': [35],
  'North Troy': [47],
  'North Truro': [20],
  'North Turner': [22],
  'North Uxbridge': [20],
  'North Vassalboro': [22],
  'North Vernon': [16],
  'North Versailles': [39],
  'North Wales': [39],
  'North Walpole': [31],
  'North Waltham': [20],
  'North Washington': [13, 39],
  'North Waterboro': [22],
  'North Waterford': [22],
  'North Webster': [16],
  'North Westchester': [7],
  'North Weymouth': [20],
  'North Wildwood': [32],
  'North Wilkesboro': [28],
  'North Windham': [7],
  'North Woodstock': [31],
  'North Yarmouth': [22],
  'North Zulch': [44],
  Northampton: [20, -28, 39, -39, -46],
  Northboro: [13],
  Northborough: [20],
  Northbridge: [20],
  Northbrook: [15],
  'Northeast Harbor': [22],
  'Northern Cambria': [39],
  Northfield: [7, 20, 24, 32, 36, 47],
  'Northfield Falls': [47],
  Northford: [7],
  Northfork: [50],
  Northglenn: [6],
  Northome: [24],
  Northpoint: [39],
  Northport: [2, 23, 35, 48],
  Northridge: [5],
  Northrop: [24],
  Northumberland: [39, -39, -46],
  Northvale: [32],
  Northville: [23, 35, 42],
  Northway: [1],
  'Northwest Arctic': [-1],
  Northwood: [13, 29, 31, 36],
  Norton: [17, -17, 20, 44, 46, 47, 50],
  Nortonville: [17, 18],
  Norvell: [23],
  Norvelt: [39],
  Norwalk: [5, 7, 13, 36, 49],
  Norway: [13, 17, 22, 23, 41],
  Norwell: [20],
  Norwich: [7, 17, 29, 35, 36, 47],
  Norwood: [6, 11, 19, 20, 24, 25, 28, 32, 35, 39, 46],
  'Norwood Young America': [24],
  Notasulga: [2],
  Noti: [38],
  'Notre Dame': [16],
  Notrees: [44],
  Nottawa: [23],
  Nottingham: [21, 31, 39],
  Nottoway: [46, -46],
  Notus: [14],
  Nova: [36],
  Novato: [5],
  Novelty: [25, 36],
  Novi: [23],
  Novice: [44],
  Novinger: [25],
  Nowata: [37, -37],
  Noxapater: [26],
  Noxen: [39],
  Noxon: [27],
  Noxubee: [-26],
  Noyes: [24],
  'Nu Mine': [39],
  Nubieber: [5],
  Nuckolls: [-30],
  Nucla: [6],
  Nueces: [-44],
  Nuevo: [5],
  Nuiqsut: [1],
  Nulato: [1],
  Numidia: [39],
  'Nunam Iqua': [1],
  Nunapitchuk: [1],
  Nunda: [35, 42],
  Nunez: [11],
  Nunica: [23],
  Nunn: [6],
  Nunnelly: [43],
  Nuremberg: [39],
  Nursery: [44],
  Nutley: [32],
  Nutrioso: [4],
  'Nutter Fort': [50],
  'Nutting Lake': [20],
  Nuttsville: [46],
  Nyack: [35],
  Nye: [27, -34],
  Nyssa: [38],
  "O'Brien": [-13],
  Oacoma: [42],
  Oak: [30],
  'Oak Bluffs': [20],
  'Oak Brook': [15],
  'Oak City': [28, 45],
  'Oak Creek': [6, 49],
  'Oak Forest': [15],
  'Oak Grove': [3, 18, 19, 25],
  'Oak Hall': [46],
  'Oak Harbor': [36, 48],
  'Oak Hill': [2, 10, 35, 36, 50],
  'Oak Hills': [5],
  'Oak Island': [24, 28],
  'Oak Lawn': [15],
  'Oak Park': [5, 15, 23, 24],
  'Oak Ridge': [19, 25, 28, 32, 39, 43],
  'Oak Run': [5],
  'Oak Vale': [26],
  'Oak View': [5],
  Oakboro: [28],
  Oakdale: [5, 7, 15, 19, 30, 35, 39, 43, 49],
  Oakes: [29],
  Oakesdale: [48],
  Oakfield: [11, 22, 35, 43, 49],
  Oakford: [15, 16],
  Oakham: [20],
  Oakhurst: [5, 32, 37, 44],
  Oakland: [3, 5, 10, 13, 15, 18, 21, 22, 23, -23, 26, 30, 32, 38, 40, 43, 44],
  'Oakland City': [16],
  'Oakland Gardens': [35],
  'Oakland Mills': [39],
  Oakley: [5, 14, 17, 23, 45],
  Oaklyn: [32],
  Oakman: [2, 11],
  Oakmont: [39],
  Oakpark: [46],
  Oakridge: [38],
  Oaks: [37, 39],
  'Oaks Corners': [35],
  Oakton: [46],
  Oaktown: [16],
  Oakville: [5, 7, 13, 16, 44, 48],
  Oakwood: [11, 15, 36, 37, 44, 46],
  Oark: [3],
  Oatman: [4],
  Oberlin: [17, 19, 36],
  Obernburg: [35],
  Oberon: [29],
  Obion: [43, -43],
  Oblong: [15],
  Obrien: [5],
  Ocala: [10],
  Ocate: [33],
  Occidental: [5],
  Occoquan: [46],
  Ocean: [-32],
  'Ocean Beach': [35],
  'Ocean Bluff': [20],
  'Ocean City': [21, 32],
  'Ocean Gate': [32],
  'Ocean Grove': [32],
  'Ocean Isle Beach': [28],
  'Ocean Park': [22, 48],
  'Ocean Pines': [21],
  'Ocean Shores': [48],
  'Ocean Springs': [26],
  'Ocean View': [9, 12, 32],
  Oceana: [-23, 50],
  Oceano: [5],
  Oceanport: [32],
  Oceanside: [5, 35, 38],
  Oceanville: [32],
  Oceola: [36],
  Ochelata: [37],
  Ocheyedan: [13],
  Ochiltree: [-44],
  Ochlocknee: [11],
  Ochopee: [10],
  Ocilla: [11],
  Ocklawaha: [10],
  Ocoee: [10, 43],
  Oconee: [11, -11, 15, -41],
  Oconomowoc: [49],
  Oconto: [30, 49, -49],
  'Oconto Falls': [49],
  Ocotillo: [5],
  Ocracoke: [28],
  Odanah: [49],
  Odd: [50],
  Odebolt: [13],
  Odell: [15, 30, 38, 44],
  Odem: [44],
  Oden: [3, 23],
  Odenton: [21],
  Odenville: [2],
  Odessa: [9, 10, 24, 25, 30, 35, 44, 48],
  Odin: [15, 24],
  Odon: [16],
  Odonnell: [44],
  Odum: [11],
  Oelrichs: [42],
  Oelwein: [13],
  Offerle: [17],
  Offerman: [11],
  'Offutt Afb': [30],
  Ogallah: [17],
  Ogallala: [30],
  Ogden: [3, 13, 15, 17, 45],
  Ogdensburg: [32, 35, 49],
  Ogema: [24, 49],
  Ogemaw: [-23],
  Ogilvie: [24],
  Oglala: [42],
  'Oglala Lakota': [-42],
  Ogle: [-15],
  Oglesby: [15, 44],
  Oglethorpe: [11, -11],
  Ogunquit: [22],
  Ohatchee: [2],
  Ohio: [15, -16, -18, -50],
  'Ohio City': [6, 36],
  Ohiopyle: [39],
  Ohiowa: [30],
  'Ohkay Owingeh': [33],
  Ohlman: [15],
  'Oil City': [19, 39],
  'Oil Springs': [18],
  'Oil Trough': [3],
  Oilmont: [27],
  Oilton: [37, 44],
  Oilville: [46],
  Ojai: [5],
  Ojibwa: [49],
  'Ojo Caliente': [33],
  'Ojo Feliz': [33],
  Okabena: [24],
  Okahumpka: [10],
  Okaloosa: [-10],
  Okanogan: [48, -48],
  Okarche: [37],
  Okatie: [41],
  Okaton: [42],
  Okauchee: [49],
  Okawville: [15],
  Okay: [37],
  Okeana: [36],
  Okeechobee: [10, -10],
  Okeene: [37],
  Okemah: [37],
  Okemos: [23],
  Oketo: [17],
  Okfuskee: [-37],
  Oklahoma: [-37],
  'Oklahoma City': [37],
  Oklaunion: [44],
  Oklee: [24],
  Okmulgee: [37, -37],
  Okoboji: [13],
  Okolona: [3, 26, 36],
  Okreek: [42],
  Oktaha: [37],
  Oktibbeha: [-26],
  Ola: [3, 14],
  Olalla: [48],
  Olancha: [5],
  Olanta: [39, 41],
  Olar: [41],
  Olathe: [6, 17],
  Olaton: [18],
  Olcott: [35],
  'Old Appleton': [25],
  'Old Bethpage': [35],
  'Old Bridge': [32],
  'Old Chatham': [35],
  'Old Fields': [50],
  'Old Forge': [35, 39],
  'Old Fort': [28, 36, 43],
  'Old Glory': [44],
  'Old Greenwich': [7],
  'Old Harbor': [1],
  'Old Hickory': [43],
  'Old Lyme': [7],
  'Old Mission': [23],
  'Old Monroe': [25],
  'Old Mystic': [7],
  'Old Ocean': [44],
  'Old Orchard Beach': [22],
  'Old Saybrook': [7],
  'Old Station': [5],
  'Old Town': [10, 22],
  'Old Washington': [36],
  'Old Westbury': [35],
  'Old Zionsville': [39],
  Olden: [44],
  Oldenburg: [16],
  Oldfield: [25],
  Oldham: [-18, 42, -44],
  Oldhams: [46],
  Olds: [13],
  Oldsmar: [10],
  Oldtown: [14, 21],
  Oldwick: [32],
  Olean: [25, 35],
  Olema: [5],
  Oley: [39],
  Olga: [48],
  Olin: [13, 28],
  Olive: [27],
  'Olive Branch': [15, 26],
  'Olive Hill': [18],
  Olivebridge: [35],
  Oliveburg: [39],
  Olivehill: [43],
  Olivehurst: [5],
  Oliver: [11, -29, 39],
  'Oliver Springs': [43],
  Olivet: [23, 42],
  Olivia: [24, 28],
  Olla: [19],
  Ollie: [13],
  Olmito: [44],
  Olmitz: [17],
  Olmstead: [18],
  Olmsted: [15, -24],
  'Olmsted Falls': [36],
  Olmstedville: [35],
  Olney: [15, 21, 25, 27, 44],
  'Olney Springs': [6],
  Olpe: [17],
  Olsburg: [17],
  Olton: [44],
  Olustee: [37],
  Olympia: [18, 48],
  'Olympia Fields': [15],
  'Olympic Valley': [5],
  Olyphant: [39],
  Omaha: [3, 11, 15, 30, 44],
  Omak: [48],
  Omar: [50],
  Omega: [11, 37],
  Omena: [23],
  Omer: [23],
  Omro: [49],
  Ona: [10, 50],
  Onaga: [17],
  Onaka: [42],
  Onalaska: [44, 48, 49],
  Onamia: [24],
  Onancock: [46],
  Onarga: [15],
  Onawa: [13],
  Onaway: [23],
  Oneco: [7, 10],
  Onego: [50],
  Oneida: [3, -14, 15, 17, 18, 35, -35, 39, 43, 49, -49],
  Oneill: [30],
  Onekama: [23],
  Onemo: [46],
  Oneonta: [2, 35],
  Ong: [30],
  Onia: [3],
  Onida: [42],
  Onley: [46],
  Only: [43],
  Ono: [39],
  Onondaga: [23, -35],
  Onset: [20],
  Onslow: [13, -28],
  Onsted: [23],
  Ontario: [5, 35, -35, 36, 38, 49],
  'Ontario Center': [35],
  Ontonagon: [23, -23],
  Onward: [16],
  Onyx: [5],
  Ookala: [12],
  Oolitic: [16],
  Oologah: [37],
  Ooltewah: [43],
  Oostburg: [49],
  'Opa Locka': [10],
  Opal: [51],
  Opdyke: [15],
  Opelika: [2],
  Opelousas: [19],
  Opheim: [27],
  Ophelia: [46],
  Ophiem: [15],
  Ophir: [6, 38],
  Opolis: [17],
  Opp: [2],
  Oquawka: [15],
  Oquossoc: [22],
  Ora: [16],
  Oracle: [4],
  Oradell: [32],
  Oral: [42],
  Oran: [13, 25],
  Orange: [5, -5, 7, -10, -16, 20, -28, 32, -35, 44, -44, 46, -46, -47],
  'Orange Beach': [2],
  'Orange City': [10, 13],
  'Orange Cove': [5],
  'Orange Grove': [44],
  'Orange Lake': [10],
  'Orange Park': [10],
  'Orange Springs': [10],
  Orangeburg: [35, 41, -41],
  Orangefield: [44],
  Orangevale: [5],
  Orangeville: [15, 36, 39, 45],
  Orbisonia: [39],
  Orcas: [48],
  Orchard: [6, 13, 30, 44],
  'Orchard Hill': [11],
  'Orchard Park': [35],
  Orcutt: [5],
  Ord: [30],
  Orderville: [45],
  Ordinary: [46],
  Ordway: [6],
  'Ore City': [44],
  Oreana: [15],
  Orefield: [39],
  Oregon: [15, 25, -25, 36, 49],
  'Oregon City': [38],
  'Oregon House': [5],
  Oregonia: [36],
  Oreland: [39],
  Orem: [45],
  Orestes: [16],
  Orford: [31],
  Orfordville: [49],
  Organ: [33],
  Orgas: [50],
  Orick: [5],
  Orient: [13, 15, 22, 35, 36, 42, 48],
  Oriental: [28],
  Orinda: [5],
  Orion: [15],
  Oriska: [29],
  Oriskany: [35, 46],
  'Oriskany Falls': [35],
  'Orkney Springs': [46],
  Orla: [44],
  Orland: [5, 16, 22],
  'Orland Park': [15],
  Orlando: [10, 18, 37, 50],
  Orlean: [46],
  Orleans: [5, 16, -19, 20, 23, 30, -35, 47, -47],
  Orlinda: [43],
  Orma: [50],
  'Ormond Beach': [10],
  Ormsby: [24],
  'Oro Grande': [5],
  Orofino: [14],
  Orogrande: [33],
  Orondo: [48],
  Orono: [22],
  Oronoco: [24],
  Oronogo: [25],
  Orosi: [5],
  Orovada: [34],
  Oroville: [5, 48],
  Orr: [24],
  Orrick: [25],
  Orrington: [22],
  'Orrs Island': [22],
  Orrstown: [39],
  Orrtanna: [39],
  Orrum: [28],
  Orrville: [2, 36],
  Orson: [39],
  Orting: [48],
  Ortley: [42],
  Ortonville: [23, 24],
  Orviston: [39],
  Orwell: [35, 36, 47],
  Orwigsburg: [39],
  Osage: [13, -17, 24, -25, 37, -37, 50, 51],
  'Osage Beach': [25],
  'Osage City': [17],
  Osakis: [24],
  Osawatomie: [17],
  Osborn: [25],
  Osborne: [17, -17],
  Osburn: [14],
  Oscar: [19, 37],
  Osceola: [3, -10, 13, -13, 16, -23, 25, 30, 39, 49],
  'Osceola Mills': [39],
  Osco: [15],
  Oscoda: [23, -23],
  Osgood: [16, 36],
  Oshkosh: [30, 49],
  Oshtemo: [23],
  Oskaloosa: [13, 17],
  Oslo: [24],
  Osmond: [30],
  Osnabrock: [29],
  Osprey: [10],
  Osseo: [23, 24, 49],
  Ossian: [13, 16],
  Ossineke: [23],
  Ossining: [35],
  Ossipee: [31],
  Osteen: [10],
  Osterburg: [39],
  Osterville: [20],
  Ostrander: [24, 36],
  Oswegatchie: [35],
  Oswego: [15, 17, 35, -35],
  Osyka: [26],
  Otego: [35],
  Otero: [-6, -33],
  Othello: [48],
  Otho: [13],
  Otis: [6, 17, 19, 20, 38],
  'Otis Orchards': [48],
  Otisco: [16],
  Otisville: [23, 35],
  Otley: [13],
  Oto: [13],
  Otoe: [30, -30],
  Otsego: [23, -23, 24, -35],
  Ottawa: [15, 17, -17, -23, 36, -36, -37, 50],
  'Ottawa Lake': [23],
  Otter: [27],
  'Otter Creek': [10],
  'Otter Lake': [23],
  'Otter Rock': [38],
  'Otter Tail': [-24],
  Otterbein: [16],
  Ottertail: [24],
  Otterville: [25],
  Ottine: [44],
  Otto: [28, 35, 51],
  Ottosen: [13],
  Ottoville: [36],
  Ottsville: [39],
  Ottumwa: [13],
  Otway: [36],
  Otwell: [16],
  Ouachita: [-3, -19],
  Ouaquaga: [35],
  Ouray: [6, -6],
  Outagamie: [-49],
  Outing: [24],
  Outlook: [27, 48],
  Ouzinkie: [1],
  Ovalo: [44],
  Ovando: [27],
  Overbrook: [17, 37],
  Overgaard: [4],
  'Overland Park': [17],
  Overpeck: [36],
  Overton: [30, 34, -43, 44],
  Ovett: [26],
  Ovid: [6, 23, 35],
  Oviedo: [10],
  Owaneco: [15],
  Owanka: [42],
  Owasso: [37],
  Owatonna: [24],
  Owego: [35],
  Owen: [-16, -18, 49],
  Owendale: [23],
  'Owens Cross Roads': [2],
  Owensboro: [18],
  Owensburg: [16],
  Owensville: [16, 25, 36],
  Owenton: [18],
  Owings: [21],
  'Owings Mills': [21],
  Owingsville: [18],
  'Owls Head': [22, 35],
  Owosso: [23],
  Owsley: [-18],
  Owyhee: [-14, 34],
  Oxbow: [22, 38],
  Oxford: [2, 3, 7, 10, 11, 13, 16, 17, 20, 21, 22, -22, 23, 26, 28, 30, 32, 35, 36, 39, 49],
  'Oxford Junction': [13],
  Oxly: [25],
  Oxnard: [5],
  'Oxon Hill': [21],
  Oyens: [13],
  Oyster: [46],
  'Oyster Bay': [35],
  Oysterville: [48],
  Ozan: [3],
  Ozark: [2, 3, 15, 25, -25],
  Ozaukee: [-49],
  Ozawkie: [17],
  Ozona: [10, 44],
  Ozone: [3],
  'Ozone Park': [35],
  'O\u2019Brien': [10, 38, 44],
  'O\u2019Fallon': [15, 25],
  'O\u2019Kean': [3],
  'O\u2019Neals': [5],
  Paauilo: [12],
  Pablo: [27],
  Pace: [26],
  Pachuta: [26],
  Pacific: [25, 48, -48],
  'Pacific Beach': [48],
  'Pacific City': [38],
  'Pacific Grove': [5],
  'Pacific Junction': [13],
  'Pacific Palisades': [5],
  Pacifica: [5],
  Packwaukee: [49],
  Packwood: [13, 48],
  Pacoima: [5],
  Pacolet: [41],
  'Pacolet Mills': [41],
  Paden: [37],
  'Paden City': [50],
  Padroni: [6],
  Paducah: [18, 44],
  'Paeonian Springs': [46],
  Page: [4, -13, 29, 30, -46, 50],
  Pageland: [41],
  Pageton: [50],
  'Pagosa Springs': [6],
  Paguate: [33],
  Pahala: [12],
  Pahoa: [12],
  Pahokee: [10],
  Pahrump: [34],
  Paia: [12],
  Paicines: [5],
  Paige: [44],
  Paincourtville: [19],
  Painesdale: [23],
  Painesville: [36],
  'Paint Bank': [46],
  'Paint Lick': [18],
  'Paint Rock': [2, 44],
  'Painted Post': [35],
  Painter: [46],
  Paintsville: [18],
  Paisley: [10, 38],
  Pala: [5],
  Palacios: [44],
  Palatine: [15],
  'Palatine Bridge': [35],
  Palatka: [10],
  Palco: [17],
  Palenville: [35],
  Palermo: [5, 22, 29],
  Palestine: [3, 15, 36, 44, 50],
  Palisade: [6, 24, 30],
  Palisades: [35, 48],
  'Palisades Park': [32],
  'Pall Mall': [43],
  Palm: [39],
  'Palm Bay': [10],
  'Palm Beach': [10, -10],
  'Palm Beach Gardens': [10],
  'Palm City': [10],
  'Palm Coast': [10],
  'Palm Desert': [5],
  'Palm Harbor': [10],
  'Palm Springs': [5],
  Palmdale: [5, 10],
  Palmer: [1, 13, 15, 17, 20, 23, 30, 43, 44],
  'Palmer Lake': [6],
  Palmerdale: [2],
  Palmersville: [43],
  Palmerton: [39],
  Palmetto: [10, 11, 19],
  Palms: [23],
  Palmyra: [15, 16, 22, 23, 25, 30, 32, 35, 39, 43, 46, 49],
  Palo: [13, 23],
  'Palo Alto': [5, -13],
  'Palo Cedro': [5],
  'Palo Pinto': [44, -44],
  'Palo Verde': [4, 5],
  Paloma: [15],
  'Palomar Mountain': [5],
  'Palos Heights': [15],
  'Palos Hills': [15],
  'Palos Park': [15],
  'Palos Verdes Estates': [5],
  'Palos Verdes Peninsula': [5],
  Palouse: [48],
  Paluxy: [44],
  Pamlico: [-28],
  Pampa: [44],
  Pamplico: [41],
  Pamplin: [46],
  Pana: [15],
  Panaca: [34],
  Panacea: [10],
  Panama: [13, 15, 30, 35, 37],
  'Panama City': [10],
  'Panama City Beach': [10],
  Pandora: [36, 44],
  Pangburn: [3],
  Panguitch: [45],
  Panhandle: [44],
  'Panna Maria': [44],
  Panola: [2, -26, 37, 44, -44],
  Panora: [13],
  'Panorama City': [5],
  Pansey: [2],
  Pantego: [28],
  Panther: [50],
  'Panther Burn': [26],
  Paola: [17],
  Paoli: [6, 16, 37, 39],
  Paonia: [6],
  Papaaloa: [12],
  Papaikou: [12],
  Papillion: [30],
  Papineau: [15],
  Parachute: [6],
  Paradis: [19],
  Paradise: [5, 17, 23, 27, 39, 44, 45],
  'Paradise Inn': [48],
  'Paradise Valley': [4, 34],
  Paradox: [6, 35],
  Paragon: [16],
  Paragonah: [45],
  Paragould: [3],
  Paramount: [5],
  Paramus: [32],
  'Parcel Return Service': [8],
  Parchman: [26],
  Pardeeville: [49],
  Paris: [3, 14, 15, 18, 22, 23, 25, 26, 36, 43, 44, 46],
  'Paris Crossing': [16],
  Parish: [35],
  Parishville: [35],
  Park: [-6, 17, -27, -51],
  'Park City': [18, 27, 45],
  'Park Falls': [49],
  'Park Forest': [15],
  'Park Hall': [21],
  'Park Hill': [37],
  'Park Hills': [25],
  'Park Rapids': [24],
  'Park Ridge': [15, 32],
  'Park River': [29],
  'Park Valley': [45],
  Parkdale: [3],
  Parke: [-16],
  Parker: [4, 6, 14, 17, 39, 42, -44, 48],
  'Parker City': [16],
  'Parker Dam': [5],
  'Parker Ford': [39],
  'Parkers Lake': [18],
  'Parkers Prairie': [24],
  Parkersburg: [13, 15, 50],
  Parkesburg: [39],
  Parkhill: [39],
  Parkin: [3],
  Parkland: [10],
  Parkman: [36, 51],
  Parks: [3, 4, 30],
  Parksley: [46],
  Parkston: [42],
  Parksville: [18, 35, 41],
  Parkton: [21, 28],
  Parkville: [21],
  Parlier: [5],
  Parlin: [6, 32],
  Parma: [14, 23, 25],
  Parmele: [28],
  Parmelee: [42],
  Parmer: [-44],
  Parnell: [13, 25],
  Paron: [3],
  Parowan: [45],
  'Parris Island': [41],
  Parrish: [2, 10],
  Parrott: [11, 46],
  Parrottsville: [43],
  Parryville: [39],
  Parshall: [6, 29],
  Parsippany: [32],
  Parsons: [17, 43, 50],
  Parsonsburg: [21],
  Parsonsfield: [22],
  Parthenon: [3],
  Partlow: [46],
  Partridge: [17, 18],
  Pasadena: [5, 21, 44],
  Pascagoula: [26],
  Pasco: [-10, 48],
  Pascoag: [40],
  Paskenta: [5],
  'Paso Robles': [5],
  Pasquotank: [-28],
  'Pass Christian': [26],
  Passadumkeag: [22],
  Passaic: [32, -32],
  Passumpsic: [47],
  Patagonia: [4],
  Pataskala: [36],
  'Patch Grove': [49],
  Patchogue: [35],
  Pateros: [48],
  Paterson: [32, 48],
  Pathfork: [18],
  Patoka: [15, 16],
  Paton: [13],
  Patrick: [41, -46],
  'Patrick Afb': [10],
  'Patrick Springs': [46],
  Patricksburg: [16],
  Patriot: [16, 36],
  Patten: [22],
  Patterson: [3, 5, 11, 13, 15, 19, 25, 28, 35],
  Pattersonville: [35],
  Pattison: [26, 44],
  Patton: [5, 25, 39],
  Pattonsburg: [25],
  Pattonville: [44],
  'Patuxent River': [21],
  Paul: [14],
  'Paul Smiths': [35],
  Paulden: [4],
  Paulding: [-11, 26, 36, -36],
  Paulina: [19, 38],
  Pauline: [41],
  Paullina: [13],
  'Pauls Valley': [37],
  Paulsboro: [32],
  'Pauma Valley': [5],
  Paupack: [39],
  Pavilion: [35],
  Pavillion: [51],
  Pavo: [11],
  'Paw Creek': [28],
  'Paw Paw': [15, 23, 50],
  Pawcatuck: [7],
  Pawhuska: [37],
  Pawlet: [47],
  'Pawleys Island': [41],
  Pawling: [35],
  Pawnee: [15, -17, -30, 37, -37, 44],
  'Pawnee City': [30],
  'Pawnee Rock': [17],
  Pawtucket: [40],
  Pax: [50],
  Paxico: [17],
  Paxinos: [39],
  Paxton: [10, 15, 16, 20, 30],
  Paxtonville: [39],
  Payette: [14, -14],
  Payne: [36, -37],
  'Paynes Creek': [5],
  Paynesville: [24, 50],
  Payneville: [18],
  Payson: [4, 15, 45],
  'Pe Ell': [48],
  'Pea Ridge': [3],
  Peabody: [17, 20],
  'Peace Dale': [40],
  'Peace Valley': [25],
  Peach: [-11],
  'Peach Bottom': [39],
  'Peach Creek': [50],
  'Peach Glen': [39],
  'Peach Orchard': [3],
  'Peach Springs': [4],
  Peacham: [47],
  Peachland: [28],
  'Peachtree City': [11],
  'Peachtree Corners': [11],
  Peak: [41],
  'Peaks Island': [22],
  Peapack: [32],
  Pearblossom: [5],
  Pearce: [4],
  Pearcy: [3],
  Pearisburg: [46],
  Pearl: [15, 26],
  'Pearl City': [12, 15],
  'Pearl River': [19, -26, 35],
  Pearland: [44],
  Pearlington: [26],
  Pearsall: [44],
  Pearson: [11, 49],
  Pease: [24],
  Peaster: [44],
  'Pebble Beach': [5],
  'Pecan Gap': [44],
  Pecatonica: [15],
  Peck: [14, 17, 23],
  'Pecks Mill': [50],
  Peckville: [39],
  Peconic: [35],
  Pecos: [33, 44, -44],
  Peculiar: [25],
  Pedricktown: [32],
  Pedro: [36],
  'Pedro Bay': [1],
  Peebles: [36],
  Peekskill: [35],
  Peel: [3],
  Peerless: [27],
  Peetz: [6],
  Peever: [42],
  Peggs: [37],
  Peggy: [44],
  Pegram: [43],
  Pekin: [15, 16, 29],
  Pelahatchie: [26],
  Pelham: [2, 11, 28, 31, 35, 43],
  Pelican: [1, 19],
  'Pelican Lake': [49],
  'Pelican Rapids': [24],
  Pelion: [41],
  Pelkie: [23],
  'Pell City': [2],
  'Pell Lake': [49],
  Pella: [13],
  Pellston: [23],
  Pelsor: [3],
  Pelzer: [41],
  Pemaquid: [22],
  Pemberton: [24, 32, 36],
  Pemberville: [36],
  Pembina: [29, -29],
  Pembine: [49],
  Pembroke: [11, 18, 20, 22, 28, 46],
  'Pembroke Pines': [10],
  'Pembroke Township': [15],
  Pemiscot: [-25],
  'Pen Argyl': [39],
  'Pena Blanca': [33],
  Penasco: [33],
  'Pence Springs': [50],
  'Pencil Bluff': [3],
  'Pend Oreille': [-48],
  Pender: [-28, 30],
  Pendergrass: [11],
  Pendleton: [16, 18, -18, 28, 38, 41, 44, -50],
  Pendroy: [27],
  Penelope: [44],
  Penfield: [15, 35, 39],
  Pengilly: [24],
  Penhook: [46],
  Peninsula: [36],
  Penitas: [44],
  Penland: [28],
  Penn: [29, 39],
  'Penn Laird': [46],
  'Penn Run': [39],
  'Penn Valley': [5],
  'Penn Yan': [35],
  Pennellville: [35],
  'Penney Farms': [10],
  Penngrove: [5],
  Pennington: [2, 24, -24, 32, -42, 44],
  'Pennington Gap': [46],
  Pennock: [24],
  'Penns Creek': [39],
  'Penns Grove': [32],
  'Penns Park': [39],
  Pennsauken: [32],
  Pennsboro: [50],
  Pennsburg: [39],
  Pennsville: [32],
  'Pennsylvania Furnace': [39],
  Pennville: [16],
  Penobscot: [22, -22],
  Penokee: [17],
  Penrose: [6, 28],
  Penryn: [5, 39],
  Pensacola: [10],
  Pentress: [50],
  Pentwater: [23],
  Penwell: [44],
  Peoa: [45],
  Peoria: [4, 15, -15],
  'Peoria Heights': [15],
  Peosta: [13],
  Peotone: [15],
  Pep: [33, 44],
  Pepeekeo: [12],
  Pepin: [49, -49],
  'Pepper Pike': [36],
  Pepperell: [20],
  Pequabuck: [7],
  Pequannock: [32],
  Pequea: [39],
  'Pequot Lakes': [24],
  Peralta: [33],
  Percival: [13],
  Percy: [15],
  Perdido: [2],
  'Perdido Key': [10],
  'Perdue Hill': [2],
  Perham: [22, 24],
  Peridot: [4],
  Perkasie: [39],
  Perkins: [11, 23, 25, -30, 37, -42],
  Perkinston: [26],
  Perkinsville: [35, 47],
  Perkiomenville: [39],
  Perks: [15],
  Perley: [24],
  Perote: [2],
  Perquimans: [-28],
  Perrin: [44],
  Perrinton: [23],
  Perris: [5],
  Perronville: [23],
  Perry: [-2, 3, -3, 10, 11, 13, 15, -15, -16, 17, -18, 19, 22, 23, 25, -25, -26, 35, 36, -36, 37, -39, -43],
  'Perry Hall': [21],
  'Perry Park': [18],
  'Perry Point': [21],
  Perryman: [21],
  Perryopolis: [39],
  Perrysburg: [35, 36],
  Perrysville: [16, 36],
  Perryton: [44],
  Perryville: [1, 3, 18, 21, 25],
  Pershing: [16, -34],
  Persia: [13],
  Person: [-28],
  Perth: [29],
  'Perth Amboy': [32],
  Peru: [13, 15, 16, 17, 22, 30, 35, 47],
  Pescadero: [5],
  Peshastin: [48],
  Peshtigo: [49],
  Pesotum: [15],
  Petaca: [33],
  Petal: [26],
  Petaluma: [5],
  Peterboro: [35],
  Peterborough: [31],
  Peterman: [2],
  Petersburg: [1, -1, 15, 16, 18, 23, 29, 30, 35, 36, 39, 43, 44, 46, 50],
  Petersham: [20],
  Peterson: [2, 13, 24],
  Peterstown: [50],
  Petoskey: [23],
  Petrey: [2],
  'Petrified Forest Natl Pk': [4],
  Petroleum: [16, -27, 50],
  Petrolia: [5, 39, 44],
  Petros: [43],
  Pettibone: [29],
  Pettigrew: [3],
  Pettis: [-25],
  Pettisville: [36],
  Pettus: [44],
  Petty: [44],
  Pevely: [25],
  Pewamo: [23],
  Pewaukee: [49],
  'Pewee Valley': [18],
  Peyton: [6],
  Peytona: [50],
  Pfafftown: [28],
  Pfeifer: [17],
  Pflugerville: [44],
  Pharr: [44],
  Pheba: [26],
  Phelan: [5],
  Phelps: [18, -25, -30, 35, 49],
  Phenix: [46],
  'Phenix City': [2],
  'Phil Campbell': [2],
  Philadelphia: [25, 26, 35, 39, -39, 43],
  Philip: [42],
  Philipp: [26],
  Philippi: [50],
  Philipsburg: [27, 39],
  Phillips: [-3, -6, -17, 22, -27, 30, 49],
  'Phillips Ranch': [5],
  Phillipsburg: [17, 25, 32, 36],
  Phillipsport: [35],
  Phillipsville: [5],
  Philmont: [35],
  Philo: [5, 15, 36],
  Philomath: [38],
  Philomont: [46],
  Philpot: [18],
  Phippsburg: [6, 22],
  Phlox: [49],
  Phoenicia: [35],
  Phoenix: [4, 21, 35, 38],
  Phoenixville: [39],
  Phyllis: [18],
  Piasa: [15],
  Piatt: [-15],
  Picabo: [14],
  Picacho: [4, 33],
  'Picatinny Arsenal': [32],
  Picayune: [26],
  Picher: [37],
  Pickaway: [-36],
  Pickens: [-2, 3, -11, 26, 37, 41, -41, 50],
  Pickerel: [49],
  Pickering: [25],
  Pickerington: [36],
  Pickett: [-43, 49],
  Pickford: [23],
  Pickrell: [30],
  Pickstown: [42],
  Pickton: [44],
  'Pickwick Dam': [43],
  'Pico Rivera': [5],
  'Picture Rocks': [39],
  'Pie Town': [33],
  Piedmont: [2, 5, 17, 25, 36, 37, 41, 42, 50],
  Piedra: [5],
  Pierce: [6, -11, 14, -29, 30, -30, 44, -48, -49],
  'Pierce City': [25],
  Piercefield: [35],
  Pierceton: [16],
  Pierceville: [16, 17],
  Piercy: [5],
  Piermont: [31, 35],
  Pierpont: [36, 42],
  Pierre: [42],
  'Pierre Part': [19],
  'Pierrepont Manor': [35],
  Pierron: [15],
  Pierson: [10, 13, 23],
  Pierz: [24],
  Piffard: [35],
  Pigeon: [23],
  'Pigeon Falls': [49],
  'Pigeon Forge': [43],
  Piggott: [3],
  Pike: [-2, -3, -11, -15, -16, -18, -25, -26, 31, 35, -36, -39],
  'Pike Road': [2],
  Pikesville: [21],
  Piketon: [36],
  Pikeville: [18, 28, 43],
  Pilger: [30],
  Pilgrim: [18],
  'Pilgrims Knob': [46],
  Pillager: [24],
  Pillow: [39],
  Pillsbury: [29],
  Pilot: [46],
  'Pilot Grove': [13, 25],
  'Pilot Hill': [5],
  'Pilot Knob': [25],
  'Pilot Mound': [13],
  'Pilot Mountain': [28],
  'Pilot Point': [1, 44],
  'Pilot Rock': [38],
  'Pilot Station': [1],
  Pilottown: [19],
  Pima: [4, -4],
  Pimento: [16],
  Pinal: [-4],
  Pinch: [50],
  Pinckard: [2],
  Pinckney: [23],
  Pinckneyville: [15],
  Pinconning: [23],
  Pindall: [3],
  Pine: [4, 6, -24],
  'Pine Apple': [2],
  'Pine Beach': [32],
  'Pine Bluff': [3],
  'Pine Bluffs': [51],
  'Pine Brook': [32],
  'Pine Bush': [35],
  'Pine City': [24, 35],
  'Pine Forge': [39],
  'Pine Grove': [5, 19, 39, 50],
  'Pine Grove Mills': [39],
  'Pine Hall': [28],
  'Pine Hill': [2, 35],
  'Pine Island': [24, 35],
  'Pine Knot': [18],
  'Pine Lake': [11],
  'Pine Level': [2, 28],
  'Pine Meadow': [7],
  'Pine Mountain': [11],
  'Pine Mountain Club': [5],
  'Pine Mountain Valley': [11],
  'Pine Plains': [35],
  'Pine Prairie': [19],
  'Pine Ridge': [18, 42],
  'Pine River': [24, 49],
  'Pine Top': [18],
  'Pine Valley': [5, 35, 45],
  'Pine Village': [16],
  Pinebluff: [28],
  Pinecliffe: [6],
  Pinecrest: [5, 10],
  Pinedale: [4, 51],
  Pinehill: [33],
  Pinehurst: [11, 14, 20, 28, 44],
  Pineland: [10, 41, 44],
  Pinellas: [-10],
  'Pinellas Park': [10],
  Pineola: [28],
  Pinesdale: [27],
  Pinetop: [4],
  Pinetops: [28],
  Pinetown: [28],
  Pinetta: [10],
  Pineview: [11],
  Pineville: [3, 18, 19, 25, 28, 39, 41, 50],
  Pinewood: [41],
  'Piney Creek': [28],
  'Piney Flats': [43],
  'Piney Fork': [36],
  'Piney Point': [21],
  'Piney River': [46],
  'Piney View': [50],
  'Piney Woods': [26],
  Pingree: [14, 29],
  'Pink Hill': [28],
  Pinnacle: [28],
  Pinola: [26],
  Pinole: [5],
  Pinon: [4, 33],
  'Pinon Hills': [5],
  Pinopolis: [41],
  'Pinos Altos': [33],
  Pinson: [2, 43],
  Pinsonfork: [18],
  Pinto: [21],
  Pioche: [34],
  Pioneer: [5, 19, 36, 43],
  Pioneertown: [5],
  'Pipe Creek': [44],
  'Piper City': [15],
  Pipersville: [39],
  Pipestem: [50],
  Pipestone: [24, -24],
  'Pippa Passes': [18],
  Piqua: [17, 36],
  Pirtleville: [4],
  Piru: [5],
  Piscataquis: [-22],
  Piscataway: [32],
  Piseco: [35],
  Pisek: [29],
  Pisgah: [2, 13],
  'Pisgah Forest': [28],
  'Pismo Beach': [5],
  Pitcairn: [39],
  Pitcher: [35],
  Pitkin: [6, -6, 19],
  Pitman: [32, 39],
  Pitsburg: [36],
  Pitt: [-28],
  Pitts: [11],
  Pittsboro: [16, 26, 28],
  Pittsburg: [5, 15, 17, 18, 25, 31, 37, -37, 44],
  Pittsburgh: [39],
  Pittsfield: [15, 20, 22, 31, 39, 47],
  Pittsford: [23, 35, 47],
  Pittston: [39],
  Pittstown: [32],
  Pittsview: [2],
  Pittsville: [21, 46, 49],
  Pittsylvania: [-46],
  Piute: [-45],
  Pixley: [5],
  Placedo: [44],
  Placentia: [5],
  Placer: [-5],
  Placerville: [5, 6, 14],
  Placida: [10],
  Placitas: [33],
  Plain: [49],
  'Plain City': [36],
  'Plain Dealing': [19],
  Plainfield: [7, 13, 15, 16, 20, 31, 32, 36, 39, 47, 49],
  Plains: [11, 17, 27, 44],
  Plainsboro: [32],
  Plainview: [3, 24, 30, 35, 44],
  Plainville: [7, 11, 15, 16, 17, 20, 35],
  Plainwell: [23],
  Plaistow: [31],
  Planada: [5],
  Plankinton: [42],
  Plano: [13, 15, 44],
  'Plant City': [10],
  Plantation: [10],
  Plantersville: [2, 26, 44],
  Plantsville: [7],
  Plaquemine: [19],
  Plaquemines: [-19],
  Platina: [5],
  Platinum: [1],
  Plato: [24, 25],
  Platte: [-25, -30, 42, -51],
  'Platte Center': [30],
  'Platte City': [25],
  Plattekill: [35],
  Plattenville: [19],
  Platter: [37],
  Platteville: [6, 49],
  Plattsburg: [25],
  Plattsburgh: [35],
  Plattsmouth: [30],
  Plaucheville: [19],
  'Playa Del Rey': [5],
  'Playa Vista': [5],
  Playas: [33],
  Plaza: [29],
  'Pleasant City': [36],
  'Pleasant Dale': [30],
  'Pleasant Garden': [28],
  'Pleasant Grove': [2, 3, 5, 45],
  'Pleasant Hall': [39],
  'Pleasant Hill': [5, 13, 15, 19, 25, 28, 36, 38, 43],
  'Pleasant Hope': [25],
  'Pleasant Lake': [16, 23],
  'Pleasant Mills': [16],
  'Pleasant Mount': [39],
  'Pleasant Plain': [36],
  'Pleasant Plains': [3, 15],
  'Pleasant Prairie': [49],
  'Pleasant Ridge': [23],
  'Pleasant Shade': [43],
  'Pleasant Unity': [39],
  'Pleasant Valley': [13, 35, 46],
  'Pleasant View': [6, 43, 45],
  Pleasanton: [5, 17, 30, 44],
  Pleasants: [-50],
  Pleasantville: [13, 32, 35, 36, 39],
  Pleasureville: [18],
  Pledger: [44],
  Plentywood: [27],
  Plessis: [35],
  Plevna: [17, 27],
  Plover: [13, 49],
  Pluckemin: [32],
  Plum: [44],
  'Plum Branch': [41],
  'Plum City': [49],
  Plumas: [-5],
  'Plumas Lake': [5],
  Plumerville: [3],
  Plummer: [14, 24],
  'Plummers Landing': [18],
  Plumsteadville: [39],
  Plumtree: [28],
  Plumville: [39],
  Plush: [38],
  Plymouth: [5, 7, 10, 13, -13, 15, 16, 20, -20, 22, 23, 24, 28, 30, 31, 35, 36, 39, 45, 47, 48, 49],
  'Plymouth Meeting': [39],
  Plympton: [20],
  Poca: [50],
  Pocahontas: [3, 13, -13, 15, 25, 43, 46, -50],
  Pocasset: [20, 37],
  Pocatello: [14],
  Pocola: [37],
  'Pocomoke City': [21],
  'Pocono Lake': [39],
  'Pocono Lake Preserve': [39],
  'Pocono Manor': [39],
  'Pocono Pines': [39],
  'Pocono Summit': [39],
  Pocopson: [39],
  Poestenkill: [35],
  Poinsett: [-3],
  Point: [44],
  'Point Arena': [5],
  'Point Baker': [1],
  'Point Clear': [2],
  'Point Comfort': [44],
  'Point Harbor': [28],
  'Point Hope': [1],
  'Point Lay': [1],
  'Point Lookout': [25, 35],
  'Point Marion': [39],
  'Point Mugu Nawc': [5],
  'Point Of Rocks': [21, 51],
  'Point Pleasant': [39, 50],
  'Point Pleasant Beach': [32],
  'Point Reyes Station': [5],
  'Point Roberts': [48],
  Pointblank: [44],
  'Pointe A La Hache': [19],
  'Pointe Aux Pins': [23],
  'Pointe Coupee': [-19],
  Points: [50],
  Polacca: [4],
  Poland: [16, 22, 35, 36],
  Polaris: [27],
  Polebridge: [27],
  Polk: [-3, -10, -11, -13, -24, 25, -25, -28, 30, -30, 36, -38, 39, -43, -44, -49],
  'Polk City': [10, 13],
  Polkton: [28],
  Polkville: [28],
  Pollard: [3],
  Pollock: [14, 19, 25, 42],
  'Pollock Pines': [5],
  Pollocksville: [28],
  Pollok: [44],
  Polo: [15, 25],
  Polson: [27],
  Polvadera: [33],
  Pomaria: [41],
  Pomerene: [4],
  Pomeroy: [13, 36, 39, 48],
  Pomfret: [7, 21],
  'Pomfret Center': [7],
  Pomona: [5, 15, 17, 25, 32, 35],
  'Pomona Park': [10],
  'Pompano Beach': [10],
  Pompeii: [23],
  Pompey: [35],
  'Pompeys Pillar': [27],
  'Pompton Lakes': [32],
  'Pompton Plains': [32],
  Ponca: [3, 30],
  'Ponca City': [37],
  'Ponce De Leon': [10, 25],
  'Poncha Springs': [6],
  Ponchatoula: [19],
  'Pond Creek': [37],
  'Pond Eddy': [35],
  'Pond Gap': [50],
  Ponder: [44],
  Pondera: [-27],
  Ponderay: [14],
  Ponderosa: [33],
  Ponemah: [24],
  Poneto: [16],
  Ponsford: [24],
  'Ponte Vedra': [10],
  'Ponte Vedra Beach': [10],
  Pontiac: [15, 23, 25],
  Pontotoc: [26, -26, -37, 44],
  Pony: [27],
  Pool: [50],
  Poole: [18],
  Pooler: [11],
  Poolesville: [21],
  Poolville: [44],
  Pope: [-3, -15, -24, 26],
  'Pope Army Airfield': [28],
  'Pope Valley': [5],
  Popejoy: [13],
  Poplar: [27, 49],
  'Poplar Bluff': [25],
  'Poplar Branch': [28],
  'Poplar Grove': [3, 15],
  'Poplar Ridge': [35],
  Poplarville: [26],
  Poquonock: [7],
  Poquoson: [46],
  Porcupine: [42],
  'Port Alexander': [1],
  'Port Allegany': [39],
  'Port Allen': [19],
  'Port Alsworth': [1],
  'Port Angeles': [48],
  'Port Aransas': [44],
  'Port Arthur': [44],
  'Port Austin': [23],
  'Port Barre': [19],
  'Port Bolivar': [44],
  'Port Byron': [15, 35],
  'Port Carbon': [39],
  'Port Charlotte': [10],
  'Port Chester': [35],
  'Port Clinton': [36, 39],
  'Port Clyde': [22],
  'Port Costa': [5],
  'Port Crane': [35],
  'Port Deposit': [21],
  'Port Edwards': [49],
  'Port Elizabeth': [32],
  'Port Ewen': [35],
  'Port Gamble': [48],
  'Port Gibson': [26, 35],
  'Port Hadlock': [48],
  'Port Haywood': [46],
  'Port Heiden': [1],
  'Port Henry': [35],
  'Port Hope': [23],
  'Port Hueneme': [5],
  'Port Hueneme Cbc Base': [5],
  'Port Huron': [23],
  'Port Isabel': [44],
  'Port Jefferson': [35, 36],
  'Port Jefferson Station': [35],
  'Port Jervis': [35],
  'Port Kent': [35],
  'Port Lavaca': [44],
  'Port Leyden': [35],
  'Port Lions': [1],
  'Port Ludlow': [48],
  'Port Mansfield': [44],
  'Port Matilda': [39],
  'Port Monmouth': [32],
  'Port Murray': [32],
  'Port Neches': [44],
  'Port Norris': [32],
  'Port Orange': [10],
  'Port Orchard': [48],
  'Port Orford': [38],
  'Port O\u2019Connor': [44],
  'Port Penn': [9],
  'Port Reading': [32],
  'Port Republic': [21, 32, 46],
  'Port Richey': [10],
  'Port Royal': [18, 39, 41, 46],
  'Port Saint Joe': [10],
  'Port Saint Lucie': [10],
  'Port Salerno': [10],
  'Port Sanilac': [23],
  'Port Sulphur': [19],
  'Port Tobacco': [21],
  'Port Townsend': [48],
  'Port Trevorton': [39],
  'Port Washington': [35, 36, 49],
  'Port Wentworth': [11],
  'Port William': [36],
  'Port Wing': [49],
  Portage: [16, 22, 23, 36, -36, 39, 45, 49, -49],
  'Portage Des Sioux': [25],
  Portageville: [25, 35],
  Portal: [11, 29],
  Portales: [33],
  Porter: [-16, 22, 24, 37, 44],
  'Porter Corners': [35],
  'Porter Ranch': [5],
  Porterdale: [11],
  Porterfield: [49],
  'Porters Falls': [50],
  Portersville: [39],
  Porterville: [5, 26],
  Porthill: [14],
  Portia: [3],
  Portis: [17],
  Portland: [3, 7, 16, 22, 23, 25, 29, 35, 36, 38, 39, 43, 44],
  Portlandville: [35],
  Portola: [5],
  'Portola Valley': [5],
  Portsmouth: [13, 31, 36, 40, 46],
  Portville: [35],
  Porum: [37],
  Posen: [15, 23],
  Posey: [5, -16],
  Poseyville: [16],
  Post: [38, 44],
  'Post Falls': [14],
  'Post Mills': [47],
  Poston: [4],
  Postville: [13],
  Poteau: [37],
  Potecasi: [28],
  Poteet: [44],
  Poth: [44],
  Potlatch: [14],
  Potomac: [15, 21],
  Potosi: [25, 49],
  Potrero: [5],
  Potsdam: [35, 36],
  Pottawatomie: [-17, -37],
  Pottawattamie: [-13],
  Potter: [30, -39, -42, -44, 49],
  'Potter Valley': [5],
  Pottersdale: [39],
  Pottersville: [25, 32, 35],
  Potterville: [23],
  'Potts Camp': [26],
  'Potts Grove': [39],
  Pottsboro: [44],
  Pottstown: [39],
  Pottsville: [3, 39, 44],
  Potwin: [17],
  Poughkeepsie: [3, 35],
  Poughquag: [35],
  Poulan: [11],
  Poulsbo: [48],
  Poultney: [47],
  Pound: [46, 49],
  'Pound Ridge': [35],
  'Pounding Mill': [46],
  Poway: [5],
  'Powder River': [51],
  'Powder Springs': [11, 43],
  PowderRiver: [-27],
  Powderhorn: [6],
  Powderly: [18, 44],
  Powdersville: [41],
  Powderville: [27],
  Powell: [-18, 25, -27, 36, 43, 44, 51],
  'Powell Butte': [38],
  'Powells Point': [28],
  Powellsville: [28],
  Powellton: [50],
  Powellville: [21],
  Power: [-14, 27],
  Powers: [23, 38],
  'Powers Lake': [29, 49],
  Powersite: [25],
  Powersville: [25],
  Poweshiek: [-13],
  Powhatan: [3, 19, 46, -46],
  'Powhatan Point': [36],
  Powhattan: [17],
  Pownal: [22, 47],
  'Poy Sippi': [49],
  Poyen: [3],
  Poynette: [49],
  Poynor: [44],
  Poyntelle: [39],
  Prague: [30, 37],
  Prairie: [-3, 26, -27],
  'Prairie City': [13, 15, 38, 42],
  'Prairie Creek': [16],
  'Prairie Du Chien': [49],
  'Prairie Du Rocher': [15],
  'Prairie Du Sac': [49],
  'Prairie Farm': [49],
  'Prairie Grove': [3],
  'Prairie Hill': [44],
  'Prairie Home': [25],
  'Prairie Lea': [44],
  'Prairie View': [17, 44],
  'Prairie Village': [17],
  Prairieburg: [13],
  Prairieton: [16],
  Prairieville: [19],
  Prather: [5],
  Pratt: [17, -17, 50],
  Pratts: [46],
  Prattsburgh: [35],
  Prattsville: [3, 35],
  Prattville: [2],
  Pray: [27],
  Preble: [16, 35, -36],
  Preemption: [15],
  Premier: [50],
  Premium: [18],
  Premont: [44],
  Prentice: [49],
  Prentiss: [26, -26],
  Prescott: [3, 4, 13, 17, 23, 48, 49],
  'Prescott Valley': [4],
  Presho: [42],
  Presidio: [44, -44],
  'Presque Isle': [22, 23, -23, 49],
  Presto: [39],
  Preston: [7, 11, 13, 14, 21, 24, 25, 26, 37, 48, -50],
  'Preston Hollow': [35],
  'Preston Park': [39],
  Prestonsburg: [18],
  'Pretty Prairie': [17],
  Prewitt: [33],
  Price: [21, 44, 45, -49],
  Pricedale: [39],
  Prichard: [50],
  Priddy: [44],
  Pride: [19],
  'Prides Crossing': [20],
  'Priest River': [14],
  Prim: [3],
  Primghar: [13],
  'Primm Springs': [43],
  Primrose: [30],
  Prince: [50],
  'Prince Edward': [-46],
  'Prince Frederick': [21],
  'Prince George': [46, -46],
  'Prince Georges': [-21],
  'Prince William': [-46],
  'Prince of Wales-Hyder': [-1],
  'Princess Anne': [21],
  Princeton: [2, 5, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 25, 28, 32, 38, 44, 49, 50],
  'Princeton Junction': [32],
  Princeville: [12, 15],
  Princewick: [50],
  Prineville: [38],
  Pringle: [42],
  Prinsburg: [24],
  Printer: [18],
  'Prior Lake': [24],
  Pritchett: [6],
  Proberta: [5],
  Procious: [50],
  Proctor: [3, 27, 37, 44, 47, 50],
  Proctorsville: [47],
  Proctorville: [28, 36],
  Progreso: [44],
  Prole: [13],
  'Promise City': [13],
  Prompton: [39],
  Prophetstown: [15],
  Prospect: [7, 18, 35, 36, 38, 39, 43, 46],
  'Prospect Harbor': [22],
  'Prospect Heights': [15],
  'Prospect Hill': [28],
  'Prospect Park': [39],
  Prosper: [44],
  Prosperity: [39, 41, 50],
  Prosser: [48],
  Protection: [17],
  Protem: [25],
  Protivin: [13],
  Provencal: [19],
  Providence: [18, 28, 40, -40, 45],
  'Providence Forge': [46],
  Provincetown: [20],
  Provo: [45],
  Prowers: [-6],
  Pruden: [43],
  'Prudence Island': [40],
  Prudenville: [23],
  'Prudhoe Bay': [1],
  Prue: [37],
  Pryor: [27, 37],
  Puckett: [26],
  Pueblo: [6, -6],
  'Pueblo Of Acoma': [33],
  'Pueblo West': [6],
  Pukalani: [12],
  Pukwana: [42],
  Pulaski: [-3, 11, -11, 13, 15, -15, -16, -18, -25, 26, 35, 39, 43, 46, -46, 49],
  Pullman: [23, 48, 50],
  Pulteney: [35],
  Pultneyville: [35],
  Pungoteague: [46],
  'Punta Gorda': [10],
  Punxsutawney: [39],
  Puposky: [24],
  Purcell: [25, 37],
  Purcellville: [46],
  Purchase: [35],
  Purdin: [25],
  Purdon: [44],
  Purdum: [30],
  Purdy: [25],
  Purdys: [35],
  Purgitsville: [50],
  Purlear: [28],
  Purling: [35],
  Purmela: [44],
  Pursglove: [50],
  Purvis: [26],
  Puryear: [43],
  Pushmataha: [-37],
  'Put In Bay': [36],
  Putnam: [7, -10, -11, 15, -15, -16, -25, -35, -36, 37, -43, 44, -50],
  'Putnam Hall': [10],
  'Putnam Station': [35],
  'Putnam Valley': [35],
  Putnamville: [16],
  Putney: [11, 18, 47],
  Puunene: [12],
  Puxico: [25],
  Puyallup: [48],
  Pyatt: [3],
  Pylesville: [21],
  Pyote: [44],
  Pyrites: [35],
  Quail: [44],
  Quakake: [39],
  'Quaker City': [36],
  'Quaker Hill': [7],
  'Quaker Street': [35],
  Quakertown: [32, 39],
  Quanah: [44],
  Quantico: [21, 46],
  Quapaw: [37],
  Quarryville: [39],
  'Quartz Hill': [5],
  Quartzsite: [4],
  Quasqueton: [13],
  Quay: [33, -33],
  Quebeck: [43],
  Quechee: [47],
  Quecreek: [39],
  Queen: [39],
  'Queen Anne': [21],
  'Queen Annes': [-21],
  'Queen City': [25, 44],
  'Queen Creek': [4],
  Queens: [-35],
  'Queens Village': [35],
  Queensbury: [35],
  Queenstown: [21],
  Quemado: [33, 44],
  Quenemo: [17],
  Quentin: [39],
  Questa: [33],
  Quicksburg: [46],
  Quilcene: [48],
  Quimby: [13],
  Quinault: [48],
  Quinby: [46],
  Quincy: [5, 10, 15, 16, 18, 20, 23, 25, 36, 39, 48],
  Quinebaug: [7],
  Quinhagak: [1],
  Quinlan: [44],
  Quinn: [42],
  Quinnesec: [23],
  Quinque: [46],
  Quinter: [17],
  Quinton: [2, 32, 37, 46],
  Quinwood: [50],
  Quitaque: [44],
  Quitman: [3, 11, -11, 19, 26, -26, 44],
  Qulin: [25],
  Quogue: [35],
  Rabun: [-11],
  'Rabun Gap': [11],
  Raccoon: [18],
  Raceland: [19],
  Rachel: [50],
  Racine: [24, 25, 36, 49, -49, 50],
  Rackerby: [5],
  Radcliff: [18],
  Radcliffe: [13],
  Radersburg: [27],
  Radford: [46],
  Radiant: [46],
  Radisson: [49],
  'Radium Springs': [33],
  Radnor: [36],
  Radom: [15],
  Raeford: [28],
  Ragan: [30],
  Ragland: [2, 50],
  Ragley: [19],
  Ragsdale: [16],
  Rahway: [32],
  Raiford: [10],
  'Rail Road Flat': [5],
  Railroad: [39],
  Rainbow: [44],
  'Rainbow City': [2],
  'Rainbow Lake': [35],
  Rainelle: [50],
  Rainier: [38, 48],
  Rains: [41, -44],
  Rainsville: [2, 33],
  'Raisin City': [5],
  Rake: [13],
  Raleigh: [15, 26, 28, 29, 50, -50],
  Ralls: [-25, 44],
  Ralph: [2, 23, 42],
  Ralston: [13, 37, 39, 51],
  Ramah: [6, 33],
  Ramer: [2, 43],
  Ramey: [39],
  Ramona: [5, 17, 37, 42],
  Rampart: [1],
  Ramsay: [23, 27],
  Ramseur: [28],
  Ramsey: [15, 16, 24, -24, -29, 32],
  Ranburne: [2],
  Ranchester: [51],
  Ranchita: [5],
  'Rancho Bernardo': [5],
  'Rancho Cordova': [5],
  'Rancho Cucamonga': [5],
  'Rancho Mirage': [5],
  'Rancho Palos Verdes': [5],
  'Rancho San Diego': [5],
  'Rancho Santa Fe': [5],
  'Rancho Santa Margarita': [5],
  'Ranchos De Taos': [33],
  Rancocas: [32],
  Rand: [6],
  Randalia: [13],
  Randall: [13, 17, 24, -44],
  Randallstown: [21],
  Randle: [48],
  Randleman: [28],
  Randlett: [37, 45],
  Randolph: [2, -2, -3, -11, 13, -15, -16, 17, 20, 22, 24, -25, 26, -28, 30, 31, 32, 35, 36, 44, 45, 46, 47, 49, -50],
  'Randolph Center': [47],
  'Random Lake': [49],
  Randsburg: [5],
  Range: [2],
  Rangeley: [22],
  Rangely: [6],
  Ranger: [11, 44, 50],
  Ranier: [24],
  Rankin: [15, -26, 44],
  Ransom: [15, 17, 18, -29, 39],
  'Ransom Canyon': [44],
  Ransomville: [35],
  Ranson: [50],
  Rantoul: [15, 17],
  Rapelje: [27],
  Raphine: [46],
  'Rapid City': [23, 42],
  'Rapid River': [23],
  Rapidan: [46],
  Rapides: [-19],
  'Rapids City': [15],
  Rappahannock: [-46],
  'Rappahannock Academy': [46],
  'Raquette Lake': [35],
  Rarden: [36],
  Raritan: [15, 32],
  Ratcliff: [3, 44],
  Rathdrum: [14],
  'Ratliff City': [37],
  Raton: [33],
  Rattan: [37],
  Ravalli: [27, -27],
  Raven: [18, 46],
  Ravena: [35],
  Ravencliff: [50],
  Ravendale: [5],
  Ravenden: [3],
  'Ravenden Springs': [3],
  Ravenel: [41],
  Ravenna: [18, 23, 30, 36, 44],
  Ravensdale: [48],
  Ravenswood: [50],
  Ravenwood: [25],
  Ravia: [37],
  Ravine: [39],
  Rawl: [50],
  Rawlings: [21, 46],
  Rawlins: [-17, 51],
  Rawson: [36],
  Ray: [23, -25, 29, 36],
  'Ray Brook': [35],
  'Ray City': [11],
  Rayland: [36],
  Rayle: [11],
  Raymond: [5, 13, 15, 17, 22, 24, 26, 27, 30, 31, 36, 42, 48],
  Raymondville: [25, 35, 44],
  Raymore: [25],
  Rayne: [19],
  Raynesford: [27],
  Raynham: [20],
  'Raynham Center': [20],
  Raysal: [50],
  Rayville: [19, 25],
  Raywick: [18],
  Raywood: [44],
  Rea: [25],
  Reader: [50],
  Readfield: [22, 49],
  Reading: [17, 20, 23, 24, 39, 47],
  'Reading Center': [35],
  Readington: [32],
  Readlyn: [13],
  'Reads Landing': [24],
  Readsboro: [47],
  Readstown: [49],
  Readville: [20],
  Readyville: [43],
  Reagan: [43, 44, -44],
  Real: [-44],
  Realitos: [44],
  Reamstown: [39],
  Reardan: [48],
  Reasnor: [13],
  Rebecca: [11],
  Rebersburg: [39],
  Rebuck: [39],
  Recluse: [51],
  Rector: [3, 39],
  Rectortown: [46],
  'Red Ash': [46],
  'Red Bank': [32],
  'Red Banks': [26],
  'Red Bay': [2],
  'Red Bluff': [5],
  'Red Boiling Springs': [43],
  'Red Bud': [15],
  'Red Cliff': [6],
  'Red Cloud': [30],
  'Red Creek': [35, 50],
  'Red Devil': [1],
  'Red Feather Lakes': [6],
  'Red Hill': [39],
  'Red Hook': [35],
  'Red House': [46, 50],
  'Red Jacket': [50],
  'Red Lake': [-24],
  'Red Lake Falls': [24],
  'Red Level': [2],
  'Red Lion': [39],
  'Red Lodge': [27],
  'Red Mountain': [5],
  'Red Oak': [11, 13, 28, 37, 44, 46],
  'Red River': [-19, 33, -44],
  'Red Rock': [4, 37, 44],
  'Red Springs': [28],
  'Red Valley': [4],
  'Red Willow': [-30],
  'Red Wing': [24],
  Redan: [11],
  Redbird: [37],
  Redby: [24],
  Redcrest: [5],
  Reddell: [19],
  Reddick: [10, 15],
  Redding: [5, 7, 13],
  'Redding Center': [7],
  'Redding Ridge': [7],
  Redfield: [3, 13, 17, 35, 42],
  Redford: [23, 25, 35, 44],
  Redfox: [18],
  Redgranite: [49],
  Redig: [42],
  Redkey: [16],
  Redlake: [24],
  Redlands: [5],
  Redmon: [15],
  Redmond: [38, 45, 48],
  'Redondo Beach': [5],
  Redrock: [33],
  Redstone: [27],
  Redvale: [6],
  Redwater: [44],
  Redway: [5],
  Redwood: [-24, 26, 35, 46],
  'Redwood City': [5],
  'Redwood Estates': [5],
  'Redwood Falls': [24],
  'Redwood Valley': [5],
  'Ree Heights': [42],
  Reed: [18],
  'Reed City': [23],
  'Reed Point': [27],
  Reeder: [29],
  Reeders: [39],
  Reedley: [5],
  Reeds: [25],
  'Reeds Spring': [25],
  Reedsburg: [49],
  Reedsport: [38],
  Reedsville: [36, 39, 49, 50],
  Reedville: [46],
  Reedy: [50],
  Reelsville: [16],
  Reese: [23],
  Reeseville: [49],
  Reesville: [36],
  Reeves: [19, -44],
  Reevesville: [41],
  Reform: [2],
  Refton: [39],
  Refugio: [44, -44],
  Regan: [29],
  Regent: [29],
  Regina: [18, 33],
  Register: [11],
  'Rego Park': [35],
  Rehobeth: [21],
  Rehoboth: [20, 33],
  'Rehoboth Beach': [9],
  Rehrersburg: [39],
  Reidsville: [11, 28],
  Reidville: [41],
  Reinbeck: [13],
  Reinholds: [39],
  Reisterstown: [21],
  Reklaw: [44],
  Reliance: [42, 43, 51],
  Rembert: [41],
  Rembrandt: [13],
  Remer: [24],
  Remington: [16, 46],
  Remlap: [2],
  Remsen: [13, 35],
  Remsenburg: [35],
  Remus: [23],
  'Rena Lara': [26],
  Renault: [15],
  Renfrew: [39],
  'Renfro Valley': [18],
  Renick: [25, 50],
  Renner: [42],
  Reno: [-17, 34, 36, 39],
  Renovo: [39],
  Rensselaer: [16, 35, -35],
  'Rensselaer Falls': [35],
  Rensselaerville: [35],
  Rentiesville: [37],
  Renton: [48],
  Rentz: [11],
  Renville: [24, -24, -29],
  Renwick: [13],
  Represa: [5],
  Repton: [2],
  Republic: [17, -17, 23, 25, 36, 39, 48],
  'Republican City': [30],
  Resaca: [11],
  Rescue: [5, 46],
  Reseda: [5],
  Reserve: [19, 27, 33],
  Reston: [46],
  Retsil: [48],
  Retsof: [35],
  Reubens: [14],
  Reva: [42, 46],
  Revelo: [18],
  Revere: [20, 24, 25, 39],
  Revillo: [42],
  Revloc: [39],
  Rew: [39],
  Rewey: [49],
  Rex: [11, 28],
  Rexburg: [14],
  Rexford: [17, 27, 35],
  Rexmont: [39],
  Rexville: [35],
  Reydell: [3],
  Reydon: [37],
  Reyno: [3],
  Reynolds: [11, 15, 16, 25, -25, 29, 30],
  'Reynolds Station': [18],
  Reynoldsburg: [36],
  Reynoldsville: [39, 50],
  Rhame: [29],
  Rhea: [-43],
  Rheems: [39],
  Rhine: [11],
  Rhinebeck: [35],
  Rhinecliff: [35],
  Rhinehart: [19],
  Rhineland: [25],
  Rhinelander: [49],
  Rhoadesville: [46],
  Rhodelia: [18],
  Rhodell: [50],
  Rhodes: [13, 23],
  Rhodesdale: [21],
  Rhodhiss: [28],
  Rhododendron: [38],
  Rhome: [44],
  Rialto: [5],
  'Rib Lake': [49],
  Ribera: [33],
  Rice: [-17, 24, -24, 44, 46, 48],
  'Rice Lake': [49],
  Riceboro: [11],
  'Rices Landing': [39],
  Ricetown: [18],
  Riceville: [13, 39, 43],
  Rich: [-45],
  'Rich Creek': [46],
  'Rich Hill': [25],
  'Rich Square': [28],
  Richards: [25, 44],
  Richardson: [-30, 44],
  Richardsville: [46],
  Richardton: [29],
  Richboro: [39],
  Richburg: [35, 41],
  Richey: [27],
  Richeyville: [39],
  Richfield: [14, 17, 28, 36, 39, 45, 49],
  'Richfield Springs': [35],
  Richford: [35, 47],
  Richgrove: [5],
  Richland: [11, 13, -15, 16, -19, 23, 25, 26, 27, -27, -29, 32, 35, -36, 38, 39, 41, -41, 44, 48, -49],
  'Richland Center': [49],
  'Richland Springs': [44],
  Richlands: [28, 46],
  Richlandtown: [39],
  Richmond: [5, -11, 15, 16, 17, 18, 20, 22, 23, 24, 25, -28, -35, 36, 40, 44, 45, 46, -46, 47],
  'Richmond Dale': [36],
  'Richmond Hill': [11, 35],
  Richmondville: [35],
  Richton: [26],
  'Richton Park': [15],
  Richvale: [5],
  Richview: [15],
  Richville: [23, 24, 35],
  Richwood: [24, 32, 36, 50],
  Richwoods: [25],
  Ricketts: [13],
  Rickman: [43],
  Rickreall: [38],
  Rico: [6],
  Riddle: [38],
  Riddlesburg: [39],
  Riddleton: [43],
  Riderwood: [21],
  Ridge: [21, 35],
  'Ridge Farm': [15],
  'Ridge Spring': [41],
  Ridgecrest: [5, 28],
  Ridgedale: [25],
  Ridgefield: [7, 32, 48],
  'Ridgefield Park': [32],
  Ridgeland: [26, 41, 49],
  Ridgeley: [50],
  Ridgely: [21, 43],
  Ridgetop: [43],
  Ridgeview: [42, 50],
  Ridgeville: [16, 41],
  'Ridgeville Corners': [36],
  Ridgeway: [13, 25, 28, 36, 41, 46, 49, 50],
  Ridgewood: [32, 35],
  Ridgway: [6, 15, 39],
  'Ridley Park': [39],
  Ridott: [15],
  Riegelsville: [39],
  Riegelwood: [28],
  Rienzi: [26],
  Riesel: [44],
  Rifle: [6],
  Rifton: [35],
  Riga: [23],
  Rigby: [14],
  Riggins: [14],
  Riley: [16, 17, -17, 38],
  Rileyville: [46],
  Rillito: [4],
  Rillton: [39],
  Rimersburg: [39],
  Rimforest: [5],
  Rimrock: [4],
  Rinard: [15],
  Rincon: [11, 33],
  Rindge: [31],
  Riner: [46],
  Rineyville: [18],
  Ringgold: [11, -13, 19, 39, 44, 46],
  Ringle: [49],
  Ringling: [27, 37],
  Ringoes: [32],
  Ringold: [37],
  Ringsted: [13],
  Ringtown: [39],
  Ringwood: [15, 32, 37],
  Rio: [15, 49, 50],
  'Rio Arriba': [-33],
  'Rio Blanco': [-6],
  'Rio Dell': [5],
  'Rio Frio': [44],
  'Rio Grande': [-6, 32, 36],
  'Rio Grande City': [44],
  'Rio Hondo': [44],
  'Rio Linda': [5],
  'Rio Medina': [44],
  'Rio Nido': [5],
  'Rio Oso': [5],
  'Rio Rancho': [33],
  'Rio Rico': [4],
  'Rio Verde': [4],
  'Rio Vista': [5, 44],
  Rion: [41],
  Riparius: [35],
  Ripley: [-16, -25, 26, 35, 36, 37, 43, 50],
  Ripon: [5, 49],
  Rippey: [13],
  Ripplemead: [46],
  Rippon: [50],
  Ripton: [47],
  Ririe: [14],
  Risco: [25],
  'Rising City': [30],
  'Rising Fawn': [11],
  'Rising Star': [44],
  'Rising Sun': [16, 21],
  Risingsun: [36],
  Rison: [3],
  Ritchie: [-50],
  Rittman: [36],
  Ritzville: [48],
  Riva: [21],
  River: [18],
  'River Edge': [32],
  'River Falls': [2, 49],
  'River Forest': [15],
  'River Grove': [15],
  'River Pines': [5],
  'River Ranch': [10],
  'River Rouge': [23],
  Riverbank: [5],
  Riverdale: [5, 11, 15, 21, 23, 29, 30, 32],
  Riverhead: [35],
  Riverside: [2, 5, -5, 7, 13, 15, 23, 25, 32, 38, 39, 40, 44, 45, 48],
  Riverton: [7, 13, 15, 17, 30, 32, 45, 50, 51],
  Rivervale: [3],
  Riverview: [10, 23],
  Rives: [25, 43],
  'Rives Junction': [23],
  Rivesville: [50],
  Riviera: [44],
  Rixeyville: [46],
  Rixford: [39],
  Roach: [25],
  Roachdale: [16],
  'Roan Mountain': [43],
  Roane: [-43, -50],
  Roann: [16],
  Roanoke: [2, 15, 16, 19, 44, 46, -46],
  'Roanoke Rapids': [28],
  'Roans Prairie': [44],
  'Roaring Branch': [39],
  'Roaring Gap': [28],
  'Roaring River': [28],
  'Roaring Spring': [39],
  'Roaring Springs': [44],
  Roark: [18],
  Robards: [18],
  Robbins: [5, 15, 28, 43],
  Robbinsdale: [24],
  Robbinston: [22],
  Robbinsville: [28, 32],
  Robeline: [19],
  Robersonville: [28],
  Robert: [19],
  'Robert Lee': [44],
  Roberta: [11],
  Roberts: [14, 15, 27, -42, -44, 49],
  Robertsdale: [2, 39],
  Robertson: [-18, -43, -44, 51],
  Robertsville: [25, 36],
  Robeson: [-28],
  Robesonia: [39],
  Robins: [13],
  Robinson: [15, 17, 29, 39, 44],
  'Robinson Creek': [18],
  Robinsonville: [26],
  Robson: [50],
  Robstown: [44],
  Roby: [25, 44],
  Roca: [30],
  Rochdale: [20],
  Rochelle: [11, 15, 44, 46],
  'Rochelle Park': [32],
  Rocheport: [25],
  Rochert: [24],
  Rochester: [15, 16, 18, 20, 23, 24, 31, 35, 39, 44, 47, 48, 49],
  'Rochester Mills': [39],
  Rociada: [33],
  Rock: [17, 23, -24, -30, -49, 50],
  'Rock Camp': [36],
  'Rock Cave': [50],
  'Rock City': [15],
  'Rock City Falls': [35],
  'Rock Creek': [24, 36, 50],
  'Rock Falls': [13, 15, 49],
  'Rock Glen': [39],
  'Rock Hall': [21],
  'Rock Hill': [35, 41],
  'Rock Island': [15, -15, 43, 44, 48],
  'Rock Point': [4, 21],
  'Rock Port': [25],
  'Rock Rapids': [13],
  'Rock River': [51],
  'Rock Spring': [11],
  'Rock Springs': [49, 51],
  'Rock Stream': [35],
  'Rock Tavern': [35],
  'Rock Valley': [13],
  'Rock View': [50],
  Rockaway: [32],
  'Rockaway Beach': [25, 38],
  'Rockaway Park': [35],
  Rockbridge: [15, 25, 36, -46],
  Rockcastle: [-18],
  Rockdale: [-11, 44],
  Rockfall: [7],
  Rockfield: [16, 18],
  Rockford: [2, 13, 15, 23, 24, 36, 43, 48],
  Rockham: [42],
  'Rockhill Furnace': [39],
  Rockholds: [18],
  Rockhouse: [18],
  Rockingham: [28, -28, -31, -46],
  Rocklake: [29],
  Rockland: [9, 14, 20, 22, 23, -35, 49],
  Rockledge: [10, 11],
  Rocklin: [5],
  Rockmart: [11],
  Rockport: [15, 16, 18, 20, 22, 44, 48, 50],
  Rocksprings: [44],
  Rockton: [15, 39],
  Rockvale: [6, 43],
  Rockville: [7, 16, 21, 24, 25, 30, 40, 45, 46],
  'Rockville Centre': [35],
  Rockwall: [44, -44],
  Rockwell: [13, 28],
  'Rockwell City': [13],
  Rockwood: [15, 22, 23, 39, 43, 44],
  Rocky: [37],
  'Rocky Comfort': [25],
  'Rocky Face': [11],
  'Rocky Ford': [6, 11],
  'Rocky Gap': [46],
  'Rocky Hill': [7, 18, 32],
  'Rocky Mount': [25, 28, 46],
  'Rocky Point': [28, 35],
  'Rocky Ridge': [21, 36],
  'Rocky River': [36],
  'Rocky Top': [43],
  Rodanthe: [28],
  Rodeo: [5, 33],
  Roderfield: [50],
  Rodessa: [19],
  Rodman: [35],
  Rodney: [13, 23],
  Roduco: [28],
  Roe: [3],
  Roebling: [32],
  Roebuck: [41],
  Roff: [37],
  'Roger Mills': [-37],
  Rogers: [3, 7, 18, 24, 29, 30, 33, 36, -37, 44],
  'Rogers City': [23],
  Rogerson: [14],
  Rogersville: [2, 25, 39, 43],
  Roggen: [6],
  'Rogue River': [38],
  'Rohnert Park': [5],
  Rohrersville: [21],
  Rohwer: [3],
  Roland: [3, 13, 37],
  Rolesville: [28],
  Rolette: [29, -29],
  Rolfe: [13],
  Roll: [4],
  Rolla: [17, 25, 29],
  'Rolling Fork': [26],
  'Rolling Hills Estates': [5],
  'Rolling Meadows': [15],
  'Rolling Prairie': [16],
  Rollingbay: [48],
  Rollingstone: [24],
  Rollins: [27],
  'Rollins Fork': [46],
  Rollinsford: [31],
  Rollinsville: [6],
  Roma: [44],
  Romance: [3],
  Romayor: [44],
  Rombauer: [25],
  Rome: [11, 13, 15, 16, 26, 35, 36, 39],
  'Rome City': [16],
  Romeo: [6, 23],
  Romeoville: [15],
  Romney: [16, 50],
  Romulus: [23, 35],
  Ronald: [48],
  Ronan: [27],
  Ronceverte: [50],
  Ronco: [39],
  Ronda: [28],
  Ronkonkoma: [35],
  Ronks: [39],
  Roodhouse: [15],
  Rooks: [-17],
  Roopville: [11],
  Roosevelt: [4, 24, -27, 32, -33, 35, 37, 44, 45, 48],
  Rooseveltown: [35],
  Rootstown: [36],
  Roper: [28],
  Ropesville: [44],
  Rosalia: [17, 48],
  Rosalie: [30],
  Rosamond: [5, 15],
  Rosanky: [44],
  Rosburg: [48],
  Roscoe: [15, 24, 25, 27, 35, 39, 42, 44],
  Roscommon: [23, -23],
  Rose: [35, 37],
  'Rose Bud': [3],
  'Rose City': [23],
  'Rose Creek': [24],
  'Rose Hill': [13, 17, 26, 28, 46],
  'Rose Lodge': [38],
  Roseau: [24, -24],
  Roseboom: [35],
  Roseboro: [28],
  Rosebud: [25, 27, -27, 42, 44],
  Roseburg: [38],
  Rosebush: [23],
  Rosedale: [16, 19, 21, 26, 35, 46, 50],
  Roseglen: [29],
  Roseland: [10, 19, 30, 32, 46],
  Roselawn: [16],
  Roselle: [15, 32],
  'Roselle Park': [32],
  Rosemead: [5],
  Rosemont: [32, 50],
  Rosemount: [24],
  Rosenberg: [44],
  Rosendale: [25, 35, 49],
  Rosenhayn: [32],
  Rosepine: [19],
  Roseville: [5, 15, 23, 24, 36],
  Rosewood: [36],
  Rosharon: [44],
  Rosholt: [42, 49],
  Rosiclare: [15],
  Rosie: [3],
  Rosine: [18],
  Roslindale: [20],
  Roslyn: [35, 42, 48],
  'Roslyn Heights': [35],
  Rosman: [28],
  Ross: [5, 29, 36, -36, 44],
  Rossburg: [36],
  Rosser: [44],
  Rossford: [36],
  Rossiter: [39],
  Rosston: [3, 37, 44],
  Rossville: [11, 15, 16, 17, 39, 43],
  Roswell: [11, 33],
  Rotan: [44],
  Rothbury: [23],
  Rothsay: [24],
  Rothschild: [49],
  Rothville: [25],
  'Rotonda West': [10],
  'Rotterdam Junction': [35],
  Rougemont: [28],
  'Rough And Ready': [5],
  Rougon: [19],
  Roulette: [39],
  'Round Hill': [46],
  'Round Lake': [15, 24, 35],
  'Round Mountain': [5, 34, 44],
  'Round O': [41],
  'Round Pond': [22],
  'Round Rock': [4, 44],
  'Round Top': [35, 44],
  Roundhead: [36],
  Roundhill: [18],
  Roundup: [27],
  'Rouses Point': [35],
  Rouseville: [39],
  Rousseau: [18],
  Routt: [-6],
  Rouzerville: [39],
  Rover: [3],
  Rowan: [13, -18, -28],
  Rowdy: [18],
  Rowe: [20, 33, 46],
  Rowena: [44],
  Rowesville: [41],
  Rowland: [28, 39],
  'Rowland Heights': [5],
  Rowlesburg: [50],
  Rowlett: [44],
  Rowley: [13, 20],
  Roxana: [15, 18],
  Roxboro: [28],
  Roxbury: [7, 17, 20, 22, 35, 39, 47],
  'Roxbury Crossing': [20],
  Roxie: [26],
  Roxobel: [28],
  Roxton: [44],
  Roy: [27, 33, 45, 48],
  Royal: [3, 13, 15, 30],
  'Royal Center': [16],
  'Royal City': [48],
  'Royal Oak': [21, 23],
  'Royal Palm Beach': [10],
  Royalston: [20],
  Royalton: [15, 18, 24],
  Royersford: [39],
  'Royse City': [44],
  Royston: [11],
  Rozel: [17],
  Rozet: [51],
  Rubicon: [49],
  Ruby: [1, 19, 35, 41, 46],
  'Ruby Valley': [34],
  Ruckersville: [46],
  Rudd: [13],
  Rudolph: [36, 49],
  Rudy: [3],
  Rudyard: [23, 27],
  Rueter: [25],
  Rufe: [37],
  Ruffin: [28, 41],
  'Ruffs Dale': [39],
  Rufus: [38],
  Rugby: [29, 43],
  Ruidoso: [33],
  'Ruidoso Downs': [33],
  Rule: [44],
  Ruleville: [26],
  Rulo: [30],
  Rumely: [23],
  Rumford: [22, 40],
  Rumney: [31],
  Rumsey: [5, 18],
  Rumson: [32],
  Runge: [44],
  Runnells: [13],
  Runnels: [-44],
  Runnemede: [32],
  'Running Springs': [5],
  Rupert: [11, 14, 47, 50],
  'Rural Hall': [28],
  'Rural Retreat': [46],
  'Rural Ridge': [39],
  'Rural Valley': [39],
  Rush: [6, -16, -17, 18, 35],
  'Rush Center': [17],
  'Rush City': [24],
  'Rush Hill': [25],
  'Rush Springs': [37],
  'Rush Valley': [45],
  Rushford: [24, 35],
  Rushland: [39],
  Rushmore: [24],
  Rushsylvania: [36],
  Rushville: [15, 16, 25, 30, 35, 36],
  Rusk: [44, -44, -49],
  Ruskin: [10, 30],
  Ruso: [29],
  Russell: [-2, 3, 13, 15, 17, -17, 18, -18, 20, 24, 35, 39, -46],
  'Russell Springs': [18],
  'Russells Point': [36],
  Russellton: [39],
  Russellville: [2, 3, 16, 18, 25, 36, 41, 43],
  Russia: [36],
  'Russian Mission': [1],
  Russiaville: [16],
  Rustburg: [46],
  Ruston: [19],
  Ruth: [23, 26, 34],
  'Ruther Glen': [46],
  Rutherford: [5, -28, 32, 43, -43],
  'Rutherford College': [28],
  Rutherfordton: [28],
  Ruthersford: [-28],
  Ruthton: [24],
  Ruthven: [13],
  Ruthville: [46],
  Rutland: [13, 15, 20, 29, 36, 42, 47, -47],
  Rutledge: [2, 11, 25, 43],
  Ryan: [13, 37],
  Rydal: [11],
  Ryde: [5],
  Ryder: [29],
  Ryderwood: [48],
  Rye: [6, 31, 35, 44],
  'Rye Beach': [31],
  Ryegate: [27],
  Ryland: [2],
  'S Coffeyville': [37],
  Sabael: [35],
  Sabattus: [22],
  Sabetha: [17],
  Sabillasville: [21],
  Sabin: [24],
  Sabina: [36],
  Sabinal: [44],
  Sabine: [-19, -44, 50],
  'Sabine Pass': [44],
  Sabinsville: [39],
  Sabula: [13],
  Sac: [-13],
  'Sac City': [13],
  Sacaton: [4],
  Sachse: [44],
  'Sackets Harbor': [35],
  Saco: [22, 27],
  Sacramento: [5, -5, 18, 33, 39],
  'Sacred Heart': [24],
  Sacul: [44],
  'Saddle Brook': [32],
  'Saddle River': [32],
  Saddlestring: [51],
  Sadieville: [18],
  Sadler: [44],
  Sadorus: [15],
  Sadsburyville: [39],
  Saegertown: [39],
  'Safety Harbor': [10],
  Saffell: [3],
  Safford: [2, 4],
  'Sag Harbor': [35],
  Sagadahoc: [-22],
  Sagamore: [20, 39],
  'Sagamore Beach': [20],
  Sagaponack: [35],
  Sage: [3],
  Saginaw: [2, 23, -23, 24, 25],
  Sagle: [14],
  Sagola: [23],
  Saguache: [6, -6],
  Sahuarita: [4],
  'Sailor Springs': [15],
  'Saint Agatha': [22],
  'Saint Albans': [22, 25, 35, 47, 50],
  'Saint Albans Bay': [47],
  'Saint Amant': [19],
  'Saint Ann': [25],
  'Saint Anne': [15],
  'Saint Ansgar': [13],
  'Saint Anthony': [13, 14, 16, 29],
  'Saint Augustine': [10, 15],
  'Saint Benedict': [19, 38, 39],
  'Saint Bernard': [19, -19],
  'Saint Bernice': [16],
  'Saint Bonaventure': [35],
  'Saint Boniface': [39],
  'Saint Bonifacius': [24],
  'Saint Catharine': [18],
  'Saint Charles': [3, 13, 14, 15, 18, -19, 23, 24, 25, -25, 42, 46],
  'Saint Clair': [-2, -15, 23, -23, 24, 25, -25, 39],
  'Saint Clair Shores': [23],
  'Saint Clairsville': [36],
  'Saint Cloud': [10, 24, 49],
  'Saint Croix': [16, -49],
  'Saint Croix Falls': [49],
  'Saint David': [4, 15, 22],
  'Saint Donatus': [13],
  'Saint Edward': [30],
  'Saint Elizabeth': [25],
  'Saint Elmo': [2, 15],
  'Saint Francis': [3, -3, 17, 18, 22, 24, 42, 49],
  'Saint Francisville': [15, 19],
  'Saint Francois': [-25],
  'Saint Gabriel': [19],
  'Saint George': [11, 17, 41, 45],
  'Saint George Island': [1],
  'Saint Georges': [9],
  'Saint Germain': [49],
  'Saint Hedwig': [44],
  'Saint Helen': [23],
  'Saint Helena': [5, -19, 30],
  'Saint Helena Island': [41],
  'Saint Helens': [18, 38],
  'Saint Henry': [36],
  'Saint Hilaire': [24],
  'Saint Ignace': [23],
  'Saint Ignatius': [27],
  'Saint Inigoes': [21],
  'Saint Jacob': [15],
  'Saint James': [19, -19, 21, 24, 25, 35],
  'Saint James City': [10],
  'Saint Jo': [44],
  'Saint Joe': [3, 16],
  'Saint John': [16, 29, 48],
  'Saint John the Baptist': [-19],
  'Saint Johns': [4, 10, -10, 23, 36, 39],
  'Saint Johnsbury': [47],
  'Saint Johnsbury Center': [47],
  'Saint Johnsville': [35],
  'Saint Joseph': [15, -16, 19, 23, -23, 24, 25, 43],
  'Saint Landry': [19, -19],
  'Saint Lawrence': [-35, 42],
  'Saint Leo': [10],
  'Saint Leonard': [21],
  'Saint Libory': [15, 30],
  'Saint Louis': [23, -24, 25, -25, 37],
  'Saint Louis Park': [24],
  'Saint Louisville': [36],
  'Saint Lucas': [13],
  'Saint Lucie': [-10],
  'Saint Marie': [27],
  'Saint Maries': [14],
  'Saint Marks': [10],
  'Saint Martin': [-19, 24],
  'Saint Martinville': [19],
  'Saint Mary': [18, -19, 25],
  'Saint Mary Of The Woods': [16],
  'Saint Marys': [1, 11, 13, 17, -21, 36, 39, 50],
  'Saint Marys City': [21],
  'Saint Matthews': [41],
  'Saint Maurice': [19],
  'Saint Meinrad': [16],
  'Saint Michael': [1, 24, 29, 39],
  'Saint Michaels': [4, 21],
  'Saint Nazianz': [49],
  'Saint Olaf': [13],
  'Saint Onge': [42],
  'Saint Paris': [36],
  'Saint Patrick': [25],
  'Saint Paul': [3, 13, 16, 17, 24, 30, 38, 46],
  'Saint Paul Island': [1],
  'Saint Paul Park': [24],
  'Saint Pauls': [28],
  'Saint Peter': [15, 24],
  'Saint Peters': [25, 39],
  'Saint Petersburg': [10, 39],
  'Saint Regis': [27],
  'Saint Regis Falls': [35],
  'Saint Robert': [25],
  'Saint Rose': [19],
  'Saint Simons Island': [11],
  'Saint Stephen': [24, 41],
  'Saint Stephens': [2, 51],
  'Saint Stephens Church': [46],
  'Saint Tammany': [-19],
  'Saint Thomas': [25, 29, 39],
  'Saint Vincent': [24],
  'Saint Vrain': [33],
  'Saint Xavier': [27],
  'Sainte Genevieve': [25, -25],
  'Sainte Marie': [15],
  Salado: [3, 44],
  Salamanca: [35],
  Salamonia: [16],
  Salcha: [1],
  'Sale City': [11],
  'Sale Creek': [43],
  Salem: [2, 3, 7, 10, 13, 15, 16, 18, 20, 23, 25, 30, 31, 32, -32, 33, 35, 36, 38, 41, 42, 45, 46, 49, 50],
  Salemburg: [28],
  Salesville: [36],
  Salford: [39],
  Salfordville: [39],
  Salida: [5, 6],
  Salina: [17, 37, 39, 45],
  Salinas: [5],
  Saline: [-3, -15, -17, 19, 23, -25, -30],
  Salineno: [44],
  Salineville: [36],
  Salisbury: [7, 20, 21, 25, 28, 31, 39, 47],
  'Salisbury Center': [35],
  'Salisbury Mills': [35],
  Salix: [13, 39],
  Salkum: [48],
  Salley: [41],
  Sallis: [26],
  Sallisaw: [37],
  Salmon: [14],
  Salol: [24],
  Salome: [4],
  'Salsbury Cove': [22],
  'Salt Flat': [44],
  'Salt Lake': [-45],
  'Salt Lake City': [45],
  'Salt Lick': [18],
  'Salt Point': [35],
  'Salt Rock': [50],
  'Salter Path': [28],
  Salters: [41],
  Saltese: [27],
  Saltillo: [26, 39, 43, 44],
  'Salton City': [5],
  Saltsburg: [39],
  Saltville: [46],
  Saluda: [28, 41, -41, 46],
  Salvisa: [18],
  Salvo: [28],
  Salyer: [5],
  Salyersville: [18],
  'Sam Rayburn': [44],
  Samantha: [2],
  Samaria: [23],
  Samburg: [43],
  Sammamish: [48],
  Samnorwood: [44],
  Samoa: [5],
  Sampson: [-28],
  Samson: [2],
  'San Acacia': [33],
  'San Andreas': [5],
  'San Angelo': [44],
  'San Anselmo': [5],
  'San Antonio': [10, 33, 44],
  'San Ardo': [5],
  'San Augustine': [44, -44],
  'San Benito': [-5, 44],
  'San Bernardino': [5, -5],
  'San Bruno': [5],
  'San Carlos': [4, 5],
  'San Clemente': [5],
  'San Cristobal': [33],
  'San Diego': [5, -5, 44],
  'San Dimas': [5],
  'San Elizario': [44],
  'San Felipe': [44],
  'San Fernando': [5],
  'San Fidel': [33],
  'San Francisco': [5, -5],
  'San Gabriel': [5],
  'San Geronimo': [5],
  'San Gregorio': [5],
  'San Isidro': [44],
  'San Jacinto': [5, -44],
  'San Joaquin': [5, -5],
  'San Jon': [33],
  'San Jose': [5, 15, 33],
  'San Juan': [-6, -33, 44, -45, -48],
  'San Juan Bautista': [5],
  'San Juan Capistrano': [5],
  'San Leandro': [5],
  'San Lorenzo': [5],
  'San Lucas': [5],
  'San Luis': [4, 6],
  'San Luis Obispo': [5, -5],
  'San Luis Rey': [5],
  'San Manuel': [4],
  'San Marcos': [5, 44],
  'San Marino': [5],
  'San Martin': [5],
  'San Mateo': [5, -5, 10],
  'San Miguel': [5, -6, 33, -33],
  'San Pablo': [5],
  'San Patricio': [33, -44],
  'San Pedro': [5],
  'San Perlita': [44],
  'San Pierre': [16],
  'San Quentin': [5],
  'San Rafael': [5, 33],
  'San Ramon': [5],
  'San Saba': [44, -44],
  'San Simeon': [5],
  'San Simon': [4],
  'San Tan Valley': [4],
  'San Ygnacio': [44],
  'San Ysidro': [5, 33],
  Sanborn: [13, 24, 29, 35, -42],
  Sanbornton: [31],
  Sanbornville: [31],
  'Sand Coulee': [27],
  'Sand Creek': [23, 49],
  'Sand Fork': [50],
  'Sand Lake': [23, 35],
  'Sand Point': [1],
  'Sand Springs': [27, 37],
  Sandborn: [16],
  Sanders: [4, 18, 27, -27],
  Sanderson: [10, 44],
  Sandersville: [11, 26],
  Sandgap: [18],
  Sandhill: [26],
  Sandia: [44],
  'Sandia Park': [33],
  Sandisfield: [20],
  Sandoval: [15, -33],
  Sandown: [31],
  Sandpoint: [14],
  Sandston: [46],
  Sandstone: [24, 50],
  Sandusky: [23, 35, 36, -36],
  Sandwich: [15, 20],
  Sandy: [38, 45],
  'Sandy Creek': [35],
  'Sandy Hook': [7, 18, 26, 46],
  'Sandy Lake': [39],
  'Sandy Level': [46],
  'Sandy Point': [22, 46],
  'Sandy Ridge': [28, 39],
  'Sandy Run': [41],
  'Sandy Spring': [21],
  'Sandy Springs': [41],
  Sandyville: [36, 50],
  Sanford: [6, 10, 22, 23, 28, 44, 46],
  Sangamon: [-15],
  Sanger: [5, 44],
  Sangerfield: [35],
  Sangerville: [22],
  Sanibel: [10],
  Sanilac: [-23],
  Sanostee: [33],
  Sanpete: [-45],
  Santa: [14],
  'Santa Ana': [5],
  'Santa Anna': [44],
  'Santa Barbara': [5, -5],
  'Santa Clara': [5, -5, 33, 45],
  'Santa Clarita': [5],
  'Santa Claus': [16],
  'Santa Cruz': [-4, 5, -5, 33],
  'Santa Elena': [44],
  'Santa Fe': [25, 33, -33, 43, 44],
  'Santa Fe Springs': [5],
  'Santa Margarita': [5],
  'Santa Maria': [5, 44],
  'Santa Monica': [5],
  'Santa Paula': [5],
  'Santa Rita Park': [5],
  'Santa Rosa': [5, -10, 33, 44],
  'Santa Rosa Beach': [10],
  'Santa Teresa': [33],
  'Santa Ynez': [5],
  'Santa Ysabel': [5],
  Santaquin: [45],
  Santee: [5, 41],
  Santiago: [24],
  Santo: [44],
  'Santo Domingo Pueblo': [33],
  Sapello: [33],
  'Sapelo Island': [11],
  Sapphire: [28],
  Sapulpa: [37],
  Saragosa: [44],
  Sarah: [26],
  'Sarah Ann': [50],
  Sarahsville: [36],
  Saraland: [2],
  Saranac: [23, 35],
  'Saranac Lake': [35],
  Sarasota: [10, -10],
  Saratoga: [3, 5, 16, 28, -35, 44, 51],
  'Saratoga Springs': [35, 45],
  Sarcoxie: [25],
  Sardinia: [35, 36, 41],
  Sardis: [2, 11, 26, 36, 43],
  Sarepta: [19],
  Sargeant: [24],
  Sargent: [11, -29, 30, 44],
  Sargents: [6],
  Sargentville: [22],
  Sarita: [44],
  Sarles: [29],
  Sarona: [49],
  Saronville: [30],
  Sarpy: [-30],
  Sartell: [24],
  Sarver: [39],
  Sasabe: [4],
  Sasakwa: [37],
  Sassafras: [18],
  Sassamansville: [39],
  Sasser: [11],
  Satanta: [17],
  Satartia: [26],
  'Satellite Beach': [10],
  Satin: [44],
  Satsop: [48],
  Satsuma: [2, 10],
  Saucier: [26],
  Saugatuck: [23],
  Saugerties: [35],
  Saugus: [20],
  Sauk: [-49],
  'Sauk Centre': [24],
  'Sauk City': [49],
  'Sauk Rapids': [24],
  Saukville: [49],
  Saul: [18],
  Saulsbury: [43],
  Saulsville: [50],
  'Sault Sainte Marie': [23],
  Saunders: [-30],
  Saunderstown: [40],
  Saunemin: [15],
  Sauquoit: [35],
  Sausalito: [5],
  'Sautee Nacoochee': [11],
  Savage: [21, 24, 27],
  Savanna: [15, 37],
  Savannah: [11, 25, 35, 36, 43],
  Saverton: [25],
  Savery: [51],
  Savona: [35],
  Savonburg: [17],
  Savoonga: [1],
  Savoy: [15, 20, 44],
  Sawyer: [17, 23, 24, 29, 37, -49],
  Sawyerville: [2, 15],
  Saxapahaw: [28],
  Saxe: [46],
  Saxeville: [49],
  Saxis: [46],
  Saxon: [49, 50],
  Saxonburg: [39],
  Saxton: [39],
  'Saxtons River': [47],
  Saybrook: [15],
  Saylorsburg: [39],
  Sayner: [49],
  Sayre: [2, 37, 39],
  Sayreville: [32],
  Sayville: [35],
  'Scales Mound': [15],
  Scalf: [18],
  'Scaly Mountain': [28],
  Scammon: [17],
  'Scammon Bay': [1],
  Scandia: [17, 24],
  Scandinavia: [49],
  Scappoose: [38],
  Scarborough: [22],
  Scarbro: [50],
  Scarsdale: [35],
  Scarville: [13],
  'Scenery Hill': [39],
  Scenic: [42],
  Schaefferstown: [39],
  Schaghticoke: [35],
  Schaller: [13],
  Schaumburg: [15],
  'Schell City': [25],
  Scheller: [15],
  Schellsburg: [39],
  Schenectady: [35, -35],
  Schenevus: [35],
  Schenley: [39],
  Schererville: [16],
  Schertz: [44],
  'Schiller Park': [15],
  Schlater: [26],
  Schleicher: [-44],
  Schleswig: [13],
  Schley: [-11, 46],
  Schnecksville: [39],
  Schneider: [16],
  Schnellville: [16],
  'Schodack Landing': [35],
  Schoenchen: [17],
  Schofield: [49],
  'Schofield Barracks': [12],
  Schoharie: [35, -35],
  Schoolcraft: [23, -23],
  'Schooleys Mountain': [32],
  Schriever: [19],
  Schroeder: [24],
  'Schroon Lake': [35],
  Schulenburg: [44],
  Schulter: [37],
  Schurz: [34],
  Schuyler: [-15, -25, 30, -35, 46],
  'Schuyler Falls': [35],
  'Schuyler Lake': [35],
  Schuylerville: [35],
  Schuylkill: [-39],
  'Schuylkill Haven': [39],
  Schwenksville: [39],
  Schwertner: [44],
  'Science Hill': [18],
  Scio: [35, 36, 38],
  Sciota: [15, 39],
  Scioto: [-36],
  'Scioto Furnace': [36],
  Scipio: [16, 45],
  'Scipio Center': [35],
  Scituate: [20, 40],
  Scobey: [26, 27],
  Scooba: [26],
  'Scotch Plains': [32],
  Scotia: [5, 30, 35, 41],
  Scotland: [3, 7, 11, 16, 21, -25, -28, 39, 42, 44],
  'Scotland Neck': [28],
  Scotrun: [39],
  Scott: [3, -3, -13, -15, -16, -17, -18, 19, -24, -25, 26, -26, 36, -43, -46],
  'Scott Air Force Base': [15],
  'Scott Bar': [5],
  'Scott City': [17, 25],
  'Scott Depot': [50],
  Scottdale: [11, 39],
  Scottown: [36],
  Scotts: [23, 28],
  'Scotts Bluff': [-30],
  'Scotts Hill': [43],
  'Scotts Mills': [38],
  'Scotts Valley': [5],
  Scottsbluff: [30],
  Scottsboro: [2],
  Scottsburg: [16, 35, 38, 46],
  Scottsdale: [4],
  Scottsmoor: [10],
  Scottsville: [18, 35, 44, 46],
  Scottville: [15, 23, 28],
  Scranton: [3, 13, 17, 28, 29, 39, 41],
  Screven: [11, -11],
  Scribner: [30],
  Scroggins: [44],
  Scuddy: [18],
  Scurry: [44, -44],
  'Sea Cliff': [35],
  'Sea Girt': [32],
  'Sea Island': [11],
  'Sea Isle City': [32],
  Seabeck: [48],
  Seaboard: [28],
  Seabrook: [31, 41, 44],
  Seadrift: [44],
  Seaford: [9, 35, 46],
  Seaforth: [24],
  Seagoville: [44],
  Seagraves: [44],
  Seagrove: [28],
  Seahurst: [48],
  'Seal Beach': [5],
  'Seal Cove': [22],
  'Seal Harbor': [22],
  'Seal Rock': [38],
  Seale: [2],
  Sealevel: [28],
  Sealston: [46],
  Sealy: [44],
  Seaman: [36],
  Seanor: [39],
  Searchlight: [34],
  Searcy: [3, -3],
  Searles: [24],
  Sears: [23],
  Searsboro: [13],
  Searsmont: [22],
  Searsport: [22],
  Seaside: [5, 38],
  'Seaside Heights': [32],
  'Seaside Park': [32],
  Seaton: [15],
  Seatonville: [15],
  Seattle: [48],
  Seaview: [46, 48],
  Seaville: [32],
  Sebago: [22],
  'Sebasco Estates': [22],
  Sebastian: [-3, 10, 44],
  Sebastopol: [5, 26],
  Sebec: [22],
  Sebeka: [24],
  Sebewaing: [23],
  Sebree: [18],
  Sebring: [10, 36],
  Secaucus: [32],
  Seco: [18],
  'Second Mesa': [4],
  Secondcreekx: [50],
  Secor: [15],
  Secretary: [21],
  Section: [2],
  Sedalia: [6, 16, 18, 25, 28, 36],
  Sedan: [17, 33],
  Sedgewickville: [25],
  Sedgwick: [3, 6, -6, 17, -17, 22],
  Sedley: [46],
  Sedona: [4],
  'Sedro Woolley': [48],
  Seekonk: [20],
  Seeley: [5],
  'Seeley Lake': [27],
  Seelyville: [16],
  Seffner: [10],
  Seguin: [44],
  'Seiad Valley': [5],
  Seibert: [6],
  Seiling: [37],
  Sekiu: [48],
  Selah: [48],
  Selawik: [1],
  Selby: [42],
  Selbyville: [9, 50],
  Selden: [17, 35],
  Seldovia: [1],
  Selfridge: [29],
  Seligman: [4, 25],
  Selinsgrove: [39],
  Selkirk: [35],
  Sellers: [41],
  Sellersburg: [16],
  Sellersville: [39],
  Sells: [4],
  Selma: [2, 5, 13, 16, 28, 38, 46],
  'Selma)': [44],
  'Selman City': [44],
  Selmer: [43],
  Seltzer: [39],
  Seminary: [26],
  Seminole: [2, 10, -10, -11, 37, -37, 39, 44],
  Semmes: [2],
  Semora: [28],
  Senath: [25],
  Senatobia: [26],
  Seneca: [15, 17, 25, 30, -35, -36, 38, 39, 41, 42, 49],
  'Seneca Castle': [35],
  'Seneca Falls': [35],
  'Seneca Rocks': [50],
  Senecaville: [36],
  Seney: [23],
  Senoia: [11],
  Sentinel: [37],
  'Sentinel Butte': [29],
  Sequatchie: [43, -43],
  Sequim: [48],
  'Sequoia National Park': [5],
  Sequoyah: [-37],
  Serafina: [33],
  Serena: [15],
  'Sergeant Bluff': [13],
  Sergeantsville: [32],
  Servia: [16],
  Sesser: [15],
  Seth: [50],
  'Seven Mile': [36],
  'Seven Points': [44],
  'Seven Springs': [28],
  'Seven Valleys': [39],
  Severance: [6, 35],
  Severn: [21, 28, 46],
  'Severna Park': [21],
  Severy: [17],
  Sevier: [-3, -43, 45, -45],
  Sevierville: [43],
  Seville: [10, 11, 36],
  Sewanee: [43],
  Seward: [1, 15, -17, 30, -30, 39],
  Sewaren: [32],
  Sewell: [32],
  Sewickley: [39],
  'Sextons Creek': [18],
  Sextonville: [49],
  Seymour: [7, 13, 15, 16, 25, 43, 44, 49],
  Shabbona: [15],
  Shackelford: [-44],
  Shacklefords: [46],
  Shade: [36],
  'Shade Gap': [39],
  'Shadow Hills': [5],
  'Shady Cove': [38],
  'Shady Dale': [11],
  'Shady Grove': [10, 39],
  'Shady Point': [37],
  'Shady Side': [21],
  'Shady Spring': [50],
  'Shady Valley': [43],
  Shadyside: [36],
  Shafer: [24],
  Shafter: [5],
  Shaftsburg: [23],
  Shaftsbury: [47],
  Shageluk: [1],
  Shakopee: [24],
  Shaktoolik: [1],
  Shalimar: [10],
  Shallotte: [28],
  Shallowater: [44],
  Shambaugh: [13],
  Shamokin: [39],
  'Shamokin Dam': [39],
  Shamrock: [37, 44],
  Shandaken: [35],
  Shandon: [5, 36],
  Shaniko: [38],
  Shanks: [50],
  Shanksville: [39],
  Shannock: [40],
  Shannon: [2, 11, 15, -25, 26, 28, -42],
  'Shannon City': [13],
  Shapleigh: [22],
  Sharkey: [-26],
  Sharon: [7, 11, 17, 20, 26, 29, 37, 39, 41, 43, 47, 49],
  'Sharon Center': [36],
  'Sharon Grove': [18],
  'Sharon Hill': [39],
  'Sharon Springs': [17, 35],
  Sharp: [-3],
  Sharpes: [10],
  Sharples: [50],
  Sharps: [46],
  'Sharps Chapel': [43],
  Sharpsburg: [11, 13, 18, 21, 28, 36],
  Sharpsville: [16, 39],
  Sharptown: [21],
  Shartlesville: [39],
  Shasta: [5, -5],
  'Shasta Lake': [5],
  Shattuck: [37],
  Shauck: [36],
  'Shaver Lake': [5],
  Shavertown: [39],
  Shaw: [26],
  'Shaw Afb': [41],
  'Shaw Island': [48],
  Shawanee: [43],
  Shawanese: [39],
  Shawano: [49, -49],
  Shawboro: [28],
  Shawmut: [22, 27],
  Shawnee: [6, 17, -17, 36, 37, 51],
  'Shawnee Mission': [17],
  'Shawnee On Delaware': [39],
  Shawneetown: [15],
  Shawsville: [46],
  Shawville: [39],
  Sheakleyville: [39],
  Sheboygan: [49, -49],
  'Sheboygan Falls': [49],
  Shedd: [38],
  'Sheep Springs': [33],
  Sheffield: [2, 13, 15, 20, 39, 44, 47],
  'Sheffield Lake': [36],
  Shelbiana: [18],
  Shelbina: [25],
  Shelburn: [16],
  Shelburne: [47],
  'Shelburne Falls': [20],
  Shelby: [2, -2, 13, -13, -15, 16, -16, -18, 23, -25, 26, 27, 28, 30, 36, -36, -43, -44],
  'Shelby Gap': [18],
  'Shelby Township': [23],
  Shelbyville: [15, 16, 18, 23, 25, 43, 44],
  Sheldahl: [13],
  Sheldon: [13, 15, 25, 29, 41, 47, 49],
  'Sheldon Springs': [47],
  Sheldonville: [20],
  Shell: [51],
  'Shell Knob': [25],
  'Shell Lake': [49],
  'Shell Rock': [13],
  Shelley: [14],
  Shellman: [11],
  'Shellman Bluff': [11],
  Shellsburg: [13],
  Shelly: [24],
  Shelocta: [39],
  'Shelter Island': [35],
  'Shelter Island Heights': [35],
  Shelton: [7, 30, 48],
  Shenandoah: [13, 39, 46, -46],
  'Shenandoah Junction': [50],
  Shenorock: [35],
  Shepardsville: [16],
  Shepherd: [23, 27, 44],
  Shepherdstown: [50],
  Shepherdsville: [18],
  'Sheppard Afb': [44],
  Sheppton: [39],
  Sherard: [26],
  Sherborn: [20],
  Sherburn: [24],
  Sherburne: [-24, 35],
  Sheridan: [3, 5, 15, 16, -17, 22, 23, 25, 27, -27, -29, -30, 35, 38, 44, 51, -51],
  'Sheridan Lake': [6],
  Sherman: [7, 15, -17, 22, 26, -30, 35, -38, 44, -44],
  'Sherman Oaks': [5],
  'Shermans Dale': [39],
  Sherrard: [15],
  Sherrill: [3, 13, 35],
  'Sherrills Ford': [28],
  Sherrodsville: [36],
  Sherwood: [3, 21, 23, 29, 36, 38, 43, 49],
  Shevlin: [24],
  Sheyenne: [29],
  Shiawassee: [-23],
  Shickley: [30],
  Shickshinny: [39],
  Shidler: [37],
  Shields: [29],
  Shiloh: [11, 15, 28, 32, 36, 43],
  Shiner: [44],
  'Shingle Springs': [5],
  Shinglehouse: [39],
  Shingleton: [23],
  Shingletown: [5],
  Shingobee: [24],
  Shinnston: [50],
  Shiocton: [49],
  'Ship Bottom': [32],
  Shipman: [15, 46],
  Shippensburg: [39],
  Shippenville: [39],
  Shippingport: [39],
  Shiprock: [33],
  Shipshewana: [16],
  Shirland: [15],
  Shirley: [3, 15, 16, 20, 35, 50],
  'Shirley Basin': [51],
  'Shirley Mills': [22],
  Shirleysburg: [39],
  Shiro: [44],
  Shishmaref: [1],
  Shoals: [16, 50],
  Shobonier: [15],
  Shock: [50],
  Shoemakersville: [39],
  Shohola: [39],
  Shokan: [35],
  Shongaloo: [19],
  Shonto: [4],
  Shoreham: [35, 47],
  Shoreline: [48],
  Shorewood: [15, 49],
  'Short Creek': [50],
  'Short Hills': [32],
  Shorter: [2],
  Shorterville: [2],
  Shortsville: [35],
  'Shortt Gap': [46],
  Shoshone: [5, 14, -14],
  Shoshoni: [51],
  Shoup: [14],
  'Show Low': [4],
  Showell: [21],
  Shreve: [36],
  Shreveport: [19],
  Shrewsbury: [20, 32, 39],
  'Shrub Oak': [35],
  Shubert: [30],
  Shubuta: [26],
  Shullsburg: [49],
  Shumway: [15],
  Shungnak: [1],
  Shunk: [39],
  Shuqualak: [26],
  Shushan: [35],
  Shutesbury: [20],
  Siasconset: [20],
  Sibley: [13, 15, 19, -24, 25, 26],
  'Sicily Island': [19],
  Sicklerville: [32],
  'Side Lake': [24],
  Sidell: [15],
  Sidman: [39],
  Sidnaw: [23],
  Sidney: [3, 13, 15, 18, 23, 27, 30, 35, 36, 44],
  'Sidney Center': [35],
  Sidon: [26],
  Sieper: [19],
  Sierra: [-5, -33],
  'Sierra Blanca': [44],
  'Sierra City': [5],
  'Sierra Madre': [5],
  'Sierra Vista': [4],
  Sierraville: [5],
  'Siesta Key': [10],
  Sigel: [15, 39],
  'Signal Hill': [5],
  'Signal Mountain': [43],
  Sigourney: [13],
  Sigurd: [45],
  Sikes: [19],
  Sikeston: [25],
  Silas: [2],
  Siler: [18],
  'Siler City': [28],
  Silerton: [43],
  Siletz: [38],
  Silex: [25],
  Siloam: [11, 28],
  'Siloam Springs': [3],
  Silsbee: [44],
  Silt: [6],
  Siluria: [2],
  Silva: [25],
  Silvana: [48],
  Silver: [44],
  'Silver Bay': [24, 35],
  'Silver City': [13, 26, 33, 34],
  'Silver Creek': [11, 26, 30, 35, 48],
  'Silver Gate': [27],
  'Silver Grove': [18],
  'Silver Lake': [16, 17, 24, 31, 35, 38, 49],
  'Silver Plume': [6],
  'Silver Point': [43],
  'Silver Spring': [21, 39],
  'Silver Springs': [10, 34, 35],
  'Silver Star': [27],
  SilverBow: [-27],
  Silverado: [5],
  Silverdale: [39, 48],
  Silverhill: [2],
  Silverlake: [48],
  Silverpeak: [34],
  Silverstreet: [41],
  Silverthorne: [6],
  Silverton: [6, 14, 38, 44],
  Silverwood: [23],
  Silvis: [15],
  'Simi Valley': [5],
  Simla: [6],
  Simmesport: [19],
  Simms: [27, 44],
  Simon: [50],
  Simonton: [44],
  Simpson: [15, 17, -18, 19, -26, 28, 50],
  Simpsonville: [18, 21, 41],
  Sims: [3, 15, 28],
  Simsboro: [19],
  Simsbury: [7],
  Sinai: [42],
  Sinclair: [22, 51],
  Sinclairville: [35],
  Singer: [19],
  'Singers Glen': [46],
  'Sinking Spring': [36],
  'Sinks Grove': [50],
  Sinnamahoning: [39],
  Sinsinawa: [49],
  Sinton: [44],
  Sioux: [-13, -29, -30],
  'Sioux Center': [13],
  'Sioux City': [13],
  'Sioux Falls': [42],
  'Sioux Rapids': [13],
  Sipesville: [39],
  Sipsey: [2],
  Siren: [49],
  Siskiyou: [-5],
  Sisseton: [42],
  'Sister Bay': [49],
  Sisters: [38],
  Sistersville: [50],
  Sitka: [1, -1, 18],
  'Six Lakes': [23],
  'Six Mile': [41],
  'Six Mile Run': [39],
  Sixes: [38],
  Sizerock: [18],
  Skagit: [-48],
  Skagway: [1, -1],
  Skamania: [-48],
  Skamokawa: [48],
  Skandia: [23],
  Skaneateles: [35],
  'Skaneateles Falls': [35],
  Skanee: [23],
  Skellytown: [44],
  Skelton: [50],
  Skiatook: [37],
  Skidmore: [25, 44],
  Skillman: [32],
  Skippack: [39],
  Skippers: [46],
  Skipperville: [2],
  Skipwith: [46],
  Skokie: [15],
  Skowhegan: [22],
  'Skull Valley': [4],
  Skwentna: [1],
  Skyforest: [5],
  Skykomish: [48],
  Skyland: [28],
  Skytop: [39],
  'Slab Fork': [50],
  Slade: [18],
  Slagle: [19],
  Slanesville: [50],
  'Slate Hill': [35],
  'Slate Run': [39],
  'Slate Spring': [26],
  Slatedale: [39],
  Slater: [6, 13, 25, 41],
  Slatersville: [40],
  'Slaterville Springs': [35],
  Slatington: [39],
  Slaton: [44],
  Slatyfork: [50],
  Slaughter: [19],
  Slaughters: [18],
  Slayden: [43],
  Slayton: [24],
  Sledge: [26],
  'Sleepy Eye': [24],
  Sleetmute: [1],
  Slemp: [18],
  Slick: [37],
  Slickville: [39],
  Slidell: [19, 44],
  Sligo: [39],
  Slinger: [49],
  Slingerlands: [35],
  'Slippery Rock': [39],
  Sloan: [13, 34],
  Sloansville: [35],
  Sloatsburg: [35],
  Slocomb: [2],
  Slocum: [40],
  Slope: [-29],
  Sloughhouse: [5],
  Slovan: [39],
  Smackover: [3],
  Smallwood: [35],
  Smarr: [11],
  Smartsville: [5],
  Smartt: [43],
  Smelterville: [14],
  Smethport: [39],
  Smicksburg: [39],
  Smilax: [18],
  Smiley: [44],
  Smith: [-17, -26, 34, -43, -44],
  'Smith Center': [17],
  'Smith Lake': [33],
  'Smith Mills': [18],
  'Smith River': [5],
  Smithboro: [15, 35],
  Smithburg: [50],
  Smithdale: [26],
  Smithers: [50],
  Smithfield: [15, 18, 22, 28, 30, 36, 39, 40, 45, 46, 50],
  Smithland: [13, 18],
  Smithmill: [39],
  'Smiths Creek': [23],
  'Smiths Grove': [18],
  'Smiths Station': [2],
  Smithsburg: [21],
  Smithshire: [15],
  Smithton: [15, 25, 39],
  Smithtown: [35],
  Smithville: [3, 11, 16, 25, 26, 36, 37, 43, 44, 50],
  'Smithville Flats': [35],
  Smithwick: [42],
  Smoaks: [41],
  Smock: [39],
  Smokerun: [39],
  Smoketown: [39],
  Smoot: [50, 51],
  Smyer: [44],
  Smyrna: [9, 11, 23, 28, 35, 41, 43],
  'Smyrna Mills': [22],
  Smyth: [-46],
  Sneads: [10],
  'Sneads Ferry': [28],
  Sneedville: [43],
  Snelling: [5],
  Snellville: [11],
  Snohomish: [48, -48],
  Snook: [44],
  Snoqualmie: [48],
  'Snoqualmie Pass': [48],
  Snover: [23],
  Snow: [37],
  'Snow Camp': [28],
  'Snow Hill': [21, 28],
  'Snow Lake': [3],
  'Snow Shoe': [39],
  Snowflake: [4],
  Snowmass: [6],
  'Snowmass Village': [6],
  Snowshoe: [50],
  Snowville: [45],
  Snyder: [6, 30, 37, -39, 44],
  Snydersburg: [39],
  Snydertown: [39],
  'Soap Lake': [48],
  Sobieski: [49],
  'Social Circle': [11],
  'Society Hill': [41],
  Socorro: [33, -33],
  Sod: [50],
  'Soda Springs': [5, 14],
  'Soddy Daisy': [43],
  Sodus: [23, 35],
  'Sodus Point': [35],
  'Solana Beach': [5],
  Solano: [-5, 33],
  Soldier: [13, 17, 18],
  'Soldiers Grove': [49],
  Soldotna: [1],
  Solebury: [39],
  Soledad: [5],
  Solen: [29],
  Solgohachia: [3],
  Solo: [25],
  Solomon: [4, 17],
  Solomons: [21],
  Solon: [13, 22, 36],
  'Solon Springs': [49],
  Solsberry: [16],
  Solsville: [35],
  Solvang: [5],
  Solway: [24],
  Somerdale: [32, 36],
  Somers: [7, 13, 27, 35, 49],
  'Somers Point': [32],
  Somerset: [5, 6, 16, 18, 20, -21, -22, 23, 32, -32, 36, 39, -39, 44, 46, 49],
  'Somerset Center': [23],
  Somersville: [7],
  Somersworth: [31],
  Somerton: [4],
  Somervell: [-44],
  Somerville: [2, 16, 20, 32, 36, 43, 44, 46],
  'Somes Bar': [5],
  Somis: [5],
  Somonauk: [15],
  Sondheimer: [19],
  Sonoita: [4],
  Sonoma: [5, -5],
  Sonora: [5, 18, 44],
  Sontag: [26],
  Sonyea: [35],
  Sopchoppy: [10],
  Soper: [37],
  Soperton: [11],
  Sophia: [28, 50],
  Soquel: [5],
  Sorento: [15],
  Sorrento: [10, 19, 22],
  Soso: [26],
  Soudan: [24],
  Souderton: [39],
  Soulsbyville: [5],
  'Sound Beach': [35],
  'Sour Lake': [44],
  Souris: [29],
  'South Abington Township': [39],
  'South Acworth': [31],
  'South Amana': [13],
  'South Amboy': [32],
  'South Barre': [20, 47],
  'South Barrington': [15],
  'South Bay': [10],
  'South Beach': [38],
  'South Beloit': [15],
  'South Bend': [16, 30, 44, 48],
  'South Berwick': [22],
  'South Bethlehem': [35],
  'South Bloomfield': [36],
  'South Bloomingville': [36],
  'South Boardman': [23],
  'South Boston': [20, 46],
  'South Bound Brook': [32],
  'South Branch': [23],
  'South Bristol': [22],
  'South Britain': [7],
  'South Burlington': [47],
  'South Butler': [35],
  'South Byron': [35],
  'South Cairo': [35],
  'South Canaan': [39],
  'South Carrollton': [18],
  'South Carver': [20],
  'South Casco': [22],
  'South Charleston': [36, 50],
  'South Chatham': [20],
  'South China': [22],
  'South Cle Elum': [48],
  'South Colby': [48],
  'South Colton': [35],
  'South Dartmouth': [20],
  'South Dayton': [35],
  'South Deerfield': [20],
  'South Dennis': [20, 32],
  'South Dos Palos': [5],
  'South Easton': [20],
  'South Egremont': [20],
  'South El Monte': [5],
  'South Elgin': [15],
  'South English': [13],
  'South Fallsburg': [35],
  'South Fork': [6, 39],
  'South Freeport': [22],
  'South Fulton': [43],
  'South Gardiner': [22],
  'South Gate': [5],
  'South Gibson': [39],
  'South Glastonbury': [7],
  'South Glens Falls': [35],
  'South Grafton': [20],
  'South Greenfield': [25],
  'South Hackensack': [32],
  'South Hadley': [20],
  'South Hamilton': [20],
  'South Harwich': [20],
  'South Haven': [17, 23, 24],
  'South Heart': [29],
  'South Heights': [39],
  'South Hero': [47],
  'South Hill': [46],
  'South Holland': [15],
  'South Houston': [44],
  'South Hutchinson': [17],
  'South International Falls': [24],
  'South Jamesport': [35],
  'South Jordan': [45],
  'South Kent': [7],
  'South Kingstown': [40],
  'South Kortright': [35],
  'South Lake Tahoe': [5],
  'South Lancaster': [20],
  'South Lebanon': [36],
  'South Lee': [20],
  'South Lima': [35],
  'South Londonderry': [47],
  'South Lyme': [7],
  'South Lyon': [23],
  'South Miami': [10],
  'South Milford': [16],
  'South Mills': [28],
  'South Milwaukee': [49],
  'South Montrose': [39],
  'South Mountain': [39],
  'South Naknek': [1],
  'South New Berlin': [35],
  'South Newbury': [31],
  'South Newfane': [47],
  'South Ogden': [45],
  'South Orange': [32],
  'South Orleans': [20],
  'South Otselic': [35],
  'South Ozone Park': [35],
  'South Padre Island': [44],
  'South Paris': [22],
  'South Park': [39],
  'South Pasadena': [5],
  'South Pekin': [15],
  'South Philipsburg': [39],
  'South Pittsburg': [43],
  'South Plainfield': [32],
  'South Plains': [44],
  'South Plymouth': [35],
  'South Point': [36],
  'South Pomfret': [47],
  'South Portland': [22],
  'South Portsmouth': [18],
  'South Prairie': [48],
  'South Range': [23, 49],
  'South Richmond Hill': [35],
  'South River': [32],
  'South Rockwood': [23],
  'South Roxana': [15],
  'South Royalton': [47],
  'South Ryegate': [47],
  'South Saint Paul': [24],
  'South Salem': [35, 36],
  'South San Francisco': [5],
  'South Seaville': [32],
  'South Shore': [18, 42],
  'South Sioux City': [30],
  'South Solon': [36],
  'South Sterling': [39],
  'South Strafford': [47],
  'South Sutton': [31],
  'South Tamworth': [31],
  'South Thomaston': [22],
  'South Vienna': [36],
  'South Wales': [35],
  'South Walpole': [20],
  'South Wayne': [49],
  'South Webster': [36],
  'South Wellfleet': [20],
  'South West City': [25],
  'South Weymouth': [20],
  'South Whitley': [16],
  'South Williamson': [18],
  'South Williamsport': [39],
  'South Willington': [7],
  'South Wilmington': [15],
  'South Windham': [7, 22],
  'South Windsor': [7],
  'South Woodstock': [7, 47],
  'South Yarmouth': [20],
  Southampton: [20, 35, 39, -46],
  Southard: [37],
  Southaven: [26],
  Southborough: [20],
  Southbridge: [20],
  Southbury: [7],
  'Southeast Fairbanks': [-1],
  Southeastern: [39],
  'Southern Md Facility': [21],
  'Southern Pines': [28],
  'Southern Shores': [28],
  Southfield: [20, 23],
  Southfields: [35],
  Southgate: [23],
  Southington: [7, 36],
  Southlake: [44],
  Southmayd: [44],
  Southmont: [28],
  Southold: [35],
  Southport: [7, 22, 28],
  Southside: [2, 43, 50],
  Southview: [39],
  Southwest: [39],
  'Southwest Harbor': [22],
  'Southwest Ranches': [10],
  Southwick: [20],
  Southworth: [48],
  'Spaceport City': [33],
  Spade: [44],
  Spalding: [-11, 23, 30],
  Spanaway: [48],
  Spangle: [48],
  Spangler: [39],
  'Spanish Fork': [45],
  'Spanish Fort': [2],
  Spanishburg: [50],
  Sparkill: [35],
  Sparkman: [3],
  Sparks: [11, 30, 34, 37],
  'Sparks Glencoe': [21],
  Sparland: [15],
  Sparr: [10],
  'Sparrow Bush': [35],
  'Sparrows Point': [21],
  Sparta: [11, 15, 18, 23, 25, 28, 32, 36, 43, 46, 49],
  Spartanburg: [41, -41],
  Spartansburg: [39],
  Spavinaw: [37],
  Spearfish: [42],
  Spearman: [44],
  Spearsville: [19],
  Spearville: [17],
  Speculator: [35],
  Speed: [28],
  Speedwell: [43, 46],
  Speer: [15],
  Spelter: [50],
  Spencer: [13, 14, 16, -16, -18, 20, 28, 30, 35, 36, 37, 42, 43, 46, 49, 50],
  Spencerport: [35],
  Spencertown: [35],
  Spencerville: [16, 21, 36, 37],
  Speonk: [35],
  Sperry: [13, 37],
  Sperryville: [46],
  Spiceland: [16],
  Spicer: [24],
  Spicewood: [44],
  Spickard: [25],
  Spillville: [13],
  Spindale: [28],
  Spink: [-42],
  Spinnerstown: [39],
  'Spirit Lake': [13, 14],
  Spiritwood: [29],
  Spiro: [37],
  Spivey: [17],
  Splendora: [44],
  Spofford: [31],
  Spokane: [25, 48, -48],
  'Spokane Valley': [48],
  Spooner: [49],
  Spotswood: [32],
  Spotsylvania: [46, -46],
  Spottsville: [18],
  'Spout Spring': [46],
  Spraggs: [39],
  Sprague: [30, 48],
  'Sprague River': [38],
  Spragueville: [13],
  Sprakers: [35],
  'Sprankle Mills': [39],
  Spray: [38],
  Spreckels: [5],
  Spring: [44],
  'Spring Arbor': [23],
  'Spring Branch': [44],
  'Spring Brook': [35],
  'Spring Church': [39],
  'Spring City': [39, 43, 45],
  'Spring Creek': [34, 39, 43],
  'Spring Dale': [50],
  'Spring Gap': [21],
  'Spring Garden': [2],
  'Spring Glen': [35, 39],
  'Spring Green': [49],
  'Spring Grove': [15, 24, 39, 46],
  'Spring Hill': [10, 17, 43],
  'Spring Hope': [28],
  'Spring House': [39],
  'Spring Lake': [23, 24, 28, 32],
  'Spring Mills': [39],
  'Spring Mount': [39],
  'Spring Park': [24],
  'Spring Run': [39],
  'Spring Valley': [5, 15, 24, 35, 36, 49],
  Springboro: [36, 39],
  Springbrook: [13, 49],
  Springdale: [3, 27, 39, 45, 48],
  Springer: [33, 37],
  Springerton: [15],
  Springerville: [4],
  Springfield: [3, 6, 11, 14, 15, 18, 19, 20, 22, 24, 25, 30, 31, 32, 36, 38, 39, 41, 42, 43, 46, 47, 49, 50],
  'Springfield Center': [35],
  'Springfield Gardens': [35],
  Springhill: [19],
  Springlake: [44],
  Springport: [16, 23],
  Springs: [39],
  Springtown: [39, 44],
  Springvale: [22],
  Springview: [30],
  Springville: [2, 5, 13, 16, 35, 39, 43, 45],
  Springwater: [35],
  Sproul: [39],
  Spruce: [23],
  'Spruce Creek': [39],
  'Spruce Head': [22],
  'Spruce Pine': [2, 28],
  Spur: [44],
  Spurgeon: [16],
  Spurger: [44],
  Spurlockville: [50],
  'Squaw Lake': [24],
  'Squaw Valley': [5],
  Squire: [50],
  Squires: [25],
  'Squirrel Island': [22],
  'St Columbans': [30],
  'St John': [17],
  Staatsburg: [35],
  Stacy: [24, 28],
  Stacyville: [13, 22],
  Stafford: [7, 17, -17, 35, 36, 44, 46, -46],
  'Stafford Springs': [7],
  Staffordsville: [18, 46],
  Staffordville: [7],
  Stahlstown: [39],
  Staley: [28],
  Stambaugh: [18, 23],
  Stamford: [7, 30, 35, 44, 47],
  'Stamping Ground': [18],
  Stamps: [3],
  Stanaford: [50],
  Stanardsville: [46],
  Stanberry: [25],
  Stanchfield: [24],
  Standard: [5, 15],
  Standish: [5, 22, 23],
  Stanfield: [4, 28, 38],
  Stanford: [5, 15, 16, 18, 27],
  Stanfordville: [35],
  Stanhope: [13, 32],
  Stanislaus: [-5],
  Stanley: [13, 14, 28, 29, 33, 35, -42, 46, 49],
  Stanleytown: [46],
  Stanly: [-28],
  "Stansbury Park'": [45],
  Stanton: [2, 5, 13, -17, 18, 23, 25, 29, 30, -30, 32, 43, 44],
  Stantonsburg: [28],
  Stantonville: [43],
  Stanville: [18],
  Stanwood: [13, 23, 48],
  Staplehurst: [30],
  Staples: [24, 44],
  Stapleton: [2, 11, 30],
  Star: [14, 26, 28, 44],
  'Star City': [3, 16],
  'Star Junction': [39],
  'Star Lake': [35, 49],
  'Star Prairie': [49],
  'Star Tannery': [46],
  Starbuck: [24, 48],
  Starford: [39],
  Stark: [-15, 17, -29, -36],
  'Stark City': [25],
  Starke: [10, -16],
  Starks: [19],
  Starksboro: [47],
  Starkville: [26],
  Starkweather: [29],
  Starlight: [39],
  Starr: [41, -44],
  Starrucca: [39],
  Start: [19],
  Startex: [41],
  Startup: [48],
  'State Center': [13],
  'State College': [39],
  'State Farm': [46],
  'State Line': [16, 26, 39],
  'State Park': [41],
  'State Road': [28],
  'State University': [3],
  Stateline: [34],
  'Staten Island': [35],
  Statenville: [11],
  Statesboro: [11],
  Statesville: [28],
  Statham: [11],
  Staunton: [15, 16, 46],
  Stayton: [38],
  'Steamboat Rock': [13],
  'Steamboat Springs': [6],
  Steamburg: [35],
  Stearns: [18, -24],
  Stebbins: [1],
  Stedman: [28],
  Steedman: [25],
  Steele: [2, 18, -24, 25, 29, -29],
  'Steele City': [30],
  'Steeles Tavern': [46],
  Steeleville: [15],
  Steelville: [25],
  Steen: [24],
  Steens: [26],
  'Steep Falls': [22],
  Steger: [15],
  Stehekin: [48],
  Steilacoom: [48],
  Steinauer: [30],
  Steinhatchee: [10],
  Stella: [25, 28, 30],
  'Stella Niagara': [35],
  Stem: [28],
  Stendal: [16],
  'Stennis Space Center': [26],
  Stephan: [42],
  Stephen: [24],
  Stephens: [3, 11, -11, -37, -44],
  'Stephens City': [46],
  Stephenson: [-15, 23, 46, 50],
  Stephensport: [18],
  Stephentown: [35],
  Stephenville: [44],
  Steptoe: [48],
  Sterling: [1, 6, 7, 15, 17, 20, 23, 29, 30, 35, 36, 37, 39, -44, 45, 46],
  'Sterling City': [44],
  'Sterling Forest': [35],
  'Sterling Heights': [23],
  Sterlington: [19],
  Sterrett: [2],
  Stet: [25],
  Stetson: [22],
  Stetsonville: [49],
  Steuben: [-16, 22, -35, 49],
  Steubenville: [36],
  Stevens: [-17, -24, 39, -48],
  'Stevens Point': [49],
  'Stevens Village': [1],
  Stevensburg: [46],
  Stevenson: [2, 7, 21, 48],
  'Stevenson Ranch': [5],
  Stevensville: [21, 23, 27, 39, 46],
  Stevinson: [5],
  Steward: [15],
  Stewardson: [15],
  Stewart: [-11, 24, 26, 36, 43, -43],
  'Stewarts Point': [5],
  Stewartstown: [39],
  Stewartsville: [25, 32],
  Stewartville: [24],
  Stickney: [42],
  Stidham: [37],
  Stigler: [37],
  Stilesville: [16],
  'Still Pond': [21],
  'Still River': [20],
  'Stillman Valley': [15],
  Stillmore: [11],
  Stillwater: [22, 24, -27, 32, 35, 36, 37, 39],
  Stilwell: [17, 37],
  Stinesville: [16],
  Stinnett: [18, 44],
  'Stinson Beach': [5],
  Stirling: [32],
  'Stirling City': [5],
  Stirum: [29],
  Stites: [14],
  Stittville: [35],
  Stitzer: [49],
  Stockbridge: [11, 20, 23, 47, 49],
  Stockdale: [36, 39, 44],
  Stockertown: [39],
  Stockett: [27],
  Stockholm: [22, 32, 42, 49],
  Stockland: [15],
  Stockport: [13, 36],
  Stockton: [2, 5, 11, 13, 15, 17, 21, 24, 25, 32, 35, 45],
  'Stockton Springs': [22],
  Stockville: [30],
  Stockwell: [16],
  Stoddard: [-25, 31, 49],
  Stokes: [28, -28],
  Stokesdale: [28],
  Stollings: [50],
  Stone: [-3, 18, -25, -26],
  'Stone Creek': [36],
  'Stone Harbor': [32],
  'Stone Lake': [49],
  'Stone Mountain': [11],
  'Stone Park': [15],
  'Stone Ridge': [35],
  Stoneboro: [39],
  Stonefort: [15],
  Stoneham: [6, 20, 22],
  Stoneville: [26, 28],
  Stonewall: [19, 26, 28, 37, 44, -44],
  'Stoney Fork': [18],
  Stonington: [7, 15, 22],
  'Stony Brook': [35],
  'Stony Creek': [35, 46],
  'Stony Point': [28, 35],
  'Stony Ridge': [36],
  Stonyford: [5],
  Stopover: [18],
  Storden: [24],
  Storey: [-34],
  'Storm Lake': [13],
  Stormville: [35],
  Storrie: [5],
  Storrs: [7],
  'Storrs Mansfield': [7],
  Story: [3, -13, 51],
  'Story City': [13],
  'Stotts City': [25],
  Stottville: [35],
  Stoughton: [20, 49],
  Stout: [13, 36],
  Stoutland: [25],
  Stoutsville: [25, 36],
  Stovall: [28],
  Stover: [25],
  Stow: [20, 35, 36],
  Stowe: [47],
  Stowell: [44],
  Stoy: [15],
  Stoystown: [39],
  Strabane: [39],
  Strafford: [25, 31, -31, 47],
  Strandburg: [42],
  Strandquist: [24],
  Strang: [30, 37],
  Strasburg: [6, 15, 25, 29, 36, 39, 46],
  Stratford: [5, 7, 13, 32, 35, 37, 42, 44, 46, 48, 49],
  Stratham: [31],
  Strathcona: [24],
  Strathmere: [32],
  Strathmore: [5],
  Strattanville: [39],
  Stratton: [6, 22, 30, 36],
  Straughn: [16],
  Strausstown: [39],
  Strawberry: [3, 5],
  'Strawberry Plains': [43],
  'Strawberry Point': [13],
  'Strawberry Valley': [5],
  Strawn: [15, 44],
  Streamwood: [15],
  Streator: [15],
  Street: [21],
  Streeter: [29],
  Streetman: [44],
  Streetsboro: [36],
  Stringer: [26],
  Stringtown: [37],
  Stroh: [16],
  Stromsburg: [30],
  Strong: [3, 22],
  'Strong City': [17],
  Stronghurst: [15],
  Strongstown: [39],
  Strongsville: [36],
  Stroud: [37],
  Stroudsburg: [39],
  Strum: [49],
  Strunk: [18],
  Struthers: [36],
  Stryker: [27, 36],
  Strykersville: [35],
  Stuart: [10, 13, 30, 37, 46],
  'Stuarts Draft': [46],
  'Studio City': [5],
  Studley: [46],
  'Stump Creek': [39],
  'Stumpy Point': [28],
  Sturbridge: [20],
  Sturdivant: [25],
  Sturgeon: [25, 39],
  'Sturgeon Bay': [49],
  'Sturgeon Lake': [24],
  Sturgis: [18, 23, 26, 42],
  Sturkie: [3],
  Sturtevant: [49],
  Stutsman: [-29],
  Stuttgart: [3],
  Stuyvesant: [35],
  'Stuyvesant Falls': [35],
  Suamico: [49],
  Subiaco: [3],
  Sublette: [15, 17, -51],
  Sublime: [44],
  Sublimity: [38],
  'Suburb Maryland Fac': [21],
  Succasunna: [32],
  Success: [3, 25],
  Suches: [11],
  Sudan: [44],
  Sudbury: [20],
  Sudlersville: [21],
  Suffern: [35],
  Suffield: [7],
  Suffolk: [-20, -35, 46],
  'Sugar City': [6, 14],
  'Sugar Grove': [15, 28, 36, 39, 46, 50],
  'Sugar Hill': [31],
  'Sugar Land': [44],
  'Sugar Loaf': [35],
  'Sugar Mountain': [28],
  'Sugar Run': [39],
  'Sugar Tree': [43],
  'Sugar Valley': [11],
  Sugarcreek: [36],
  Sugarloaf: [5, 39],
  Sugartown: [19],
  'Suisun City': [5],
  Suitland: [21],
  Sula: [27],
  Sulligent: [2],
  Sullivan: [15, 16, -16, 18, 22, 25, -25, 31, -31, -35, 36, -39, -43, 49],
  'Sullivan City': [44],
  'Sullivans Island': [41],
  Sully: [13, -42],
  Sulphur: [16, 18, 19, 37],
  'Sulphur Bluff': [44],
  'Sulphur Rock': [3],
  'Sulphur Springs': [3, 16, 36, 44],
  Sultan: [48],
  Sultana: [5],
  Sumas: [48],
  Sumatra: [10, 27],
  'Sumava Resorts': [16],
  Sumerco: [50],
  Sumerduck: [46],
  Sumiton: [2],
  'Summer Lake': [38],
  'Summer Shade': [18],
  Summerdale: [2, 39],
  Summerfield: [10, 15, 17, 19, 28, 36, 44],
  Summerhill: [39],
  Summerland: [5],
  'Summerland Key': [10],
  Summers: [3, -50],
  Summersville: [18, 25, 50],
  Summerton: [41],
  Summertown: [43],
  Summerville: [11, 38, 39, 41],
  Summit: [3, -6, 26, 32, 35, -36, 42, 45, -45],
  'Summit Argo': [15],
  'Summit Hill': [39],
  'Summit Lake': [49],
  'Summit Point': [50],
  'Summit Station': [36, 39],
  Summitville: [16, 35, 36, 43],
  Sumner: [11, 13, 15, -17, 22, 23, 25, 26, 30, -43, 44, 48],
  Sumneytown: [39],
  Sumpter: [38],
  Sumrall: [26],
  Sumter: [-2, -10, -11, 41, -41],
  Sumterville: [10],
  Sun: [19],
  'Sun City': [4, 10, 17],
  'Sun City Center': [10],
  'Sun City West': [4],
  'Sun Prairie': [49],
  'Sun River': [27],
  'Sun Valley': [4, 5, 14, 34],
  Sunapee: [31],
  Sunbright: [43],
  Sunburg: [24],
  Sunburst: [27],
  Sunbury: [28, 36, 39],
  Suncook: [31],
  Sundance: [51],
  Sunderland: [20, 21],
  Sundown: [44],
  Sunfield: [23],
  Sunflower: [2, 26, -26],
  Sunland: [5],
  'Sunland Park': [33],
  Sunman: [16],
  'Sunny Isles Beach': [10],
  'Sunny Side': [11],
  Sunnyside: [35, 45, 48],
  Sunnyvale: [5, 44],
  Sunol: [5],
  Sunray: [44],
  'Sunrise Beach': [25],
  Sunset: [19, 22, 41, 44],
  'Sunset Beach': [5, 28],
  Sunshine: [19],
  Sunspot: [33],
  Supai: [4],
  Superior: [4, 13, 27, 30, 49, 51],
  Supply: [28],
  Suquamish: [48],
  'Surf City': [28],
  Surfside: [5],
  'Surfside Beach': [41, 44],
  Surgoinsville: [43],
  Suring: [49],
  Surprise: [4, 30, 35],
  Surrency: [11],
  Surrey: [29],
  Surry: [22, -28, 46, -46],
  Surveyor: [50],
  Susan: [46],
  Susanville: [5],
  Susquehanna: [39, -39],
  Sussex: [-9, 32, -32, 46, -46, 49],
  Sutersville: [39],
  Sutherland: [13, 30, 46],
  'Sutherland Springs': [44],
  Sutherlin: [38, 46],
  Sutter: [5, -5, 15],
  'Sutter Creek': [5],
  Sutton: [1, 20, 29, 30, -44, 47, 50],
  'Suttons Bay': [23],
  Suwanee: [11],
  Suwannee: [10, -10],
  Swain: [-28, 35],
  Swainsboro: [11],
  Swaledale: [13],
  Swampscott: [20],
  Swan: [13],
  'Swan Lake': [26, 35],
  'Swan River': [24],
  'Swan Valley': [14],
  Swanlake: [14],
  Swannanoa: [28],
  Swanquarter: [28],
  'Swans Island': [22],
  Swansboro: [28],
  Swansea: [15, 20, 41],
  Swanton: [21, 30, 36, 47],
  Swanville: [24],
  Swanzey: [31],
  Swarthmore: [39],
  Swartswood: [32],
  Swartz: [19],
  'Swartz Creek': [23],
  Swatara: [24],
  Swayzee: [16],
  'Swea City': [13],
  Swedesboro: [32],
  Swedesburg: [13],
  Sweeden: [18],
  Sweeny: [44],
  Sweet: [14],
  'Sweet Briar': [46],
  'Sweet Grass': [27],
  'Sweet Home': [3, 38, 44],
  'Sweet Springs': [25],
  'Sweet Valley': [39],
  'Sweet Water': [2],
  SweetGrass: [-27],
  Sweetser: [16],
  Sweetwater: [37, 43, 44, -51],
  Swengel: [39],
  Swepsonville: [28],
  Swift: [-24],
  Swifton: [3],
  Swiftown: [26],
  Swiftwater: [39],
  Swink: [6, 37],
  Swisher: [13, -44],
  Swiss: [50],
  Swisshome: [38],
  Switchback: [50],
  'Switz City': [16],
  Switzer: [50],
  Switzerland: [-16],
  Swoope: [46],
  'Swords Creek': [46],
  Sybertsville: [39],
  Sycamore: [2, 11, 15, 17, 36, 39, 41],
  Sydney: [10],
  Sykeston: [29],
  Sykesville: [21, 39],
  Sylacauga: [2],
  Sylmar: [5],
  Sylva: [28],
  'Sylvan Beach': [23, 35],
  'Sylvan Grove': [17],
  Sylvania: [2, 11, 36, 39],
  Sylvester: [11, 44, 50],
  Sylvia: [17],
  Symsonia: [18],
  Syosset: [35],
  Syracuse: [16, 17, 25, 30, 35, 36, 45],
  Syria: [46],
  Taberg: [35],
  Tabernash: [6],
  Tabiona: [45],
  'Table Grove': [15],
  'Table Rock': [30],
  Tabor: [13, 42],
  'Tabor City': [28],
  Tacna: [4],
  Tacoma: [48],
  Taconic: [7],
  Taconite: [24],
  Tad: [50],
  Taft: [5, 37, 43, 44],
  Tafton: [39],
  Taftsville: [47],
  Taftville: [7],
  Tahlequah: [37],
  'Tahoe City': [5],
  'Tahoe Vista': [5],
  Tahoka: [44],
  Taholah: [48],
  Tahoma: [5],
  Tahuya: [48],
  Taiban: [33],
  'Takoma Park': [21],
  Takotna: [1],
  Talala: [37],
  Talbot: [-11, -21],
  Talbott: [43],
  Talbotton: [11],
  Talco: [44],
  Talcott: [50],
  Talent: [38],
  Taliaferro: [-11],
  Talihina: [37],
  Talisheek: [19],
  Talkeetna: [1],
  'Talking Rock': [11],
  'Tall Timbers': [21],
  Talladega: [2, -2],
  Tallahassee: [10],
  Tallahatchie: [-26],
  Tallapoosa: [-2, 11, 25],
  Tallassee: [2, 43],
  Tallevast: [10],
  Tallmadge: [36],
  Tallman: [35],
  Tallmansville: [50],
  Tallula: [15],
  Tallulah: [19],
  'Tallulah Falls': [11],
  Talmage: [5, 17, 30, 39, 45],
  Talmo: [11],
  Talmoon: [24],
  Taloga: [37],
  Talpa: [44],
  Tama: [13, -13],
  Tamaqua: [39],
  Tamarac: [10],
  Tamarack: [24],
  Tamaroa: [15],
  Tamassee: [41],
  Tamiment: [39],
  Tamms: [15],
  Tampa: [10, 17],
  Tampico: [15],
  Tamworth: [31],
  Tanacross: [1],
  Tanana: [1],
  Taney: [-25],
  Taneytown: [21],
  Taneyville: [25],
  Tangent: [38],
  Tangerine: [10],
  Tangier: [46],
  Tangipahoa: [19, -19],
  Tanner: [2],
  Tannersville: [35, 39, 46],
  Taopi: [24],
  Taos: [33, -33],
  'Taos Ski Valley': [33],
  Tappahannock: [46],
  Tappan: [35],
  Tappen: [29],
  'Tar Heel': [28],
  'Tarawa Terrace': [28],
  Tarboro: [28],
  Tarentum: [39],
  Tariffville: [7],
  Tarkio: [25],
  Tarlton: [36],
  Tarpley: [44],
  'Tarpon Springs': [10],
  Tarrant: [-44],
  Tarrs: [39],
  Tarrytown: [11, 35],
  Tarzan: [44],
  Tarzana: [5],
  Tasley: [46],
  Taswell: [16],
  Tatamy: [39],
  Tate: [11, -26],
  Tateville: [18],
  Tatitlek: [1],
  Tattnall: [-11],
  Tatum: [33, 41, 44],
  Tatums: [37],
  Taunton: [20, 24],
  Tavares: [10],
  Tavernier: [10],
  'Tawas City': [23],
  Taylor: [3, 4, -10, -11, -13, -18, 19, 23, 25, 26, 29, 30, 39, 44, -44, 49, -49, -50],
  'Taylor Hill': [19],
  'Taylor Mill': [18],
  'Taylor Ridge': [15],
  'Taylor Springs': [15],
  Taylors: [41],
  'Taylors Falls': [24],
  'Taylors Island': [21],
  Taylorstown: [39],
  Taylorsville: [5, 11, 16, 18, 26, 28],
  Taylorville: [15],
  Tazewell: [-15, 43, 46, -46],
  Tchula: [26],
  Tea: [42],
  Teaberry: [18],
  Teachey: [28],
  Teague: [44],
  Teaneck: [32],
  Teasdale: [45],
  Teays: [50],
  Tebbetts: [25],
  Tecate: [5],
  Techny: [15],
  Tecopa: [5],
  Tecumseh: [17, 23, 25, 30, 37],
  'Teec Nos Pos': [4],
  'Teeds Grove': [13],
  Tefft: [16],
  Tehachapi: [5],
  Tehama: [5, -5],
  Tehuacana: [44],
  Teigen: [27],
  Tekamah: [30],
  Tekoa: [48],
  Tekonsha: [23],
  Telegraph: [44],
  Telephone: [44],
  Telfair: [-11],
  Telferner: [44],
  Telford: [39, 43],
  Tell: [44],
  'Tell City': [16],
  Teller: [1, -6],
  'Tellico Plains': [43],
  Telluride: [6],
  Telogia: [10],
  Temecula: [5],
  Tempe: [4],
  Temperance: [23],
  Temperanceville: [46],
  Temple: [11, 22, 31, 37, 39, 44],
  'Temple Bar Marina': [4],
  'Temple City': [5],
  'Temple Hills': [21],
  Templeton: [5, 13, 16, 20, 39],
  Templeville: [21],
  'Ten Mile': [43],
  'Ten Sleep': [51],
  Tenafly: [32],
  Tenaha: [44],
  'Tenakee Springs': [1],
  'Tenants Harbor': [22],
  Tendoy: [14],
  Tenino: [48],
  Tenmile: [38],
  Tennent: [32],
  Tennessee: [15],
  'Tennessee Colony': [44],
  'Tennessee Ridge': [43],
  Tennga: [11],
  Tennille: [11],
  Tennyson: [16, 44],
  Tensas: [-19],
  Tensed: [14],
  Tenstrike: [24],
  Tererro: [33],
  Terlingua: [44],
  Terlton: [37],
  Termo: [5],
  'Terra Alta': [50],
  'Terra Bella': [5],
  'Terra Ceia': [10],
  'Terrace Park': [36],
  Terral: [37],
  'Terre Haute': [16],
  'Terre Hill': [39],
  Terrebonne: [-19, 38],
  Terrell: [-11, 28, 44, -44],
  Terreton: [14],
  Terril: [13],
  Terry: [26, 27, -44],
  Terrytown: [19],
  Terryville: [7],
  Tescott: [17],
  Tesuque: [33],
  Teterboro: [32],
  Teton: [14, -14, -27, -51],
  'Teton Village': [51],
  Tetonia: [14],
  Teutopolis: [15],
  Tewksbury: [20],
  Texarkana: [3, 44],
  Texas: [-25, -37],
  'Texas City': [44],
  Texhoma: [37, 44],
  Texico: [15, 33],
  Texline: [44],
  Texola: [37],
  Thackerville: [37],
  Thatcher: [4, 14],
  Thawville: [15],
  Thaxton: [26, 46],
  Thayer: [13, 15, 16, 17, 25, -30],
  Thayne: [51],
  'The Colony': [44],
  'The Dalles': [38],
  'The Lakes': [34],
  'The Plains': [36, 46],
  'The Rock': [11],
  'The Sea Ranch': [5],
  'The Villages': [10],
  'The Woodlands': [44],
  Thebes: [15],
  Thedford: [30],
  Thelma: [18],
  Thendara: [35],
  Theodore: [2],
  Theodosia: [25],
  Theresa: [35, 49],
  Theriot: [19],
  Thermal: [5],
  Thermopolis: [51],
  Thetford: [47],
  'Thetford Center': [47],
  Thibodaux: [19],
  Thicket: [44],
  Thida: [3],
  'Thief River Falls': [24],
  Thiells: [35],
  Thomas: [-11, -17, -30, 37, 50],
  Thomasboro: [15],
  Thomaston: [2, 7, 11, 22, 44],
  Thomastown: [26],
  Thomasville: [2, 11, 28, 39],
  Thompson: [7, 13, 25, 29, 36, 39, 45],
  'Thompson Falls': [27],
  'Thompson Ridge': [35],
  Thompsons: [44],
  'Thompsons Station': [43],
  Thompsontown: [39],
  Thompsonville: [15, 23, 35],
  Thomson: [11, 15],
  Thonotosassa: [10],
  Thor: [13],
  Thoreau: [33],
  'Thorn Hill': [43],
  Thornburg: [13, 46],
  Thorndale: [39, 44],
  Thorndike: [20, 22],
  'Thorne Bay': [1],
  Thornfield: [25],
  Thornton: [3, 5, 6, 13, 15, 18, 31, 39, 44, 48, 50],
  Thorntown: [16],
  Thornville: [36],
  Thornwood: [35],
  Thorofare: [32],
  Thorp: [48, 49],
  Thorpe: [50],
  Thorsby: [2],
  'Thousand Island Park': [35],
  'Thousand Oaks': [5],
  'Thousand Palms': [5],
  Thousandsticks: [18],
  Thrall: [44],
  'Three Bridges': [32],
  'Three Forks': [27],
  'Three Lakes': [49],
  'Three Mile Bay': [35],
  'Three Oaks': [23],
  'Three Rivers': [5, 20, 23, 44],
  'Three Springs': [39],
  Throckmorton: [44, -44],
  Thurman: [13, 36],
  Thurmond: [28, 50],
  Thurmont: [21],
  Thurston: [30, -30, 36, -48],
  Tibbie: [2],
  Tiburon: [5],
  Tichnor: [3],
  Tickfaw: [19],
  Ticonderoga: [35],
  Tidewater: [38],
  Tidioute: [39],
  'Tie Plant': [26],
  'Tie Siding': [51],
  'Tierra Amarilla': [33],
  Tieton: [48],
  Tiff: [25],
  'Tiff City': [25],
  Tiffin: [13, 36],
  Tift: [-11],
  Tifton: [11],
  Tigard: [38],
  Tiger: [11],
  Tigerton: [49],
  Tigerville: [41],
  Tignall: [11],
  Tigrett: [43],
  Tijeras: [33],
  Tilden: [15, 30, 44],
  Tilghman: [21],
  Tiline: [18],
  Tillamook: [38, -38],
  Tillar: [3],
  Tillatoba: [26],
  Tilleda: [49],
  Tiller: [38],
  Tillery: [28],
  Tillman: [-37, 41],
  Tillson: [35],
  Tilly: [3],
  Tilton: [15, 31],
  Tiltonsville: [36],
  Timber: [38],
  'Timber Lake': [42],
  Timberlake: [28],
  Timberon: [33],
  Timberville: [46],
  Timblin: [39],
  Timbo: [3],
  Timewell: [15],
  Timmonsville: [41],
  Timnath: [6],
  Timonium: [21],
  Timpson: [44],
  Tina: [25],
  Tingley: [13],
  'Tinley Park': [15],
  Tinnie: [33],
  Tinsley: [26],
  Tintah: [24],
  Tioga: [19, 29, -35, 39, -39, 44, 50],
  'Tioga Center': [35],
  Tiona: [39],
  Tionesta: [39],
  Tiplersville: [26],
  'Tipp City': [36],
  Tippah: [-26],
  Tippecanoe: [16, -16, 36],
  Tippo: [26],
  Tipton: [5, 13, 16, -16, 17, 23, 25, 37, 39, 43, -43],
  Tiptonville: [43],
  'Tire Hill': [39],
  Tiro: [36],
  'Tisch Mills': [49],
  Tishomingo: [26, -26, 37],
  Tiskilwa: [15],
  Titonka: [13],
  Titus: [2, -44],
  Titusville: [10, 32, 39],
  Tiverton: [40],
  Tivoli: [35, 44],
  Toano: [46],
  Toast: [28],
  Tobaccoville: [28],
  Tobias: [30],
  Tobyhanna: [39],
  Toccoa: [11],
  'Toccoa Falls': [11],
  Toccopola: [26],
  Todd: [-18, -24, 28, 39, -42],
  Toddville: [13, 21],
  Toeterville: [13],
  Tofte: [24],
  Togiak: [1],
  Tohatchi: [33],
  Toivola: [23],
  Tok: [1],
  Tokeland: [48],
  Tokio: [29, 44],
  'Toksook Bay': [1],
  Tolar: [44],
  Toledo: [13, 15, 36, 38, 48],
  Tolland: [7, -7],
  Tollesboro: [18],
  Tolleson: [4],
  Tolley: [29],
  Tollhouse: [5],
  Tolna: [29],
  Tolono: [15],
  'Tolovana Park': [38],
  Tolstoy: [42],
  Toluca: [15],
  'Toluca Lake': [5],
  'Tom Bean': [44],
  'Tom Green': [-44],
  Tomah: [49],
  Tomahawk: [18, 49],
  Tomales: [5],
  Tomball: [44],
  Tombstone: [4],
  Tome: [33],
  'Tomkins Cove': [35],
  Tompkins: [-35],
  Tompkinsville: [18],
  'Toms Brook': [46],
  'Toms River': [32],
  Tonalea: [4],
  Tonasket: [48],
  Tonawanda: [35],
  Toney: [2],
  Tonganoxie: [17],
  Tonica: [15],
  Tonkawa: [37],
  Tonopah: [4, 34],
  Tontitown: [3],
  'Tonto Basin': [4],
  Tontogany: [36],
  Tony: [49],
  Tooele: [45, -45],
  Toole: [-27],
  Toombs: [-11],
  Toomsboro: [11],
  Toomsuba: [26],
  Toone: [43],
  Topanga: [5],
  Topawa: [4],
  Topaz: [5],
  Topeka: [15, 16, 17],
  Topinabee: [23],
  Topmost: [18],
  Topock: [4],
  Toponas: [6],
  Toppenish: [48],
  Topping: [46],
  Topsfield: [20, 22],
  Topsham: [22, 47],
  Topton: [28, 39],
  Toquerville: [45],
  Tornado: [50],
  Tornillo: [44],
  Toronto: [17, 36, 42],
  Torrance: [5, -33, 39],
  Torreon: [33],
  Torrey: [45],
  Torrington: [7, 51],
  'Tortilla Flat': [4],
  Toston: [27],
  Totowa: [32],
  Totz: [18],
  Touchet: [48],
  Tougaloo: [26],
  Toughkenamon: [39],
  Toulon: [15],
  Toutle: [48],
  Tovey: [15],
  Tow: [44],
  Towaco: [32],
  Towanda: [15, 17, 39],
  Towaoc: [6],
  Tower: [23, 24],
  'Tower City': [29, 39],
  'Tower Hill': [15],
  'Town Creek': [2],
  Towner: [29, -29],
  Townley: [2],
  Towns: [-11],
  Townsend: [9, 11, 20, 27, 43, 46, 49],
  Townshend: [47],
  'Township Of Washington': [32],
  Townsville: [28],
  Townville: [39, 41],
  Towson: [21],
  Toxey: [2],
  Toyah: [44],
  Toyahvale: [44],
  'Trabuco Canyon': [5],
  Tracy: [5, 13, 24],
  'Tracy City': [43],
  'Tracys Landing': [21],
  Tracyton: [48],
  Trade: [43],
  Traer: [13],
  Trafalgar: [16],
  Trafford: [2, 39],
  Trail: [24, 38],
  'Trail City': [42],
  Traill: [-29],
  Tram: [18],
  Trampas: [33],
  Tranquility: [32],
  Tranquillity: [5],
  Transfer: [39],
  Transylvania: [19, -28],
  Traphill: [28],
  Trappe: [21],
  'Trapper Creek': [1],
  Traskwood: [3],
  'Travelers Rest': [41],
  Traver: [5],
  Traverse: [-24],
  'Traverse City': [23],
  Travis: [-44],
  'Travis Afb': [5],
  Treadwell: [35],
  Treasure: [-27],
  Trebloc: [26],
  Treece: [17],
  Trego: [-17, 27, 49],
  Treichlers: [39],
  Treloar: [25],
  Trementina: [33],
  Tremont: [15, 26, 39],
  'Tremont City': [36],
  Tremonton: [45],
  Trempealeau: [49, -49],
  Trenary: [23],
  Trent: [42, 44],
  Trenton: [2, 10, 11, 15, 18, 23, 25, 28, 29, 30, 32, 36, 41, 43, 44, 45],
  'Tres Piedras': [33],
  'Tres Pinos': [5],
  Tresckow: [39],
  Treutlen: [-11],
  Trevett: [22],
  Trevilians: [46],
  Trevor: [49],
  Trevorton: [39],
  Trexlertown: [39],
  Treynor: [13],
  Trezevant: [43],
  Triadelphia: [50],
  Triangle: [46],
  'Tribes Hill': [35],
  Tribune: [17],
  Tridell: [45],
  Trigg: [-18],
  Trilby: [10],
  Trilla: [15],
  Trimble: [-18, 25, 36, 43],
  Trimont: [24],
  Trinchera: [6],
  Trinidad: [5, 6, 44],
  Trinity: [2, -5, 10, 28, 44, -44],
  'Trinity Center': [5],
  Trinway: [36],
  Trion: [11],
  'Tripler Army Medical Center': [12],
  Triplett: [25],
  Tripoli: [13, 49],
  Tripp: [42, -42],
  Triumph: [15],
  Trivoli: [15],
  Trona: [5],
  'Trophy Club': [44],
  Tropic: [45],
  Trosper: [18],
  Troup: [-11, 44],
  Troupsburg: [35],
  Trousdale: [-43],
  Trout: [19],
  'Trout Creek': [23, 27, 35],
  'Trout Lake': [23, 48],
  'Trout Run': [39],
  Troutdale: [38, 46],
  Troutman: [28],
  Troutville: [39, 46],
  Troxelville: [39],
  Troy: [2, 14, 15, 16, 17, 22, 23, 25, 27, 28, 31, 35, 36, 39, 41, 43, 44, 46, 47, 50],
  'Troy Grove': [15],
  'Troy Mills': [13],
  Truchas: [33],
  Truckee: [5],
  Truesdale: [13, 25],
  Trufant: [23],
  Truman: [24],
  Trumann: [3],
  Trumansburg: [35],
  Trumbauersville: [39],
  Trumbull: [7, 30, -36],
  Truro: [13, 20],
  Trussville: [2],
  'Truth Or Consequences': [33],
  Truxton: [25, 35],
  Tryon: [28, 30, 37],
  Tsaile: [4],
  Tualatin: [38],
  'Tuba City': [4],
  Tubac: [4],
  Tuckahoe: [32, 35],
  Tuckasegee: [28],
  Tucker: [3, 11, -50],
  Tuckerman: [3],
  Tuckerton: [32],
  Tucson: [4],
  Tucumcari: [33],
  Tujunga: [5],
  Tulare: [5, -5, 42],
  Tularosa: [33],
  Tulelake: [5],
  Tuleta: [44],
  Tulia: [44],
  Tullahoma: [43],
  Tullos: [19],
  Tully: [35],
  Tulsa: [37, -37],
  Tuluksak: [1],
  Tumacacori: [4],
  'Tumbling Shoals': [3],
  Tumtum: [48],
  Tumwater: [48],
  Tunas: [25],
  Tunbridge: [47],
  Tunica: [19, 26, -26],
  Tunkhannock: [39],
  Tunnel: [35],
  'Tunnel City': [49],
  'Tunnel Hill': [11],
  Tunnelton: [16, 50],
  Tuntutuliak: [1],
  Tununak: [1],
  Tuolumne: [5, -5],
  Tupelo: [3, 26, 37],
  Tupman: [5],
  'Tupper Lake': [35],
  'Tuppers Plains': [36],
  Turbeville: [41],
  Turbotville: [39],
  Turin: [11, 35],
  Turkey: [28, 44],
  'Turkey City': [39],
  'Turkey Creek': [19],
  Turlock: [5],
  Turner: [3, -11, 22, 23, 27, 38, -42],
  Turners: [25],
  'Turners Falls': [20],
  'Turners Station': [18],
  Turnersburg: [28],
  Turnersville: [32],
  Turnerville: [11],
  Turney: [25],
  Turon: [17],
  Turpin: [37],
  Turrell: [3],
  'Turtle Creek': [39, 50],
  'Turtle Lake': [29, 49],
  Turtlepoint: [39],
  Turtletown: [43],
  Turton: [42],
  Tuscaloosa: [2, -2],
  Tuscarawas: [36, -36],
  Tuscarora: [21, 34, 39],
  Tuscola: [15, -23, 44],
  Tuscumbia: [2, 25],
  Tuskahoma: [37],
  Tuskegee: [2],
  'Tuskegee Institute': [2],
  Tussy: [37],
  Tustin: [5, 23],
  Tuthill: [42],
  'Tutor Key': [18],
  Tuttle: [29, 37],
  Tutwiler: [26],
  Tuxedo: [28],
  'Tuxedo Park': [35],
  Twain: [5],
  'Twain Harte': [5],
  'Twelve Mile': [16],
  'Twentynine Palms': [5],
  Twig: [24],
  Twiggs: [-11],
  Twilight: [50],
  'Twin Bridges': [5, 27],
  'Twin Brooks': [42],
  'Twin City': [11],
  'Twin Falls': [14, -14],
  'Twin Lake': [23],
  'Twin Lakes': [6, 24, 49],
  'Twin Mountain': [31],
  'Twin Oaks': [37],
  'Twin Peaks': [5],
  'Twin Rocks': [39],
  'Twin Valley': [24],
  Twining: [23],
  Twinsburg: [36],
  Twisp: [48],
  'Two Buttes': [6],
  'Two Dot': [27],
  'Two Harbors': [24],
  'Two Rivers': [1, 49],
  'Ty Ty': [11],
  Tyaskin: [21],
  'Tybee Island': [11],
  Tye: [44],
  'Tygh Valley': [38],
  Tyler: [2, 24, 44, -44, -50],
  'Tyler Hill': [39],
  Tylersburg: [39],
  Tylersport: [39],
  Tylerton: [21],
  Tylertown: [26],
  Tynan: [44],
  Tyndall: [42],
  Tyner: [16, 18, 28],
  Tyngsboro: [20],
  Tyonek: [1],
  Tyringham: [20],
  Tyro: [17, 46],
  Tyrone: [11, 33, 35, 37, 39],
  Tyronza: [3],
  Tyrrell: [-28],
  Ubly: [23],
  Ucon: [14],
  Udall: [17, 25],
  Udell: [13],
  Uehling: [30],
  Uhrichsville: [36],
  Uinta: [-51],
  Uintah: [-45],
  Ukiah: [5, 38],
  Uledi: [39],
  Ulen: [24],
  Ullin: [15],
  Ulm: [3, 27],
  Ulman: [25],
  Ulmer: [41],
  Ulster: [-35, 39],
  'Ulster Park': [35],
  Ulysses: [17, 18, 30, 39],
  Umatilla: [10, 38, -38],
  Umbarger: [44],
  Umpire: [3],
  Umpqua: [38],
  Una: [41],
  Unadilla: [11, 30, 35],
  Unalakleet: [1],
  Unalaska: [1],
  Uncasville: [7],
  'Uncle Sam': [19],
  Underhill: [47],
  'Underhill Center': [47],
  Underwood: [13, 16, 24, 29, 48],
  Uneeda: [50],
  Unicoi: [43, -43],
  Union: [-3, -10, -11, 13, -13, 15, -15, -16, 18, -18, -19, 22, 23, 25, 26, -26, -28, 30, 31, 32, -32, -33, -36, 38, -38, -39, 41, -41, -42, -43, 48, 50],
  'Union Bridge': [21],
  'Union Center': [42, 49],
  'Union Church': [26],
  'Union City': [5, 11, 16, 23, 32, 36, 37, 39, 43],
  'Union Dale': [39],
  'Union Furnace': [36],
  'Union Grove': [2, 28, 49],
  'Union Hall': [46],
  'Union Hill': [15, 35],
  'Union Lake': [23],
  'Union Mills': [16, 28],
  'Union Pier': [23],
  'Union Point': [11],
  'Union Springs': [2, 35],
  'Union Star': [18, 25],
  Uniondale: [16, 35],
  Uniontown: [2, 3, 17, 18, 25, 36, 39, 48],
  Unionville: [7, 13, 16, 21, 23, 25, 35, 36, 39, 43, 46],
  'Unionville Center': [36],
  Uniopolis: [36],
  United: [39],
  Unity: [15, 22, 38, 49],
  Unityville: [39],
  Universal: [16],
  'Universal City': [5, 44],
  University: [26],
  'University Center': [23],
  'University Park': [13, 15, 39],
  'University Place': [48],
  Upatoi: [11],
  Upham: [29],
  Upland: [5, 16, 30],
  'Upper Arlington': [36],
  'Upper Black Eddy': [39],
  'Upper Darby': [39],
  'Upper Fairmount': [21],
  'Upper Falls': [21],
  'Upper Jay': [35],
  'Upper Lake': [5],
  'Upper Marlboro': [21],
  'Upper Montclair': [32],
  'Upper Sandusky': [36],
  'Upper Tract': [50],
  Upperco: [21],
  Upperglade: [50],
  Upperstrasburg: [39],
  Upperville: [46],
  Upsala: [24],
  Upshur: [-44, -50],
  Upson: [-11, 49],
  Upton: [18, 20, 35, -44, 51],
  Urania: [19],
  Urbana: [13, 15, 16, 21, 25, 36],
  Urbandale: [13],
  Urbanna: [46],
  Uriah: [2],
  Urich: [25],
  Ursa: [15],
  Ursina: [39],
  'Usaf Academy': [6],
  Usk: [48],
  Utah: [-45],
  Ute: [13],
  'Ute Park': [33],
  Utica: [15, 17, 18, 23, 24, 25, 26, 30, 35, 36, 39, 42],
  Utopia: [44],
  Uvalda: [11],
  Uvalde: [44, -44],
  Uwchland: [39],
  Uxbridge: [20],
  Vacaville: [5],
  Vacherie: [19],
  Vader: [48],
  Vadito: [33],
  Vado: [33],
  Vaiden: [26],
  Vail: [4, 6, 13],
  'Vails Gate': [35],
  'Val Verde': [-44],
  Valatie: [35],
  Valders: [49],
  Valdese: [28],
  Valdez: [1, 33],
  Valdosta: [11],
  Vale: [28, 38, 42],
  Valencia: [5, -33, 39],
  Valentine: [4, 30, 44],
  Valentines: [46],
  Valera: [44],
  Valhalla: [35],
  'Valhermoso Springs': [2],
  Valier: [15, 27, 39],
  'Valle Crucis': [28],
  Vallecito: [5],
  Vallecitos: [33],
  Vallejo: [5],
  'Valles Mines': [25],
  Valley: [2, -14, -27, 30, -30, 48],
  'Valley Bend': [50],
  'Valley Center': [5, 17],
  'Valley City': [29, 36],
  'Valley Cottage': [35],
  'Valley Falls': [17, 35],
  'Valley Farms': [4],
  'Valley Ford': [5],
  'Valley Forge': [39],
  'Valley Grove': [50],
  'Valley Head': [2, 50],
  'Valley Lee': [21],
  'Valley Mills': [44],
  'Valley Park': [25, 26],
  'Valley Spring': [44],
  'Valley Springs': [3, 5, 42],
  'Valley Stream': [35],
  'Valley View': [39, 44],
  'Valley Village': [5],
  Valleyford: [48],
  Valliant: [37],
  Vallonia: [16],
  Valmeyer: [15],
  Valmora: [33],
  Valmy: [34],
  Valparaiso: [10, 16, 30],
  Valrico: [10],
  Valyermo: [5],
  Van: [44, 50],
  'Van Alstyne': [44],
  'Van Buren': [3, -3, -13, 16, 22, -23, 25, 36, -43],
  'Van Buren Point': [35],
  'Van Dyne': [49],
  'Van Etten': [35],
  'Van Horn': [44],
  'Van Horne': [13],
  'Van Hornesville': [35],
  'Van Lear': [18],
  'Van Meter': [13],
  'Van Nuys': [5],
  'Van Orin': [15],
  'Van Tassell': [51],
  'Van Vleck': [44],
  'Van Vleet': [26],
  'Van Voorhis': [39],
  'Van Wert': [13, 36, -36],
  'Van Wyck': [41],
  'Van Zandt': [-44],
  Vance: [2, 26, -28, 41],
  Vanceboro: [22, 28],
  Vanceburg: [18],
  Vancleave: [26],
  Vancleve: [18],
  Vancourt: [44],
  Vancouver: [48],
  Vandalia: [15, 23, 25, 27, 36],
  Vandemere: [28],
  Vanderbilt: [23, 39, 44],
  Vanderburgh: [-16],
  Vandergrift: [39],
  Vanderpool: [44],
  Vandervoort: [3],
  Vanderwagen: [33],
  Vandiver: [2],
  Vanduser: [25],
  Vanleer: [43],
  Vanlue: [36],
  Vanndale: [3],
  Vansant: [46],
  Vantage: [48],
  Vanzant: [25],
  Vardaman: [26],
  Varina: [13],
  Varna: [15],
  Varnado: [19],
  Varnell: [11],
  Varney: [18, 50],
  Varnville: [41],
  Varysburg: [35],
  Vashon: [48],
  Vass: [28],
  Vassalboro: [22],
  Vassar: [17, 23],
  Vaucluse: [41],
  Vaughan: [26, 28],
  Vaughn: [27, 33, 48],
  Vaughnsville: [36],
  Vauxhall: [32],
  Veblen: [42],
  Veedersburg: [16],
  Vega: [44],
  Veguita: [33],
  Velarde: [33],
  Velma: [37],
  Velpen: [16],
  Velva: [29],
  Venango: [30, 39, -39],
  Vendor: [3],
  Venedocia: [36],
  Veneta: [38],
  Venetia: [39],
  Venetie: [1],
  Venice: [5, 10, 15, 19],
  'Ventnor City': [32],
  Ventress: [19],
  Ventura: [5, -5, 13],
  Venus: [10, 39, 44],
  Vera: [37],
  Veradale: [48],
  Verbank: [35],
  Verbena: [2],
  Verden: [37],
  Verdi: [34],
  Verdigre: [30],
  Verdon: [30],
  'Verdugo City': [5],
  Verdunville: [50],
  Vergas: [24],
  Vergennes: [15, 47],
  Veribest: [44],
  Vermilion: [15, -15, -19, 36],
  Vermillion: [-16, 17, 24, 42],
  Vermont: [15],
  Vermontville: [23, 35],
  Vernal: [45],
  Vernalis: [5],
  Verndale: [24],
  Verner: [50],
  Vernon: [2, 4, 6, 7, 10, 15, 16, -19, 23, -25, 32, 35, 44, 45, 47, 49, -49],
  'Vernon Center': [24, 35],
  'Vernon Hill': [46],
  'Vernon Hills': [15],
  Vernonia: [38],
  'Vero Beach': [10],
  Verona: [15, 18, 25, 26, 29, 32, 35, 36, 39, 46, 49],
  'Verona Beach': [35],
  Verplanck: [35],
  Versailles: [7, 15, 16, 18, 25, 35, 36],
  Vershire: [47],
  Vesper: [49],
  Vest: [18],
  Vesta: [24, 46],
  Vestaburg: [23, 39],
  Vestal: [35],
  'Vestavia Hills': [2],
  Vesuvius: [46],
  Veteran: [51],
  Vevay: [16],
  Veyo: [45],
  Vian: [37],
  Viborg: [42],
  Viburnum: [25],
  Vicco: [18],
  Vichy: [25],
  Vici: [37],
  Vickery: [36],
  Vicksburg: [23, 26],
  Victor: [5, 6, 13, 14, 27, 35, 50],
  Victoria: [15, 17, 24, 26, 44, -44, 46],
  Victorville: [5],
  Victory: [49],
  'Victory Mills': [35],
  Vida: [27, 38],
  Vidal: [5],
  Vidalia: [11, 19],
  Vidor: [44],
  Vienna: [11, 15, 21, 22, 25, 32, 36, 42, 46, 50],
  Viewtown: [46],
  Vigo: [-16],
  Viking: [24],
  Vilas: [6, 28, -49],
  'Villa Grande': [5],
  'Villa Grove': [6, 15],
  'Villa Maria': [39],
  'Villa Park': [5, 15],
  'Villa Rica': [11],
  'Villa Ridge': [15, 25],
  Village: [46],
  'Village Mills': [44],
  Villamont: [46],
  Villanova: [39],
  Villanueva: [33],
  Villard: [24],
  Villas: [32],
  'Ville Platte': [19],
  Villisca: [13],
  Vilonia: [3],
  Vina: [2, 5],
  Vinalhaven: [22],
  Vincennes: [16],
  Vincent: [2, 13, 18, 36],
  Vincentown: [32],
  'Vine Grove': [18],
  Vineburg: [5],
  'Vinegar Bend': [2],
  Vineland: [32],
  Vinemont: [2],
  Vineyard: [45],
  'Vineyard Haven': [20],
  Vining: [13, 24],
  Vinita: [37],
  Vinson: [37],
  Vinton: [5, 13, 19, 36, -36, 46],
  Vintondale: [39],
  Viola: [3, 9, 14, 15, 17, 43, 49],
  Violet: [19],
  'Violet Hill': [3],
  Viper: [18],
  Virden: [15],
  Virgie: [18],
  Virgil: [17, 42],
  Virgilina: [46],
  Virgin: [45],
  Virginia: [15, 24, 30],
  'Virginia Beach': [46],
  'Virginia City': [27, 34],
  'Virginia State University': [46],
  Virginville: [39],
  Viroqua: [49],
  Visalia: [5],
  Vista: [5],
  Vivian: [19, 42],
  Voca: [44],
  Volant: [39],
  Volborg: [27],
  Volcano: [5, 12],
  Volga: [13, 42, 50],
  Volin: [42],
  Voltaire: [29],
  Voluntown: [7],
  Volusia: [-10],
  'Von Ormy': [44],
  Vona: [6],
  Vonore: [43],
  Voorhees: [32],
  Voorheesville: [35],
  Voss: [44],
  Vossburg: [26],
  Votaw: [44],
  Vowinckel: [39],
  Vredenburgh: [2],
  Vulcan: [23, 25],
  Waban: [20],
  Wabash: [3, -15, 16, -16],
  Wabasha: [24, -24],
  Wabasso: [10, 24],
  Wabaunsee: [-17],
  Wabbaseka: [3],
  Wabeno: [49],
  Waccabuc: [35],
  Wachapreague: [46],
  Wacissa: [10],
  Waco: [11, 18, 28, 30, 44],
  Waconia: [24],
  Waddell: [4],
  Waddington: [35],
  Waddy: [18],
  Wade: [28],
  Wadena: [13, 24, -24],
  Wadesboro: [28],
  Wadesville: [16],
  'Wading River': [35],
  Wadley: [2, 11],
  'Wadmalaw Island': [41],
  Wadsworth: [15, 34, 36, 44],
  Waelder: [44],
  Wagarville: [2],
  Wagener: [41],
  Waggoner: [15],
  Wagner: [42],
  'Wagon Mound': [33],
  Wagoner: [37, -37],
  Wagontown: [39],
  Wagram: [28],
  Wahiawa: [12],
  Wahkiacus: [48],
  Wahkiakum: [-48],
  Wahkon: [24],
  Wahoo: [30],
  Wahpeton: [29],
  Waialua: [12],
  Waianae: [12],
  Waikoloa: [12],
  Wailea: [12],
  Wailuku: [12],
  Waimanalo: [12],
  Waimea: [12],
  Wainscott: [35],
  Wainwright: [1, 37],
  Waipahu: [12],
  Waite: [22],
  'Waite Park': [24],
  Waiteville: [50],
  Waitsburg: [48],
  Waitsfield: [47],
  Waka: [44],
  Wakarusa: [16, 17],
  Wake: [-28, 46],
  'Wake Forest': [28],
  'Wake Island': [12],
  Wakeeney: [17],
  Wakefield: [17, 19, 20, 23, 30, 36, 40, 46],
  Wakeman: [36],
  Wakita: [37],
  Wakonda: [42],
  Wakpala: [42],
  Wakulla: [-10],
  Walbridge: [36],
  Walburg: [44],
  Walcott: [3, 13, 29, 51],
  Walden: [6, 35],
  Waldenburg: [3],
  Waldo: [3, 10, 17, -22, 36, 49],
  Waldoboro: [22],
  Waldorf: [21, 24],
  Waldport: [38],
  Waldron: [3, 16, 17, 23, 25, 48],
  Waldwick: [32],
  Wales: [1, 20, 29, 45, 49],
  'Wales Center': [35],
  Waleska: [11],
  Walford: [13],
  Walhalla: [23, 29, 41],
  Walhonding: [36],
  Walker: [-2, -11, 13, 17, 18, 19, 24, 25, 42, -44, 50],
  'Walker Valley': [35],
  Walkersville: [21, 50],
  Walkerton: [16, 46],
  Walkertown: [28],
  Walkerville: [23],
  Wall: [42, 44],
  'Wall Lake': [13],
  'Walla Walla': [48, -48],
  Wallace: [5, 14, 16, 17, -17, 23, 28, 30, 41, 42, 50],
  Wallaceton: [39],
  Wallagrass: [22],
  Walland: [43],
  Wallback: [50],
  Wallburg: [28],
  'Walled Lake': [23],
  Waller: [44, -44],
  Walling: [43],
  Wallingford: [7, 13, 18, 39, 47],
  Wallington: [32],
  'Wallins Creek': [18],
  Wallis: [44],
  Wallisville: [44],
  Wallkill: [35],
  'Walloon Lake': [23],
  'Wallops Island': [46],
  Wallowa: [38, -38],
  'Wallpack Center': [32],
  Walls: [26],
  Wallsburg: [45],
  Wallula: [48],
  Walnut: [5, 13, 15, 17, 26],
  'Walnut Bottom': [39],
  'Walnut Cove': [28],
  'Walnut Creek': [5, 36],
  'Walnut Grove': [2, 5, 24, 25, 26],
  'Walnut Hill': [15],
  'Walnut Ridge': [3],
  'Walnut Shade': [25],
  'Walnut Springs': [44],
  Walnutport: [39],
  Walpole: [20, 22, 31],
  Walsenburg: [6],
  Walsh: [6, 15, -29],
  Walshville: [15],
  Walston: [39],
  Walstonburg: [28],
  Walterboro: [41],
  Walters: [37],
  Waltersburg: [39],
  Walterville: [38],
  Walthall: [26, -26],
  Waltham: [20, 24],
  Walthill: [30],
  Walthourville: [11],
  Walton: [-10, -11, 16, 17, 18, 30, 35, 38, 50],
  Waltonville: [15],
  Walworth: [35, -42, 49, -49],
  Wamego: [17],
  Wampsville: [35],
  Wampum: [39],
  Wamsutter: [51],
  Wana: [50],
  Wanakena: [35],
  Wanamingo: [24],
  Wanaque: [32],
  Wanatah: [16],
  Wanblee: [42],
  Wanchese: [28],
  Wanda: [24],
  Waneta: [18],
  Wanette: [37],
  Wann: [37],
  Wannaska: [24],
  Wantagh: [35],
  Wapakoneta: [36],
  Wapanucka: [37],
  Wapato: [48],
  Wapella: [15],
  Wapello: [13, -13],
  Wapiti: [51],
  Wappapello: [25],
  'Wappingers Falls': [35],
  Wapwallopen: [39],
  War: [50],
  Warba: [24],
  Warbranch: [18],
  Ward: [2, 3, 6, -29, 41, -44],
  'Ward Cove': [1],
  Warda: [44],
  Wardell: [25],
  Warden: [48],
  Wardensville: [50],
  Wardner: [14],
  Wardsboro: [47],
  Wardtown: [46],
  Wardville: [37],
  Ware: [-11, 20],
  'Ware Neck': [46],
  'Ware Shoals': [41],
  Wareham: [20],
  Waresboro: [11],
  Waretown: [32],
  Warfield: [18, 46],
  Warfordsburg: [39],
  Waring: [44],
  'Warm Springs': [3, 11, 27, 38, 46],
  Warminster: [39],
  Warne: [28],
  Warner: [31, 37, 42],
  'Warner Robins': [11],
  'Warner Springs': [5],
  Warners: [35],
  Warnerville: [35],
  Warnock: [36],
  Warren: [3, -11, -13, 14, 15, -15, 16, -16, -18, 20, 22, 23, 24, -25, -26, -28, 31, 32, -32, -35, 36, -36, 38, 39, -39, 40, -43, 44, -46, 47],
  'Warren Center': [39],
  Warrendale: [39],
  Warrens: [49],
  Warrensburg: [15, 25, 35],
  Warrensville: [28],
  Warrenton: [11, 25, 28, 38, 44, 46],
  Warrenville: [15, 41],
  Warrick: [-16],
  Warrington: [39],
  Warrior: [2],
  Warriormine: [50],
  'Warriors Mark': [39],
  Warroad: [24],
  Warsaw: [15, 16, 18, 24, 25, 28, 35, 36, 46],
  Wartburg: [43],
  Warthen: [11],
  Wartrace: [43],
  Warwick: [11, 20, 21, 29, 35, 40],
  Wasatch: [-45],
  Wasco: [5, 15, 38, -38],
  Wascott: [49],
  Waseca: [24, -24],
  Washakie: [-51],
  Washburn: [15, 22, 25, 29, 43, 49, -49],
  Washington: [-2, 3, -3, 5, -6, 7, 8, -10, 11, -11, 13, -13, -14, 15, -15, 16, -16, 17, -17, 18, -18, 19, -19, -21, 22, -22, 23, -24, 25, -25, 26, -26, 28, -28, 30, -30, 31, 32, -35, -36, 37, -37, -38, 39, -39, -40, -43, 44, -44, 45, -45, 46, -46, 47, -47, -49, 50],
  'Washington Boro': [39],
  'Washington Court House': [36],
  'Washington Crossing': [39],
  'Washington Depot': [7],
  'Washington Grove': [21],
  'Washington Island': [49],
  'Washington Mills': [35],
  'Washington Navy Yard': [8],
  Washingtonville: [35, 36, 39],
  Washita: [-37],
  Washoe: [-34],
  'Washoe Valley': [34],
  Washougal: [48],
  Washta: [13],
  Washtenaw: [-23],
  Washtucna: [48],
  Wasilla: [1],
  Waskish: [24],
  Waskom: [44],
  Wasola: [25],
  Wassaic: [35],
  Wasta: [42],
  Wataga: [15],
  Watauga: [-28, 42, 43],
  Watchung: [32],
  'Water Mill': [35],
  'Water Valley': [18, 26, 44],
  'Water View': [46],
  Waterboro: [22],
  Waterbury: [7, 30, 47],
  'Waterbury Center': [47],
  Waterfall: [39],
  Waterflow: [33],
  Waterford: [5, 7, 22, 23, 26, 35, 36, 39, 46, 49],
  'Waterford Works': [32],
  Waterloo: [2, 13, 15, 16, 30, 35, 36, 41, 49],
  Waterman: [15],
  Waterport: [35],
  Waterproof: [19],
  Waters: [23],
  Watersmeet: [23],
  Watertown: [7, 20, 24, 35, 36, 42, 43, 49],
  Waterville: [13, 17, 22, 24, 35, 36, 39, 47, 48],
  'Waterville Valley': [31],
  Watervliet: [23, 35],
  'Watford City': [29],
  Wathena: [17],
  Watkins: [6, 13, 24],
  'Watkins Glen': [35],
  Watkinsville: [11],
  Watonga: [37],
  Watonwan: [-24],
  Watrous: [33],
  Watseka: [15],
  Watson: [2, 3, 15, 19, 24, 25, 37],
  Watsontown: [39],
  Watsonville: [5],
  Watton: [23],
  Watts: [37],
  Wattsburg: [39],
  Wattsville: [2, 46],
  Waubay: [42],
  Waubun: [24],
  Wauchula: [10],
  Waucoma: [13],
  Wauconda: [15, 48],
  Waukau: [49],
  Waukee: [13],
  Waukegan: [15],
  Waukena: [5],
  Waukesha: [49, -49],
  Waukomis: [37],
  Waukon: [13],
  Wauna: [48],
  Waunakee: [49],
  Wauneta: [30],
  Waupaca: [49, -49],
  Waupun: [49],
  Wauregan: [7],
  Waurika: [37],
  Wausa: [30],
  Wausau: [10, 49],
  Wausaukee: [49],
  Wauseon: [36],
  Waushara: [-49],
  Wautoma: [49],
  Wauwatosa: [49],
  Wauzeka: [49],
  Waveland: [16, 26],
  Waverley: [20],
  Waverly: [2, 10, 11, 13, 15, 17, 18, 24, 25, 30, 35, 36, 39, 43, 46, 48, 50],
  'Waverly Hall': [11],
  Waves: [28],
  Wawaka: [16],
  Wawarsing: [35],
  Waxahachie: [44],
  Waxhaw: [28],
  Wayan: [14],
  Waycross: [11],
  Wayland: [13, 18, 20, 23, 25, 35, 36],
  Waymart: [39],
  Wayne: [-11, -13, 15, -15, -16, -18, 22, 23, -23, -25, -26, -28, 30, -30, 32, 35, -35, 36, -36, 37, 39, -39, -43, -45, 50, -50],
  'Wayne City': [15],
  Waynesboro: [11, 26, 39, 43, 46],
  Waynesburg: [18, 36, 39],
  Waynesfield: [36],
  Waynesville: [11, 15, 25, 28, 36],
  Waynetown: [16],
  Waynoka: [37],
  Wayside: [26, 44, 50],
  Wayzata: [24],
  Weakley: [-43],
  Weare: [31],
  Weatherby: [25],
  Weatherford: [37, 44],
  Weatherly: [39],
  Weatogue: [7],
  Weaubleau: [25],
  Weaver: [2],
  Weaverville: [5, 28],
  Webb: [2, 13, 26, -44],
  'Webb City': [25],
  Webber: [17],
  'Webbers Falls': [37],
  Webberville: [23],
  Webbville: [18],
  Weber: [-45],
  'Weber City': [46],
  Webster: [10, -11, 13, -13, 16, 18, -18, -19, 20, 24, -25, -26, 28, 29, -30, 35, 39, 42, 44, 49, -50],
  'Webster City': [13],
  'Webster Springs': [50],
  Websterville: [47],
  Wedderburn: [38],
  Wedgefield: [41],
  Wedowee: [2],
  Wedron: [15],
  Weed: [5, 33],
  Weedsport: [35],
  Weedville: [39],
  Weehawken: [32],
  Weeksbury: [18],
  Weems: [46],
  'Weeping Water': [30],
  Weesatche: [44],
  Weidman: [23],
  Weikert: [39],
  Weimar: [5, 44],
  Weiner: [3],
  Weinert: [44],
  Weippe: [14],
  Weir: [17, 26, 44],
  Weirsdale: [10],
  Weirton: [50],
  Weiser: [14],
  Welaka: [10],
  Welch: [24, 37, 44, 50],
  Welches: [38],
  Welcome: [21, 24, 28],
  Weld: [-6, 22],
  Welda: [17],
  Weldon: [5, 13, 15, 28],
  'Weldon Spring': [25],
  Weldona: [6],
  Weleetka: [37],
  Wellborn: [10, 44],
  Wellersburg: [39],
  Wellesley: [20],
  'Wellesley Hills': [20],
  'Wellesley Island': [35],
  Wellfleet: [20, 30],
  Wellford: [41],
  Welling: [37],
  Wellington: [2, 6, 10, 15, 17, 18, 25, 34, 36, 44, 45],
  Wellman: [13, 44],
  Wellpinit: [48],
  Wells: [-16, 22, 23, 24, -29, 34, 35, 44, 47],
  'Wells Bridge': [35],
  'Wells River': [47],
  'Wells Tannery': [39],
  Wellsboro: [39],
  Wellsburg: [13, 35, 50],
  Wellston: [23, 36, 37],
  Wellsville: [17, 25, 35, 36, 39, 45],
  Wellton: [4],
  Welsh: [19],
  Welton: [13],
  Wenatchee: [48],
  Wendel: [5, 39],
  Wendell: [14, 20, 24, 28],
  'Wendell Depot': [20],
  Wenden: [4],
  Wendover: [18, 45],
  Wenham: [20],
  Wenona: [15],
  Wenonah: [32],
  Wentworth: [25, 28, 31, 42],
  Wentzville: [25],
  Weogufka: [2],
  Weott: [5],
  Wernersville: [39],
  Wesco: [25],
  Weskan: [17],
  Weslaco: [44],
  Wesley: [3, 13, 22],
  'Wesley Chapel': [10],
  Wessington: [42],
  'Wessington Springs': [42],
  Wesson: [26],
  West: [26, 44],
  'West Abington Township': [39],
  'West Alexander': [39],
  'West Alexandria': [36],
  'West Alton': [25],
  'West Augusta': [46],
  'West Babylon': [35],
  'West Baden Springs': [16],
  'West Baldwin': [22],
  'West Barnstable': [20],
  'West Baton Rouge': [-19],
  'West Bend': [13, 49],
  'West Berlin': [32],
  'West Bethel': [22],
  'West Blocton': [2],
  'West Bloomfield': [23, 35],
  'West Boothbay Harbor': [22],
  'West Boxford': [20],
  'West Boylston': [20],
  'West Branch': [13, 23],
  'West Bridgewater': [20],
  'West Brookfield': [20],
  'West Brooklyn': [15],
  'West Burke': [47],
  'West Burlington': [13, 35],
  'West Camp': [35],
  'West Carroll': [-19],
  'West Charleston': [47],
  'West Chatham': [20],
  'West Chazy': [35],
  'West Chester': [13, 36, 39],
  'West Chester Township': [36],
  'West Chesterfield': [20, 31],
  'West Chicago': [15],
  'West Clarksville': [35],
  'West College Corner': [16],
  'West Columbia': [41, 44, 50],
  'West Concord': [24],
  'West Cornwall': [7],
  'West Covina': [5],
  'West Coxsackie': [35],
  'West Creek': [32],
  'West Danville': [47],
  'West Davenport': [35],
  'West Decatur': [39],
  'West Dennis': [20],
  'West Des Moines': [13],
  'West Dover': [47],
  'West Dummerston': [47],
  'West Eaton': [35],
  'West Edmeston': [35],
  'West Elizabeth': [39],
  'West Elkton': [36],
  'West End': [28],
  'West Enfield': [22],
  'West Fairlee': [47],
  'West Falls': [35],
  'West Falmouth': [20],
  'West Fargo': [29],
  'West Farmington': [22, 36],
  'West Feliciana': [-19],
  'West Finley': [39],
  'West Fork': [3],
  'West Forks': [22],
  'West Frankfort': [15],
  'West Friendship': [21],
  'West Fulton': [35],
  'West Glacier': [27],
  'West Granby': [7],
  'West Green': [11],
  'West Greene': [2],
  'West Greenwich': [40],
  'West Groton': [20],
  'West Grove': [39],
  'West Halifax': [47],
  'West Hamlin': [50],
  'West Harrison': [16, 35],
  'West Hartford': [7, 47],
  'West Hartland': [7],
  'West Harwich': [20],
  'West Hatfield': [20],
  'West Haven': [7],
  'West Haverstraw': [35],
  'West Helena': [3],
  'West Hempstead': [35],
  'West Henrietta': [35],
  'West Hickory': [39],
  'West Hills': [5],
  'West Hollywood': [5],
  'West Hurley': [35],
  'West Hyannisport': [20],
  'West Islip': [35],
  'West Jefferson': [28, 36],
  'West Jordan': [45],
  'West Kennebunk': [22],
  'West Kill': [35],
  'West Kingston': [40],
  'West Lafayette': [16, 36],
  'West Lawn': [39],
  'West Lebanon': [16, 31, 35, 39],
  'West Leisenring': [39],
  'West Leyden': [35],
  'West Liberty': [13, 15, 18, 36, 50],
  'West Linn': [38],
  'West Long Branch': [32],
  'West Louisville': [18],
  'West Manchester': [36],
  'West Mansfield': [36],
  'West Mclean': [46],
  'West Medford': [20],
  'West Memphis': [3],
  'West Middlesex': [39],
  'West Middleton': [16],
  'West Middletown': [39],
  'West Mifflin': [39],
  'West Milford': [32, 50],
  'West Millbury': [20],
  'West Millgrove': [36],
  'West Milton': [36, 39],
  'West Mineral': [17],
  'West Minot': [22],
  'West Monroe': [19, 35],
  'West Mystic': [7],
  'West New York': [32],
  'West Newbury': [20, 47],
  'West Newfield': [22],
  'West Newton': [16, 20, 39],
  'West Nottingham': [31],
  'West Nyack': [35],
  'West Olive': [23],
  'West Oneonta': [35],
  'West Orange': [32],
  'West Ossipee': [31],
  'West Paducah': [18],
  'West Palm Beach': [10],
  'West Paris': [22],
  'West Park': [35],
  'West Pawlet': [47],
  'West Peterborough': [31],
  'West Pittsburg': [39],
  'West Plains': [25],
  'West Point': [3, 5, 11, 13, 15, 18, 26, 30, 35, 36, 39, 44, 46],
  'West Poland': [22],
  'West Portsmouth': [36],
  'West Richland': [48],
  'West Ridge': [3],
  'West River': [21],
  'West Rockport': [22],
  'West Roxbury': [20],
  'West Rupert': [47],
  'West Rutland': [47],
  'West Sacramento': [5],
  'West Salem': [15, 36, 49],
  'West Salisbury': [39],
  'West Sand Lake': [35],
  'West Sayville': [35],
  'West Seneca': [35],
  'West Shokan': [35],
  'West Simsbury': [7],
  'West Somerset': [18],
  'West Springfield': [20, 39],
  'West Stewartstown': [31],
  'West Stockbridge': [20],
  'West Stockholm': [35],
  'West Suffield': [7],
  'West Sunbury': [39],
  'West Swanzey': [31],
  'West Terre Haute': [16],
  'West Tisbury': [20],
  'West Topsham': [47],
  'West Townsend': [20],
  'West Townshend': [47],
  'West Union': [13, 15, 24, 36, 41, 50],
  'West Unity': [36],
  'West Valley': [35],
  'West Valley City': [45],
  'West Van Lear': [18],
  'West Wardsboro': [47],
  'West Wareham': [20],
  'West Warren': [20],
  'West Warwick': [40],
  'West Wendover': [34],
  'West Winfield': [35],
  'West Yarmouth': [20],
  'West Yellowstone': [27],
  'West York': [15],
  Westboro: [25, 49],
  Westborough: [20],
  Westbrook: [7, 22, 24, 44],
  Westbrookville: [35],
  Westbury: [35],
  Westby: [27, 49],
  Westchester: [15, -35],
  Westcliffe: [6],
  Westdale: [35],
  Westerlo: [35],
  Westerly: [40],
  Western: [30],
  'Western Grove': [3],
  'Western Springs': [15],
  Westernport: [21],
  Westernville: [35],
  Westerville: [30, 36],
  Westfall: [38],
  Westfield: [13, 15, 16, 20, 22, 28, 32, 35, 39, 47, 49],
  'Westfield Center': [36],
  Westfir: [38],
  Westford: [20, 35, 47],
  Westgate: [13],
  Westhampton: [35],
  'Westhampton Beach': [35],
  Westhoff: [44],
  Westhope: [29],
  Westlake: [19, 36, 38, 44],
  'Westlake Village': [5],
  Westland: [23, 39],
  Westley: [5],
  Westminster: [5, 6, 20, 21, 41, 44, 47],
  'Westminster Station': [47],
  Westmont: [15],
  Westmoreland: [17, 31, 35, -39, 43, -46],
  'Westmoreland City': [39],
  Westmorland: [5],
  Weston: [6, 7, 10, 11, 14, 20, 23, 25, 30, 36, 38, 39, 44, 47, 50, 51, -51],
  'Westons Mills': [35],
  Westover: [2, 21, 39],
  Westphalia: [13, 16, 17, 23, 25],
  Westpoint: [16, 43],
  Westport: [5, 7, 16, 18, 20, 35, 39, 42, 43, 48],
  'Westport Point': [20],
  Westside: [13],
  Westtown: [35, 39],
  Westview: [18],
  Westville: [10, 15, 16, 32, 36, 37, 41],
  Westwego: [19],
  Westwood: [5, 20, 32],
  Wethersfield: [7],
  Wetmore: [6, 17, 23],
  Wetumka: [37],
  Wetumpka: [2],
  Wetzel: [-50],
  Wever: [13],
  Wevertown: [35],
  Wewahitchka: [10],
  Wewoka: [37],
  Wexford: [-23, 39],
  Weyanoke: [19],
  Weyauwega: [49],
  Weyerhaeuser: [49],
  'Weyers Cave': [46],
  Weymouth: [20],
  Whaleyville: [21],
  Wharncliffe: [50],
  Wharton: [32, 36, 44, -44, 50],
  'What Cheer': [13],
  Whatcom: [-48],
  Whately: [20],
  Whatley: [2],
  'Wheat Ridge': [6],
  Wheatcroft: [18],
  Wheatfield: [16, 35],
  Wheatland: [5, 13, 16, 25, -27, 29, 37, 39, 51],
  Wheatley: [3],
  Wheaton: [15, 24, 25],
  Wheeler: [-11, 15, 16, 23, 26, -30, 38, -38, 44, -44, 49],
  'Wheeler Army Airfield': [12],
  Wheelersburg: [36],
  Wheeling: [15, 25, 50],
  Wheelock: [44],
  Wheelwright: [18, 20],
  'Whelen Springs': [3],
  Whick: [18],
  Whigham: [11],
  Whippany: [32],
  Whipple: [36],
  Whippleville: [35],
  Whiskeytown: [5],
  Whitakers: [28],
  White: [-3, 11, -11, -15, -16, 39, 42, -43],
  'White Bear Lake': [24],
  'White Bird': [14],
  'White Bluff': [43],
  'White Castle': [19],
  'White City': [17, 38],
  'White Cloud': [17, 23],
  'White Cottage': [36],
  'White Deer': [39, 44],
  'White Earth': [24, 29],
  'White Hall': [3, 15, 21, 46, 50],
  'White Haven': [39],
  'White Heath': [15],
  'White Horse Beach': [20],
  'White House': [43],
  'White Lake': [23, 35, 42, 49],
  'White Marsh': [21, 46],
  'White Mills': [18, 39],
  'White Mountain': [1],
  'White Mountain Lake': [4],
  'White Oak': [11, 28, 44, 50],
  'White Owl': [42],
  'White Pigeon': [23],
  'White Pine': [23, -34, 43],
  'White Plains': [11, 18, 21, 35, 46],
  'White Post': [46],
  'White River': [42],
  'White River Junction': [47],
  'White Rock': [33, 41],
  'White Salmon': [48],
  'White Sands Missile Range': [33],
  'White Springs': [10],
  'White Stone': [41, 46],
  'White Sulphur Springs': [27, 35, 50],
  'White Swan': [48],
  Whiteclay: [30],
  Whiteface: [44],
  Whitefield: [22, 31, 37],
  Whitefish: [27],
  'Whitefish Bay': [49],
  Whiteford: [21],
  Whitehall: [23, 27, 35, 39, 49],
  Whitehorse: [42],
  Whitehouse: [32, 36, 44],
  'Whitehouse Station': [32],
  Whiteland: [16],
  Whitelaw: [49],
  'Whiteman Air Force Base': [25],
  Whiteoak: [25],
  Whiteriver: [4],
  Whiterocks: [45],
  'Whites City': [33],
  'Whites Creek': [43],
  Whitesboro: [32, 35, 37, 44],
  Whitesburg: [11, 18, 43],
  Whiteside: [-15, 25, 43],
  Whitestone: [35],
  Whitestown: [16],
  Whitesville: [18, 35, 50],
  Whitetail: [27],
  Whitethorn: [5],
  Whitetop: [46],
  Whiteville: [28, 43],
  Whitewater: [5, 6, 17, 25, 27, 49],
  Whitewood: [42, 46],
  Whitewright: [44],
  Whitfield: [-11, 26],
  Whitharral: [44],
  Whiting: [13, 16, 17, 22, 32, 47],
  Whitingham: [47],
  Whitinsville: [20],
  Whitlash: [27],
  Whitley: [-16, -18],
  'Whitley City': [18],
  Whitleyville: [43],
  Whitman: [20, 30, -48, 50],
  Whitmer: [50],
  Whitmire: [41],
  Whitmore: [5],
  'Whitmore Lake': [23],
  Whitney: [30, 39, 44],
  'Whitney Point': [35],
  Whitsett: [28, 44],
  Whitt: [44],
  Whittaker: [23],
  Whittemore: [13, 23],
  Whitten: [13],
  Whittier: [1, 5, 28],
  Whittington: [15],
  Whitwell: [43],
  Wibaux: [27, -27],
  Wichita: [17, -17, -44],
  'Wichita Falls': [44],
  Wickatunk: [32],
  Wickenburg: [4],
  Wickes: [3],
  Wickett: [44],
  Wickhaven: [39],
  Wickliffe: [18, 36],
  Wicomico: [-21, 46],
  'Wicomico Church': [46],
  Wiconisco: [39],
  Wideman: [3],
  Widen: [50],
  Widener: [3],
  Widnoon: [39],
  Wiergate: [44],
  Wiggins: [6, 26],
  Wikieup: [4],
  Wilbarger: [-44],
  Wilber: [30],
  Wilberforce: [36],
  Wilbraham: [20],
  Wilbur: [38, 48],
  Wilburn: [3],
  Wilburton: [37, 39],
  Wilcoe: [50],
  Wilcox: [-2, 5, -11, 30, 39],
  'Wild Horse': [6],
  'Wild Rose': [49],
  Wilder: [14, 43, 47],
  Wildersville: [43],
  Wilderville: [38],
  Wildie: [18],
  Wildomar: [5],
  Wildorado: [44],
  Wildrose: [29],
  Wildsville: [19],
  Wildwood: [10, 11, 25, 32, 39],
  Wiley: [6, 11],
  'Wiley Ford': [50],
  Wilkes: [-11, -28],
  'Wilkes Barre': [39],
  Wilkesboro: [28],
  Wilkeson: [48],
  Wilkesville: [36],
  Wilkin: [-23, -24],
  Wilkinson: [-11, 16, -26, 50],
  Will: [-15],
  Willacoochee: [11],
  Willacy: [-44],
  Willamina: [38],
  Willard: [18, 25, 27, 28, 33, 35, 36, 45, 49],
  Willards: [21],
  Willcox: [4],
  Willernie: [24],
  Willet: [35],
  Williams: [4, 5, 13, 16, 24, -29, -36, 38, 41],
  'Williams Bay': [49],
  Williamsburg: [13, 16, 17, 18, 20, 23, 25, 33, 36, 39, -41, 46, 50],
  Williamsfield: [15, 36],
  Williamson: [11, 13, -15, 35, -43, -44, 50],
  Williamsport: [16, 18, 21, 36, 39, 43],
  Williamston: [23, 28, 41],
  Williamstown: [18, 20, 25, 32, 35, 36, 39, 47, 50],
  Williamsville: [15, 25, 35, 46, 47],
  Williford: [3],
  Willimantic: [7],
  Willingboro: [32],
  Willington: [7],
  Willis: [23, 44, 46],
  'Willis Wharf': [46],
  Willisburg: [18],
  Williston: [10, 28, 29, 36, 41, 43, 47],
  'Williston Park': [35],
  Willisville: [3, 15],
  Willits: [5],
  Willmar: [24],
  Willoughby: [36],
  Willow: [1, 35, 37],
  'Willow Beach': [4],
  'Willow City': [29, 44],
  'Willow Creek': [5, 27],
  'Willow Grove': [39],
  'Willow Hill': [15, 39],
  'Willow Island': [30],
  'Willow Lake': [42],
  'Willow Park': [44],
  'Willow River': [24],
  'Willow Spring': [28],
  'Willow Springs': [15, 25],
  'Willow Street': [39],
  'Willow Wood': [36],
  Willowbrook: [15],
  Willows: [5],
  'Wills Point': [44],
  Willsboro: [35],
  Willseyville: [35],
  Willshire: [36],
  Wilmar: [3],
  Wilmer: [2, 44],
  Wilmerding: [39],
  Wilmette: [15],
  Wilmington: [5, 9, 15, 20, 28, 35, 36, 47],
  Wilmont: [24],
  Wilmore: [17, 18, 39],
  Wilmot: [3, 31, 36, 42, 49],
  Wilsall: [27],
  Wilsey: [17],
  Wilseyville: [5],
  Wilson: [3, 17, -17, 19, 23, 28, -28, 35, 37, -43, 44, -44, 49, 51],
  'Wilson Creek': [48],
  Wilsondale: [50],
  Wilsons: [46],
  'Wilsons Mills': [28],
  Wilsonville: [2, 15, 30, 38],
  Wilton: [2, 3, 5, 7, 13, 22, 24, 29, 31, 49],
  'Wilton Manors': [10],
  Wimauma: [10],
  Wimberley: [44],
  Wimbledon: [29],
  Winamac: [16],
  Winburne: [39],
  Winchendon: [20],
  Winchester: [3, 5, 14, 15, 16, 17, 18, 20, 31, 36, 38, 43, 46],
  'Winchester Center': [7],
  'Wind Gap': [39],
  'Wind Ridge': [39],
  Windber: [39],
  Winder: [11],
  Windermere: [10],
  Windfall: [16],
  Windham: [7, -7, 22, 31, 35, 36, -47],
  Windom: [17, 24, 44],
  'Window Rock': [4],
  Windsor: [5, 6, 7, 15, 18, 20, 22, 25, 28, 32, 35, 36, 39, 41, 46, 47, -47, 49],
  'Windsor Heights': [13, 50],
  'Windsor Locks': [7],
  'Windsor Mill': [21],
  Windthorst: [44],
  Windyville: [25],
  Winesburg: [36],
  Winfall: [28],
  Winfield: [2, 13, 15, 17, 25, 39, 43, 44, 50],
  Winfred: [42],
  Wing: [2, 29],
  Wingate: [16, 21, 28, 44],
  Wingdale: [35],
  Winger: [24],
  'Wingett Run': [36],
  Wingina: [46],
  Wingo: [18],
  Winifred: [27],
  Winifrede: [50],
  Winigan: [25],
  Wink: [44],
  Winkelman: [4],
  Winkler: [-44],
  Winlock: [48],
  Winn: [-19, 22, 23],
  Winnabow: [28],
  Winnebago: [-13, 15, -15, 24, 30, 49, -49],
  Winneconne: [49],
  Winnemucca: [34],
  Winner: [42],
  Winneshiek: [-13],
  Winnetka: [5, 15],
  Winnetoon: [30],
  Winnett: [27],
  Winnfield: [19],
  Winnie: [44],
  Winnisquam: [31],
  Winnsboro: [19, 41, 44],
  Winona: [17, -23, 24, -24, 25, 26, 36, 44, 50],
  'Winona Lake': [16],
  Winooski: [47],
  Winside: [30],
  Winslow: [3, 4, 15, 16, 30, 32],
  Winsted: [7, 24],
  Winston: [-2, 11, 25, -26, 27, 33, 38],
  'Winston Salem': [28],
  Winstonville: [26],
  Winter: [49],
  'Winter Beach': [10],
  'Winter Garden': [10],
  'Winter Harbor': [22],
  'Winter Haven': [10],
  'Winter Park': [6, 10],
  'Winter Springs': [10],
  Wintergreen: [46],
  Winterhaven: [5],
  Winterport: [22],
  Winters: [5, 44],
  Winterset: [13],
  Wintersville: [36],
  Winterthur: [9],
  Winterville: [11, 26, 28],
  Winthrop: [3, 13, 20, 22, 24, 35, 48],
  'Winthrop Harbor': [15],
  Winton: [5, 24, 28],
  Wiota: [13],
  Wirt: [24, -50],
  Wirtz: [46],
  Wiscasset: [22],
  'Wisconsin Dells': [49],
  'Wisconsin Rapids': [49],
  Wisdom: [27],
  Wise: [28, -44, 46, -46],
  'Wise River': [27],
  Wiseman: [3],
  Wishek: [29],
  Wishon: [5],
  Wishram: [48],
  Wisner: [19, 30],
  Wister: [37],
  'Witchita Falls': [44],
  Withams: [46],
  Withee: [49],
  Witherbee: [35],
  Witmer: [39],
  Witt: [15],
  Witten: [42],
  Wittenberg: [49],
  Wittensville: [18],
  Witter: [3],
  'Witter Springs': [5],
  Wittman: [21],
  Wittmann: [4],
  'Witts Springs': [3],
  Wixom: [23],
  Woburn: [20],
  Woden: [13, 44],
  'Wofford Heights': [5],
  Wolbach: [30],
  Wolcott: [6, 7, 16, 35, 47],
  Wolcottville: [16],
  Wolf: [51],
  'Wolf Creek': [27, 38],
  'Wolf Island': [25],
  'Wolf Lake': [15, 24],
  'Wolf Point': [27],
  'Wolf Run': [36],
  Wolfcreek: [50],
  Wolfe: [-18, 50],
  'Wolfe City': [44],
  Wolfeboro: [31],
  'Wolfeboro Falls': [31],
  Wolfforth: [44],
  Wolflake: [16],
  Wolford: [29, 46],
  Wolftown: [46],
  Wolsey: [42],
  Wolverine: [23],
  Wolverton: [24],
  Womelsdorf: [39],
  Wonalancet: [31],
  'Wonder Lake': [15],
  Wonewoc: [49],
  Wood: [-36, 39, 42, -44, -49, -50],
  'Wood Dale': [15],
  'Wood Lake': [24, 30],
  'Wood Ridge': [32],
  'Wood River': [15, 30],
  'Wood River Junction': [40],
  Woodacre: [5],
  'Woodberry Forest': [46],
  Woodbine: [11, 13, 17, 18, 21, 32],
  Woodbourne: [35],
  Woodbridge: [5, 7, 32, 46],
  Woodburn: [13, 16, 18, 38],
  Woodbury: [7, 11, -13, 18, 24, 32, 35, 39, 43, 47],
  'Woodbury Heights': [32],
  'Woodcliff Lake': [32],
  Woodford: [-15, -18, 46, 49],
  Woodgate: [35],
  Woodhaven: [35],
  Woodhull: [15, 35],
  Woodinville: [48],
  Woodlake: [5, 44],
  Woodland: [2, 5, 11, 15, 23, 26, 28, 39, 48, 49],
  'Woodland Hills': [5],
  'Woodland Mills': [43],
  'Woodland Park': [6, 32],
  Woodlawn: [15, 43, 44, 46],
  Woodleaf: [28],
  Woodlyn: [39],
  Woodman: [49],
  Woodmere: [35],
  Woodridge: [15, 35],
  Woodrow: [6],
  Woodruff: [-3, 4, 41, 45, 49],
  Woods: [-37],
  'Woods Cross': [45],
  'Woods Cross Roads': [46],
  'Woods Hole': [20],
  Woodsboro: [21, 44],
  Woodsfield: [36],
  Woodside: [9, 35],
  Woodson: [3, 15, -17, 44],
  Woodstock: [2, 7, 11, 15, 21, 24, 31, 35, 36, 46, 47],
  'Woodstock Valley': [7],
  Woodston: [17],
  Woodstown: [32],
  Woodsville: [31],
  Woodville: [2, 10, 20, 26, 36, 44, 46, 49, 50],
  Woodward: [13, 37, -37, 39],
  Woodway: [44],
  Woodworth: [19, 29, 49],
  Woody: [5],
  'Woody Creek': [6],
  Wooldridge: [25],
  Woolford: [21],
  Woolrich: [39],
  Woolstock: [13],
  Woolwich: [22],
  Woolwine: [46],
  Woonsocket: [40, 42],
  Wooster: [3, 36],
  Woosung: [15],
  Wooton: [18],
  Worcester: [20, -20, -21, 35, 39, 47],
  Worden: [15, 27],
  Worland: [51],
  Worley: [14],
  Woronoco: [20],
  Worth: [-11, -13, 15, 25, -25],
  Wortham: [44],
  Worthing: [42],
  Worthington: [13, 16, 18, 20, 24, 25, 36, 39, 50],
  'Worthington Springs': [10],
  Worthville: [18, 39],
  Worton: [21],
  'Wounded Knee': [42],
  Woxall: [39],
  Wrangell: [1, -1],
  Wray: [6, 11],
  Wren: [36],
  Wrens: [11],
  Wrenshall: [24],
  Wrentham: [20],
  Wright: [3, -13, 17, -23, 24, -24, -25, 51],
  'Wright City': [25, 37],
  Wrights: [15],
  Wrightsboro: [44],
  Wrightstown: [32, 49],
  Wrightsville: [3, 11, 39],
  'Wrightsville Beach': [28],
  Wrightwood: [5],
  Wrigley: [18],
  Wurtsboro: [35],
  Wyaconda: [25],
  Wyalusing: [39],
  Wyandanch: [35],
  Wyandot: [-36],
  Wyandotte: [-17, 23, 37],
  Wyanet: [15],
  Wyano: [39],
  Wyarno: [51],
  Wyatt: [16, 25, 50],
  Wyckoff: [32],
  Wyco: [50],
  Wycombe: [39],
  'Wye Mills': [21],
  Wykoff: [24],
  Wylie: [44],
  Wylliesburg: [46],
  Wymore: [30],
  Wynantskill: [35],
  Wyncote: [39],
  Wyndmere: [29],
  Wynnburg: [43],
  Wynne: [3],
  Wynnewood: [37, 39],
  Wynona: [37],
  Wynot: [30],
  Wyocena: [49],
  Wyola: [27],
  Wyoming: [13, 15, 23, 24, 35, -35, 39, -39, 40, 50, -50],
  Wyomissing: [39],
  'Wyomissing Hills': [39],
  Wysox: [39],
  'Wysox Township': [39],
  Wythe: [-46],
  Wytheville: [46],
  Wytopitlock: [22],
  Xenia: [15, 36],
  Yachats: [38],
  Yacolt: [48],
  Yadkin: [-28],
  Yadkinville: [28],
  Yakima: [48, -48],
  Yakutat: [1, -1],
  Yalaha: [10],
  Yale: [13, 15, 23, 37, 42, 46],
  Yalobusha: [-26],
  Yamhill: [38, -38],
  Yampa: [6],
  Yancey: [-28, 44],
  Yanceyville: [28],
  Yankeetown: [10],
  Yankton: [42, -42],
  Yantic: [7],
  Yantis: [44],
  Yaphank: [35],
  Yardley: [39],
  Yarmouth: [13, 22],
  'Yarmouth Port': [20],
  Yarnell: [4],
  Yatahey: [33],
  Yates: [-35],
  'Yates Center': [17],
  'Yates City': [15],
  Yatesboro: [39],
  Yatesville: [11],
  Yavapai: [-4],
  Yawkey: [50],
  Yazoo: [-26],
  'Yazoo City': [26],
  Yeaddiss: [18],
  Yeagertown: [39],
  Yell: [-3],
  'Yellow Jacket': [6],
  'Yellow Medicine': [-23, -24],
  'Yellow Pine': [14],
  'Yellow Spring': [50],
  'Yellow Springs': [36],
  Yellowstone: [-27],
  'Yellowstone National Park': [51],
  Yellville: [3],
  Yelm: [48],
  Yemassee: [41],
  Yeoman: [16],
  Yerington: [34],
  Yerkes: [18],
  Yermo: [5],
  Yeso: [33],
  Yettem: [5],
  Yoakum: [44, -44],
  Yoder: [6, 16, 17, 51],
  Yolo: [5, -5],
  Yolyn: [50],
  Yoncalla: [38],
  Yonkers: [35],
  'Yorba Linda': [5],
  York: [2, 22, -22, 29, 30, -30, 35, 39, -39, 41, -41, -46],
  'York Beach': [22],
  'York Harbor': [22],
  'York Haven': [39],
  'York New Salem': [39],
  'York Springs': [39],
  Yorklyn: [9],
  Yorkshire: [35, 36],
  Yorktown: [3, 13, 16, 44, 46],
  'Yorktown Heights': [35],
  Yorkville: [5, 15, 35, 36, 43],
  Yosemite: [18],
  'Yosemite National Park': [5],
  Young: [4, -44],
  'Young America': [16, 24],
  'Young Harris': [11],
  Youngstown: [10, 35, 36, 39],
  Youngsville: [19, 28, 33, 35, 39],
  Youngtown: [4],
  Youngwood: [39],
  Yountville: [5],
  Ypsilanti: [23, 29],
  Yreka: [5],
  Yuba: [-5],
  'Yuba City': [5],
  Yucaipa: [5],
  Yucca: [4],
  'Yucca Valley': [5],
  Yukon: [25, 37, 39],
  'Yukon-Koyukuk': [-1],
  Yulan: [35],
  Yulee: [10],
  Yuma: [4, -4, 6, -6, 43],
  Yutan: [30],
  Zacata: [46],
  Zachary: [19],
  Zahl: [29],
  Zaleski: [36],
  Zalma: [25],
  Zamora: [5],
  Zanesfield: [36],
  Zanesville: [16, 36],
  Zanoni: [25],
  Zap: [29],
  Zapata: [44, -44],
  Zarephath: [32],
  Zavala: [-44],
  Zavalla: [44],
  Zearing: [13],
  Zebulon: [11, 28],
  Zeeland: [23, 29],
  Zeigler: [15],
  Zelienople: [39],
  Zellwood: [10],
  Zenda: [17, 49],
  Zenia: [5],
  Zephyr: [44],
  'Zephyr Cove': [34],
  Zephyrhills: [10],
  Ziebach: [-42],
  Zieglerville: [39],
  Zillah: [48],
  Zimmerman: [24],
  Zion: [15],
  'Zion Grove': [39],
  Zionhill: [39],
  Zionsville: [16, 39],
  Zionville: [28],
  Zirconia: [28],
  Zoar: [36],
  Zoe: [18],
  'Zolfo Springs': [10],
  Zortman: [27],
  Zullinger: [39],
  'Zumbro Falls': [24],
  Zumbrota: [24],
  Zuni: [33, 46],
  Zurich: [27],
  Zwingle: [13],
  Zwolle: [19]
}
const StatesCodes: string = 'AK,AL,AR,AZ,CA,CO,CT,DC,DE,FL,GA,HI,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VA,VT,WA,WI,WV,WY'

export { PlacesKinds, StatesCodes }
