<template>
  <v-theme-provider light>
    <v-form ref="form">
      <v-overlay :value="isPageLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <div><BannerAccepted v-if="isAcceptedProp && !isPageLoading" :timeout="infinityTimeoutProp" :value="true" shaped color="primary" /></div>
      <base-section class="text-center" space="86">
        <base-section-heading title="Professional Search" color="accent"><v-icon left>mdi-web</v-icon>{{ networkname }}</base-section-heading>
        <v-responsive class="d-flex align-center" height="100%" width="100%">
          <div v-on:input="isFormDirty = true">
            <v-container fluid class="d-flex align-center justify-center search-container">
              <v-container v-if="portraitModeProp" fluid>
                <v-row justify="center" align="center">
                  <v-col cols="10" class="d-flex align-center">
                    <v-select height="56" v-model="professionModel" :items="professions" item-text="name" item-value="id" label="In Profession…" dense hide-details required>
                      <template #append-outer="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs">mdi-menu-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <div class="vertical-spacing"></div>
                <v-row justify="center" align="center">
                  <v-col cols="11" class="d-flex align-center">
                    <v-text-field height="56" v-model.trim="searchCriteria" color="primary" outlined rounded clearable @input="showSearchFailure = false" @click:clear="reset()" label="Search for…" placeholder="a place, someone…" :rules="searchRules" required> </v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <div class="pr-2 pb-8" @click="isSearchInfoDialogVisible = true">
                      <v-icon color="accent">mdi-information</v-icon>
                    </div>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col cols="12" class="d-flex align-end">
                    <v-btn :loading="isTableLoading" block class="position-absolute align-self-center" elevation="0" dark x-large rounded color="primary" @click="submit">
                      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
                        <div style="flex: 1; text-align: center">Search</div>
                        <v-icon large dark color="white">mdi-magnify</v-icon>
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-else fluid>
                <v-row no-gutters align="start" class="my-auto">
                  <v-col cols="4" class="d-flex align-center">
                    <v-select height="56" class="custom-select" ref="selectMenu" outlined v-model="professionModel" placeholder="None" :items="professions" item-text="name" item-value="id" dense hide-details required>
                      <template #prepend-inner>
                        <div class="v-select__prepend-inner-icon">
                          <div class="v-select__label">In Profession…</div>
                        </div>
                      </template>
                      <template #append-outer="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs">mdi-menu-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="d-flex align-center">
                    <v-text-field height="56" v-model.trim="searchCriteria" color="primary" outlined rounded class="connected-text-field" clearable @input="showSearchFailure = false" @click:clear="reset()" label="Search for…" placeholder="a place, someone…" :rules="searchRules" required>
                      <template v-slot:append>
                        <v-btn :loading="isTableLoading" class="position-absolute align-self-center" fab elevation="0" dark small color="primary" @click="submit" style="bottom: 8px; left: 16px; box-shadow: none">
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                    <div class="pl-2" @click="isSearchInfoDialogVisible = true">
                      <v-icon color="accent">mdi-information</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-container>
          </div>

          <v-dialog v-model="suggestionsDialogisVisible" scrollable overlay-color="black" overlay-opacity=".4" transition="dialog-top-transition" closable>
            <v-data-table :key="suggestionsDialogisVisible" :loading="isPageLoading" :hide-default-header="true" :hide-default-footer="!showPaginationProp" :headers="suggestionHeader" :items-per-page="itemsPerTableFiveProp" :page="1" :footer-props="{ itemsPerPageOptions: [5] }" :items="mysuggestionsList" class="elevation-1 row-pointer white" @click:row="suggestionsTableRowAction"></v-data-table>
          </v-dialog>

          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" v-if="showHeader">
                <div class="vertical-spacing"></div>
                <v-divider />
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field v-model="filterCriteria" append-icon="mdi-magnify" clearable label="Filter by" single-line hide-details></v-text-field>
                </v-card-title>
                <v-data-table :loading="isPageLoading" :headers="headerProp" :hide-default-footer="true" :items-per-page="itemsPerTableAllProp" :items="resultsList" class="elevation-1 row-pointer" @click:row="searchResultsTableRowAction" :search="filterCriteria">
                  <template v-slot:header.distance>
                    <div class="d-flex align-center mt-4" style="align-items: flex-end">
                      <v-icon class="primary--text">mdi-map-marker</v-icon>
                    </div>
                  </template>
                  <template v-slot:item.distance="{ item }">
                    <span v-if="portraitModeProp"> <v-icon medium class="primary--text">mdi-map-marker</v-icon></span>
                    <span :class="[portraitModeProp ? '' : 'primary--text']" style="font-weight: bold">{{ item.distance.toFixed(1) }}</span>
                    <span v-if="!portraitModeProp" class="primary--text" style="font-weight: thin"> mi</span>
                  </template>
                  <template v-slot:item.isinmynetwork="{ item }">
                    <v-icon small v-if="item.isinmynetwork" color="accent ">mdi-check</v-icon>
                    <v-icon v-else color="error lighten-1">mdi-web-off</v-icon>
                  </template>
                  <template v-slot:item.sales="{ item }">
                    <v-chip :color="getSalesColor(item.sales)" dark>
                      {{ item.sales }}
                    </v-chip>
                  </template>
                  <template v-slot:item.prepopulated="{ item }">
                    <v-icon v-if="!item.prepopulated" right color="primary">mdi-check-decagram</v-icon>
                  </template>
                  <template v-slot:item.zip="{ item }">
                    <v-chip color="#555" text-color="white" label>
                      {{ item.zip }}
                    </v-chip>
                  </template>
                  <template v-slot:no-results>
                    <v-alert :value="true" type="info">We could not find anything with "{{ filterCriteria }}".</v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-dialog v-model="isSearchInfoDialogVisible" scrollable overlay-color="black" overlay-opacity=".8" transition="dialog-bottom-transition" closable>
                <v-card>
                  <v-card-title>
                    <v-icon left color="accent">mdi-information</v-icon>
                    <v-toolbar-title nowrap>Search for…</v-toolbar-title>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-card elevation="0">
                      <div class="contentcolor">
                        <v-card-text class="text-h6 accent--text font-weight-bold titlecolor">5 DIGITS ZIP CODE</v-card-text>
                        <v-divider />
                        <v-card-text>45678</v-card-text>
                      </div>
                      <v-divider />
                      <div class="contentcolor">
                        <v-card-text class="text-h6 accent--text font-weight-bold titlecolor">CITY</v-card-text>
                        <v-divider />
                        <v-card-text>Ashland</v-card-text>
                      </div>
                      <v-divider />
                      <div class="contentcolor">
                        <v-card-text class="text-h6 accent--text font-weight-bold titlecolor">COUNTY</v-card-text>
                        <v-divider />
                        <v-card-text>Washoe</v-card-text>
                      </div>
                      <v-divider />
                      <!-- <div class="contentcolor">
                        <v-card-text class="text-h6 accent--text font-weight-bold titlecolor">AIRPORT CODE</v-card-text>
                        <v-divider />
                        <v-card-text>JFK</v-card-text>
                      </div> -->
                      <v-divider />
                      <div class="contentcolor">
                        <v-card-text class="text-h6 accent--text font-weight-bold titlecolor">NAME</v-card-text>
                        <v-divider />
                        <v-card-text>Don Yoakum</v-card-text>
                      </div>
                      <br />
                    </v-card>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn dark color="accent" @click="isSearchInfoDialogVisible = false">Got it</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-container>

          <v-footer :fixed="true">
            <v-row justify="center" align="center" class="footer">
              <v-col cols="12" sm="12">
                <v-snackbar loading v-model="showSavedProgress" :timeout="longTimeoutProp">
                  Searching…
                  <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-snackbar>
              </v-col>
              <v-snackbar v-model="showSearchFailure" :timeout="longTimeoutProp" color="accent">
                <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
                  <span class="centered">
                    <v-icon>mdi-information</v-icon><v-spacer />
                    <span>{{ searchFailureErrorMessage }}</span>
                    <v-spacer />
                    <v-btn @click="showSearchFailure = false" text style="display: flex; justify-content: flex-end">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </span>
                </v-container>
              </v-snackbar>
            </v-row>
          </v-footer>
        </v-responsive>
      </base-section>
    </v-form>
  </v-theme-provider>
</template>

<script>
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { AutoComplete } from '@/AutoComplete.ts'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX } from '@/environments/EnvX.ts'
import { RegistrationStage, AlertTimeout, ItemsPerTable } from '@/RegistrationStage.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  components: {
    BannerAccepted: () => import('./BannerAccepted.vue')
  },

  name: 'SearchSection',
  data: () => ({
    filterCriteria: '',
    isFormDirty: false,
    isPageLoading: true,
    isSearchInfoDialogVisible: false,
    isTableLoading: false,
    mysuggestionsList: [],
    networkname: '',
    professionModel: 'profession-realtor', // ID of the default selected profession
    professions: [
      { id: 'profession-realtor', name: 'Real Estate Agent' },
      { id: 'profession-loanofficer', name: 'Residential Lender' }
    ],
    resultsList: [],
    searchFailureErrorMessage: '',
    searchCriteria: '',
    searchRules: [(v) => !!v || 'Search criteria is required'],
    showHeader: false,
    showSearchFailure: false,
    showSavedProgress: false,
    showSavedSuccess: false,
    suggestionHeader: [{ text: '', sortable: false, value: 'item', align: 'start' }],
    suggestionsDialogisVisible: false,
    registrationStage: RegistrationStage.Disabled
  }),

  mounted() {
    setTimeout(this.fetchRegistrationStageThenSearch, this.nullTimeoutProp)
  },

  computed: {
    headerProp() {
      const headers = [
        {
          text: 'Miles',
          value: 'distance'
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'fullname'
        },
        {
          text: 'Annual Clients Served',
          value: 'sales'
        },
        {
          text: 'Profession',
          value: 'profession'
        },

        {
          text: 'Zip',
          value: 'zip'
        },
        {
          text: 'City',
          value: 'city'
        },
        {
          text: 'State',
          value: 'state'
        },
        {
          text: this.networkname, // Dynamically insert the network name
          value: 'isinmynetwork'
        },
        {
          text: '',
          value: 'prepopulated'
        }
      ]

      return headers
    },

    infinityTimeoutProp() {
      return AlertTimeout.infinity
    },

    isAcceptedProp() {
      return this.registrationStage === RegistrationStage.Accepted
    },

    itemsPerTableAllProp() {
      return ItemsPerTable.all
    },

    itemsPerTableFiveProp() {
      return ItemsPerTable.five
    },

    longTimeoutProp() {
      return AlertTimeout.long
    },

    nullTimeoutProp() {
      return AlertTimeout.null
    },

    portraitModeProp() {
      return this.$vuetify.breakpoint.xsOnly
    },

    searchCriteriaUrlProp: {
      get: function () {
        return new URLSearchParams(window.location.search).get('search')
      },
      set: function (search) {
        this.$router.replace({ query: { ...this.$route.query, search } })
      }
    },

    showPaginationProp() {
      return this.mysuggestionsList.length > this.itemsPerTableFiveProp
    }
  },

  methods: {
    checkFormValidity() {
      return this.searchCriteria && this.searchCriteria.trim() !== ''
    },

    searchResultsTableRowAction(person) {
      this.$router.push({
        name: 'profilereadonlyroute',
        params: {
          person
        }
      })
    },

    suggestionsTableRowAction(selectedCriteria) {
      this.searchCriteria = selectedCriteria.item
      this.searchAction()
    },

    fetchRegistrationStageThenSearch() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🔎 SearchSection.fetchRegistrationStageThenSearch', // log hint
        endpoints.myprofileEndpoint, // endpoint
        environmentVariables.MY_PROFILE_KEY, // code
        {}, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.isPageLoading = true

          return true
        },

        // onSuccess
        (data) => {
          this.isPageLoading = false
          this.registrationStage = data.profile.registrationstage
          this.networkname = data.profile.networkname

          // this.searchCriteria = new URLSearchParams(window.location.search).get('search')
          EnvX.log('search parameters', this.searchCriteriaUrlProp)
          if (this.searchCriteriaUrlProp) {
            this.searchAction()
          }
        },

        // All sort of errors
        (error, _unusedDescription) => {
          this.isPageLoading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          }
        }
      )
    },

    getSalesColor(sales) {
      if (sales < 5) return '#DDD'
      if (sales < 10) return '#BCC5CC'
      if (sales < 20) return '#9BADBB'
      if (sales < 40) return '#7B96AA'
      if (sales < 60) return '#5A7E99'
      if (sales < 80) return '#396688'
      return '#184e77'
    },

    getMemberIcon() {
      return 'mdi-check-decagram'
    },

    reset() {
      this.isFormDirty = false
    },

    searchAction() {
      if (this.searchCriteria) {
        this.$router.push({ path: '/search', query: { search: this.searchCriteria } })
      } else {
        this.searchCriteria = this.searchCriteriaUrlProp
      }
      let searchCriteria = this.searchCriteria
      const engine = new AutoComplete()
      const suggestionsList = engine.Suggestions(searchCriteria)
      this.mysuggestionsList = []
      this.suggestionsDialogisVisible = false

      switch (suggestionsList.length) {
        case 0:
          // do nothing
          break
        case 1:
          searchCriteria = suggestionsList[0]
          break
        default:
          this.mysuggestionsList = suggestionsList.map((item) => ({ item }))
          this.suggestionsDialogisVisible = true
          return
      }

      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🔦 SearchSection.searchAction', // log hint
        endpoints.usersSearchEndpoint, // endpoint
        environmentVariables.USERS_SEARCH_KEY, // code
        { criteria: searchCriteria, professioncode: this.professionModel }, // post body with criteria & professioncode

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          const validated = this.checkFormValidity() // this.$refs.form.validate() does not work navigating back to the page
          this.isTableLoading = validated
          this.showSavedProgress = !validated
          this.resultsList = []
          this.showHeader = false
          this.savedFailureErrorMessage = ''
          this.showSavedFailure = false
          if (validated) {
            this.searchFailureErrorMessage = ''
            this.showSearchFailure = false
          } else {
            this.showSearchFailure = true
            this.searchFailureErrorMessage = 'Search criteria is required'
          }
          return validated
        },

        // onSuccess
        (data) => {
          this.isFormDirty = false
          this.isTableLoading = false
          this.resultsList = data.list
          this.showHeader = true
          this.showSavedProgress = false
        },

        // All sort of errors
        (error, errorDescription) => {
          this.showSavedProgress = false
          this.isTableLoading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showSearchFailure = true
            this.searchFailureErrorMessage = errorDescription
          }
        }
      )
    },

    showLoggedOutBanner() {
      // MustLoginSection should already have a modal
    },

    submit() {
      if (this.checkFormValidity() && !this.isTableLoading) {
        this.searchAction()
      } else {
        this.resultsList = []
        this.showHeader = false
        this.showSearchFailure = true
        this.searchFailureErrorMessage = 'Search criteria is required'
      }
    }
  }
}
</script>

<style scoped>
.centered {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.connected-text-field {
  height: 56px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  position: relative;
}

.custom-select :deep() fieldset {
  border-color: #42a5f6;
}

.custom-select {
  height: 56px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  position: relative;
  left: 1px;
  border-color: #42a5f6;
  border-width: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.row-pointer:hover {
  cursor: pointer;
}

.search-container {
  max-width: 100%;
  padding: 16px;
  margin: 0 auto;
}

.search-info-dialog-background {
  background-color: #fefefe;
}

.shadow-box {
  position: relative;
  top: 8px;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.titlecolor {
  background-color: #fcfcfc;
}

.v-text-field--outlined:not(.v-select) :deep() fieldset {
  border-color: #42a5f6;
  border-width: 1px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.vertical-spacing {
  height: 52px;
}

.v-select__label {
  margin-left: 8px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 2px;
  position: absolute;
  background-color: #fff;
  top: -8px;
  font-size: 12px;
  color: #42a5f6;
  z-index: 1;
}
</style>
